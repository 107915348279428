import React, { useState } from "react";
import { Modal, Table, Button, Spinner } from "react-bootstrap";
import { FaCheckCircle, FaExclamationCircle } from "react-icons/fa";
import {
  getSimWatchdogs,
  removeSimWatchdog,
} from "../../../../redux/slices/watchdogs";
import { useDispatch, useSelector } from "react-redux";

const RemoveWatchdogModal = ({
  watchdog,
  showRemoveWatchdogModal,
  setShowRemoveWatchdogModal,
  imsi,
}) => {
  const handleClose = () => setShowRemoveWatchdogModal(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [getStatus, setRequestStatus] = useState("");
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);

  return (
    <Modal
      show={showRemoveWatchdogModal}
      backdrop="static"
      centered
      onHide={handleClose}
    >
      <Modal.Header>Remove SIM Watchdog</Modal.Header>
      <Modal.Body>
        {currentStep === 1 && watchdog && (
          <Table responsive striped>
            <thead>
              <th>IMSI</th>
              <th>Type</th>
              <th>Amount</th>
              <th>Service</th>
            </thead>
            <tbody>
              <tr>
                <td>{imsi}</td>
                <td>
                  {watchdog.watchdogType.includes("externalservice")
                    ? "Live Usage"
                    : "Rated Usage"}
                </td>
                <td>{watchdog.amount}</td>
                <td>{watchdog.service}</td>
              </tr>
            </tbody>
          </Table>
        )}
        {currentStep === 2 && (
          <div className="text-center">
            <Spinner
              animation="border"
              variant="primary"
              className="mt-5 mb-3"
              style={{ width: "5rem", height: "5rem" }}
            />
            <h3>Waiting for completion</h3>
          </div>
        )}
        {currentStep === 3 && getStatus === "Success" && (
          <div className="text-center">
            <FaCheckCircle size={120} className="mt-3" color="#4BBF73" />
            <h3 className="mt-3">Order Complete</h3>
          </div>
        )}
        {currentStep === 3 && getStatus === "Error" && (
          <div className="text-center">
            <FaExclamationCircle size={120} className="mt-3" color="#FF9494" />
            <h3 className="mt-3">Order Error</h3>
            <p>Please contact the administrator.</p>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        {currentStep === 1 && (
          <>
            <Button
              style={{
                "--dynamic-bg-color": !impersonationUser.id
                  ? user.backgroundColor
                  : impersonationUser.backgroundColor,
                "--dynamic-font-color": !impersonationUser.id
                  ? user.backgroundColor
                  : impersonationUser.backgroundColor,
                border: "none",
              }}
              onClick={() => {
                setShowRemoveWatchdogModal(false);
              }}
            >
              Cancel
            </Button>
            <Button
              style={{
                "--dynamic-bg-color": !impersonationUser.id
                  ? user.backgroundColor
                  : impersonationUser.backgroundColor,
                "--dynamic-font-color": !impersonationUser.id
                  ? user.backgroundColor
                  : impersonationUser.backgroundColor,
                border: "none",
              }}
              onClick={async () => {
                setCurrentStep(2);
                if (!impersonationUser.id) {
                  await removeSimWatchdog(user.id, watchdog.id, imsi)
                    .then((response) => {
                      setRequestStatus("Success");
                      setCurrentStep(3);
                    })
                    .catch((error) => {
                      setRequestStatus("Error");
                      setCurrentStep(3);
                    });
                } else {
                  await removeSimWatchdog(
                    impersonationUser.id,
                    watchdog.id,
                    imsi
                  )
                    .then((response) => {
                      setRequestStatus("Success");
                      setCurrentStep(3);
                    })
                    .catch((error) => {
                      setRequestStatus("Error");
                      setCurrentStep(3);
                    });
                }
              }}
            >
              Remove
            </Button>
          </>
        )}
        {currentStep === 3 && (
          <Button
            style={{
              "--dynamic-bg-color": !impersonationUser.id
                ? user.backgroundColor
                : impersonationUser.backgroundColor,
              "--dynamic-font-color": !impersonationUser.id
                ? user.backgroundColor
                : impersonationUser.backgroundColor,
              border: "none",
            }}
            onClick={async () => {
              if (!impersonationUser.id) {
                await dispatch(getSimWatchdogs(user.id, imsi));
              } else {
                await dispatch(getSimWatchdogs(impersonationUser.id, imsi));
              }

              setShowRemoveWatchdogModal(false);
            }}
          >
            Close
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default RemoveWatchdogModal;
