import React, { useState, useContext, useEffect } from "react";
import { Card, Form, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import NotyfContext from "../../../contexts/NotyfContext";
import axios from "../../../utils/axios";
import TwoFactorConfigModal from "./modals/TwoFactorConfigModal";
import { updateCurrentUserIs2FAEnabled } from "../../../redux/slices/user";

function Privacy() {
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const [setIsClicked] = useState(false);
  const [showConfigure2FAAuthentication, setShowConfigure2FAAuthentication] =
    useState(false);
  const [qrCodeUrl, setQrCodeUrl] = useState("");
  const notyf = useContext(NotyfContext);
  const [isSwitchEnabled, setIsSwitchEnabled] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user.is2FAEnabled === "false") {
      setIsSwitchEnabled(false);
    } else {
      setIsSwitchEnabled(true);
    }
  }, [user.is2FAEnabled]);

  const handle2FASwitchChange = async () => {
    try {
      const id = impersonationUser.id ? impersonationUser.id : user.id;
      const accessToken = window.localStorage.getItem("accessToken");
      const response = await axios.get(
        `https://dev.pos2cloud.com:5000/api/2fa/${id}/generate`,
        {
          headers: {
            accessToken: `Bearer ${accessToken}`,
          },
        }
      );
      setQrCodeUrl(response.data);
      setShowConfigure2FAAuthentication(true);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        setIsSwitchEnabled(false);
        notyf.open({
          type: "danger",
          message: "Unauthorized. Sign in again",
          duration: 5000,
          ripple: true,
          dismissible: false,
          position: {
            x: "center",
            y: "top",
          },
        });
      }
    }
  };

  const handleCancel2FA = async (id) => {
    const accessToken = window.localStorage.getItem("accessToken");
    await axios
      .delete(`https://dev.pos2cloud.com:5000/api/2fa/${id}/disable`, {
        headers: {
          accessToken: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        dispatch(updateCurrentUserIs2FAEnabled("false"));
        notyf.open({
          type: "success",
          message: "2FA Disabled Successfully",
          duration: 5000,
          ripple: true,
          dismissible: false,
          position: {
            x: "center",
            y: "top",
          },
        });
        setShowConfigure2FAAuthentication(false);
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          notyf.open({
            type: "danger",
            message: "Unauthorized",
            duration: 5000,
            ripple: true,
            dismissible: false,
            position: {
              x: "center",
              y: "top",
            },
          });
          dispatch(updateCurrentUserIs2FAEnabled("false"));
        }
      });
  };

  return (
    <Card>
      <Card.Header>
        <Card.Title className="mb-0">Privacy & Safety</Card.Title>
      </Card.Header>
      <Card.Body>
        <Card.Header>
          <Card.Title className="mb-3">Passwords</Card.Title>
        </Card.Header>
        <Form>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={2} className="text-sm-right">
              Current Password:
            </Form.Label>
            <Col sm={10}>
              <Form.Control type="password" name="password" className="mb-2" />
              <Link to="/auth/reset-password">Forgot password?</Link>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={2} className="text-sm-right">
              New Password:
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                type="password"
                name="password"
                placeholder="Password"
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={2} className="text-sm-right">
              Confirm Password:
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                type="password"
                name="password"
                placeholder="Password"
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={2} className="text-sm-right">
              Enable 2FA Auth:
            </Form.Label>
            <Col sm={10}>
              <Form.Check
                type="switch"
                id="2faEnabledswitch"
                name="2faEnabledSwitch"
                checked={isSwitchEnabled}
                onChange={() => {
                  if (isSwitchEnabled) {
                    handleCancel2FA(user.id);
                  } else {
                    handle2FASwitchChange();
                  }
                }}
              />
            </Col>
          </Form.Group>
          <Form.Group className="mb-3">
            <Button
              style={{
                "--dynamic-bg-color": !impersonationUser.id
                  ? user.backgroundColor
                  : impersonationUser.backgroundColor,
                border: "none",
              }}
              className="me-1 mb-1 float-end"
              onClick={() => setIsClicked(true)}
            >
              Submit
            </Button>
          </Form.Group>
        </Form>
        <TwoFactorConfigModal
          showConfigure2FAAuthentication={showConfigure2FAAuthentication}
          setShowConfigure2FAAuthentication={setShowConfigure2FAAuthentication}
          qrCodeUrl={qrCodeUrl}
          user={impersonationUser.id ? impersonationUser : user}
          handleCancel2FA={handleCancel2FA}
        />
      </Card.Body>
    </Card>
  );
}

export default Privacy;
