import React, { useMemo } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import CountrySelectContext from "../../../../utils/CountrySelectContext";
import countryList from "react-select-country-list";
import { useSelector } from "react-redux";

const Basic = ({
  handleBlur,
  handleChange,
  values,
  errors,
  touched,
  entityType,
  image,
  inputRef,
  handleDisplayFileDetails,
  handleUpload,
  setFieldValue,
}) => {
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const countryOptions = useMemo(() => {
    const options = countryList().getData();
    return [{ label: "Select a country", value: "" }, ...options];
  }, []);

  if (entityType !== "Subaccount") {
    return (
      <>
        <Row>
          <Col md={8}>
            <Form.Group className="mb-4">
              <Form.Label>Full name</Form.Label>
              <Form.Control
                type="text"
                name="fullName"
                value={values.fullName}
                isInvalid={Boolean(touched.fullName && errors.fullName)}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {!!touched.fullName && (
                <Form.Control.Feedback type="invalid">
                  {errors.fullName}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Label>Portfolio ID</Form.Label>
              <Form.Control
                type="text"
                name="portfolioId"
                value={values.portfolioId}
                isInvalid={Boolean(touched.portfolioId && errors.portfolioId)}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {!!touched.portfolioId && (
                <Form.Control.Feedback type="invalid">
                  {errors.portfolioId}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Col>
          <Col md="4">
            <div className="text-center">
              <img
                alt=""
                src={image}
                className="rounded-circle img-responsive mt-2"
                width="100"
                height="100"
              />
              <div className="mt-2">
                <Form.Group>
                  <Form.Control
                    ref={inputRef}
                    name="image"
                    onChange={(event) => {
                      if (!event.target.files[0]) {
                        return;
                      }
                      handleDisplayFileDetails(setFieldValue);
                      setFieldValue("photo", event.target.files[0]);
                    }}
                    type="file"
                    id="upload-image"
                    style={{ display: "none" }}
                    isInvalid={!!errors.photo && touched.photo}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.photo}
                  </Form.Control.Feedback>
                  <Button
                    variant="primary"
                    onClick={handleUpload}
                    style={{
                      "--dynamic-bg-color": !impersonationUser.id
                        ? user.backgroundColor
                        : impersonationUser.backgroundColor,
                      border: "none",
                    }}
                  >
                    <FontAwesomeIcon icon={faUpload} /> Upload
                  </Button>
                </Form.Group>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Form.Group className="mb-4">
              <Form.Label>Address</Form.Label>
              <Form.Control
                type="text"
                name="address"
                value={values.address}
                isInvalid={Boolean(touched.address && errors.address)}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {!!touched.address && (
                <Form.Control.Feedback type="invalid">
                  {errors.address}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={4}>
            <Form.Group className="mb-4">
              <Form.Label>City</Form.Label>
              <Form.Control
                type="text"
                name="city"
                value={values.city}
                isInvalid={Boolean(touched.city && errors.city)}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {!!touched.city && (
                <Form.Control.Feedback type="invalid">
                  {errors.city}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group className="mb-4">
              <Form.Label>Country</Form.Label>
              <CountrySelectContext.Provider value={countryOptions}>
                <CountrySelectContext.Consumer>
                  {(countryOptions) => (
                    <Form.Select
                      value={values.country}
                      onChange={handleChange}
                      name="country"
                      isInvalid={Boolean(touched.country && errors.country)}
                    >
                      {countryOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>
                  )}
                </CountrySelectContext.Consumer>
              </CountrySelectContext.Provider>
              {!!touched.country && (
                <Form.Control.Feedback type="invalid">
                  {errors.country}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group className="mb-4">
              <Form.Label>Postcode</Form.Label>
              <Form.Control
                type="text"
                name="postcode"
                value={values.postcode}
                isInvalid={Boolean(touched.postcode && errors.postcode)}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {!!touched.postcode && (
                <Form.Control.Feedback type="invalid">
                  {errors.postcode}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Col>
        </Row>
      </>
    );
  } else {
    return (
      <>
        <Row>
          <Col md={8}>
            <Form.Group className="mb-4">
              <Form.Label>Full name</Form.Label>
              <Form.Control
                type="text"
                name="fullName"
                value={values.fullName}
                isInvalid={Boolean(touched.fullName && errors.fullName)}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {!!touched.fullName && (
                <Form.Control.Feedback type="invalid">
                  {errors.fullName}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Label>Address</Form.Label>
              <Form.Control
                type="text"
                name="address"
                value={values.address}
                isInvalid={Boolean(touched.address && errors.address)}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {!!touched.address && (
                <Form.Control.Feedback type="invalid">
                  {errors.address}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Col>
          <Col md="4">
            <div className="text-center">
              <img
                alt=""
                src={image}
                className="rounded-circle img-responsive mt-2"
                width="100"
                height="100"
              />
              <div className="mt-2">
                <input
                  className="form-control"
                  ref={inputRef}
                  name="image"
                  onChange={(event) => {
                    handleDisplayFileDetails();
                    setFieldValue("photo", event.target.files[0]);
                  }}
                  type="file"
                  id="upload-image"
                  style={{ display: "none" }}
                />
                <Button
                  variant="primary"
                  onClick={handleUpload}
                  style={{
                    "--dynamic-bg-color": !impersonationUser.id
                      ? user.backgroundColor
                      : impersonationUser.backgroundColor,
                    border: "none",
                  }}
                >
                  <FontAwesomeIcon icon={faUpload} /> Upload
                </Button>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <Form.Group className="mb-4">
              <Form.Label>City</Form.Label>
              <Form.Control
                type="text"
                name="city"
                value={values.city}
                isInvalid={Boolean(touched.city && errors.city)}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {!!touched.city && (
                <Form.Control.Feedback type="invalid">
                  {errors.city}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group className="mb-4">
              <Form.Label>Country</Form.Label>
              <CountrySelectContext.Provider value={countryOptions}>
                <CountrySelectContext.Consumer>
                  {(countryOptions) => (
                    <Form.Select
                      value={values.country}
                      onChange={handleChange}
                      name="country"
                      isInvalid={Boolean(touched.country && errors.country)}
                    >
                      {countryOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>
                  )}
                </CountrySelectContext.Consumer>
              </CountrySelectContext.Provider>
              {!!touched.country && (
                <Form.Control.Feedback type="invalid">
                  {errors.country}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group className="mb-4">
              <Form.Label>Postcode</Form.Label>
              <Form.Control
                type="text"
                name="postcode"
                value={values.postcode}
                isInvalid={Boolean(touched.postcode && errors.postcode)}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {!!touched.postcode && (
                <Form.Control.Feedback type="invalid">
                  {errors.postcode}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Col>
        </Row>
      </>
    );
  }
};

export default Basic;
