import React from "react";
import { Card } from "react-bootstrap";
import StatementsTable from "./StatementsTable";
import statementsColumns from "../../inputData/statementsData";

const StatementsList = (props) => {
  return (
    <Card>
      <Card.Body>
        <StatementsTable data={props.data} columns={statementsColumns} />
      </Card.Body>
    </Card>
  );
};

export default StatementsList;
