const statementsColumns = [
  {
    Header: "Cut Date",
    accessor: "date",
    sortable: true,
  },
  {
    Header: "Description",
    accessor: "description",
    sortable: true,
  },
  {
    Header: "Amount (Incl. VAT)",
    accessor: "amount",
    Cell: ({ row: { original } }) => <>{original.amount}€</>,
  },
  {
    Header: "Balance",
    accessor: "balance",
    Cell: ({ row: { original } }) => <>{original.balance}€</>,
    sortable: true,
  },
];

export default statementsColumns;
