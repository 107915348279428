import React from "react";
import { Card } from "react-bootstrap";
import { offerColumns } from "../../inputData/offerData";
import { Container, Row, Col, Table, Pagination, Form } from "react-bootstrap";
import {
  defaultColumn,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import { FaSortUp, FaSortDown, FaSort } from "react-icons/fa";
import { useTranslation } from "react-i18next";

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  }
);

const RowSelectingTable = ({ columns: offerColumns, data }) => {
  const { t } = useTranslation();

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    prepareRow,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns: offerColumns,
      data,
      initialState: { pageIndex: 0 },
      defaultColumn,
    },
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: "select",
          sortable: false,
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ]);
    }
  );
  return (
    <Card>
      <Card.Body>
        <Table id="billingProductsTable" hover responsive {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    <span {...column.getSortByToggleProps()}>
                      {column.render("Header")}
                      <span>
                        {column.sortable ? (
                          column.isSorted ? (
                            column.isSortedDesc ? (
                              <FaSortUp className="ms-2 " />
                            ) : (
                              <FaSortDown className="ms-2 " />
                            )
                          ) : (
                            <FaSort className="ms-2" />
                          )
                        ) : null}
                      </span>
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
        <Row>
          <Col md="8">
            <span className="mx-2">
              {t("Page")}{" "}
              <strong>
                {pageIndex + 1} {t("of")} {pageOptions.length}
              </strong>
            </span>
            <span className="ms-3 me-2">{t("Show")}:</span>
            <Form.Select
              className="d-inline-block w-auto"
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
            >
              {[5, 10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </Form.Select>

            <span className="ms-3 me-2">{t("Go to page")}:</span>
            <Form.Control
              className="d-inline-block"
              type="number"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
              style={{ width: "75px" }}
            />
          </Col>
          <Col md="4">
            <Pagination className="float-end">
              <Pagination.First
                onClick={() => {
                  gotoPage(0);
                }}
                disabled={!canPreviousPage}
              />
              <Pagination.Prev
                onClick={() => {
                  previousPage();
                }}
                disabled={!canPreviousPage}
              />
              <Pagination.Next
                onClick={() => {
                  nextPage();
                }}
                disabled={!canNextPage}
              />
              <Pagination.Last
                onClick={() => {
                  gotoPage(pageCount - 1);
                }}
                disabled={!canNextPage}
              />
            </Pagination>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

const RowExpanding = (props) => {
  return (
    <React.Fragment>
      <Container fluid className="p-0">
        <RowSelectingTable columns={offerColumns} data={props.data} />
      </Container>
    </React.Fragment>
  );
};

const OffersList = (props) => {
  return (
    <Card>
      <Card.Body>
        <RowExpanding data={props.data} />
      </Card.Body>
    </Card>
  );
};

export default OffersList;
