import React from "react";
import { Card, Col, Form, Button, Row } from "react-bootstrap";
import { useSelector } from "react-redux";

const Notifications = () => {
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  return (
    <Card>
      <Card.Header>
        <Card.Title className="mb-0">Notifications</Card.Title>
      </Card.Header>
      <Card.Body>
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm={2} className="text-sm-right">
            Receive Notifications:
          </Form.Label>
          <Col sm={10}>
            <div className="custom-controls-stacked">
              <Form.Check
                type="checkbox"
                id="radio1"
                name="customRadio"
                label="SMS"
                className="mb-2"
              />
              <Form.Check
                type="checkbox"
                id="radio2"
                name="customRadio"
                label="E-mail"
                className="mb-2"
              />
            </div>
          </Col>
        </Form.Group>
        <Form.Label htmlFor="#weeklyAnalytics" className="font-weight-bold">
          Weekly Analytics
        </Form.Label>
        <Form.Group as={Row} className="mb-3 ml-3">
          <Col sm={10}>
            <Form.Check
              type="switch"
              id="weeklyAnalytics"
              name="customSwitch"
              label="Check if you want to receive Weekly Analytics"
            />
          </Col>
        </Form.Group>
        <Form.Label htmlFor="#notifyAlerts" className="font-weight-bold">
          Notify About New Alerts
        </Form.Label>
        <Form.Group as={Row} className="mb-3 ml-3">
          <Col sm={10}>
            <Form.Check
              type="switch"
              id="notifyAlerts"
              name="customSwitch"
              label="Check if you want to receive notifications when there are new alerts"
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3">
          <Col sm={{ size: 10, offset: 2 }}>
            <Button
              style={{
                "--dynamic-bg-color": !impersonationUser.id
                  ? user.backgroundColor
                  : impersonationUser.backgroundColor,
                border: "none",
              }}
              id="generalSettingsSubmit"
            >
              Submit
            </Button>
          </Col>
        </Form.Group>
      </Card.Body>
    </Card>
  );
};

export default Notifications;
