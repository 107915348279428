import axios from "../../utils/axios";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  reports: [],
};

const reportsSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    setReports(state, action) {
      state.reports = action.payload;
    },
  },
});

export function getUsageReport(
  id,
  usageType,
  groupByField,
  groupByPeriod,
  usageStartDate,
  usageEndDate,
  customerId,
  simType,
  simSearchCriteriaType,
  imsi,
  startImsi,
  endImsi,
  iccid
) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const params = {
      usageType: usageType,
      groupByField: groupByField,
      groupByPeriod: groupByPeriod,
      usageStartDate: usageStartDate,
      usageEndDate: usageEndDate,
      customerId: customerId,
      simType: simType,
      simSearchCriteria: simSearchCriteriaType,
      imsi: imsi,
      startImsi: startImsi,
      endImsi: endImsi,
      iccid: iccid,
    };
    try {
      const response = await axios.get(
        `https://dev.pos2cloud.com:5000/api/reports/${id}/fetchUsageReport`,
        {
          headers: {
            accessToken: `Bearer ${accessToken}`,
          },
          params: params,
        }
      );
      dispatch(reportsSlice.actions.setReports(response.data));
    } catch (error) {
      dispatch(reportsSlice.actions.setReports([]));
    }
  };
}

export const { setReports } = reportsSlice.actions;
export default reportsSlice.reducer;
