import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { HorizontalBar } from "react-chartjs-2";

import { Card, Dropdown, Spinner } from "react-bootstrap";

import { MoreHorizontal } from "react-feather";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getTopMetrics } from "../../redux/slices/dataSessions";

const TopMetricsBarChart = ({ period }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const [type, setType] = useState("dataSessions");
  const [isLoading, setIsLoading] = useState(false);
  const [barData, setBarData] = useState([]);

  useEffect(() => {
    const fetchTopMetrics = async (id) => {
      if (!id) {
        return;
      }
      setIsLoading(true);
      const response = await getTopMetrics(id, type, period);
      setBarData(response);
      setIsLoading(false);
    };
    if (!impersonationUser.id) {
      fetchTopMetrics(user.id);
    } else {
      fetchTopMetrics(impersonationUser.id);
    }
  }, [user.id, impersonationUser.id, type, period, dispatch]);

  function calculateData(item, type) {
    switch (type) {
      case "dataSessions":
        return item.count;
      case "dataVolume":
        return parseFloat(item.count / 1024.0 / 1024.0).toFixed(2);
      case "zeroSessions":
        return item.count;
      case "alerts":
        return item.count;
      default:
        return 0;
    }
  }

  const data = {
    labels: barData.map((item) => item.imsi),
    datasets: [
      {
        label:
          type === "dataVolume"
            ? t("Data Volume")
            : type === "dataSessions"
            ? t("Data Sessions")
            : type === "alerts"
            ? t("Alerts")
            : type === "zeroSessions"
            ? t("Zero Sessions")
            : type === "extraCharge"
            ? t("Extra Charge")
            : null,
        backgroundColor: impersonationUser.id
          ? impersonationUser.backgroundColor
          : user.backgroundColor,
        borderColor: impersonationUser.id
          ? impersonationUser.backgroundColor
          : user.backgroundColor,
        hoverBackgroundColor: impersonationUser.id
          ? impersonationUser.backgroundColor
          : user.backgroundColor,
        hoverBorderColor: impersonationUser.id
          ? impersonationUser.backgroundColor
          : user.backgroundColor,
        data: barData.map((item) => calculateData(item, type)),
        barPercentage: 0.5,
        categoryPercentage: 0.5,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    cornerRadius: 15,
    legend: {
      display: false,
    },
    scales: {
      yAxes: [
        {
          gridLines: {
            display: false,
          },
          ticks: {
            stepSize: 20,
          },
          stacked: true,
        },
      ],
      xAxes: [
        {
          gridLines: {
            color: "transparent",
          },
          stacked: true,
        },
      ],
    },
    onClick: function (evt, element) {
      if (element.length > 0) {
        navigate("/sims/profile", {
          state: {
            imsi: element[0]._chart.config.data.labels[element[0]._index],
          },
        });
      }
    },
  };

  return (
    <Card className="flex-fill w-100">
      <Card.Header>
        <Card.Title className="mb-0">
          {t("Top Metrics")}:{" "}
          {type === "dataVolume"
            ? t("Data Volume")
            : type === "dataSessions"
            ? t("Data Sessions")
            : type === "alerts"
            ? t("Alerts")
            : type === "zeroSessions"
            ? t("Zero Sessions")
            : type === "extraCharge"
            ? t("Extra Charge")
            : null}
        </Card.Title>
        <div className="card-actions float-end">
          <Dropdown align="end">
            <Dropdown.Toggle as="a" bsPrefix="-">
              <MoreHorizontal />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => setType("dataVolume")}>
                {t("Data Volume")}
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setType("alerts")}>
                {t("Alerts")}
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setType("dataSessions")}>
                {t("Data Sessions")}
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setType("zeroSessions")}>
                {t("Zero Sessions")}
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setType("extraCharge")}>
                {t("Extra Charge")}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </Card.Header>
      <Card.Body className="d-flex">
        {!isLoading && (
          <div className="align-self-center w-100">
            <div className="chart">
              <HorizontalBar data={data} options={options} />
            </div>
          </div>
        )}
        {isLoading && (
          <div className="text-center d-flex w-100 justify-content-center align-items-center">
            <Spinner animation="border" variant="primary" />
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

export default TopMetricsBarChart;
