import React from "react";
import { useTranslation } from "react-i18next";
import { Col, Card, Row } from "react-bootstrap";
import { useState } from "react";
import { useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import NotyfContext from "../../../../contexts/NotyfContext";
import {
  getDataVolumeComparisonForSim,
  getSessionComparisonForSim,
  getZeroSessionComparisonForSim,
} from "../../../../redux/slices/dataSessions";
import CountUp from "react-countup";
import { getAlertCountForSim } from "../../../../redux/slices/alerts";

const SimStatistics = ({ sim, period }) => {
  const { t } = useTranslation();
  const [dataVolume, setDataVolume] = useState([]);
  const [dataSessions, setDataSessions] = useState([]);
  const [zeroSessions, setZeroSessions] = useState([]);
  const [alerts, setAlerts] = useState();
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const notyf = useContext(NotyfContext);

  useEffect(() => {
    const fetchSelectedRowsStatistics = async (id) => {
      try {
        const sessions = await getSessionComparisonForSim(id, sim.imsi, period);
        setDataSessions(sessions);
      } catch (error) {
        notyf.open({
          type: "danger",
          message: "Error calculating Data Sessions",
          duration: 5000,
          ripple: true,
          dismissible: false,
          position: {
            x: "center",
            y: "top",
          },
        });
      }
      try {
        const zeros = await getZeroSessionComparisonForSim(
          id,
          sim.imsi,
          period
        );
        setZeroSessions(zeros);
      } catch (error) {
        notyf.open({
          type: "danger",
          message: "Error calculating Zero Sessions",
          duration: 5000,
          ripple: true,
          dismissible: false,
          position: {
            x: "center",
            y: "top",
          },
        });
      }

      try {
        const volume = await getDataVolumeComparisonForSim(
          id,
          sim.imsi,
          period
        );
        setDataVolume(volume);
      } catch (error) {
        notyf.open({
          type: "danger",
          message: error.message,
          duration: 5000,
          ripple: true,
          dismissible: false,
          position: {
            x: "center",
            y: "top",
          },
        });
      }

      try {
        const alerts = await getAlertCountForSim(id, period, sim.imsi);
        setAlerts(alerts);
      } catch (error) {
        notyf.open({
          type: "danger",
          message: error.message,
          duration: 5000,
          ripple: true,
          dismissible: false,
          position: {
            x: "center",
            y: "top",
          },
        });
      }
    };

    if (!impersonationUser.id) {
      if (user && user.id && sim.imsi) {
        fetchSelectedRowsStatistics(user.id);
      }
    } else {
      fetchSelectedRowsStatistics(impersonationUser.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, impersonationUser.id, period, sim.imsi]);

  return (
    <div className="w-100">
      <Row>
        <Col sm="6" lg="12" className="d-flex col-xxl-6">
          <Card className="flex-fill">
            <Card.Body>
              <Row>
                <Col className="mt-0">
                  <h5 className="card-title">{t("Data Volume")}</h5>
                </Col>
              </Row>
              <span className="h1 d-inline-block mt-3 mb-4">
                <CountUp
                  start={0}
                  end={parseFloat(dataVolume[0] / 1024.0 / 1024.0).toFixed(3)}
                  duration={2.75}
                />
                MB
              </span>
            </Card.Body>
          </Card>
        </Col>
        <Col sm="6" lg="12" className="d-flex col-xxl-6">
          <Card className="flex-fill">
            <Card.Body>
              <Row>
                <Col className="mt-0">
                  <h5 className="card-title">{t("Data Sessions")}</h5>
                </Col>
              </Row>
              <span className="h1 d-inline-block mt-3 mb-4">
                <CountUp start={0} end={dataSessions[0]} duration={2.75} />
              </span>
            </Card.Body>
          </Card>
        </Col>
        <Col sm="6" lg="12" className="d-flex col-xxl-6">
          <Card className="flex-fill">
            <Card.Body>
              <Row>
                <Col className="mt-0">
                  <h5 className="card-title">Alerts</h5>
                </Col>
              </Row>
              <span className="h1 d-inline-block mt-3 mb-4">
                <CountUp start={0} end={alerts} duration={2.75} />
              </span>
            </Card.Body>
          </Card>
        </Col>
        <Col sm="6" lg="12" className="d-flex col-xxl-6">
          <Card className="flex-fill">
            <Card.Body>
              <Row>
                <Col className="mt-0">
                  <h5 className="card-title">Zero Sessions</h5>
                </Col>
              </Row>
              <span className="h1 d-inline-block mt-3 mb-4">
                <CountUp start={0} end={zeroSessions[0]} duration={2.75} />
              </span>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default SimStatistics;
