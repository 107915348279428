import countryList from "react-select-country-list";
import axios from "../../utils/axios";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  subaccounts: [],
};

const subaccountsSlice = createSlice({
  name: "subaccounts",
  initialState,
  reducers: {
    setSubaccounts(state, action) {
      state.subaccounts = action.payload;
    },
    updateFullName(state, action) {
      const { id, fullName } = action.payload;
      const updatedUsers = state.subaccounts.map((user) => {
        if (user.id === id) {
          return {
            ...user,
            fullName: fullName,
          };
        }
        return user;
      });
      return {
        ...state,
        subaccounts: updatedUsers,
      };
    },
    updateAddress(state, action) {
      const { id, address } = action.payload;
      const updatedUsers = state.subaccounts.map((user) => {
        if (user.id === id) {
          return {
            ...user,
            address: address,
          };
        }
        return user;
      });
      return {
        ...state,
        subaccounts: updatedUsers,
      };
    },
    updateCity(state, action) {
      const { id, city } = action.payload;
      const updatedUsers = state.subaccounts.map((user) => {
        if (user.id === id) {
          return {
            ...user,
            city: city,
          };
        }
        return user;
      });
      return {
        ...state,
        subaccounts: updatedUsers,
      };
    },
    updateCountry(state, action) {
      const { id, country } = action.payload;
      const updatedUsers = state.subaccounts.map((user) => {
        if (user.id === id) {
          return {
            ...user,
            country: country,
          };
        }
        return user;
      });
      return {
        ...state,
        subaccounts: updatedUsers,
      };
    },
    updatePostcode(state, action) {
      const { id, postcode } = action.payload;
      const updatedUsers = state.subaccounts.map((user) => {
        if (user.id === id) {
          return {
            ...user,
            postcode: postcode,
          };
        }
        return user;
      });
      return {
        ...state,
        subaccounts: updatedUsers,
      };
    },
    updateTelephone(state, action) {
      const { id, telephone } = action.payload;
      const updatedUsers = state.subaccounts.map((user) => {
        if (user.id === id) {
          return {
            ...user,
            telephone: telephone,
          };
        }
        return user;
      });
      return {
        ...state,
        subaccounts: updatedUsers,
      };
    },
    updateMobile(state, action) {
      const { id, mobile } = action.payload;
      const updatedUsers = state.subaccounts.map((user) => {
        if (user.id === id) {
          return {
            ...user,
            mobileNumber: mobile,
          };
        }
        return user;
      });
      return {
        ...state,
        subaccounts: updatedUsers,
      };
    },
    updateEmail(state, action) {
      const { id, email } = action.payload;
      const updatedUsers = state.subaccounts.map((user) => {
        if (user.id === id) {
          return {
            ...user,
            email: email,
          };
        }
        return user;
      });
      return {
        ...state,
        subaccounts: updatedUsers,
      };
    },
    updateOccupation(state, action) {
      const { id, occupation } = action.payload;
      const updatedUsers = state.subaccounts.map((user) => {
        if (user.id === id) {
          return {
            ...user,
            occupation: occupation,
          };
        }
        return user;
      });
      return {
        ...state,
        subaccounts: updatedUsers,
      };
    },
    updateCompany(state, action) {
      const { id, company } = action.payload;
      const updatedUsers = state.subaccounts.map((user) => {
        if (user.id === id) {
          return {
            ...user,
            company: company,
          };
        }
        return user;
      });
      return {
        ...state,
        subaccounts: updatedUsers,
      };
    },
    updateTIN(state, action) {
      const { id, tin } = action.payload;
      const updatedUsers = state.subaccounts.map((user) => {
        if (user.id === id) {
          return {
            ...user,
            tinNumber: tin,
          };
        }
        return user;
      });
      return {
        ...state,
        subaccounts: updatedUsers,
      };
    },
    updatePrivilege(state, action) {
      const { id, privilege } = action.payload;
      const updatedUsers = state.subaccounts.map((user) => {
        if (user.id === id) {
          return {
            ...user,
            privilege: privilege,
          };
        }
        return user;
      });
      return {
        ...state,
        subaccounts: updatedUsers,
      };
    },
    addSubaccount: (state, action) => {
      const newUser = action.payload;
      return {
        ...state,
        subaccounts: [...state.subaccounts, newUser],
      };
    },
    removeSubaccount: (state, action) => {
      const idToRemove = action.payload;
      const newUsers = state.subaccounts.filter(
        (user) => user.id !== idToRemove
      );
      return {
        ...state,
        subaccounts: newUsers,
      };
    },
  },
});

export function getSubaccounts(id) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    try {
      const response = await axios.get(
        "https://dev.pos2cloud.com:5000/api/subaccounts/" +
          id +
          "/getSubaccounts",
        {
          headers: {
            accessToken: "Bearer " + accessToken,
          },
        }
      );
      dispatch(subaccountsSlice.actions.setSubaccounts(response.data));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        dispatch(subaccountsSlice.actions.setSubaccounts([]));
      }
    }
  };
}

export function updateSubaccountName(id, fullName) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("fullName", fullName);
    try {
      await axios.put(
        "https://dev.pos2cloud.com:5000/api/systemusers/" +
          id +
          "/update/fullName",
        formData,
        {
          headers: {
            accessToken: "Bearer " + accessToken,
          },
        }
      );
      dispatch(subaccountsSlice.actions.updateFullName({ id, fullName }));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.log("User not found");
      } else if (error.status === 500) {
        console.log("Internal Server Error");
      }
    }
  };
}

export function updateSubaccountAddress(id, address) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("address", address);
    try {
      await axios.put(
        "https://dev.pos2cloud.com:5000/api/systemusers/" +
          id +
          "/update/address",
        formData,
        {
          headers: {
            accessToken: "Bearer " + accessToken,
          },
        }
      );
      dispatch(subaccountsSlice.actions.updateAddress({ id, address }));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.log("User not found");
      } else if (error.status === 500) {
        console.log("Internal Server Error");
      }
    }
  };
}

export function updateSubaccountCity(id, city) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("city", city);
    try {
      await axios.put(
        "https://dev.pos2cloud.com:5000/api/systemusers/" + id + "/update/city",
        formData,
        {
          headers: {
            accessToken: "Bearer " + accessToken,
          },
        }
      );
      dispatch(subaccountsSlice.actions.updateCity({ id, city }));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.log("User not found");
      } else if (error.status === 500) {
        console.log("Internal Server Error");
      }
    }
  };
}

export function updateSubaccountCountry(id, country) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("country", country);
    try {
      await axios.put(
        "https://dev.pos2cloud.com:5000/api/systemusers/" +
          id +
          "/update/country",
        formData,
        {
          headers: {
            accessToken: "Bearer " + accessToken,
          },
        }
      );
      dispatch(subaccountsSlice.actions.updateCountry({ id, country }));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.log("User not found");
      } else if (error.status === 500) {
        console.log("Internal Server Error");
      }
    }
  };
}

export function updateSubaccountPostcode(id, postcode) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("postcode", postcode);
    try {
      await axios.put(
        "https://dev.pos2cloud.com:5000/api/systemusers/" +
          id +
          "/update/postcode",
        formData,
        {
          headers: {
            accessToken: "Bearer " + accessToken,
          },
        }
      );
      dispatch(subaccountsSlice.actions.updatePostcode({ id, postcode }));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.log("User not found");
      } else if (error.status === 500) {
        console.log("Internal Server Error");
      }
    }
  };
}

export function updateSubaccountTelephone(id, telephone) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("telephone", telephone);
    try {
      await axios.put(
        "https://dev.pos2cloud.com:5000/api/systemusers/" +
          id +
          "/update/telephone",
        formData,
        {
          headers: {
            accessToken: "Bearer " + accessToken,
          },
        }
      );
      dispatch(subaccountsSlice.actions.updateTelephone({ id, telephone }));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.log("User not found");
      } else if (error.status === 500) {
        console.log("Internal Server Error");
      }
    }
  };
}

export function updateSubaccountMobile(id, mobile) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("mobile", mobile);
    try {
      await axios.put(
        "https://dev.pos2cloud.com:5000/api/systemusers/" +
          id +
          "/update/mobile",
        formData,
        {
          headers: {
            accessToken: "Bearer " + accessToken,
          },
        }
      );
      dispatch(subaccountsSlice.actions.updateMobile({ id, mobile }));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.log("User not found");
      } else if (error.status === 500) {
        console.log("Internal Server Error");
      }
    }
  };
}

export function updateSubaccountEmail(id, email) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("email", email);
    try {
      await axios.put(
        "https://dev.pos2cloud.com:5000/api/systemusers/" +
          id +
          "/update/email",
        formData,
        {
          headers: {
            accessToken: "Bearer " + accessToken,
          },
        }
      );
      dispatch(subaccountsSlice.actions.updateEmail({ id, email }));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.log("User not found");
      } else if (error.status === 500) {
        console.log("Internal Server Error");
      }
    }
  };
}

export function updateSubAccountOccupation(id, occupation) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("occupation", occupation);
    try {
      await axios.put(
        "https://dev.pos2cloud.com:5000/api/systemusers/" +
          id +
          "/update/occupation",
        formData,
        {
          headers: {
            accessToken: "Bearer " + accessToken,
          },
        }
      );
      dispatch(subaccountsSlice.actions.updateOccupation({ id, occupation }));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.log("User not found");
      } else if (error.status === 500) {
        console.log("Internal Server Error");
      }
    }
  };
}

export function updateSubaccountCompany(id, company) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("company", company);
    try {
      await axios.put(
        "https://dev.pos2cloud.com:5000/api/systemusers/" +
          id +
          "/update/company",
        formData,
        {
          headers: {
            accessToken: "Bearer " + accessToken,
          },
        }
      );
      dispatch(subaccountsSlice.actions.updateCompany({ id, company }));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.log("User not found");
      } else if (error.status === 500) {
        console.log("Internal Server Error");
      }
    }
  };
}

export function updateSubaccountTIN(id, tin) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("tin", tin);
    try {
      await axios.put(
        "https://dev.pos2cloud.com:5000/api/systemusers/" + id + "/update/tin",
        formData,
        {
          headers: {
            accessToken: "Bearer " + accessToken,
          },
        }
      );
      dispatch(subaccountsSlice.actions.updateTIN({ id, tin }));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.log("User not found");
      } else if (error.status === 500) {
        console.log("Internal Server Error");
      }
    }
  };
}

export function updateSubaccountPrivilege(
  userId,
  clientId,
  privilege,
  upperEntityId
) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("entityId", clientId);
    formData.append("privilege", privilege);
    if (privilege !== "ADMIN" && privilege !== "VIEWER") {
      formData.append("upperEntityId", upperEntityId);
    } else {
      formData.append("upperEntityId", -1);
    }
    try {
      await axios.put(
        "https://dev.pos2cloud.com:5000/api/systemusers/" +
          userId +
          "/update/privilege",
        formData,
        {
          headers: {
            accessToken: "Bearer " + accessToken,
          },
        }
      );
      dispatch(
        subaccountsSlice.actions.updatePrivilege({ clientId, privilege })
      );
      dispatch(subaccountsSlice.actions.removeUser({ clientId }));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.log("User not found");
      } else if (error.status === 500) {
        console.log("Internal Server Error");
      }
    }
  };
}

export function addNewSubaccount(
  id,
  fullName,
  address,
  city,
  country,
  postcode,
  telephoneNumber,
  mobileNumber,
  email,
  occupation,
  company,
  tin,
  image,
  offers
) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    if (accessToken) {
      const options = countryList().getData();
      const abbreviation = country;
      const countryRecord = options.find((c) => c.value === abbreviation);
      const formData = new FormData();
      formData.append("fullName", fullName);
      formData.append("address", address);
      formData.append("city", city);
      formData.append("country", countryRecord.label);
      formData.append("postcode", postcode);
      formData.append("telephoneNumber", telephoneNumber);
      formData.append("mobileNumber", mobileNumber);
      formData.append("email", email);
      formData.append("occupation", occupation);
      formData.append("company", company);
      formData.append("tin", tin);
      formData.append("photo", image);
      formData.append("offers", JSON.stringify(offers));
      await axios.post(
        "https://dev.pos2cloud.com:5000/api/subaccounts/" +
          id +
          "/addSubaccount",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            accessToken: "Bearer " + accessToken,
          },
        }
      );
      dispatch(getSubaccounts(id));
    }
  };
}

export function removeExistingSubaccount(id) {
  return (dispatch) => {
    dispatch(subaccountsSlice.actions.removeSubaccount(id));
  };
}

export const {
  setSubaccounts,
  updateFullName,
  updateAddress,
  updateCity,
  updateCountry,
  updatePostcode,
  updateTelephone,
  updateEmail,
  updateOccupation,
  updateCompany,
  updateTIN,
  updatePrivilege,
  addSubaccount,
  removeSubaccount,
} = subaccountsSlice.actions;
export default subaccountsSlice.reducer;
