import React from "react";
import { useTranslation } from "react-i18next";

import {
  useTable,
  useRowSelect,
  usePagination,
  useSortBy,
  useFilters,
  useGlobalFilter,
} from "react-table";
import DefaultColumnFilter from "../../utils/DefaultColumnFilter";
import IndeterminateCheckbox from "../../utils/IndeterminateCheckbox";
import FiltersCard from "../../utils/FiltersCard";
import DataCard from "./DataCard";

const RowSelectingTable = ({
  columns: userColumns,
  data,
  setShownClient,
  setOpen,
  setSingleIsShown,
  title,
  showCreateEntityModal,
  setShowCreateEntityModal,
}) => {
  const { t } = useTranslation();

  const filterTypes = React.useMemo(
    () => ({
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    prepareRow,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns: userColumns,
      data,
      initialState: { pageIndex: 0 },
      defaultColumn,
      filterTypes,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: "select",
          sortable: false,
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox
                {...row.getToggleRowSelectedProps()}
                onClick={(e) => e.stopPropagation()}
              />
            </div>
          ),
        },
        ...columns,
      ]);
    }
  );

  return (
    <>
      <FiltersCard headerGroups={headerGroups} />
      <DataCard
        getTableProps={getTableProps}
        getTableBodyProps={getTableBodyProps}
        headerGroups={headerGroups}
        page={page}
        prepareRow={prepareRow}
        setShownClient={setShownClient}
        setOpen={setOpen}
        setSingleIsShown={setSingleIsShown}
        pageIndex={pageIndex}
        pageSize={pageSize}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        pageOptions={pageOptions}
        pageCount={pageCount}
        gotoPage={gotoPage}
        nextPage={nextPage}
        previousPage={previousPage}
        setPageSize={setPageSize}
        t={t}
        title={title}
        showCreateEntityModal={showCreateEntityModal}
        setShowCreateEntityModal={setShowCreateEntityModal}
      />
    </>
  );
};

export default RowSelectingTable;
