import React, { useContext } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import QRCode from "qrcode.react";
import NotyfContext from "../../../../contexts/NotyfContext";
import axios from "../../../../utils/axios";
import { Formik } from "formik";
import * as Yup from "yup";
import { updateCurrentUserIs2FAEnabled } from "../../../../redux/slices/user";

const TwoFactorConfigModal = ({
  showConfigure2FAAuthentication,
  setShowConfigure2FAAuthentication,
  qrCodeUrl,
  user,
  handleCancel2FA,
}) => {
  const notyf = useContext(NotyfContext);
  const dispatch = useDispatch();

  const handleVerify2FA = async (id, code) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const payload = {
      code: code,
    };
    await axios
      .post(`https://dev.pos2cloud.com:5000/api/2fa/${id}/verify`, payload, {
        headers: {
          accessToken: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        dispatch(updateCurrentUserIs2FAEnabled("1"));
        setShowConfigure2FAAuthentication(false);
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          notyf.open({
            type: "danger",
            message: "2FA code not verified",
            duration: 5000,
            ripple: true,
            dismissible: false,
            position: {
              x: "center",
              y: "top",
            },
          });
          dispatch(updateCurrentUserIs2FAEnabled("0"));
        } else if (error.response && error.response.status === 401) {
          notyf.open({
            type: "danger",
            message: "Unauthorized",
            duration: 5000,
            ripple: true,
            dismissible: false,
            position: {
              x: "center",
              y: "top",
            },
          });
          dispatch(updateCurrentUserIs2FAEnabled("0"));
        }
      });
  };

  return (
    <Modal
      show={showConfigure2FAAuthentication}
      onHide={() => setShowConfigure2FAAuthentication(false)}
      centered
      backdrop="static"
    >
      <Modal.Header>Enable Multi-Factor Authentication</Modal.Header>
      <Modal.Body>
        <p className="mb-4">
          Use the Google Authenticator app on your device. Scan the QR code (or
          manually input the shared key),enter the 6-digit code that is
          displayed below into the form.
        </p>
        <div className="text-center mb-4">
          <QRCode value={qrCodeUrl} />
        </div>

        <Formik
          initialValues={{
            code: "",
            submit: false,
          }}
          validationSchema={Yup.object().shape({
            code: Yup.string().required("Required"),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            handleVerify2FA(user.id, values.code);
          }}
          onReset={async (values) => {
            handleCancel2FA(user.id);
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            setValues,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3">
                <Form.Label>2FA Verification Code</Form.Label>
                <Form.Control
                  type="text"
                  name="code"
                  value={values.code}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={Boolean(touched.code && errors.code)}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.code}
                </Form.Control.Feedback>
              </Form.Group>
              <Modal.Footer>
                <Button
                  type="submit"
                  style={{
                    "--dynamic-bg-color": user.backgroundColor,
                    "--dynamic-font-color": user.fontColor,
                    border: "none",
                  }}
                >
                  Enable
                </Button>
                <Button
                  style={{
                    "--dynamic-bg-color": user.backgroundColor,
                    "--dynamic-font-color": user.fontColor,
                    border: "none",
                  }}
                  onClick={() => {
                    handleCancel2FA(user.id);
                  }}
                >
                  Cancel
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default TwoFactorConfigModal;
