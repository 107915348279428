import React from "react";
import { Card, ListGroup } from "react-bootstrap";
import { useSelector } from "react-redux";

const Navigation = () => {
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  return (
    <Card>
      <Card.Header>
        <Card.Title className="mb-0">Profile Settings</Card.Title>
      </Card.Header>
      <ListGroup
        variant="flush"
        style={{
          "--dynamic-bg-color": !impersonationUser.id
            ? user.backgroundColor
            : impersonationUser.backgroundColor,
          "--dynamic-font-color": !impersonationUser.id
            ? user.fontColor
            : impersonationUser.fontColor,
        }}
      >
        <ListGroup.Item tag="General" href="#General" action>
          General
        </ListGroup.Item>
        <ListGroup.Item tag="Account" href="#Account" action>
          Account
        </ListGroup.Item>
        <ListGroup.Item tag="Privacy" href="#Privacy" action>
          Privacy and safety
        </ListGroup.Item>
        <ListGroup.Item tag="Notifications" href="#Notifications" action>
          Notifications
        </ListGroup.Item>
      </ListGroup>
    </Card>
  );
};

export default Navigation;
