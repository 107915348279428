import React from "react";
import { Row, Col, Form } from "react-bootstrap";

const EmploymentAndTax = ({
  handleBlur,
  handleChange,
  values,
  errors,
  touched,
}) => {
  return (
    <Row>
      <Col md={12}>
        <Form.Group className="mb-4">
          <Form.Label>Occupation</Form.Label>
          <Form.Control
            type="text"
            name="occupation"
            value={values.occupation}
            isInvalid={Boolean(touched.occupation && errors.occupation)}
            onBlur={handleBlur}
            onChange={handleChange}
          />
          {!!touched.occupation && (
            <Form.Control.Feedback type="invalid">
              {errors.occupation}
            </Form.Control.Feedback>
          )}
        </Form.Group>
      </Col>
      <Col md={12}>
        <Form.Group className="mb-4">
          <Form.Label>Company</Form.Label>
          <Form.Control
            type="text"
            name="company"
            value={values.company}
            isInvalid={Boolean(touched.company && errors.company)}
            onBlur={handleBlur}
            onChange={handleChange}
          />
          {!touched.company && (
            <Form.Control.Feedback type="invalid">
              {errors.company}
            </Form.Control.Feedback>
          )}
        </Form.Group>
      </Col>
      <Col md={12}>
        <Form.Group className="mb-4">
          <Form.Label>TIN</Form.Label>
          <Form.Control
            type="text"
            name="tin"
            value={values.tin}
            isInvalid={Boolean(touched.tin && errors.tin)}
            onBlur={handleBlur}
            onChange={handleChange}
          />
          {!!touched.tin && (
            <Form.Control.Feedback type="invalid">
              {errors.tin}
            </Form.Control.Feedback>
          )}
        </Form.Group>
      </Col>
    </Row>
  );
};

export default EmploymentAndTax;
