const simProductsData = [
  {
    name: "JT-Direct (Base Product)",
    type: "Chayn variable product",
    startDate: "2022-04-07 15:05:07",
    endDate: "",
  },
  {
    name: "NextM2M SIM T2",
    type: "Sim",
    startDate: "2020-08-05 14:47:02",
    endDate: "",
  },
];

const simProductsColumns = [
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Type",
    accessor: "type",
  },
  {
    Header: "Start Date",
    accessor: "startDate",
  },
  {
    Header: "End Date",
    accessor: "endDate",
  },
];

export { simProductsData, simProductsColumns };
