import axios from "../../utils/axios";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  offers: [],
  availableOffers: [],
};

const offersSlice = createSlice({
  name: "offers",
  initialState,
  reducers: {
    setOffers(state, action) {
      state.offers = action.payload;
    },
    setAvailableOffers(state, action) {
      state.availableOffers = action.payload;
    },
  },
});

export function getOffers(id) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    try {
      await axios
        .get(`https://dev.pos2cloud.com:5000/api/offers/${id}/distribution`, {
          headers: {
            accessToken: `Bearer ${accessToken}`,
          },
        })
        .then((response) => {
          dispatch(offersSlice.actions.setOffers(response.data));
        });
    } catch (error) {
      if (
        error.response &&
        (error.response.status === 404 || error.response.status === 204)
      ) {
        dispatch(offersSlice.actions.setOffers([]));
      } else {
        dispatch(offersSlice.actions.setOffers([]));
      }
    }
  };
}

export function getAvailableOffers(id) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    try {
      await axios
        .get(`https://dev.pos2cloud.com:5000/api/offers/${id}/offers`, {
          headers: {
            accessToken: `Bearer ${accessToken}`,
          },
        })
        .then((response) => {
          dispatch(offersSlice.actions.setAvailableOffers(response.data));
        });
    } catch (error) {
      if (
        error.response &&
        (error.response.status === 404 || error.response.status === 204)
      ) {
        dispatch(offersSlice.actions.setAvailableOffers([]));
      } else {
        dispatch(offersSlice.actions.setAvailableOffers([]));
      }
    }
  };
}

export const { setOffers, setAvailableOffers } = offersSlice.actions;
export default offersSlice.reducer;
