import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Card, Table } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getAlerts } from "../../redux/slices/alerts";

const WatchdogTable = ({ alerts }) => {
  if (alerts.length > 0) {
    return (
      <Table hover responsive>
        <thead>
          <tr>
            <th>#</th>
            <th>Alert</th>
            <th>Date</th>
            <th>IMSI</th>
          </tr>
        </thead>
        <tbody>
          {alerts.slice(0, 10).map((item, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>
                {item.context} {item.action}
              </td>
              <td>{item.alertDate.replace("T", " ")}</td>
              <td>{item.imsi}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  } else {
    return <div className="text-center">No Data</div>;
  }
};

const WatchdogAlerts = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const { alerts } = useSelector((state) => state.alerts);

  const [alertsList, setAlertsList] = useState([]);

  useEffect(() => {
    const fetchWatchdogAlerts = async (id) => {
      if (!id) {
        return;
      }
      await dispatch(getAlerts(id));
    };

    if (!impersonationUser.id) {
      fetchWatchdogAlerts(user.id);
    } else {
      fetchWatchdogAlerts(impersonationUser.id);
    }
  }, [user, impersonationUser.id, dispatch]);

  useEffect(() => {
    setAlertsList(alerts);
  }, [alerts]);

  return (
    <Card className="w-100">
      <Card.Header>
        <Card.Title>Watchdog Alerts</Card.Title>
      </Card.Header>
      <Card.Body>
        <WatchdogTable alerts={alertsList} />
      </Card.Body>
    </Card>
  );
};

export default WatchdogAlerts;
