import React, { useMemo, useContext } from "react";
import { Card, Form, Row, Col, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import countryList from "react-select-country-list";
import { Formik } from "formik";
import * as Yup from "yup";
import NotyfContext from "../../../contexts/NotyfContext";
import {
  updateCurrentUserFullName,
  updateCurrentUserAddress,
  updateCurrentUserCity,
  updateCurrentUserCountry,
  updateCurrentUserPostcode,
} from "../../../redux/slices/user";
import {
  GetUpdateAddressFunction,
  GetUpdateCityFunction,
  GetUpdateCountryFunction,
  GetUpdateNameFunction,
  GetUpdatePostcodeFunction,
} from "../../crm/configuration/updateFunctions";
import { useDispatch } from "react-redux";

const PublicInfo = ({ user }) => {
  const countryOptions = useMemo(() => {
    const options = countryList().getData();
    return [{ label: "Select a country", value: "" }, ...options];
  }, []);
  const notyf = useContext(NotyfContext);
  const dispatch = useDispatch();

  const showNotyfMessage = (type, message) => {
    notyf.open({
      type: type,
      message: message,
      duration: 2500,
      ripple: true,
      dismissible: false,
      position: {
        x: "center",
        y: "top",
      },
    });
  };

  return (
    <Card>
      <Card.Header>
        <Card.Title className="mb-0">Public info</Card.Title>
      </Card.Header>
      <Card.Body>
        <Formik
          initialValues={{
            userFullName: user.fullName,
            userAddress: user.address,
            userCity: user.city,
            userCountry: user.country,
            userPostcode: user.postcode,
            submit: false,
          }}
          validationSchema={Yup.object().shape({
            userFullName: Yup.string().required("Required"),
            userAddress: Yup.string().required("Required"),
            userCity: Yup.string().required("Required"),
            userCountry: Yup.string().required("Required"),
            userPostcode: Yup.string().required("Required"),
          })}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              if (values.userFullName !== user.fullName) {
                const updateFunction = GetUpdateNameFunction(
                  user.privilege,
                  user.id,
                  dispatch
                );
                await updateFunction(values.userFullName);
                dispatch(updateCurrentUserFullName(values.userFullName));
              }
              if (values.userAddress !== user.address) {
                const updateFunction = GetUpdateAddressFunction(
                  user.privilege,
                  user.id,
                  dispatch
                );
                await updateFunction(values.userAddress);
                dispatch(updateCurrentUserAddress(values.userAddress));
              }
              if (values.userCity !== user.city) {
                const updateFunction = GetUpdateCityFunction(
                  user.privilege,
                  user.id,
                  dispatch
                );
                await updateFunction(values.userCity);
                dispatch(updateCurrentUserCity(values.userCity));
              }
              if (values.userCountry !== user.country) {
                const updateFunction = GetUpdateCountryFunction(
                  user.privilege,
                  user.id,
                  dispatch
                );
                await updateFunction(values.userCountry);
                dispatch(updateCurrentUserCountry(values.userCountry));
              }
              if (values.userPostcode !== user.postcode) {
                const updateFunction = GetUpdatePostcodeFunction(
                  user.privilege,
                  user.id,
                  dispatch
                );
                await updateFunction(values.userPostcode);
                dispatch(updateCurrentUserPostcode(values.userPostcode));
              }
            } catch (error) {
              showNotyfMessage("danger", error.message);
            }
            showNotyfMessage("success", "Details Updated");
            setSubmitting(false);
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            setValues,
          }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <Row className="mb-3">
                  <Col md={8}>
                    <Form.Group className="mb-3">
                      <Form.Label htmlFor="fullName">Full name</Form.Label>
                      <Form.Control
                        type="text"
                        name="userFullName"
                        id="userFullName"
                        placeholder="Full name"
                        value={values.userFullName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={
                          touched.userFullName && !!errors.userFullName
                        }
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label htmlFor="address">Address</Form.Label>
                      <Form.Control
                        type="text"
                        name="userAddress"
                        id="address"
                        placeholder="1234 Main St"
                        value={values.userAddress}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <div className="text-center">
                      <img
                        alt="Chris Wood"
                        src={`data:image/png;base64,${user.photo}`}
                        className="rounded-circle img-responsive mt-2"
                        width="128"
                        height="128"
                      />
                      <div className="mt-2">
                        <Button
                          style={{
                            "--dynamic-bg-color": user.backgroundColor,
                            "--dynamic-font-color": user.fontColor,
                            border: "none",
                          }}
                        >
                          <FontAwesomeIcon icon={faUpload} /> Upload
                        </Button>
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col md={4}>
                    <Form.Group className="mb-3">
                      <Form.Label htmlFor="city">City</Form.Label>
                      <Form.Control
                        type="text"
                        name="city"
                        id="city"
                        value={values.userCity}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-3">
                      <Form.Label htmlFor="state">Country</Form.Label>
                      <Form.Select name="state" id="state">
                        {countryOptions.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-3">
                      <Form.Label htmlFor="postcode">Postcode</Form.Label>
                      <Form.Control
                        type="text"
                        name="zip"
                        id="postcode"
                        value={values.userPostcode}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Button
                  style={{
                    "--dynamic-bg-color": user.backgroundColor,
                    "--dynamic-font-color": user.fontColor,
                    border: "none",
                  }}
                  type="submit"
                >
                  Save changes
                </Button>
              </Form>
            );
          }}
        </Formik>
      </Card.Body>
    </Card>
  );
};

export default PublicInfo;
