import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { Col, Form, Modal, Row, Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import { getHlrInfo } from "../../redux/slices/sims";
import NotyfContext from "../../contexts/NotyfContext";
import Loader from "../../components/spinners/Loader";

export const HlrProfileModal = ({
  showHlrProfileModal,
  setShowHlrProfileModal,
  sim,
}) => {
  const handleClose = () => setShowHlrProfileModal(false);
  const [scope, setScope] = useState("basicInfo");
  const [hlrInfo, setHlrInfo] = useState([]);
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const [isLoading, setIsLoading] = useState(false);
  const notyf = useContext(NotyfContext);

  useEffect(() => {
    const fetchHlrInfo = async (id) => {
      await getHlrInfo(id, sim.imsi, scope)
        .then((response) => {
          setHlrInfo(response);
        })
        .catch((error) => {
          notyf.open({
            type: "danger",
            message: "Error fetching HLR Profile",
            duration: 5000,
            ripple: true,
            dismissible: false,
            position: {
              x: "center",
              y: "top",
            },
          });
        });
    };

    setIsLoading(true);
    if (!impersonationUser.id) {
      if (user.id && sim && sim.imsi) {
        fetchHlrInfo(user.id);
      }
    } else {
      fetchHlrInfo(impersonationUser.id);
    }
    setIsLoading(false);
  }, [user.id, sim, scope, impersonationUser.id, notyf]);

  return (
    <Modal
      show={showHlrProfileModal}
      onHide={handleClose}
      centered
      backdrop="static"
      size="xl"
    >
      <Modal.Header>HLR Info</Modal.Header>
      <Modal.Body>
        <Row className="mb-4">
          <Col md={2} className="float-end">
            <Form.Group>
              <Form.Label>Select scope:</Form.Label>
              <Form.Select
                value={scope}
                onChange={(e) => setScope(e.target.value)}
              >
                <option key={1} value="basicInfo">
                  Basic Information
                </option>
                <option key={2} value="locationInfo">
                  Location Information
                </option>
                <option key={3} value="csInfo">
                  CS Basic Information
                </option>
                <option key={4} value="gprsInfo">
                  GPRS Service 2G/3G
                </option>
                <option key={5} value="telephonyInfo">
                  Telephony Service
                </option>
                <option key={6} value="supplementaryService">
                  Supplementary Service
                </option>
                <option key={7} value="epcLocation">
                  EPC Service 4G
                </option>
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
        {isLoading && <Loader />}
        {!isLoading && (
          <Table size="sm">
            <tbody>
              {hlrInfo.map((hlr) => (
                <tr>
                  <th>{hlr.category}</th>
                  <td>{hlr.value}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default HlrProfileModal;
