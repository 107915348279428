import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import countryList from "react-select-country-list";
import { useEffect } from "react";
import { getNetworkVolumeDistribution } from "../../redux/slices/dataSessions";
import { Card, Form, Spinner, Row, Col } from "react-bootstrap";
import { Pie } from "react-chartjs-2";
import chroma from "chroma-js";

const DataVolumeNetworkPieChart = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [volumeNetworkDistribution, setVolumeNetworkDistribution] = useState(
    []
  );
  const [country, setCountry] = useState("Greece");
  const [isLoading, setIsLoading] = useState(true);
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const countries = countryList().getData();
  const userBgColor =
    user && user.backgroundColor ? user.backgroundColor : "white"; // assuming white as default
  const impersonationUserBgColor =
    impersonationUser &&
    impersonationUser.id &&
    impersonationUser.backgroundColor
      ? impersonationUser.backgroundColor
      : "white";
  const scale = chroma
    .scale([
      chroma(impersonationUser.id ? impersonationUserBgColor : userBgColor).set(
        "hsl.h",
        "-30"
      ),
      impersonationUser.id ? impersonationUserBgColor : userBgColor,
      chroma(impersonationUser.id ? impersonationUserBgColor : userBgColor).set(
        "hsl.h",
        "+30"
      ),
    ])
    .mode("rgb");
  const [colors, setColors] = useState([]);

  useEffect(() => {
    const fetchVolumeNetworkDistribution = async (id) => {
      if (!id) {
        return;
      }
      const response = await getNetworkVolumeDistribution(id, country);
      const responseInMB = response.map((item) => ({
        ...item,
        count: item.count / (1024 * 1024),
      }));
      setVolumeNetworkDistribution(responseInMB);
    };
    setIsLoading(true);
    if (!impersonationUser.id) {
      fetchVolumeNetworkDistribution(user.id);
    } else {
      fetchVolumeNetworkDistribution(impersonationUser.id);
    }

    setIsLoading(false);
  }, [user, impersonationUser.id, country, dispatch]);

  useEffect(() => {
    setColors(
      Array.from({ length: volumeNetworkDistribution.length }, (_, i) =>
        scale(i / volumeNetworkDistribution.length).hex()
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [volumeNetworkDistribution, user.id, impersonationUser.id]);

  const data = {
    labels: volumeNetworkDistribution.map((item) => item.network),
    datasets: [
      {
        data: volumeNetworkDistribution.map((item) => item.count),
        backgroundColor: colors,
        borderColor: "transparent",
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    legend: {
      display: true,
      position: "right",
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          let dataItem =
            data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          return (
            data.labels[tooltipItem.index] + ": " + dataItem.toFixed(2) + "MB"
          );
        },
      },
    },
  };

  if (isLoading) {
    return (
      <Card className="w-100">
        <Card.Header>
          <Card.Title>{t("Data Volume by Network")}</Card.Title>
        </Card.Header>
        <Card.Body className="d-flex align-items-center justify-content-center">
          <Form.Select
            id="countrySelect"
            name="country"
            className="mb-1"
            onChange={(e) => setCountry(e.target.value)}
            value={country}
          >
            {countries.map((country, index) => (
              <option key={index} value={country.label}>
                {country.label}
              </option>
            ))}
          </Form.Select>
          <div className="text-center">
            <Spinner animation="border" variant="primary" className="me-2" />
          </div>
        </Card.Body>
      </Card>
    );
  } else if (volumeNetworkDistribution.length === 0 && !isLoading) {
    return (
      <Card className="w-100">
        <Card.Header>
          <Card.Title>{t("Data Volume by Network")}</Card.Title>
        </Card.Header>
        <Card.Body className="align-items-center justify-content-center">
          <Row>
            <Form.Group as={Col}>
              <Form.Label>Select Country</Form.Label>
              <Form.Select
                id="countrySelect"
                name="country"
                className="mb-5"
                onChange={(e) => setCountry(e.target.value)}
                value={country}
              >
                {countries.map((country, index) => (
                  <option key={index} value={country.label}>
                    {country.label}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Row>
          <Row>
            <Col>
              <div className="text-center">{t("No data found")}</div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  } else {
    return (
      <Card id="pieChart">
        <Card.Header>
          <Card.Title>{t("Data Volume by Network")}</Card.Title>
        </Card.Header>
        <Card.Body>
          <Row>
            <Form.Group as={Col}>
              <Form.Label>Select Country</Form.Label>
              <Form.Select
                id="countrySelect"
                name="country"
                className="mb-5"
                onChange={(e) => setCountry(e.target.value)}
                value={country}
              >
                {countries.map((country, index) => (
                  <option key={index} value={country.label}>
                    {country.label}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Row>
          <Row>
            <div className="chart chart-sm">
              <Pie data={data} options={options} />
            </div>
          </Row>
        </Card.Body>
      </Card>
    );
  }
};

export default DataVolumeNetworkPieChart;
