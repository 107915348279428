import React from "react";
import { useEffect } from "react";
import { useMemo } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAvailableOffers } from "../../../../redux/slices/offers";
import {
  Modal,
  ProgressBar,
  Form,
  Spinner,
  Table,
  Button,
} from "react-bootstrap";
import { FaCheckCircle, FaExclamationCircle } from "react-icons/fa";
import { getSims, modifySimProduct } from "../../../../redux/slices/sims";

const ModifySimModal = ({
  sim,
  openModifyProductModal,
  setOpenModifyProductModal,
  page,
  pageSize,
  filters,
}) => {
  const handleClose = () => setOpenModifyProductModal(false);
  const dispatch = useDispatch();
  const [selectedOffer, setSelectedOffer] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const availableOffers = useSelector((state) => state.offers.availableOffers);
  const [now, setNow] = useState(25);
  // eslint-disable-next-line no-unused-vars
  const [selectedSim, setSelectedSim] = useState();
  const [status, setStatus] = useState("");

  const label = useMemo(() => computeLabel(now), [now]);

  function computeLabel(num) {
    if (num === 25) {
      return "Offer";
    } else if (num === 50) {
      return "Summary";
    } else if (num === 75) {
      return "Order";
    } else if (num === 100) {
      return "Complete";
    }
  }

  const validateOption = () => {
    if (selectedOffer === null) {
      setHasError(true);
      return false;
    }
    setHasError(false);
    return true;
  };

  const handleInputChange = (event) => {
    const selectedOption = availableOffers.find(
      (option) => option === event.target.value
    );
    setSelectedOffer(selectedOption ? selectedOption : null);
  };

  useEffect(() => {
    const fetchAvailableOffers = async (id) => {
      if (!id) {
        return;
      }
      setIsLoading(true);
      try {
        await dispatch(getAvailableOffers(id));
      } catch (error) {
        console.error(error);
      }
      setIsLoading(false);
    };
    if (!impersonationUser.id) {
      fetchAvailableOffers(user.id);
    } else {
      fetchAvailableOffers(impersonationUser.id);
    }
  }, [user, impersonationUser.id, dispatch]);

  useEffect(() => {
    if (sim) {
      setSelectedSim({ imsi: sim.imsi, iccid: sim.iccid });
    }
  }, [sim]);

  useEffect(() => {
    if (!openModifyProductModal) {
      setSelectedOffer(null);
      setIsLoading(false);
      setHasError(false);
      setNow(25);
    }
  }, [openModifyProductModal]);

  if (sim) {
    return (
      <Modal
        show={openModifyProductModal}
        onHide={handleClose}
        backdrop="static"
      >
        <Modal.Header>Modify SIM</Modal.Header>
        <Modal.Body>
          <div className="progress-tabs">
            <ProgressBar
              className="mb-3"
              striped
              animated
              variant="primary"
              now={now}
              label={label}
            />
          </div>
          {!isLoading && now === 25 && (
            <Form.Group className="mb-3">
              <Form.Label>Offer</Form.Label>
              <Form.Select
                id="offerSelect"
                name="offerSelect"
                onChange={handleInputChange}
              >
                <option value="">Select Offer...</option>
                {availableOffers
                  .filter((offer) => {
                    return offer !== sim.offer;
                  })
                  .map((option, i) => (
                    <option key={i} value={option}>
                      {option}
                    </option>
                  ))}
              </Form.Select>
            </Form.Group>
          )}
          {isLoading && (
            <div className="text-center">
              <Spinner animation="border" variant="primary" className="me-2" />
            </div>
          )}
          {hasError && (
            <div className="text-danger">Please select a valid offer.</div>
          )}
          {now === 50 && (
            <Table>
              <thead>
                <tr>
                  <th>IMSI</th>
                  <th>ICCID</th>
                  <th>Offer</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{sim.imsi}</td>
                  <td>{sim.iccid}</td>
                  <td>{selectedOffer}</td>
                </tr>
              </tbody>
            </Table>
          )}
          {now === 75 && (
            <div className="text-center">
              <Spinner
                animation="border"
                variant="primary"
                className="mt-5 mb-3"
                style={{ width: "5rem", height: "5rem" }}
              />
              <h3>Waiting for completion</h3>
            </div>
          )}
          {now === 100 && status === "Success" && (
            <div className="text-center">
              <FaCheckCircle size={120} className="mt-3" color="#4BBF73" />
              <h3 className="mt-3">Order Complete</h3>
            </div>
          )}
          {now === 100 && status === "Error" && (
            <div className="text-center">
              <FaExclamationCircle
                size={120}
                className="mt-3"
                color="#FF9494"
              />
              <h3 className="mt-3">Order Error</h3>
              <p>Please contact the administrator</p>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          {now === 25 && (
            <>
              <Button
                variant="secondary"
                onClick={() => {
                  setOpenModifyProductModal(false);
                }}
                style={{
                  "--dynamic-bg-color": !impersonationUser.id
                    ? user.backgroundColor
                    : impersonationUser.backgroundColor,
                  "--dynamic-font-color": !impersonationUser.id
                    ? user.backgroundColor
                    : impersonationUser.backgroundColor,
                  border: "none",
                }}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                disabled={isLoading ? true : false}
                onClick={() => {
                  if (validateOption()) {
                    setNow(50);
                  }
                }}
                style={{
                  "--dynamic-bg-color": !impersonationUser.id
                    ? user.backgroundColor
                    : impersonationUser.backgroundColor,
                  "--dynamic-font-color": !impersonationUser.id
                    ? user.backgroundColor
                    : impersonationUser.backgroundColor,
                  border: "none",
                }}
              >
                Next
              </Button>
            </>
          )}
          {now === 50 && (
            <>
              <Button
                variant="secondary"
                disabled={isLoading ? true : false}
                onClick={() => setOpenModifyProductModal(false)}
                style={{
                  "--dynamic-bg-color": !impersonationUser.id
                    ? user.backgroundColor
                    : impersonationUser.backgroundColor,
                  "--dynamic-font-color": !impersonationUser.id
                    ? user.backgroundColor
                    : impersonationUser.backgroundColor,
                  border: "none",
                }}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={async () => {
                  setNow(75);
                  if (!impersonationUser.id) {
                    await modifySimProduct(sim.imsi, selectedOffer, user.id)
                      .then(async (response) => {
                        setStatus("Success");
                      })
                      .catch((error) => setStatus("Error"));
                  } else {
                    await modifySimProduct(
                      sim.imsi,
                      selectedOffer,
                      impersonationUser.id
                    )
                      .then(async (response) => {
                        setStatus("Success");
                      })
                      .catch((error) => setStatus("Error"));
                  }
                  setNow(100);
                }}
                style={{
                  "--dynamic-bg-color": !impersonationUser.id
                    ? user.backgroundColor
                    : impersonationUser.backgroundColor,
                  "--dynamic-font-color": !impersonationUser.id
                    ? user.backgroundColor
                    : impersonationUser.backgroundColor,
                  border: "none",
                }}
              >
                Modify
              </Button>
            </>
          )}
          {now === 100 && (
            <Button
              style={{
                "--dynamic-bg-color": !impersonationUser.id
                  ? user.backgroundColor
                  : impersonationUser.backgroundColor,
                "--dynamic-font-color": !impersonationUser.id
                  ? user.backgroundColor
                  : impersonationUser.backgroundColor,
                border: "none",
              }}
              onClick={async () => {
                setOpenModifyProductModal(false);
                if (!impersonationUser.id) {
                  await dispatch(getSims(user.id, page + 1, pageSize, filters));
                } else {
                  await dispatch(
                    getSims(impersonationUser.id, page + 1, pageSize, filters)
                  );
                }
              }}
            >
              Close
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    );
  } else {
    return (
      <Modal show={openModifyProductModal}>
        <Modal.Header>Modify SIM</Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <Spinner animation="border" variant="primary" className="me-2" />
          </div>
        </Modal.Body>
      </Modal>
    );
  }
};

export default ModifySimModal;
