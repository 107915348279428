import React from "react";
import { matchPath } from "react-router-dom";

import SidebarNavListItem from "./SidebarNavListItem";
import SidebarNavList from "./SidebarNavList";

const filterChildrenByPrivilege = (children, privilege) => {
  switch (privilege) {
    case "ADMIN":
      return children;
    case "MANAGER":
      return children.filter(
        (child) =>
          child.title !== "Admins" &&
          child.title !== "Managers" &&
          child.title !== "Tenants"
      );
    case "TENANT":
      return children.filter(
        (child) => child.title !== "Admins" && child.title !== "Tenants"
      );
    case "CUSTOMER":
      return children.filter(
        (child) =>
          child.title !== "Admins" &&
          child.title !== "Managers" &&
          child.title !== "Tenants" &&
          child.title !== "Customers"
      );
    case "SUBACCOUNT":
      return children.filter(
        (child) =>
          child.title !== "Admins" &&
          child.title !== "Managers" &&
          child.title !== "Tenants" &&
          child.title !== "Customers" &&
          child.title !== "Billing Products" &&
          child.title !== "Invoices" &&
          child.title !== "Statements" &&
          child.title !== "Orders"
      );
    default:
      return [];
  }
};

const reduceChildRoutes = (props) => {
  const { items, page, depth, currentRoute, privilege } = props;
  if (!page.children) {
    if (privilege === "VIEWER" && page.title !== "Dashboard") {
      return items;
    }
    if (privilege !== "ADMIN" && page.title === "Documentation") {
      return items;
    }
    items.push(
      <SidebarNavListItem
        depth={depth}
        href={page.href}
        icon={page.icon}
        key={page.title}
        badge={page.badge}
        title={page.title}
      />
    );
  } else if (page.title === "CRM") {
    if (privilege === "VIEWER" || privilege === "SUBACCOUNT") {
      return items;
    }
    const open = page.href
      ? !!matchPath(
          {
            path: page.href,
            end: false,
          },
          currentRoute
        )
      : false;

    const filteredChildren = filterChildrenByPrivilege(
      page.children,
      privilege
    );

    if (filteredChildren.length === 0) {
      return items;
    }

    items.push(
      <SidebarNavListItem
        depth={depth}
        icon={page.icon}
        key={page.title}
        badge={page.badge}
        open={!!open}
        title={page.title}
        href={page.href}
      >
        <SidebarNavList depth={depth + 1} pages={filteredChildren} />
      </SidebarNavListItem>
    );
  } else if (page.title === "Account") {
    if (privilege === "VIEWER") {
      return items;
    }
    const open = page.href
      ? !!matchPath(
          {
            path: page.href,
            end: false,
          },
          currentRoute
        )
      : false;

    const filteredChildren = filterChildrenByPrivilege(
      page.children,
      privilege
    );

    if (filteredChildren.length === 0) {
      return items;
    }
    items.push(
      <SidebarNavListItem
        depth={depth}
        icon={page.icon}
        key={page.title}
        badge={page.badge}
        open={!!open}
        title={page.title}
        href={page.href}
      >
        <SidebarNavList depth={depth + 1} pages={filteredChildren} />
      </SidebarNavListItem>
    );
  } else if (page.title === "Catalog") {
    return items;
    // if (
    //   privilege === "CUSTOMER" ||
    //   privilege === "SUBACCOUNT" ||
    //   privilege === "VIEWER"
    // ) {
    //   return items;
    // }
    // const open = page.href
    //   ? !!matchPath(
    //       {
    //         path: page.href,
    //         end: false,
    //       },
    //       currentRoute
    //     )
    //   : false;

    // items.push(
    //   <SidebarNavListItem
    //     depth={depth}
    //     icon={page.icon}
    //     key={page.title}
    //     badge={page.badge}
    //     open={!!open}
    //     title={page.title}
    //     href={page.href}
    //   >
    //     <SidebarNavList depth={depth + 1} pages={page.children} />
    //   </SidebarNavListItem>
    // );
  }

  return items;
};

export default reduceChildRoutes;
