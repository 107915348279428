import axios from "../../utils/axios";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dataSessions: [],
  zeroSessions: [],
  dataVolumes: [],
  comparison: [],
  zeroSessionComparison: [],
  dataVolumeComparison: [],
};

const sessionSlice = createSlice({
  name: "sessionSlice",
  initialState,
  reducers: {
    setDataSessions(state, action) {
      state.dataSessions = action.payload;
    },
    setComparison(state, action) {
      state.comparison = action.payload;
    },
    setZeroSessionComparison(state, action) {
      state.zeroSessionComparison = action.payload;
    },
    setDataVolumeComparison(state, action) {
      state.dataVolumeComparison = action.payload;
    },
    setDataVolumes(state, action) {
      state.dataVolumes = action.payload;
    },
  },
});

export function getSessionComparison(id, type) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    try {
      const response = await axios.get(
        `https://dev.pos2cloud.com:5000/api/dataSession/${id}/count?type=${type}`,
        {
          headers: {
            accessToken: `Bearer ${accessToken}`,
          },
        }
      );
      dispatch(sessionSlice.actions.setComparison(response.data));
    } catch (error) {
      if (error.response && error.response.status === 401) {
        throw new Error("Unauthorized");
      }
    }
  };
}

export function getZeroSessionComparison(id, type) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    try {
      const response = await axios.get(
        `https://dev.pos2cloud.com:5000/api/dataSession/${id}/zeroSession/count?type=${type}`,
        {
          headers: {
            accessToken: `Bearer ${accessToken}`,
          },
        }
      );
      dispatch(sessionSlice.actions.setZeroSessionComparison(response.data));
    } catch (error) {
      if (error.response && error.response.status === 401) {
        throw new Error("Unauthorized");
      }
    }
  };
}

export async function getSessionComparisonForSim(id, imsi, type) {
  const accessToken = window.localStorage.getItem("accessToken");
  try {
    const response = await axios.get(
      `https://dev.pos2cloud.com:5000/api/dataSession/${id}/countForSim?type=${type}&imsi=${imsi}`,
      {
        headers: {
          accessToken: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      throw new Error("Unauthorized");
    }
  }
}

export async function getZeroSessionComparisonForSim(id, imsi, type) {
  const accessToken = window.localStorage.getItem("accessToken");
  try {
    const response = await axios.get(
      `https://dev.pos2cloud.com:5000/api/dataSession/${id}/zeroSession/countForSim?type=${type}&imsi=${imsi}`,
      {
        headers: {
          accessToken: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      throw new Error("Unauthorized");
    }
  }
}

export function getDataVolumeComparison(id, type) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    try {
      const response = await axios.get(
        `https://dev.pos2cloud.com:5000/api/dataSession/${id}/dataVolume/sum?type=${type}`,
        {
          headers: {
            accessToken: `Bearer ${accessToken}`,
          },
        }
      );
      dispatch(sessionSlice.actions.setDataVolumeComparison(response.data));
    } catch (error) {
      if (error.response && error.response.status === 401) {
        throw new Error("Unauthorized");
      }
    }
  };
}

export async function getDataVolumeComparisonForSim(id, imsi, type) {
  const accessToken = window.localStorage.getItem("accessToken");
  try {
    const response = await axios.get(
      `https://dev.pos2cloud.com:5000/api/dataSession/${id}/dataVolume/sumForSim?type=${type}&imsi=${imsi}`,
      {
        headers: {
          accessToken: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      throw new Error("Unauthorized");
    }
  }
}

export async function getDistribution(id, type, period) {
  const accessToken = window.localStorage.getItem("accessToken");
  try {
    if (type !== "alerts") {
      const response = await axios.get(
        `https://dev.pos2cloud.com:5000/api/dataSession/${id}/distribution?type=${type}&period=${period}`,
        {
          headers: {
            accessToken: `Bearer ${accessToken}`,
          },
        }
      );
      return response.data;
    } else {
      const response = await axios.get(
        `https://dev.pos2cloud.com:5000/api/alerts/${id}/distribution?period=${period}`,
        {
          headers: {
            accessToken: `Bearer ${accessToken}`,
          },
        }
      );
      return response.data;
    }
  } catch (error) {
    if (error.response && error.response.status === 401) {
      throw new Error("Unauthorized");
    } else {
      throw error;
    }
  }
}

export async function getTopMetrics(id, type, period) {
  const accessToken = window.localStorage.getItem("accessToken");
  try {
    const response = await axios.get(
      `https://dev.pos2cloud.com:5000/api/dataSession/${id}/topMetrics?type=${type}&period=${period}`,
      {
        headers: {
          accessToken: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      throw new Error("Unauthorized");
    } else {
      throw error;
    }
  }
}

export async function getCountryDistribution(id, type, period) {
  const accessToken = window.localStorage.getItem("accessToken");
  try {
    const response = await axios.get(
      `https://dev.pos2cloud.com:5000/api/dataSession/${id}/distribution/country?type=${type}&period=${period}`,
      {
        headers: {
          accessToken: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      throw new Error("Unauthorized");
    } else {
      throw error;
    }
  }
}

export async function getNetworkDistribution(id, country) {
  const accessToken = window.localStorage.getItem("accessToken");
  try {
    const response = await axios.get(
      `https://dev.pos2cloud.com:5000/api/dataSession/${id}/network/distribution?country=${country}`,
      {
        headers: {
          accessToken: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      throw new Error("Unauthorized");
    } else {
      throw error;
    }
  }
}

export async function getNetworkVolumeDistribution(id, country) {
  const accessToken = window.localStorage.getItem("accessToken");
  try {
    const response = await axios.get(
      `https://dev.pos2cloud.com:5000/api/dataSession/${id}/network/volume?country=${country}`,
      {
        headers: {
          accessToken: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      throw new Error("Unauthorized");
    } else {
      throw error;
    }
  }
}

export async function countDataSessionsForImsiList(id, imsiList) {
  const accessToken = window.localStorage.getItem("accessToken");
  try {
    const response = await axios.get(
      `https://dev.pos2cloud.com:5000/api/dataSession/${id}/countSessionsForList?imsiList=${imsiList}`,
      {
        headers: {
          accessToken: `Bearer ${accessToken}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      throw new Error("Unauthorized");
    } else if (error.response && error.response.status === 500) {
      throw new Error(error.response);
    }
  }
}

export async function countZeroSessionsForImsiList(id, imsiList) {
  const accessToken = window.localStorage.getItem("accessToken");
  try {
    const response = await axios.get(
      `https://dev.pos2cloud.com:5000/api/dataSession/${id}/countZeroSessionsForList?imsiList=${imsiList}`,
      {
        headers: {
          accessToken: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      throw new Error("Unauthorized");
    } else if (error.response && error.response.status === 500) {
      throw new Error(error.response);
    }
  }
}

export async function sumDataVolumeForImsiList(id, imsiList) {
  const accessToken = window.localStorage.getItem("accessToken");
  try {
    const response = await axios.get(
      `https://dev.pos2cloud.com:5000/api/dataSession/${id}/sumDataVolumeForList?imsiList=${imsiList}`,
      {
        headers: {
          accessToken: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      throw new Error(error.response);
    } else if (error.response && error.response.status === 500) {
      throw new Error(error.response);
    }
  }
}

export async function getDistributionForSim(id, type, period, imsi) {
  const accessToken = window.localStorage.getItem("accessToken");
  try {
    if (type !== "alerts") {
      const response = await axios.get(
        `https://dev.pos2cloud.com:5000/api/dataSession/${id}/distributionForSim?type=${type}&period=${period}&imsi=${imsi}`,
        {
          headers: {
            accessToken: `Bearer ${accessToken}`,
          },
        }
      );
      return response.data;
    } else {
      const response = await axios.get(
        `https://dev.pos2cloud.com:5000/api/alerts/${id}/distributionForSim?period=${period}`,
        {
          headers: {
            accessToken: `Bearer ${accessToken}`,
          },
        }
      );
      return response.data;
    }
  } catch (error) {
    if (error.response && error.response.status === 401) {
      throw new Error("Unauthorized");
    } else {
      throw error;
    }
  }
}

export async function getSimDataSessions(id, imsi, page, pageSize) {
  const accessToken = localStorage.getItem("accessToken");
  try {
    const response = await axios.get(
      `https://dev.pos2cloud.com:5000/api/dataSession/${id}/getSimSessions?imsi=${imsi}&page=${page}&pageSize=${pageSize}`,
      {
        headers: {
          accessToken: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      throw new Error("Unauthorized");
    } else if (error.response && error.response.status === 500) {
      throw new Error(error.response);
    }
  }
}

export const {
  setDataSessions,
  setComparison,
  setZeroSessionComparison,
  setDataVolumeComparison,
  setDataVolumes,
} = sessionSlice.actions;

export default sessionSlice.reducer;
