import axios from "../../utils/axios";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  admins: [],
};

const adminsSlice = createSlice({
  name: "admins",
  initialState,
  reducers: {
    setAdmins(state, action) {
      state.admins = action.payload;
    },
    updateFullName(state, action) {
      const { id, fullName } = action.payload;
      const updatedAdmins = state.admins.map((admin) => {
        if (admin.id === id) {
          return {
            ...admin,
            fullName: fullName,
          };
        }
        return admin;
      });
      return {
        ...state,
        admins: updatedAdmins,
      };
    },
    updateAddress(state, action) {
      const { id, address } = action.payload;
      const updatedAdmins = state.admins.map((admin) => {
        if (admin.id === id) {
          return {
            ...admin,
            address: address,
          };
        }
        return admin;
      });
      return {
        ...state,
        admins: updatedAdmins,
      };
    },
    updateCity(state, action) {
      const { id, city } = action.payload;
      const updatedAdmins = state.admins.map((admin) => {
        if (admin.id === id) {
          return {
            ...admin,
            city: city,
          };
        }
        return admin;
      });
      return {
        ...state,
        admins: updatedAdmins,
      };
    },
    updateCountry(state, action) {
      const { id, country } = action.payload;
      const updatedAdmins = state.admins.map((admin) => {
        if (admin.id === id) {
          return {
            ...admin,
            country: country,
          };
        }
        return admin;
      });
      return {
        ...state,
        admins: updatedAdmins,
      };
    },
    updatePostcode(state, action) {
      const { id, postcode } = action.payload;
      const updatedAdmins = state.admins.map((admin) => {
        if (admin.id === id) {
          return {
            ...admin,
            postcode: postcode,
          };
        }
        return admin;
      });
      return {
        ...state,
        admins: updatedAdmins,
      };
    },
    updateTelephone(state, action) {
      const { id, telephone } = action.payload;
      const updatedAdmins = state.admins.map((admin) => {
        if (admin.id === id) {
          return {
            ...admin,
            telephone: telephone,
          };
        }
        return admin;
      });
      return {
        ...state,
        admins: updatedAdmins,
      };
    },
    updateMobile(state, action) {
      const { id, mobile } = action.payload;
      const updatedAdmins = state.admins.map((admin) => {
        if (admin.id === id) {
          return {
            ...admin,
            mobileNumber: mobile,
          };
        }
        return admin;
      });
      return {
        ...state,
        admins: updatedAdmins,
      };
    },
    updateEmail(state, action) {
      const { id, email } = action.payload;
      const updatedAdmins = state.admins.map((admin) => {
        if (admin.id === id) {
          return {
            ...admin,
            email: email,
          };
        }
        return admin;
      });
      return {
        ...state,
        admins: updatedAdmins,
      };
    },
    updateOccupation(state, action) {
      const { id, occupation } = action.payload;
      const updatedAdmins = state.admins.map((admin) => {
        if (admin.id === id) {
          return {
            ...admin,
            occupation: occupation,
          };
        }
        return admin;
      });
      return {
        ...state,
        admins: updatedAdmins,
      };
    },
    updateCompany(state, action) {
      const { id, company } = action.payload;
      const updatedAdmins = state.admins.map((admin) => {
        if (admin.id === id) {
          return {
            ...admin,
            company: company,
          };
        }
        return admin;
      });
      return {
        ...state,
        admins: updatedAdmins,
      };
    },
    updateTIN(state, action) {
      const { id, tin } = action.payload;
      const updatedAdmins = state.admins.map((admin) => {
        if (admin.id === id) {
          return {
            ...admin,
            tinNumber: tin,
          };
        }
        return admin;
      });
      return {
        ...state,
        admins: updatedAdmins,
      };
    },
    updatePrivilege(state, action) {
      const { id, privilege } = action.payload;
      const updatedAdmins = state.admins.map((admin) => {
        if (admin.id === id) {
          return {
            ...admin,
            privilege: privilege,
          };
        }
        return admin;
      });
      return {
        ...state,
        admins: updatedAdmins,
      };
    },
    addAdmin: (state, action) => {
      const newAdmin = action.payload;
      return {
        ...state,
        admins: [...state.admins, newAdmin],
      };
    },
    removeAdmin: (state, action) => {
      const idToRemove = action.payload;
      const newAdmins = state.admins.filter((admin) => admin.id !== idToRemove);
      return {
        ...state,
        admins: newAdmins,
      };
    },
  },
});

export function getAdmins(id) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    try {
      const response = await axios.get(
        "https://dev.pos2cloud.com:5000/api/admins/" + id + "/getAdmins",
        {
          headers: {
            accessToken: "Bearer " + accessToken,
          },
        }
      );
      dispatch(adminsSlice.actions.setAdmins(response.data));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        dispatch(adminsSlice.actions.setAdmins([]));
      }
    }
  };
}

export function updateAdminName(id, fullName) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("fullName", fullName);
    try {
      await axios.put(
        `https://dev.pos2cloud.com:5000/api/systemusers/${id}/update/fullName`,
        formData,
        {
          headers: {
            accessToken: "Bearer " + accessToken,
          },
        }
      );
      dispatch(adminsSlice.actions.updateFullName({ id, fullName }));
    } catch (error) {
      alert(error.response);
      if (error.response && error.response.status === 404) {
        console.log("User not found");
      } else if (error.status === 500) {
        console.log("Internal Server Error");
      }
    }
  };
}

export function updateAdminAddress(id, address) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("address", address);
    try {
      await axios.put(
        "https://dev.pos2cloud.com:5000/api/systemusers/" +
          id +
          "/update/address",
        formData,
        {
          headers: {
            accessToken: "Bearer " + accessToken,
          },
        }
      );
      dispatch(adminsSlice.actions.updateAddress({ id, address }));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.log("User not found");
      } else if (error.status === 500) {
        console.log("Internal Server Error");
      }
    }
  };
}

export function updateAdminCity(id, city) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("city", city);
    try {
      await axios.put(
        "https://dev.pos2cloud.com:5000/api/systemusers/" + id + "/update/city",
        formData,
        {
          headers: {
            accessToken: "Bearer " + accessToken,
          },
        }
      );
      dispatch(adminsSlice.actions.updateCity({ id, city }));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.log("User not found");
      } else if (error.status === 500) {
        console.log("Internal Server Error");
      }
    }
  };
}

export function updateAdminCountry(id, country) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("country", country);
    try {
      await axios.put(
        "https://dev.pos2cloud.com:5000/api/systemusers/" +
          id +
          "/update/country",
        formData,
        {
          headers: {
            accessToken: "Bearer " + accessToken,
          },
        }
      );
      dispatch(adminsSlice.actions.updateCountry({ id, country }));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.log("User not found");
      } else if (error.status === 500) {
        console.log("Internal Server Error");
      }
    }
  };
}

export function updateAdminPostcode(id, postcode) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("postcode", postcode);
    try {
      await axios.put(
        "https://dev.pos2cloud.com:5000/api/systemusers/" +
          id +
          "/update/postcode",
        formData,
        {
          headers: {
            accessToken: "Bearer " + accessToken,
          },
        }
      );
      dispatch(adminsSlice.actions.updatePostcode({ id, postcode }));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.log("User not found");
      } else if (error.status === 500) {
        console.log("Internal Server Error");
      }
    }
  };
}

export function updateAdminTelephone(id, telephone) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("telephone", telephone);
    try {
      await axios.put(
        "https://dev.pos2cloud.com:5000/api/systemusers/" +
          id +
          "/update/telephone",
        formData,
        {
          headers: {
            accessToken: "Bearer " + accessToken,
          },
        }
      );
      dispatch(adminsSlice.actions.updateTelephone({ id, telephone }));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.log("User not found");
      } else if (error.status === 500) {
        console.log("Internal Server Error");
      }
    }
  };
}

export function updateAdminMobile(id, mobile) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("mobile", mobile);
    try {
      await axios.put(
        "https://dev.pos2cloud.com:5000/api/systemusers/" +
          id +
          "/update/mobile",
        formData,
        {
          headers: {
            accessToken: "Bearer " + accessToken,
          },
        }
      );
      dispatch(adminsSlice.actions.updateMobile({ id, mobile }));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.log("User not found");
      } else if (error.status === 500) {
        console.log("Internal Server Error");
      }
    }
  };
}

export function updateAdminEmail(id, email) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("email", email);
    try {
      await axios.put(
        "https://dev.pos2cloud.com:5000/api/systemusers/" +
          id +
          "/update/email",
        formData,
        {
          headers: {
            accessToken: "Bearer " + accessToken,
          },
        }
      );
      dispatch(adminsSlice.actions.updateEmail({ id, email }));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.log("User not found");
      } else if (error.status === 500) {
        console.log("Internal Server Error");
      }
    }
  };
}

export function updateAdminOccupation(id, occupation) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("occupation", occupation);
    try {
      await axios.put(
        "https://dev.pos2cloud.com:5000/api/systemusers/" +
          id +
          "/update/occupation",
        formData,
        {
          headers: {
            accessToken: "Bearer " + accessToken,
          },
        }
      );
      dispatch(adminsSlice.actions.updateOccupation({ id, occupation }));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.log("User not found");
      } else if (error.status === 500) {
        console.log("Internal Server Error");
      }
    }
  };
}

export function updateAdminCompany(id, company) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("company", company);
    try {
      await axios.put(
        "https://dev.pos2cloud.com:5000/api/systemusers/" +
          id +
          "/update/company",
        formData,
        {
          headers: {
            accessToken: "Bearer " + accessToken,
          },
        }
      );
      dispatch(adminsSlice.actions.updateCompany({ id, company }));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.log("User not found");
      } else if (error.status === 500) {
        console.log("Internal Server Error");
      }
    }
  };
}

export function updateAdminTIN(id, tin) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("tin", tin);
    try {
      await axios.put(
        "https://dev.pos2cloud.com:5000/api/systemusers/" + id + "/update/tin",
        formData,
        {
          headers: {
            accessToken: "Bearer " + accessToken,
          },
        }
      );
      dispatch(adminsSlice.actions.updateTIN({ id, tin }));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.log("User not found");
      } else if (error.status === 500) {
        console.log("Internal Server Error");
      }
    }
  };
}

export function updateAdminPrivilege(
  userId,
  clientId,
  privilege,
  upperEntityId
) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("entityId", clientId);
    formData.append("privilege", privilege);
    if (privilege !== "ADMIN" && privilege !== "VIEWER") {
      formData.append("upperEntityId", upperEntityId);
    } else {
      formData.append("upperEntityId", -1);
    }
    try {
      await axios.put(
        "https://dev.pos2cloud.com:5000/api/systemusers/" +
          userId +
          "/update/privilege",
        formData,
        {
          headers: {
            accessToken: "Bearer " + accessToken,
          },
        }
      );
      dispatch(adminsSlice.actions.updatePrivilege({ clientId, privilege }));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.log("User not found");
      } else if (error.status === 500) {
        console.log("Internal Server Error");
      }
    }
  };
}

export function addNewAdmin(user) {
  return (dispatch) => {
    dispatch(adminsSlice.actions.addAdmin({ user }));
  };
}

export function removeExistingAdmin(id) {
  return (dispatch) => {
    dispatch(adminsSlice.actions.removeAdmin(id));
  };
}

export const {
  setAdmins,
  updateFullName,
  updateAddress,
  updateCity,
  updateCountry,
  updatePostcode,
  updateTelephone,
  updateEmail,
  updateOccupation,
  updateCompany,
  updateTIN,
  updatePrivilege,
  addAdmin,
  removeAdmin,
} = adminsSlice.actions;
export default adminsSlice.reducer;
