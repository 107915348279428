const offerColumns = [
  {
    Header: "Name",
    accessor: "name",
    sortable: true,
  },
  {
    Header: "Amount",
    accessor: "amount",
    sortable: true,
  },
];

export { offerColumns };
