import React from "react";
import { Card, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const Order = ({ order }) => {
  const { t } = useTranslation();

  return (
    <Card className="mb-3 bg-light cursor-grab border">
      <Card.Body className="p-3">
        <Table size="sm" className="my-2">
          <tbody>
            <tr>
              <th>{t("ID")}</th>
              <td>{order.id}</td>
            </tr>
            <tr>
              <th>{t("Name")}</th>
              <td>{order.name}</td>
            </tr>
            <tr>
              <th>{t("Creation Date")}</th>
              <td>{order.creationDate}</td>
            </tr>
            <tr>
              <th>{t("Last Update")}</th>
              <td>{order.lastUpdate}</td>
            </tr>
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

export default Order;
