import React from "react";
import { Helmet } from "react-helmet-async";
import SupportHeader from "./SupportHeader";
import SupportContent from "./SupportContent";
import { useSelector } from "react-redux";

export const SupportPage = () => {
  const { user } = useSelector((state) => state.user);
  return (
    <React.Fragment className="p-0">
      <Helmet title="Support">
        <link rel="icon" href={`data:image/x-icon;base64,${user.favicon}`} />
      </Helmet>
      <SupportHeader />
      <SupportContent />
    </React.Fragment>
  );
};

export default SupportPage;
