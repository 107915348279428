import countryList from "react-select-country-list";
import axios from "../../utils/axios";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  managers: [],
};

const managersSlice = createSlice({
  name: "managers",
  initialState,
  reducers: {
    setManagers(state, action) {
      state.managers = action.payload;
    },
    clearManagers(state, action) {
      state.managers = [];
    },
    updateFullName(state, action) {
      const { id, fullName } = action.payload;
      const updatedManagers = state.managers.map((manager) => {
        if (manager.id === id) {
          return {
            ...manager,
            fullName: fullName,
          };
        }
        return manager;
      });
      return {
        ...state,
        managers: updatedManagers,
      };
    },
    updateAddress(state, action) {
      const { id, address } = action.payload;
      const updatedManagers = state.managers.map((manager) => {
        if (manager.id === id) {
          return {
            ...manager,
            address: address,
          };
        }
        return manager;
      });
      return {
        ...state,
        managers: updatedManagers,
      };
    },
    updateCity(state, action) {
      const { id, city } = action.payload;
      const updatedManagers = state.managers.map((manager) => {
        if (manager.id === id) {
          return {
            ...manager,
            city: city,
          };
        }
        return manager;
      });
      return {
        ...state,
        managers: updatedManagers,
      };
    },
    updateCountry(state, action) {
      const { id, country } = action.payload;
      const updatedManagers = state.managers.map((manager) => {
        if (manager.id === id) {
          return {
            ...manager,
            country: country,
          };
        }
        return manager;
      });
      return {
        ...state,
        managers: updatedManagers,
      };
    },
    updatePostcode(state, action) {
      const { id, postcode } = action.payload;
      const updatedManagers = state.managers.map((manager) => {
        if (manager.id === id) {
          return {
            ...manager,
            postcode: postcode,
          };
        }
        return manager;
      });
      return {
        ...state,
        managers: updatedManagers,
      };
    },
    updateTelephone(state, action) {
      const { id, telephone } = action.payload;
      const updatedManagers = state.managers.map((manager) => {
        if (manager.id === id) {
          return {
            ...manager,
            telephone: telephone,
          };
        }
        return manager;
      });
      return {
        ...state,
        managers: updatedManagers,
      };
    },
    updateMobile(state, action) {
      const { id, mobile } = action.payload;
      const updatedManagers = state.managers.map((manager) => {
        if (manager.id === id) {
          return {
            ...manager,
            mobileNumber: mobile,
          };
        }
        return manager;
      });
      return {
        ...state,
        managers: updatedManagers,
      };
    },
    updateEmail(state, action) {
      const { id, email } = action.payload;
      const updatedManagers = state.managers.map((manager) => {
        if (manager.id === id) {
          return {
            ...manager,
            email: email,
          };
        }
        return manager;
      });
      return {
        ...state,
        managers: updatedManagers,
      };
    },
    updateOccupation(state, action) {
      const { id, occupation } = action.payload;
      const updatedManagers = state.managers.map((manager) => {
        if (manager.id === id) {
          return {
            ...manager,
            occupation: occupation,
          };
        }
        return manager;
      });
      return {
        ...state,
        managers: updatedManagers,
      };
    },
    updateCompany(state, action) {
      const { id, company } = action.payload;
      const updatedManagers = state.managers.map((manager) => {
        if (manager.id === id) {
          return {
            ...manager,
            company: company,
          };
        }
        return manager;
      });
      return {
        ...state,
        managers: updatedManagers,
      };
    },
    updateTIN(state, action) {
      const { id, tin } = action.payload;
      const updatedManagers = state.managers.map((manager) => {
        if (manager.id === id) {
          return {
            ...manager,
            tinNumber: tin,
          };
        }
        return manager;
      });
      return {
        ...state,
        managers: updatedManagers,
      };
    },
    updateOffers(state, action) {
      const { id, offers } = action.payload;
      const updatedManagers = state.managers.map((manager) => {
        if (manager.id === id) {
          return {
            ...manager,
            offers: offers,
          };
        }
        return manager;
      });
      return {
        ...state,
        managers: updatedManagers,
      };
    },
    updateFavicon(state, action) {
      const { id, favicon } = action.payload;
      const updatedManagers = state.managers.map((manager) => {
        if (manager.id === id) {
          return {
            ...manager,
            favicon: favicon,
          };
        }
        return manager;
      });
      return {
        ...state,
        managers: updatedManagers,
      };
    },
    updatePrivilege(state, action) {
      const { id, privilege } = action.payload;
      const updatedManagers = state.managers.map((manager) => {
        if (manager.id === id) {
          return {
            ...manager,
            privilege: privilege,
          };
        }
        return manager;
      });
      return {
        ...state,
        managers: updatedManagers,
      };
    },
    updateWhitelabel(state, action) {
      const { id, whitelabelImage } = action.payload;
      const updatedManagers = state.managers.map((manager) => {
        if (manager.id === id) {
          return {
            ...manager,
            whitelabelImage: whitelabelImage,
          };
        }
        return manager;
      });
      return {
        ...state,
        managers: updatedManagers,
      };
    },
    updateBackgroundColor(state, action) {
      const { id, color } = action.payload;
      const updatedManagers = state.managers.map((manager) => {
        if (manager.id === id) {
          return {
            ...manager,
            backgroundColor: color,
          };
        }
        return manager;
      });
      return {
        ...state,
        managers: updatedManagers,
      };
    },
    updateFontColor(state, action) {
      const { id, color } = action.payload;
      const updatedManagers = state.managers.map((manager) => {
        if (manager.id === id) {
          return {
            ...manager,
            fontColor: color,
          };
        }
        return manager;
      });
      return {
        ...state,
        managers: updatedManagers,
      };
    },
    updateSidebarPosition(state, action) {
      const { id, position } = action.payload;
      const updatedManagers = state.managers.map((manager) => {
        if (manager.id === id) {
          return {
            ...manager,
            sidebarPosition: position,
          };
        }
        return manager;
      });
      return {
        ...state,
        managers: updatedManagers,
      };
    },

    addManager: (state, action) => {
      const newManager = action.payload;
      return {
        ...state,
        managers: [...state.managers, newManager],
      };
    },
    removeManager: (state, action) => {
      const idToRemove = action.payload;
      const newManagers = state.managers.filter(
        (manager) => manager.id !== idToRemove
      );
      return {
        ...state,
        managers: newManagers,
      };
    },
  },
});

export const clearAllManagers = () => (dispatch) => {
  dispatch(managersSlice.actions.clearManagers());
};

export function getManagers(id) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    try {
      const response = await axios.get(
        "https://dev.pos2cloud.com:5000/api/managers/" + id + "/getManagers",
        {
          headers: {
            accessToken: "Bearer " + accessToken,
          },
        }
      );
      dispatch(managersSlice.actions.setManagers(response.data));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        dispatch(managersSlice.actions.setManagers([]));
      }
    }
  };
}

export function updateManagerName(id, fullName) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("fullName", fullName);
    try {
      await axios.put(
        "https://dev.pos2cloud.com:5000/api/systemusers/" +
          id +
          "/update/fullName",
        formData,
        {
          headers: {
            accessToken: "Bearer " + accessToken,
          },
        }
      );
      dispatch(managersSlice.actions.updateFullName({ id, fullName }));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.log("User not found");
      } else if (error.status === 500) {
        console.log("Internal Server Error");
      }
    }
  };
}

export function updateManagerAddress(id, address) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("address", address);
    try {
      await axios.put(
        "https://dev.pos2cloud.com:5000/api/systemusers/" +
          id +
          "/update/address",
        formData,
        {
          headers: {
            accessToken: "Bearer " + accessToken,
          },
        }
      );
      dispatch(managersSlice.actions.updateAddress({ id, address }));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.log("User not found");
      } else if (error.status === 500) {
        console.log("Internal Server Error");
      }
    }
  };
}

export function updateManagerCity(id, city) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("city", city);
    try {
      await axios.put(
        "https://dev.pos2cloud.com:5000/api/systemusers/" + id + "/update/city",
        formData,
        {
          headers: {
            accessToken: "Bearer " + accessToken,
          },
        }
      );
      dispatch(managersSlice.actions.updateCity({ id, city }));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.log("User not found");
      } else if (error.status === 500) {
        console.log("Internal Server Error");
      }
    }
  };
}

export function updateManagerCountry(id, country) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("country", country);
    try {
      await axios.put(
        "https://dev.pos2cloud.com:5000/api/systemusers/" +
          id +
          "/update/country",
        formData,
        {
          headers: {
            accessToken: "Bearer " + accessToken,
          },
        }
      );
      dispatch(managersSlice.actions.updateCountry({ id, country }));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.log("User not found");
      } else if (error.status === 500) {
        console.log("Internal Server Error");
      }
    }
  };
}

export function updateManagerPostcode(id, postcode) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("postcode", postcode);
    try {
      await axios.put(
        "https://dev.pos2cloud.com:5000/api/systemusers/" +
          id +
          "/update/postcode",
        formData,
        {
          headers: {
            accessToken: "Bearer " + accessToken,
          },
        }
      );
      dispatch(managersSlice.actions.updatePostcode({ id, postcode }));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.log("User not found");
      } else if (error.status === 500) {
        console.log("Internal Server Error");
      }
    }
  };
}

export function updateManagerTelephone(id, telephone) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("telephone", telephone);
    try {
      await axios.put(
        "https://dev.pos2cloud.com:5000/api/systemusers/" +
          id +
          "/update/telephone",
        formData,
        {
          headers: {
            accessToken: "Bearer " + accessToken,
          },
        }
      );
      dispatch(managersSlice.actions.updateTelephone({ id, telephone }));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.log("User not found");
      } else if (error.status === 500) {
        console.log("Internal Server Error");
      }
    }
  };
}

export function updateManagerMobile(id, mobile) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("mobile", mobile);
    try {
      await axios.put(
        "https://dev.pos2cloud.com:5000/api/systemusers/" +
          id +
          "/update/mobile",
        formData,
        {
          headers: {
            accessToken: "Bearer " + accessToken,
          },
        }
      );
      dispatch(managersSlice.actions.updateMobile({ id, mobile }));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.log("User not found");
      } else if (error.status === 500) {
        console.log("Internal Server Error");
      }
    }
  };
}

export function updateManagerEmail(id, email) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("email", email);
    try {
      await axios.put(
        "https://dev.pos2cloud.com:5000/api/systemusers/" +
          id +
          "/update/email",
        formData,
        {
          headers: {
            accessToken: "Bearer " + accessToken,
          },
        }
      );
      dispatch(managersSlice.actions.updateEmail({ id, email }));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.log("User not found");
      } else if (error.status === 500) {
        console.log("Internal Server Error");
      }
    }
  };
}

export function updateManagerOccupation(id, occupation) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("occupation", occupation);
    try {
      await axios.put(
        "https://dev.pos2cloud.com:5000/api/systemusers/" +
          id +
          "/update/occupation",
        formData,
        {
          headers: {
            accessToken: "Bearer " + accessToken,
          },
        }
      );
      dispatch(managersSlice.actions.updateOccupation({ id, occupation }));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.log("User not found");
      } else if (error.status === 500) {
        console.log("Internal Server Error");
      }
    }
  };
}

export function updateManagerCompany(id, company) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("company", company);
    try {
      await axios.put(
        "https://dev.pos2cloud.com:5000/api/systemusers/" +
          id +
          "/update/company",
        formData,
        {
          headers: {
            accessToken: "Bearer " + accessToken,
          },
        }
      );
      dispatch(managersSlice.actions.updateCompany({ id, company }));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.log("User not found");
      } else if (error.status === 500) {
        console.log("Internal Server Error");
      }
    }
  };
}

export function updateManagerTIN(id, tin) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("tin", tin);
    try {
      await axios.put(
        "https://dev.pos2cloud.com:5000/api/systemusers/" + id + "/update/tin",
        formData,
        {
          headers: {
            accessToken: "Bearer " + accessToken,
          },
        }
      );
      dispatch(managersSlice.actions.updateTIN({ id, tin }));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.log("User not found");
      } else if (error.status === 500) {
        console.log("Internal Server Error");
      }
    }
  };
}

export function updateManagerOffers(id, offers) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("offers", offers);
    try {
      await axios.put(
        `https://dev.pos2cloud.com:5000/api/managers/${id}/update/offers`,
        formData,
        {
          headers: {
            accessToken: `Bearer ${accessToken}`,
          },
        }
      );
      dispatch(managersSlice.actions.updateOffers({ id, offers }));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        throw new Error("User not found");
      } else {
        throw new Error("Internal Server Error");
      }
    }
  };
}

export function updateManagerFavicon(id, favicon) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("favicon", favicon);
    try {
      await axios.put(
        `https://dev.pos2cloud.com:5000/api/systemusers/${id}/update/favicon`,
        formData,
        {
          headers: {
            accessToken: `Bearer ${accessToken}`,
          },
        }
      );
      dispatch(managersSlice.actions.updateFavicon({ id, favicon }));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        throw new Error("User not found");
      } else if (error.status === 500) {
        console.log("Internal Server Error");
      }
    }
  };
}

export function updateManagerWhitelabel(id, whitelabel) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("whitelabel", whitelabel);
    try {
      await axios.put(
        `https://dev.pos2cloud.com:5000/api/systemusers/${id}/update/whitelabel`,
        formData,
        {
          headers: {
            accessToken: `Bearer ${accessToken}`,
          },
        }
      );
      dispatch(managersSlice.actions.updateWhitelabel({ id, whitelabel }));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        throw new Error("User not found");
      } else if (error.status === 500) {
        console.log("Internal Server Error");
      }
    }
  };
}

export function updateManagerBackgroundColor(id, color) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("backgroundColor", color);
    try {
      await axios.put(
        `https://dev.pos2cloud.com:5000/api/systemusers/${id}/update/backgroundColor`,
        formData,
        {
          headers: {
            accessToken: `Bearer ${accessToken}`,
          },
        }
      );
      dispatch(managersSlice.actions.updateBackgroundColor({ id, color }));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        throw new Error("User not found");
      } else if (error.status === 500) {
        console.log("Internal Server Error");
      }
    }
  };
}

export function updateManagerFontColor(id, color) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("fontColor", color);
    try {
      await axios.put(
        `https://dev.pos2cloud.com:5000/api/systemusers/${id}/update/fontColor`,
        formData,
        {
          headers: {
            accessToken: `Bearer ${accessToken}`,
          },
        }
      );
      dispatch(managersSlice.actions.updateFontColor({ id, color }));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        throw new Error("User not found");
      } else if (error.status === 500) {
        console.log("Internal Server Error");
      }
    }
  };
}

export function updateManagerSidebarPosition(id, position) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("sidebarPosition", position);
    try {
      await axios.put(
        `https://dev.pos2cloud.com:5000/api/systemusers/${id}/update/sidebarPosition`,
        formData,
        {
          headers: {
            accessToken: `Bearer ${accessToken}`,
          },
        }
      );
      dispatch(managersSlice.actions.updateSidebarPosition({ id, position }));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        throw new Error("User not found");
      } else if (error.status === 500) {
        console.log("Internal Server Error");
      }
    }
  };
}

export function updateManagerPrivilege(
  userId,
  clientId,
  privilege,
  upperEntityId
) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("entityId", clientId);
    formData.append("privilege", privilege);
    if (privilege !== "ADMIN" && privilege !== "VIEWER") {
      formData.append("upperEntityId", upperEntityId);
    } else {
      formData.append("upperEntityId", -1);
    }
    console.log(formData);

    try {
      await axios.put(
        "https://dev.pos2cloud.com:5000/api/systemusers/" +
          userId +
          "/update/privilege",
        formData,
        {
          headers: {
            accessToken: "Bearer " + accessToken,
          },
        }
      );
      dispatch(managersSlice.actions.updatePrivilege({ clientId, privilege }));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.log("User not found");
      } else if (error.status === 500) {
        console.log("Internal Server Error");
      }
    }
  };
}

export function addNewManager(
  id,
  fullName,
  address,
  city,
  country,
  postcode,
  telephoneNumber,
  mobileNumber,
  email,
  occupation,
  company,
  tin,
  portfolioId,
  image,
  minimumAmountToInvoice,
  invoiceType,
  invoiceHandlerType,
  currency,
  invoiceConfiguration,
  preferredLanguage,
  billingEmail,
  offers
) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    if (accessToken) {
      const options = countryList().getData();
      const abbreviation = country;
      const countryRecord = options.find((c) => c.value === abbreviation);
      const formData = new FormData();
      formData.append("fullName", fullName);
      formData.append("address", address);
      formData.append("city", city);
      formData.append("country", countryRecord.label);
      formData.append("postcode", postcode);
      formData.append("telephoneNumber", telephoneNumber);
      formData.append("mobileNumber", mobileNumber);
      formData.append("email", email);
      formData.append("occupation", occupation);
      formData.append("company", company);
      formData.append("tin", tin);
      formData.append("portfolioId", portfolioId);
      formData.append("photo", image);
      formData.append("minimumAmountToInvoice", minimumAmountToInvoice);
      formData.append("invoiceType", invoiceType);
      formData.append("invoiceHandlerType", invoiceHandlerType);
      formData.append("currency", currency);
      formData.append("invoiceConfiguration", invoiceConfiguration);
      formData.append("preferredLanguage", preferredLanguage);
      formData.append("billingEmail", billingEmail);
      formData.append("offers", JSON.stringify(offers));
      await axios.post(
        `https://dev.pos2cloud.com:5000/api/managers/${id}/addManager`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            accessToken: "Bearer " + accessToken,
          },
        }
      );
    }
    dispatch(getManagers(id));
  };
}

export function removeExistingManager(id) {
  return (dispatch) => {
    dispatch(managersSlice.actions.removeManager(id));
  };
}

export const {
  setManagers,
  clearManagers,
  updateFullName,
  updateAddress,
  updateCity,
  updateCountry,
  updatePostcode,
  updateTelephone,
  updateEmail,
  updateOccupation,
  updateCompany,
  updateTIN,
  updateOffers,
  updatePrivilege,
  addManager,
  removeManager,
  updateFavicon,
  updateWhitelabel,
  updateBackgroundColor,
  updateFontColor,
  updateSidebarPosition,
} = managersSlice.actions;
export default managersSlice.reducer;
