import React from "react";
import { Bar } from "react-chartjs-2";

import { Card, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getOffers } from "../../redux/slices/offers";
import SimsByOfferModal from "../../pages/pages/dashboard/modals/SimsByOfferModal";

const OffersDistributionBar = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [offersDistribution, setOffersDistribution] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const { offers } = useSelector((state) => state.offers);
  const [showSimsByOfferModal, setShowSimsByOfferModal] = useState(false);
  const [offer, setOffer] = useState("");

  useEffect(() => {
    const fetchSimInventory = async (id) => {
      if (!id) {
        return;
      }
      await dispatch(getOffers(id));
    };

    if (!impersonationUser.id) {
      fetchSimInventory(user.id);
    } else {
      fetchSimInventory(impersonationUser.id);
    }
  }, [user, impersonationUser.id, dispatch]);

  useEffect(() => {
    setIsLoading(true);
    setOffersDistribution(offers);
    setIsLoading(false);
  }, [offers]);

  const sum = offersDistribution.reduce(
    (accumulator, obj) => accumulator + obj.amount,
    0
  );

  const data = {
    labels: offersDistribution.map((item) => item.name),
    datasets: [
      {
        data: offersDistribution.map((item) =>
          parseFloat((item.amount / sum) * 100).toFixed(2)
        ),
        backgroundColor: impersonationUser.id
          ? impersonationUser.backgroundColor
          : user.backgroundColor,
        borderColor: impersonationUser.id
          ? impersonationUser.backgroundColor
          : user.backgroundColor,
        hoverBackgroundColor: impersonationUser.id
          ? impersonationUser.backgroundColor
          : user.backgroundColor,
        hoverBorderColor: impersonationUser.id
          ? impersonationUser.backgroundColor
          : user.backgroundColor,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    scales: {
      yAxes: [
        {
          gridLines: {
            display: false,
          },
          stacked: false,
          ticks: {
            stepSize: 20,
          },
        },
      ],
      xAxes: [
        {
          stacked: false,
          gridLines: {
            color: "transparent",
          },
        },
      ],
    },
    onClick: (e, element) => {
      if (element.length > 0) {
        const newOffer = element[0]._model.label;
        setOffer(newOffer);
        setShowSimsByOfferModal(true);
      }
    },
  };

  if (isLoading || offersDistribution.length === 0) {
    return (
      <Card className="w-100">
        <Card.Header>
          <Card.Title>{t("Offers Distribution")}</Card.Title>
        </Card.Header>
        <Card.Body className="d-flex align-items-center justify-content-center">
          <div className="text-center">
            <Spinner animation="border" variant="primary" className="me-2" />
          </div>
        </Card.Body>
      </Card>
    );
  } else {
    return (
      <Card id="pieChart">
        <Card.Header>
          <Card.Title>{t("SIM Inventory")}</Card.Title>
        </Card.Header>
        <Card.Body>
          <div className="chart chart-sm">
            <Bar data={data} options={options} />
          </div>
          <SimsByOfferModal
            showSimsByOfferModal={showSimsByOfferModal}
            setShowSimsByOfferModal={setShowSimsByOfferModal}
            offer={offer}
          />
        </Card.Body>
      </Card>
    );
  }
};

export default OffersDistributionBar;
