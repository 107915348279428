import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { VectorMap } from "react-jvectormap";

import { Card, Dropdown } from "react-bootstrap";

import { MoreHorizontal } from "react-feather";
import usePalette from "../../hooks/usePalette";
import { useSelector } from "react-redux";
import { getCountryDistribution } from "../../redux/slices/dataSessions";
import countryList from "react-select-country-list";

function WorldMap({ period }) {
  const palette = usePalette();
  const [rawData, setRawData] = useState([]);
  const [distribution, setDistribution] = useState([]);
  const [type, setType] = useState("dataSessions");
  const countries = countryList().getData();
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const scaleColors = [
    palette["gray-200"],
    impersonationUser.id
      ? impersonationUser.backgroundColor
      : user.backgroundColor,
  ];

  const options = {
    map: "world_mill",
    hoverOpacity: 0.7,
    hoverColor: false,
    zoomOnScroll: false,
    regionStyle: {
      initial: {
        fill: palette["gray-200"],
      },
    },
    containerStyle: {
      width: "100%",
      height: "100%",
    },
    series: {
      regions: [
        {
          values: distribution, // object with country codes as keys
          scale: scaleColors, // colors used for choropleth
          normalizeFunction: "polynomial", // function used to normalize distribution data
        },
      ],
    },

    onRegionTipShow: (e, el, code) => {
      const countryName = el.html() || "";
      const countryValue = distribution[code] || 0;
      if (type === "dataVolume") {
        el.html(`${countryName} : ${countryValue}MB`);
      } else {
        el.html(`${countryName} : ${countryValue}`);
      }
    },
    backgroundColor: "transparent",
  };

  useEffect(() => {
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 250);
  }, [palette]);

  useEffect(() => {
    const fetchCountryDistribution = async (id) => {
      try {
        const response = await getCountryDistribution(id, type, period);
        setRawData(response);
      } catch (err) {
        console.error("Error fetching country distribution:", err);
      }
    };
    if (!impersonationUser.id) {
      if (user.id) {
        fetchCountryDistribution(user.id);
      }
    } else {
      fetchCountryDistribution(impersonationUser.id);
    }
  }, [user.id, impersonationUser.id, type, period]);

  useEffect(() => {
    setDistribution(convertToCountryCodeDistribution(rawData));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rawData]);

  const convertToCountryCodeDistribution = (result) => {
    let countryCodeDistribution = {};
    result.forEach((data) => {
      const countryData = countries.find(
        (country) => country.label === data.country
      );
      if (countryData) {
        let count = data.count;
        if (type === "dataVolume") {
          count = convertToMegabytes(count);
        }
        countryCodeDistribution[countryData.value] = count;
      }
    });
    return countryCodeDistribution;
  };

  const convertToMegabytes = (bytes) => {
    return parseFloat(bytes / 1048576).toFixed(2);
  };

  return (
    <Card className="flex-fill w-100">
      <Card.Header>
        <div className="card-actions float-end">
          <Dropdown align="end">
            <Dropdown.Toggle as="a" bsPrefix="-">
              <MoreHorizontal />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => setType("dataVolume")}>
                {t("Data Volume")}
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setType("alerts")}>
                {t("Alerts")}
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setType("dataSessions")}>
                {t("Data Sessions")}
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setType("zeroSessions")}>
                {t("Zero Sessions")}
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setType("extraCharge")}>
                {t("Extra Charge")}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <Card.Title className="mb-0">
          {type === "dataVolume"
            ? t("Data Volume")
            : type === "dataSessions"
            ? t("Data Sessions")
            : type === "alerts"
            ? t("Alerts")
            : type === "zeroSessions"
            ? t("Zero Sessions")
            : type === "extraCharge"
            ? t("Extra Charge")
            : null}
        </Card.Title>
      </Card.Header>
      <Card.Body className="p-2">
        <div className="map-container" style={{ height: 279 }}>
          <VectorMap {...options} />
        </div>
      </Card.Body>
    </Card>
  );
}

export default WorldMap;
