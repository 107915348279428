import React from "react";
import { Helmet } from "react-helmet-async";
import { Tab, Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import Notifications from "./Notifications";
import Navigation from "./Navigation";
import GeneralSettings from "./GeneralSettings";
import PublicInfo from "./PublicInfo";
import Privacy from "./Privacy";
import PrivateInfo from "./PrivateInfo";
import ContactInfo from "./ContactInfo";
import DistributionInfo from "./DistributionInfo";

const Settings = () => {
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const generalPreferences = useSelector(
    (state) => state.preferences.generalPreferences
  );
  return (
    <React.Fragment>
      <Helmet title="Settings">
        <link rel="icon" href={`data:image/x-icon;base64,${user.favicon}`} />
      </Helmet>
      <Tab.Container fluid className="p-0" defaultActiveKey="#General">
        <h1 className="h3 mb-3">Settings</h1>
        <Row>
          <Col md="3" xl="2">
            <Navigation />
          </Col>
          <Col md="9" xl="10">
            <Tab.Content>
              <Tab.Pane eventKey="#General">
                <GeneralSettings generalPreferences={generalPreferences} />
              </Tab.Pane>
              <Tab.Pane eventKey="#Account">
                <PublicInfo
                  user={!impersonationUser.id ? user : impersonationUser}
                />
                <PrivateInfo username={user.username} />
                <ContactInfo
                  email={user.email}
                  landline={user.telephoneNumber}
                  mobile={user.mobileNumber}
                />
                <DistributionInfo />
              </Tab.Pane>
              <Tab.Pane eventKey="#Privacy">
                <Privacy />
              </Tab.Pane>
              <Tab.Pane eventKey="#Notifications">
                <Notifications />
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </React.Fragment>
  );
};

export default Settings;
