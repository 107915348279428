import React from "react";
import { Row, Col, Form } from "react-bootstrap";

const Contact = ({ handleBlur, handleChange, values, errors, touched }) => {
  return (
    <Row>
      <Col md={12}>
        <Form.Group className="mb-4">
          <Form.Label>Telephone Number</Form.Label>
          <Form.Control
            type="text"
            value={values.telephoneNumber}
            name="telephoneNumber"
            isInvalid={Boolean(
              touched.telephoneNumber && errors.telephoneNumber
            )}
            onBlur={handleBlur}
            onChange={handleChange}
          />
          {!!touched.telephoneNumber && (
            <Form.Control.Feedback type="invalid">
              {errors.telephoneNumber}
            </Form.Control.Feedback>
          )}
        </Form.Group>
      </Col>
      <Col md={12}>
        <Form.Group className="mb-4">
          <Form.Label>Mobile Number</Form.Label>
          <Form.Control
            type="text"
            value={values.mobileNumber}
            name="mobileNumber"
            isInvalid={Boolean(touched.mobileNumber && errors.mobileNumber)}
            onBlur={handleBlur}
            onChange={handleChange}
          />
          {!!touched.mobileNumber && (
            <Form.Control.Feedback type="invalid">
              {errors.mobileNumber}
            </Form.Control.Feedback>
          )}
        </Form.Group>
      </Col>
      <Col md={12}>
        <Form.Group className="mb-4">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            type="email"
            name="email"
            value={values.email}
            isInvalid={Boolean(touched.email && errors.email)}
            onBlur={handleBlur}
            onChange={handleChange}
          />
          {!!touched.email && (
            <Form.Control.Feedback type="invalid">
              {errors.email}
            </Form.Control.Feedback>
          )}
        </Form.Group>
      </Col>
    </Row>
  );
};

export default Contact;
