import React from "react";
import { Helmet } from "react-helmet-async";
import { Card } from "react-bootstrap";

import nbg from "../../assets/img/nbg.svg";
import VerifyMfa from "../../components/auth/VerifyMfa";

const VerifyMfaPage = () => (
  <React.Fragment>
    <Helmet title="Sign In" />

    <Card>
      <Card.Body>
        <div className="m-sm-4">
          <div className="text-center">
            <img
              src={nbg}
              alt=""
              className="img-fluid"
              width="150"
              height="150"
            />
          </div>
          <VerifyMfa />
        </div>
      </Card.Body>
    </Card>
  </React.Fragment>
);

export default VerifyMfaPage;
