import React from "react";
import { Card, Form, Row, Button } from "react-bootstrap";
import FilterGroup from "./FilterGroup";
import { getSims, getSimInventoryCount } from "../../../redux/slices/sims";
import { useSelector, useDispatch } from "react-redux";

const FiltersCard = ({
  headerGroups,
  filters,
  setFilters,
  page,
  pageSize,
  setIsLoading,
  customFields,
}) => {
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const dispatch = useDispatch();
  const handleSearch = async () => {
    setIsLoading(true);
    if (!impersonationUser.id) {
      await dispatch(getSims(user.id, page + 1, pageSize, filters));
      await dispatch(getSimInventoryCount(user.id, filters));
    } else {
      await dispatch(
        getSims(impersonationUser.id, page + 1, pageSize, filters)
      );
      await dispatch(getSimInventoryCount(impersonationUser.id, filters));
    }
    setIsLoading(false);
  };

  const handleClear = async () => {
    const newFilters = {};
    setFilters(newFilters);
    setIsLoading(true);
    if (!impersonationUser.id) {
      await dispatch(getSims(user.id, page + 1, pageSize, newFilters));
      await dispatch(getSimInventoryCount(user.id, newFilters));
    } else {
      await dispatch(
        getSims(impersonationUser.id, page + 1, pageSize, newFilters)
      );
      await dispatch(getSimInventoryCount(impersonationUser.id, newFilters));
    }
    setIsLoading(false);
  };

  return (
    <Card>
      <Card.Header>
        <Card.Title>Filters</Card.Title>
      </Card.Header>
      <Card.Body>
        <Form>
          <Row>
            {headerGroups.map((headerGroup, idx) =>
              headerGroup.headers.map((column, index) => (
                <FilterGroup
                  key={index}
                  column={column}
                  filters={filters}
                  setFilters={setFilters}
                  page={page}
                  pageSize={pageSize}
                />
              ))
            )}
          </Row>
          <div className="d-flex mt-2 justify-content-end">
            <Button
              className="me-3"
              style={{
                "--dynamic-bg-color": !impersonationUser.id
                  ? user.backgroundColor
                  : impersonationUser.backgroundColor,
                border: "none",
              }}
              onClick={handleSearch}
            >
              Search
            </Button>
            <Button
              style={{
                "--dynamic-bg-color": !impersonationUser.id
                  ? user.backgroundColor
                  : impersonationUser.backgroundColor,
                border: "none",
              }}
              onClick={handleClear}
            >
              Clear
            </Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default FiltersCard;
