import React, { useState, useEffect } from "react";

import { Helmet } from "react-helmet-async";

import { Button, Card, Col, Container, Row } from "react-bootstrap";

import { Briefcase, Home, MapPin, Phone, Mail } from "react-feather";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGlobe,
  faMobile,
  faReceipt,
} from "@fortawesome/free-solid-svg-icons";

import { useTranslation } from "react-i18next";
import countryList from "react-select-country-list";
import { formatDistanceToNow } from "date-fns";

import { useSelector } from "react-redux";

const ProfileDetails = (props) => {
  return (
    <Card>
      <Card.Header>
        <Card.Title className="mb-0">Profile Details</Card.Title>
      </Card.Header>
      <Card.Body className="text-center">
        <img
          src={props.image}
          alt={props.fullName}
          className="img-fluid rounded-circle mb-2"
          width="128"
          height="128"
        />
        <Card.Title className="mb-0">{props.fullName}</Card.Title>
        <div className="text-muted mb-2">@{props.username}</div>
      </Card.Body>
      <hr className="my-0" />
      <Card.Body>
        <Card.Title>Contact Information</Card.Title>

        <ul className="list-unstyled mb-0">
          <li className="mb-1">
            <Home width={14} height={14} className="me-1" /> {props.address}
          </li>
          <li className="mb-1">
            <MapPin width={14} height={14} className="me-1" /> {props.postcode}{" "}
            {props.city}
          </li>
          <li className="mb-1">
            <FontAwesomeIcon icon={faGlobe} fixedWidth className="me-1" />{" "}
            {props.country}
          </li>
          <li className="mb-1">
            <Phone width={14} height={14} className="me-1" />{" "}
            {props.telephoneNumber}
          </li>
          <li className="mb-1">
            <FontAwesomeIcon icon={faMobile} fixedWidth className="me-1" />{" "}
            {props.mobileNumber}
          </li>
          <li className="mb-1">
            <Mail width={14} height={14} className="me-1" /> {props.email}
          </li>
        </ul>
      </Card.Body>
      <hr className="my-0" />
      <Card.Body>
        <Card.Title>Professional Information</Card.Title>
        <ul className="list-unstyled mb-0">
          <li className="mb-1">
            <Briefcase width={14} height={14} className="me-1" />{" "}
            {props.occupation} at {props.company}
          </li>
          <li className="mb-1">
            <FontAwesomeIcon icon={faReceipt} fixedWidth className="me-1" />{" "}
            {props.tin}
          </li>
        </ul>
      </Card.Body>
    </Card>
  );
};

const Activities = (props) => {
  const [activityArray, setActivityArray] = useState([]);
  useEffect(() => {
    setActivityArray(props.activity);
  }, [props.activity]);
  const numOfActivities = activityArray.length;
  const [numDisplayed, setNumDisplayed] = useState(10);
  const showLoadMore = numDisplayed < numOfActivities;
  const handleLoadMore = () => {
    setNumDisplayed(activityArray.length);
  };
  return (
    <Card>
      <Card.Header>
        <Card.Title className="mb-0">Activities</Card.Title>
      </Card.Header>
      <Card.Body>
        <div
          className="activity-container"
          style={{ height: "522px", overflow: "auto" }}
        >
          {activityArray.slice(0, numDisplayed).map((activityElement) => (
            <>
              <div className="d-flex">
                <div
                  className={`ms-3 me-5 circle ${
                    activityElement.type === "SIGN_IN"
                      ? "green"
                      : activityElement.type === "SIGN_OUT"
                      ? "red"
                      : activityElement.type === "SIGN_UP"
                      ? "blue"
                      : null
                  }`}
                ></div>
                <div className="flex-grow-1">
                  <small className="float-end text-navy">
                    {formatDistanceToNow(new Date(activityElement.timestamp), {
                      addSuffix: true,
                    })}
                  </small>
                  {activityElement.type === "SIGN_IN" ? (
                    <>
                      <strong>{props.fullName}</strong> Signed In
                    </>
                  ) : activityElement.type === "SIGN_OUT" ? (
                    <>
                      <strong>{props.fullName}</strong> Signed out
                    </>
                  ) : activityElement.type === "SIGN_UP" ? (
                    <>
                      <strong>{props.fullName}</strong> Signed Up
                    </>
                  ) : null}
                </div>
              </div>
              <hr />
            </>
          ))}
        </div>
        {showLoadMore && (
          <div className="d-grid">
            <Button
              style={{
                "--dynamic-bg-color": props.backgroundColor,
                border: "none",
              }}
              onClick={handleLoadMore}
            >
              Load more
            </Button>
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

const Profile = () => {
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const [fullName, setFullName] = useState();
  const [address, setAddress] = useState();
  const [postcode, setPostcode] = useState();
  const [occupation, setOccupation] = useState();
  const [city, setCity] = useState();
  const [country, setCountry] = useState();
  const [company, setCompany] = useState();
  const [telephoneNumber, setTelephoneNumber] = useState();
  const [mobileNumber, setMobileNumber] = useState();
  const [email, setEmail] = useState();
  const [tin, setTin] = useState();
  const [username, setUsername] = useState();
  const [image, setImage] = useState();
  const [activity, setActivity] = useState([]);
  const [backgroundColor, setBackgroundColor] = useState("");
  const countries = countryList().getData();

  useEffect(() => {
    if (!impersonationUser.fullName) {
      if (user && !fullName) {
        setFullName(user.fullName);
        setAddress(user.address);
        setPostcode(user.postcode);
        setOccupation(user.occupation);
        setCity(user.city);
        setCountry(user.country);
        setCompany(user.company);
        setTelephoneNumber(user.telephoneNumber);
        setMobileNumber(user.mobileNumber);
        setEmail(user.email);
        setTin(user.tin);
        setUsername(user.username);
        setImage(`data:image/jpeg;base64,${user.photo}`);
        const keys = Object.keys(user.activities[0]);
        const activityRecords = [];
        for (let i = 0; i < keys.length; i++) {
          activityRecords.push(user.activities[0][keys[i]]);
        }
        activityRecords.sort(
          (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
        );
        setActivity(activityRecords);
        setBackgroundColor(user.backgroundColor);
      }
    } else {
      setFullName(impersonationUser.fullName);
      setAddress(impersonationUser.address);
      setPostcode(impersonationUser.postcode);
      setOccupation(impersonationUser.occupation);
      setCity(impersonationUser.city);
      setCountry(impersonationUser.country);
      setCompany(impersonationUser.company);
      setTelephoneNumber(impersonationUser.telephoneNumber);
      setMobileNumber(impersonationUser.mobileNumber);
      setEmail(impersonationUser.email);
      setTin(impersonationUser.tin);
      setUsername(impersonationUser.username);
      setImage(`data:image/jpeg;base64,${impersonationUser.photo}`);

      const keys = Object.keys(impersonationUser.activities[0]);
      const activityRecords = [];
      for (let i = 0; i < keys.length; i++) {
        activityRecords.push(impersonationUser.activities[0][keys[i]]);
      }
      activityRecords.sort(
        (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
      );
      setActivity(activityRecords);
      setBackgroundColor(impersonationUser.backgroundColor);
    }
  }, [user, impersonationUser, fullName, countries]);

  return (
    <React.Fragment>
      <Helmet title="Profile">
        <link rel="icon" href={`data:image/x-icon;base64,${user.favicon}`} />
      </Helmet>
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">{t("Profile")}</h1>

        <Row>
          <Col md="4" xl="3">
            <ProfileDetails
              fullName={fullName}
              address={address}
              postcode={postcode}
              occupation={occupation}
              city={city}
              country={country}
              company={company}
              telephoneNumber={telephoneNumber}
              mobileNumber={mobileNumber}
              email={email}
              tin={tin}
              username={username}
              image={image}
              backgroundColor={backgroundColor}
            />
          </Col>
          <Col md="8" xl="9">
            <Activities
              fullName={fullName}
              image={image}
              activity={activity}
              backgroundColor={backgroundColor}
            />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default Profile;
