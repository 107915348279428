import React, { useEffect } from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { getAvailableOffers } from "../../../../redux/slices/offers";

const OffersStep = ({
  handleBlur,
  handleChange,
  values,
  errors,
  touched,
  offers,
  setOffers,
}) => {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const availableOffers = useSelector((state) => state.offers.availableOffers);
  const options = availableOffers.map((offer) => {
    return { value: offer, label: offer };
  });

  const handleSelectChange = (selected) => {
    const selectedValues = selected.map((option) => option.value);
    setOffers(selectedValues);
  };

  useEffect(() => {
    if (!impersonationUser.id) {
      if (user.id) {
        dispatch(getAvailableOffers(user.id));
      }
    } else {
      dispatch(getAvailableOffers(impersonationUser.id));
    }
  }, [user.id, impersonationUser.id, dispatch]);

  return (
    <Form.Group className="mt-5 mb-5">
      <Form.Label>Offers</Form.Label>
      <Select
        id="offersSelect"
        name="offersSelect"
        isMulti
        options={options}
        onChange={handleSelectChange}
      />
    </Form.Group>
  );
};

export default OffersStep;
