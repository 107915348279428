import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Card } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

import SignIn from "../../components/auth/SignIn";
import pos2cloud from "../../assets/img/synetairistiki_ken_mak.png";
import { fetchWhitelabel } from "../../redux/slices/whitelabel";

const SignInPage = () => {
  const dispatch = useDispatch();
  const { whitelabel } = useSelector((state) => state.whitelabel);
  const currentUrl = window.location.hostname;
  console.log(currentUrl);
  useEffect(() => {
    dispatch(fetchWhitelabel(currentUrl));
  }, []);

  return (
    <React.Fragment>
      <Helmet title="Sign In">
        <link
          rel="icon"
          href={`data:${whitelabel.faviconMimeType};base64,${whitelabel.favicon}`}
        />
      </Helmet>
      <Card>
        <Card.Body>
          <div className="m-sm-4">
            <div className="text-center">
              <img
                src={`data:${whitelabel.faviconMimeType};base64,${whitelabel.whitelabelImage}`}
                alt=""
                className="img-fluid"
                width="150"
                height="150"
              />
            </div>
            <SignIn />
          </div>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

export default SignInPage;
