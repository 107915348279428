import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  impersonationUser: {
    id: "",
    fullName: "",
    address: "",
    city: "",
    country: "",
    postcode: "",
    telephoneNumber: "",
    mobileNumber: "",
    email: "",
    occupation: "",
    company: "",
    tin: "",
    privilege: "",
    photo: "",
    activities: null,
    is2FAEnabled: "",
    favicon: "",
    faviconMimeType: "",
    whitelabelImage: "",
    whitelabelMimeType: "",
    backgroundColor: "",
    fontColor: "",
    sidebarPosition: "",
  },
};

const impersonationUserSlice = createSlice({
  name: "impersonationUser",
  initialState,
  reducers: {
    setImpersonationUser: (state, action) => {
      const data = {
        id: String(action.payload.id),
        fullName: String(action.payload.fullName),
        address: String(action.payload.address),
        city: String(action.payload.city),
        country: String(action.payload.country),
        postcode: String(action.payload.postcode),
        telephoneNumber: String(action.payload.telephoneNumber),
        mobileNumber: String(action.payload.mobileNumber),
        email: String(action.payload.email),
        occupation: String(action.payload.occupation),
        company: String(action.payload.company),
        tin: String(action.payload.tin),
        username: String(action.payload.username),
        privilege: String(action.payload.privilege),
        photo: String(action.payload.photo),
        activities: action.payload.activities,
        is2FAEnabled: String(action.payload.is2FAEnabled),
        favicon: String(action.payload.favicon),
        faviconMimeType: String(action.payload.faviconMimeType),
        whitelabelImage: String(action.payload.whitelabelImage),
        whitelabelMimeType: String(action.payload.whitelabelMimeType),
        backgroundColor: String(action.payload.backgroundColor),
        fontColor: String(action.payload.fontColor),
        sidebarPosition: String(action.payload.sidebarPosition),
      };
      state.impersonationUser = data;
    },
    clearImpersonationUser: (state) => {
      state.impersonationUser = initialState;
    },
  },
});

export const { setImpersonationUser, clearImpersonationUser } =
  impersonationUserSlice.actions;

export default impersonationUserSlice.reducer;

export const impersonateUser = (data) => async (dispatch) => {
  await dispatch(setImpersonationUser(data));
};

export const exitImpersonationMode = () => async (dispatch) => {
  dispatch(clearImpersonationUser());
  window.localStorage.removeItem("impersonationUser");
};
