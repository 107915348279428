import React, { useEffect } from "react";
import { Card } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import ReportsFilterForm from "./ReportsFilterForm";
import { useDispatch, useSelector } from "react-redux";
import {
  clearAllCustomers,
  getCustomers,
} from "../../../redux/slices/customers";
import { clearAllTenants, getTenants } from "../../../redux/slices/tenants";
import { clearAllManagers, getManagers } from "../../../redux/slices/managers";
import { getUsageReport } from "../../../redux/slices/reports";

const ReportsFilter = (props) => {
  const { customers } = useSelector((state) => state.customers);
  const { tenants } = useSelector((state) => state.tenants);
  const { managers } = useSelector((state) => state.managers);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const { reports } = useSelector((state) => state.reports);

  useEffect(() => {
    props.setReportData(reports);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reports]);

  useEffect(() => {
    const clearLists = () => {
      dispatch(clearAllTenants());
      dispatch(clearAllManagers());
      dispatch(clearAllCustomers());
    };

    const fetchCustomersList = async (id) => {
      await dispatch(getCustomers(id));
    };
    const fetchTenantsList = async (id) => {
      await dispatch(getTenants(id));
    };
    const fetchManagersList = async (id) => {
      await dispatch(getManagers(id));
    };

    if (!impersonationUser.id) {
      if (user.id) {
        clearLists();
        if (user.privilege === "ADMIN") {
          fetchTenantsList(user.id);
        }
        if (user.privilege === "ADMIN" || user.privilege === "TENANT") {
          fetchManagersList(user.id);
        }
        if (
          user.privilege === "ADMIN" ||
          user.privilege === "TENANT" ||
          user.privilege === "MANAGER"
        ) {
          fetchCustomersList(user.id);
        }
        if (user.privilege === "CUSTOMER") {
          dispatch(clearAllTenants());
          dispatch(clearAllManagers());
          dispatch(clearAllCustomers());
        }
      }
    } else {
      clearLists();
      if (impersonationUser.privilege === "ADMIN") {
        fetchTenantsList(impersonationUser.id);
      }
      if (
        impersonationUser.privilege === "ADMIN" ||
        impersonationUser.privilege === "TENANT"
      ) {
        fetchManagersList(impersonationUser.id);
      }
      if (
        impersonationUser.privilege === "ADMIN" ||
        impersonationUser.privilege === "TENANT" ||
        impersonationUser.privilege === "MANAGER"
      ) {
        fetchCustomersList(impersonationUser.id);
      }
      if (impersonationUser.privilege === "CUSTOMER") {
        clearLists();
      }
    }
  }, [
    user.id,
    dispatch,
    user.privilege,
    impersonationUser.id,
    impersonationUser.privilege,
  ]);

  return (
    <Card
      style={{ height: "750px", overflowY: "auto", width: "fit-content" }}
      id="reportsFilterCard"
    >
      <Card.Body>
        <Formik
          initialValues={{
            reportType: "usage",
            usageType: "",
            groupByField: "all",
            groupByPeriod: "day",
            usageStartDate: "",
            usageEndDate: "",
            customer: "",
            simType: "all",
            simSearchCriteriaType: "all",
            imsi: "",
            startImsi: "",
            endImsi: "",
            iccid: "",
            invoiceNumber: "",
            orderStartDate: "",
            orderEndDate: "",
          }}
          validationSchema={Yup.object().shape({
            usageType: Yup.string().when("reportType", {
              is: "usage",
              then: Yup.string().required("Required"),
              otherwise: Yup.string().notRequired(),
            }),
            groupByField: Yup.string().when("reportType", {
              is: "usage",
              then: Yup.string().required("Required"),
              otherwise: Yup.string().notRequired(),
            }),
            groupByPeriod: Yup.string().when("reportType", {
              is: "usage",
              then: Yup.string().required("Required"),
              otherwise: Yup.string().notRequired(),
            }),
            usageStartDate: Yup.string().when("reportType", {
              is: "usage",
              then: Yup.string().required("Required"),
              otherwise: Yup.string().notRequired(),
            }),
            usageEndDate: Yup.string().when("reportType", {
              is: "usage",
              then: Yup.string().required("Required"),
              otherwise: Yup.string().notRequired(),
            }),
            customer: Yup.string().when("reportType", {
              is: "usage",
              then: Yup.string().required("Required"),
              otherwise: Yup.string().notRequired(),
            }),
            simType: Yup.string().when("reportType", {
              is: "usage",
              then: Yup.string().required("Required"),
              otherwise: Yup.string().notRequired(),
            }),
            imsi: Yup.string().when("simSearchCriteriaType", {
              is: "imsi",
              then: Yup.string().required("Required"),
              otherwise: Yup.string().notRequired(),
            }),
            iccid: Yup.string().when("simSearchCriteriaType", {
              is: "iccid",
              then: Yup.string().required("Required"),
              otherwise: Yup.string().notRequired(),
            }),
            startImsi: Yup.string().when("simSearchCriteriaType", {
              is: "imsiRange",
              then: Yup.string().required("Required"),
              otherwise: Yup.string().notRequired(),
            }),
            endImsi: Yup.string().when("simSearchCriteriaType", {
              is: "imsiRange",
              then: Yup.string().required("Required"),
              otherwise: Yup.string().notRequired(),
            }),
            orderStartDate: Yup.string().when("reportType", {
              is: "orders",
              then: Yup.string().required("Required"),
              otherwise: Yup.string().notRequired(),
            }),
            orderEndDate: Yup.string().when("reportType", {
              is: "orders",
              then: Yup.string().required("Required"),
              otherwise: Yup.string().notRequired(),
            }),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            if (values.reportType === "usage") {
              props.setTableIsShown(false);
              await dispatch(
                getUsageReport(
                  user.id,
                  values.usageType,
                  values.groupByField,
                  values.groupByPeriod,
                  values.usageStartDate,
                  values.usageEndDate,
                  values.customer,
                  values.simType,
                  values.simSearchCriteriaType,
                  values.imsi,
                  values.startImsi,
                  values.endImsi,
                  values.iccid
                )
              );

              props.setReport(values.reportType);
              props.setGroupByField(values.groupByField);
              props.setTableIsShown(true);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            setValues,
            touched,
            values,
          }) => {
            return (
              <ReportsFilterForm
                user={user}
                impersonationUser={impersonationUser}
                customersList={customers}
                tenantsList={tenants}
                managersList={managers}
                setGroupByPeriod={props.setGroupByPeriod}
                setUsageType={props.setUsageType}
              />
            );
          }}
        </Formik>
      </Card.Body>
    </Card>
  );
};

export default ReportsFilter;
