import axios from "../../utils/axios";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  watchdogs: [],
  simWatchdogs: [],
};

const watchdogSlice = createSlice({
  name: "watchdogSlice",
  initialState,
  reducers: {
    setWatchdogs(state, action) {
      state.watchdogs = action.payload;
    },
    setSimWatchdogs(state, action) {
      state.simWatchdogs = action.payload;
    },
  },
});

export function getSimWatchdogs(id, imsi) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    try {
      const response = await axios.get(
        `https://dev.pos2cloud.com:5000/api/watchdog/${id}/getSimWatchdogs?imsi=${imsi}`,
        {
          headers: {
            accessToken: `Bearer ${accessToken}`,
          },
        }
      );
      dispatch(watchdogSlice.actions.setSimWatchdogs(response.data));
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(watchdogSlice.actions.setSimWatchdogs([]));
        throw new Error("Unauthorized");
      } else if (error.response && error.response.status === 500) {
        dispatch(watchdogSlice.actions.setSimWatchdogs([]));
        throw new Error("Internal Server Error");
      }
    }
  };
}

export async function modifySimWatchdog(
  id,
  sim,
  watchdogId,
  actionType,
  amount,
  notificationType,
  service,
  suspendInterval,
  unitType,
  warning,
  warning1,
  warning2,
  watchDogInterval,
  watchDogType,
  distributionList
) {
  const accessToken = window.localStorage.getItem("accessToken");
  const data = {
    imsi: sim,
    id: watchdogId,
    actionType: actionType,
    amount: amount,
    emailList: distributionList,
    notificationType: notificationType,
    service: service,
    suspendInterval: suspendInterval,
    unitType: unitType,
    warning: warning,
    warning1: warning1,
    warning2: warning2,
    watchDogInterval: watchDogInterval,
    watchDogType: watchDogType,
  };
  await axios
    .put(
      `https://dev.pos2cloud.com:5000/api/watchdog/${id}/modifySimWatchdog`,
      data,
      {
        headers: {
          accessToken: `Bearer ${accessToken}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        throw new Error("Unauthorized");
      } else if (error.response && error.response.status === 500) {
        throw new Error("Internal Server Error");
      }
    });
}

export async function removeSimWatchdog(id, watchdogId, imsi) {
  const accessToken = window.localStorage.getItem("accessToken");
  const data = {
    watchdogId: watchdogId,
    imsi: imsi,
  };
  await axios
    .post(
      `https://dev.pos2cloud.com:5000/api/watchdog/${id}/removeSimWatchdog`,
      data,
      {
        headers: {
          accessToken: `Bearer ${accessToken}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw new Error("Could not remove Watchdog");
    });
}

export const { setSimWatchdogs } = watchdogSlice.actions;

export default watchdogSlice.reducer;
