import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      Search: "Search projects…",
      "Welcome back": "Welcome back",
      "Data Sessions": "Data Sessions",
      Overview: "Overview",
      "Data Volume": "Data Volume",
      Alerts: "Alerts",
      "Zero Sessions": "Zero Sessions",
      "Since last week": "Since last week",
      today: "today",
      "this month": "this month",
      "this year": "this year",
      Daily: "Daily",
      Monthly: "Monthly",
      Yearly: "Yearly",
      "Extra Charge": "Extra Charge",
      Dashboard: "Dashboard",
      CRM: "CRM",
      Account: "Account",
      Reports: "Reports",
      Support: "Support",
      Settings: "Settings",
      Documentation: "Documentation",
      "Top Metrics": "Top Metrics",
      Since: "Since",
      January: "January",
      February: "February",
      March: "March",
      April: "April",
      May: "May",
      June: "June",
      July: "July",
      August: "August",
      September: "September",
      October: "October",
      November: "November",
      December: "December",
      "Offers Distribution": "Offers Distribution",
      "Networks Distribution": "Networks Distribution",
      "SIM Inventory": "SIM Inventory",
      Country: "Country",
      Viewers: "Viewers",
      Users: "Users",
      Customers: "Customers",
      Tenants: "Tenants",
      Managers: "Managers",
      Admins: "Admins",
      Name: "Name",
      Information: "Information",
      Activate: "Activate",
      Suspend: "Suspend",
      Resume: "Resume",
      Terminate: "Terminate",
      "Test Mode": "Test Mode",
      "Current Session": "Current Session",
      Usage: "Usage",
      "SMS Inbox": "SMS Inbox",
      Status: "Status",
      Productive: "Productive",
      Provider: "Provider",
      "Network Operator": "Network Operator",
      Area: "Area",
      Cell: "Cell",
      Longitude: "Longitude",
      Latitude: "Latitude",
      "Cell Range": "Cell Range",
      Activity: "Activity",
      Profile: "Profile",
      Invoices: "Invoices",
      "Available Reports": "Available Reports",
      Type: "Type",
      "Group by": "Group by",
      "Date Range": "Date Range",
      "SIM Type": "SIM Type",
      "SIM Search Criteria": "SIM Search Criteria",
      Select: "Select",
      "IP Allocation": "IP Allocation",
      "Invoice Detail": "Invoice Detail",
      "Invoice Overview": "Invoice Overview",
      "Offers On Test Mode SIMs": "Offers On Test Mode SIMs",
      "SIM List": "SIM List",
      Data: "Data",
      Voice: "Voice",
      All: "All",
      "by specific IMSI": "by specific IMSI",
      "by IMSI range": "by IMSI range",
      "by ICCID": "by ICCID",
      "by EID": "by EID",
      "Start IMSI": "Start IMSI",
      "End IMSI": "End IMSI",
      "Invoice Number": "Invoice Number",
      View: "View",
      "Go to page": "Go to page",
      Help: "Help",
      "Sign out": "Sign out",
      Show: "Show",
      Page: "Page",
      of: "of",
    },
  },
  fr: {
    translation: {
      Search: "Rechercher…",
      "Welcome back": "Bienvenue",
      "Data Sessions": "Séances de Données",
      Overview: "Vue d'ensemble",
      "Data Volume": "Volume de données",
      Alerts: "Alertes",
      "Zero Sessions": "Zéro sessions",
      "Since last week": "Depuis la semaine dernière",
      today: "aujourd'hui",
      "this month": "ce mois",
      "this year": "cette année",
      Daily: "Quotidien",
      Monthly: "Mensuel",
      Yearly: "Annuel",
      "Extra Charge": "Frais supplémentaires",
      Dashboard: "Tableau",
      CRM: "GRC",
      Account: "Compte",
      Reports: "Rapports",
      Support: "Aide",
      Settings: "Paramètres",
      Documentation: "Documentation",
      "Top Metrics": "Principales mesures",
      Since: "Depuis",
      January: "Janvier",
      February: "Février",
      March: "Mars",
      April: "Avril",
      May: "Mai",
      June: "Juin",
      July: "Juillet",
      August: "Août",
      September: "Septembre",
      October: "Octobre",
      November: "Novembre",
      December: "Décembre",
      "Offers Distribution": "Distribution des Offres",
      "Networks Distribution": "Distribution des Réseaux",
      "SIM Inventory": "Inventaire des SIM",
      Country: "Pays",
      Viewers: "Spectateurs",
      Users: "Utilisateurs",
      Customers: "Clients",
      Tenants: "Locataires",
      Managers: "Gestionnaires",
      Admins: "Administrateurs",
      Name: "Nom",
      Information: "Information",
      Activate: "Activer",
      Suspend: "Suspendre",
      Resume: "Recommencer",
      Terminate: "Terminer",
      "Test Mode": "Mode d'essai",
      "Current Session": "Session en cours",
      Usage: "Usage",
      Inbox: "Boîte de réception SMS",
      Status: "Statut",
      Productive: "Productif",
      Provider: "Fournisseur",
      "Network Operator": "Opérateur de réseau",
      Area: "Zone",
      Cell: "Cellule",
      Longitude: "Longitude",
      Latitude: "Latitude",
      "Cell Range": "Portée de Cellule",
      Activity: "Activité",
      Profile: "Profil",
      Invoices: "Factures",
      "Available Reports": "Rapports disponibles",
      Type: "Type",
      "Group by": "Grouper par",
      "Date Range": "Plage de dates",
      "SIM Type": "Type de SIM",
      "SIM Search Criteria": "Critères de recherche SIM",
      Select: "Sélectionner",
      "IP Allocation": "Attribution d'adresse IP",
      "Invoice Detail": "Détails de la facture",
      "Invoice Overview": "Vue d'ensemble de la facture",
      "Offers On Test Mode SIMs": "Offres sur les cartes SIM en mode test",
      "SIM List": "Liste SIM",
      Data: "Données",
      Voice: "Voix",
      All: "Tout",
      "by specific IMSI": "par IMSI spécifique",
      "by IMSI range": "par gamme IMSI",
      "by ICCID": "par ICCID",
      "by EID": "par EID",
      "Start IMSI": "Commence IMSI",
      "End IMSI": "Fin IMSI",
      "Invoice Number": "Numéro de facture",
      View: "Voir",
      "Go to page": "À la page",
      Help: "Aide",
      "Sign out": "Se déconnecter",
      Show: "Montrer",
      Page: "Page",
      of: "de",
    },
  },
  de: {
    translation: {
      Search: "Suchen…",
      "Welcome back": "Willkommen zurück",
      "Data Sessions": "Datensitzungen",
      Overview: "Übersicht",
      "Data Volume": "Datenvolumen",
      Alerts: "Warnungen",
      "Zero Sessions": "Null Sitzungen",
      "Since last week": "Seit letzter Woche",
      today: "Heute",
      "this month": "diesen Monat",
      "this year": "dieses Jahr",
      Daily: "Täglich",
      Monthly: "Monatlich",
      Yearly: "Jährlich",
      "Extra Charge": "Gegen Aufpreis",
      Dashboard: "Armaturenbrett",
      CRM: "Kundenpflege",
      Account: "Konto",
      Reports: "Berichte",
      Support: "Unterstützung",
      Settings: "Einstellungen",
      Documentation: "Dokumentation",
      Since: "Seit",
      January: "Januar",
      February: "Februar",
      March: "Marsch",
      April: "April",
      May: "Mai",
      June: "Juni",
      July: "Juli",
      August: "August",
      September: "September",
      October: "Oktober",
      November: "November",
      December: "Dezember",
      "Offers Distribution": "Verteilung von Ange boten",
      "Networks Distribution": "Verteilung von Netzwerken",
      "SIM Inventory": "Bestand an SIMs",
      Country: "Land",
      Viewers: "Zuschauer",
      Users: "Benutzer",
      Customers: "Kunden",
      Tenants: "Mieter",
      Managers: "Geschäftsführer",
      Admins: "Administratoren",
      Name: "Name",
      Information: "Information",
      Activate: "Aktivieren",
      Suspend: "Anhalten",
      Resume: "Fortsetzen",
      Terminate: "Kündigen",
      "Test Mode": "Testmodus",
      "Current Session": "Sitzung läuft",
      Usage: "Verwendung",
      "SMS Inbox": "SMS-Posteingang",
      Status: "Status",
      Productive: "Produktiv",
      Provider: "Anbieter",
      "Network Operator": "Netzbetreiber",
      Area: "Bereich",
      Cell: "Zelle",
      Longitude: "Längengrad",
      Latitude: "Breite",
      "Cell Range": "Zellbereich",
      Activity: "Aktivität",
      Profile: "Profil",
      Invoices: "Rechnungen",
      "Available Reports": "Verfügbare Berichte",
      Type: "Art",
      "Group by": "Gruppieren nach",
      "Date Range": "Datumsbereich",
      "SIM Type": "Art der SIM-Karte",
      "SIM Search Criteria": "Suchkriterien der SIM",
      Select: "Wählen",
      "IP Allocation": "IP-Zuweisung",
      "Invoice Detail": "Rechnungsdetail",
      "Invoice Overview": "Rechnungsübersicht",
      "Offers On Test Mode SIMs": "Angebote für Testmodus-SIMs",
      "SIM List": "SIM-Liste",
      Data: "Daten",
      Voice: "Stimme",
      All: "Alle",
      "by specific IMSI": "durch bestimmte IMSI",
      "by IMSI range": "nach IMSI-Bereich",
      "by ICCID": "von ICCID",
      "by EID": "von EID",
      "Start IMSI": "IMSI starten",
      "End IMSI": "IMSI beenden",
      "Invoice Number": "Rechnungsnummer",
      View: "Sehen",
      "Go to page": "Zu Seite",
      Help: "Hilfe",
      "Sign out": "Ausloggen",
      Show: "Zeigen",
      Page: "Seite",
      of: "aus",
    },
  },
  nl: {
    translation: {
      Search: "Zoeken…",
      "Welcome back": "Welkom terug",
      "Data Sessions": "Datasessies",
      Overview: "Overzicht",
      "Data Volume": "Gegevensvolume",
      Alerts: "Waarschuwingen",
      "Zero Sessions": "Nul Sessies",
      "Since last week": "Sinds vorige week",
      today: "Vandaag",
      "this month": "deze maand",
      "this year": "dit jaar",
      Daily: "Dagelijks",
      Monthly: "Maandelijks",
      Yearly: "Yearly",
      "Extra Charge": "Extra lading",
      Dashboard: "Dashboard",
      CRM: "Klantrelatiebeheer",
      Account: "Rekening",
      Reports: "Rapporten",
      Support: "Ondersteuning",
      Settings: "Instellingen",
      Documentation: "Documentatie",
      Since: "Sinds",
      January: "Januari",
      February: "Februari",
      March: "Maart",
      April: "April",
      May: "Mei",
      June: "Juni",
      July: "Juli",
      August: "Augustus",
      September: "September",
      October: "Oktober",
      November: "November",
      December: "December",
      "Offers Distribution": "Distributie van Aanbiedingen",
      "Networks Distribution": "Distributie van netwerken",
      "SIM Inventory": "Inventaris van simkaarten",
      Country: "Land",
      Viewers: "Kijkers",
      Users: "Gebruikers",
      Customers: "Klanten",
      Tenants: "Huurders",
      Managers: "Managers",
      Admins: "Beheerders",
      Name: "Naam",
      Information: "Informatie",
      Activate: "Activeren",
      Suspend: "Opschorten",
      Resume: "Hervatten",
      Terminate: "Beëindigen",
      "Test Mode": "Testmodus",
      "Current Session": "Sessie Bezig",
      Usage: "Gebruik",
      "SMS Inbox": "SMS-Inbox",
      Status: "Staat",
      Productive: "Productief",
      Provider: "Aanbieder",
      "Network Operator": "Exploitant van netwerk",
      Area: "Gebied",
      Cell: "Cel bereik",
      Activity: "Activiteit",
      Profile: "Profiel",
      Invoices: "Facturen",
      "Available Reports": "Beschikbare rapporten",
      Type: "Soort",
      "Group by": "Groeperen op",
      "Date Range": "Datumbereik",
      "SIM Type": "Soort simkaart",
      "SIM Search Criteria": "Zoekcriteria van SIM",
      Select: "Selecteer",
      "IP Allocation": "IP-toewijzing",
      "Invoice Details": "Factuur Details",
      "Invoice Overview": "Factuur Overzicht",
      "Offers On Test Mode SIMs": "Angebote für Testmodus-SIMs",
      "SIM List": "SIM-lijst",
      Data: "Gegevens",
      Voice: "Stem",
      All: "Alle",
      "by specific IMSI": "door specifieke IMSI",
      "by IMSI range": "door IMSI-bereik",
      "by ICCID": "door ICCID",
      "by EID": "door EID",
      "Start IMSI": "IMSI starten",
      "End IMSI": "Beëindig IMSI",
      "Invoice Number": "Factuurnummer",
      View: "Zien",
      "Go to page": "Naar pagina",
      Help: "Hulp",
      "Sign out": "Uitloggen",
      Show: "Tonen",
      Page: "Pagina",
      of: "uit",
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});
