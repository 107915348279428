import axios from "../../utils/axios";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sims: [],
  count: 0,
  inventory: [],
  domainSims: [],
};

const simsSlice = createSlice({
  name: "sims",
  initialState,
  reducers: {
    setSims(state, action) {
      state.sims = action.payload;
    },
    setCount(state, action) {
      state.count = action.payload;
    },
    setInventory(state, action) {
      state.inventory = action.payload;
    },
    setDomainSims(state, action) {
      state.domainSims = action.payload;
    },
    addSims(state, action) {
      const newSims = action.payload;
      return {
        ...state,
        sims: [...state.sims, newSims],
      };
    },
    removeDomainSims(state, action) {
      const simsToRemove = action.payload;
      const newDomainSims = state.domainSims.filter(
        (sim) => !simsToRemove.includes(sim.id)
      );
      return {
        ...state,
        domainSims: newDomainSims,
      };
    },
  },
});

export async function getSim(id, imsi) {
  const accessToken = window.localStorage.getItem("accessToken");
  try {
    const response = await axios.get(
      `https://dev.pos2cloud.com:5000/api/sim/${id}/getSim?imsi=${imsi}`,
      {
        headers: {
          accessToken: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      throw new Error("Unauthorized");
    } else {
      throw error;
    }
  }
}

export function getSims(id, page, pageSize, filters) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const filterParams = Object.entries(filters)
      .reduce((params, [key, value]) => {
        if (value) {
          params.push(`${key}=${encodeURIComponent(value)}`);
        }
        return params;
      }, [])
      .join("&");
    try {
      const response = await axios.get(
        `https://dev.pos2cloud.com:5000/api/sim/${id}/getSims?page=${page}&pageSize=${pageSize}&${filterParams}`,
        {
          headers: {
            accessToken: "Bearer " + accessToken,
          },
        }
      );
      dispatch(simsSlice.actions.setSims(response.data));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        dispatch(simsSlice.actions.setSims([]));
      }
    }
  };
}

export async function getSimsByStatus(id, page, pageSize, status, query) {
  const accessToken = window.localStorage.getItem("accessToken");
  try {
    const response = await axios.get(
      `https://dev.pos2cloud.com:5000/api/sim/${id}/getSimsByStatus?page=${page}&pageSize=${pageSize}&status=${status}&query=${query}`,
      {
        headers: {
          accessToken: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      throw new Error("Unauthorized");
    } else if (error.response && error.response.status === 500) {
      throw new Error("Internal Server Error");
    }
    throw error;
  }
}

export async function getSimsByOffer(id, page, pageSize, offer, query) {
  const accessToken = window.localStorage.getItem("accessToken");
  try {
    const response = await axios.get(
      `https://dev.pos2cloud.com:5000/api/sim/${id}/getSimsByOffer?page=${page}&pageSize=${pageSize}&offer=${offer}&query=${query}`,
      {
        headers: {
          accessToken: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      throw new Error("Unauthorized");
    } else if (error.response && error.response.status === 500) {
      throw new Error("Internal Server Error");
    }
    throw error;
  }
}

export async function getSimsByNetwork(id, page, pageSize, network, query) {
  const accessToken = window.localStorage.getItem("accessToken");
  try {
    const response = await axios.get(
      `https://dev.pos2cloud.com:5000/api/sim/${id}/getSimsByNetwork?page=${page}&pageSize=${pageSize}&network=${network}&query=${query}`,
      {
        headers: {
          accessToken: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      throw new Error("Unauthorized");
    } else if (error.response && error.response.status === 500) {
      throw new Error("Internal Server Error");
    }
    throw error;
  }
}

export function getSimInventory(id) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    try {
      await axios
        .get(`https://dev.pos2cloud.com:5000/api/sim/${id}/inventory`, {
          headers: {
            accessToken: "Bearer " + accessToken,
          },
        })
        .then((response) => {
          dispatch(simsSlice.actions.setInventory(response.data));
        })
        .catch((error) => {
          dispatch(simsSlice.actions.setInventory([]));
        });
    } catch (error) {
      console.log("ERROR");
    }
  };
}

export function getSimInventoryCount(id, filters) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const filterParams = Object.entries(filters)
      .reduce((params, [key, value]) => {
        if (value) {
          params.push(`${key}=${encodeURIComponent(value)}`);
        }
        return params;
      }, [])
      .join("&");

    try {
      const response = await axios.get(
        `https://dev.pos2cloud.com:5000/api/sim/${id}/inventory/count?&${filterParams}`,
        {
          headers: {
            accessToken: "Bearer " + accessToken,
          },
        }
      );
      dispatch(simsSlice.actions.setCount(response.data));
    } catch (error) {
      dispatch(simsSlice.actions.setCount(-1));
    }
  };
}

export async function getSimCellInfo(imsi) {
  const accessToken = window.localStorage.getItem("accessToken");
  try {
    const response = await axios.get(
      `https://dev.pos2cloud.com:5000/api/sim/cellInfo?imsi=${imsi}`,
      {
        headers: {
          accessToken: "Bearer " + accessToken,
        },
      }
    );
    return response.data;
  } catch (error) {
    return {};
  }
}

export async function activateSims(sims, id, offer) {
  const accessToken = window.localStorage.getItem("accessToken");
  try {
    const data = {
      imsiList: sims,
      offer: String(offer),
    };
    await axios
      .put(`https://dev.pos2cloud.com:5000/api/sim/${id}/activate`, data, {
        headers: {
          accessToken: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (error.response && error.response === 401) {
          throw new Error("Unauthorized to activate SIMs");
        } else if (error.response && error.response === 500) {
          throw new Error("Internal Server Error");
        }
      });
  } catch (error) {
    throw new Error("Failed to Activate SIMs");
  }
}

export async function suspendSims(sims, id) {
  const accessToken = window.localStorage.getItem("accessToken");
  try {
    const data = {
      imsiList: sims,
    };
    await axios
      .put(`https://dev.pos2cloud.com:5000/api/sim/${id}/suspend`, data, {
        headers: {
          accessToken: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          throw new Error("Unauthorized to activate SIMs");
        } else if (error.response && error.response === 500) {
          throw new Error("Internal Server Error");
        }
      });
  } catch (error) {
    throw new Error("Failed to Activate SIMs");
  }
}

export async function terminateSims(sims, targetState, id) {
  const accessToken = window.localStorage.getItem("accessToken");
  try {
    const data = {
      imsiList: sims,
      targetState: targetState,
    };
    await axios
      .put(`https://dev.pos2cloud.com:5000/api/sim/${id}/terminate`, data, {
        headers: {
          accessToken: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          throw new Error("Unauthorized to activate SIMs");
        } else if (error.response && error.response.status === 500) {
          throw new Error("Internal Server Error");
        }
      });
  } catch (error) {
    throw new Error("Failed to Activate SIMs");
  }
}

export async function resumeSims(sims, id) {
  const accessToken = window.localStorage.getItem("accessToken");
  try {
    const data = {
      imsiList: sims,
    };
    await axios
      .put(`https://dev.pos2cloud.com:5000/api/sim/${id}/resume`, data, {
        headers: {
          accessToken: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          throw new Error("Unauthorized to activate SIMs");
        } else if (error.response && error.response.status === 500) {
          throw new Error("Internal Server Error");
        }
      });
  } catch (error) {
    throw new Error("Failed to Activate SIMs");
  }
}

export async function modifySimProduct(sim, targetOffer, id) {
  const accessToken = window.localStorage.getItem("accessToken");
  try {
    const data = {
      imsi: sim,
      targetOffer: targetOffer,
    };
    await axios
      .put(`https://dev.pos2cloud.com:5000/api/sim/${id}/modifySim`, data, {
        headers: {
          accessToken: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          throw new Error("Unauthorized to modify SIM");
        } else if (error.response && error.response.status === 500) {
          throw new Error("Internal Server Error");
        }
      });
  } catch (error) {
    throw new Error("Failed to modify SIM");
  }
}

export async function getTestProfiles() {}

export async function toggleTestMode(
  sims,
  action,
  testProfile,
  dataAllowance,
  smsAllowance,
  number,
  interval,
  offer,
  id
) {
  const accessToken = window.localStorage.getItem("accessToken");
  try {
    const data = {
      imsiList: sims,
      action: action,
      testProfile: testProfile,
      dataAllowance: dataAllowance,
      smsAllowance: smsAllowance,
      number: number,
      interval: interval,
      offer: offer,
    };
    await axios
      .put(
        `https://dev.pos2cloud.com:5000/api/sim/${id}/toggleTestMode`,
        data,
        {
          headers: {
            accessToken: `Bearer ${accessToken}`,
          },
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          throw new Error("Unauthorized");
        } else if (error.response && error.response.status === 500) {
          throw new Error("Internal Server Error");
        }
      });
  } catch (error) {
    throw new Error("Toggle Test Mode Failed");
  }
}

export function getDomainSims(page, pageSize, search) {
  return async (dispatch) => {
    try {
      const accessToken = window.localStorage.getItem("accessToken");
      const response = await axios.get(
        `https://dev.pos2cloud.com:5000/api/sim/getDomainSims?page=${page}&pageSize=${pageSize}&search=${search}`,
        {
          headers: {
            accessToken: `Bearer ${accessToken}`,
          },
        }
      );
      dispatch(simsSlice.actions.setDomainSims(response.data));
    } catch (error) {
      if (error.response && error.response.status === 401) {
        throw new Error("Unauthorized");
      } else if (error.response && error.response.status === 500) {
        throw new Error("Internal Server Error");
      }
    }
  };
}

export function shipSims(id, sims, price) {
  return async (dispatch) => {
    try {
      const accessToken = window.localStorage.getItem("accessToken");
      const data = {
        id: id,
        imsiList: sims,
        shipmentPrice: price,
      };
      await axios.put(`https://dev.pos2cloud.com:5000/api/sim/shipSims`, data, {
        headers: {
          accessToken: `Bearer ${accessToken}`,
        },
      });
      dispatch(simsSlice.actions.removeDomainSims(sims));
    } catch (error) {
      if (error.response && error.response.status === 401) {
        throw new Error("Unauthorized");
      } else if (error.response && error.response.status === 500) {
        throw new Error("Internal Server Error");
      } else if (error.response && error.response.status === 400) {
        throw new Error("Bad Request");
      }
    }
  };
}

export async function addWatchdog(
  id,
  sims,
  actionType,
  amount,
  notificationType,
  service,
  suspendInterval,
  unitType,
  warning,
  warning1,
  warning2,
  watchDogInterval,
  watchDogType,
  distributionList
) {
  const accessToken = window.localStorage.getItem("accessToken");
  const data = {
    imsiList: sims,
    actionType: actionType,
    amount: amount,
    emailList: distributionList,
    notificationType: notificationType,
    service: service,
    suspendInterval: suspendInterval,
    unitType: unitType,
    warning: warning,
    warning1: warning1,
    warning2: warning2,
    watchDogInterval: watchDogInterval,
    watchDogType: watchDogType,
  };
  await axios
    .post(
      `https://dev.pos2cloud.com:5000/api/watchdog/${id}/addWatchdog`,
      data,
      {
        headers: {
          accessToken: `Bearer ${accessToken}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        throw new Error("Unauthorized");
      } else if (error.response && error.response.status === 500) {
        throw new Error("Internal Server Error");
      }
    });
}

export async function removeAllSimWatchdogs(id, sims) {
  const accessToken = window.localStorage.getItem("accessToken");
  const data = {
    imsiList: sims,
  };
  await axios
    .post(
      `https://dev.pos2cloud.com:5000/api/watchdog/${id}/removeWatchdog`,
      data,
      {
        headers: {
          accessToken: `Bearer ${accessToken}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        throw new Error("Unauthorized");
      } else if (error.response && error.response.status === 500) {
        throw new Error("Internal Server Error");
      }
    });
}

export async function sendSms(
  id,
  sims,
  fromType,
  from,
  messageType,
  textContent,
  base64Content
) {
  const accessToken = window.localStorage.getItem("accessToken");
  const data = {
    imsiList: sims,
    fromType: fromType,
    from: from,
    messageType: messageType,
    textContent: textContent,
    base64Content: base64Content,
  };
  await axios
    .post(`https://dev.pos2cloud.com:5000/api/sms/${id}/sendSms`, data, {
      headers: {
        accessToken: `Bearer ${accessToken}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        throw new Error("Unauthorized");
      } else if (error.response && error.response.status === 500) {
        throw new Error("Internal Server Error");
      }
    });
}

export async function retrieveSms(id, imsi) {
  try {
    const accessToken = window.localStorage.getItem("accessToken");
    const response = await axios.get(
      `https://dev.pos2cloud.com:5000/api/sms/${id}/retrieve?imsi=${imsi}`,
      {
        headers: {
          accessToken: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      throw new Error("Unauthorized");
    } else if (error.response && error.response.status === 500) {
      throw new Error("Internal Server Error");
    }
  }
}

export async function updateSimLocation(id, imsi) {
  try {
    const accessToken = window.localStorage.getItem("accessToken");
    const response = await axios.get(
      `https://dev.pos2cloud.com:5000/api/sim/${id}/updateLocation?imsi=${imsi}`,
      {
        headers: {
          accessToken: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      throw new Error("Unauthorized");
    } else if (error.response && error.response.status === 500) {
      throw new Error("Internal Server Error");
    }
  }
}

export async function imeiLock(id, imsi, imei) {
  try {
    const accessToken = window.localStorage.getItem("accessToken");
    const data = {
      imsi: imsi,
      imei: imei,
    };
    const response = await axios.post(
      `https://dev.pos2cloud.com:5000/api/sim/${id}/imeiLock`,
      data,
      {
        headers: {
          accessToken: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      throw new Error("Unauthorized");
    } else if (error.response && error.response.status === 500) {
      throw new Error("Internal Server Error");
    }
  }
}

export async function getHlrInfo(id, imsi, scope) {
  try {
    const accessToken = window.localStorage.getItem("accessToken");
    const response = await axios.get(
      `https://dev.pos2cloud.com:5000/api/hlr/${id}/hlrInfo?imsi=${imsi}&scope=${scope}`,
      {
        headers: {
          accessToken: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      throw new Error("Unauthorized");
    } else if (error.response && error.response.status === 500) {
      throw new Error("Internal Server Error");
    }
  }
}

export const { setSims, setCount, setInventory, addSims, removeDomainSims } =
  simsSlice.actions;

export default simsSlice.reducer;
