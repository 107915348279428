import React, { useState, useRef, useEffect } from "react";
import {
  Card,
  Row,
  Col,
  CloseButton,
  Button,
  Table,
  Badge,
} from "react-bootstrap";
import ActivateSimModal from "./modals/ActivateSimModal";
import { ExternalLink } from "react-feather";
import SimsMap from "./SimsMap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import SuspendSimModal from "./modals/SuspendSimModal";
import ResumeSimModal from "./modals/ResumeSimModal";
import TerminateSimModal from "./modals/TerminateSimModal";
import { formatDistanceToNow } from "date-fns";
import { useSelector } from "react-redux";

function Single(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const [openActivateModal, setOpenActivateModal] = useState(false);
  const [openSuspendModal, setOpenSuspendModal] = useState(false);
  const [openResumeModal, setOpenResumeModal] = useState(false);
  const [openTerminateModal, setOpenTerminateModal] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [openTestModeModal, setOpenTestModeModal] = useState(false);
  const openActivateModalRef = useRef(null);
  const openResumeModalRef = useRef(null);
  const [activityRecords, setActivityRecords] = useState([]);

  useEffect(() => {
    if (
      props.shownSim &&
      props.shownSim.activities &&
      props.shownSim.activities.length > 0
    ) {
      const newActivityRecords = [...props.shownSim.activities].sort(
        (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
      );
      setActivityRecords(newActivityRecords);
    }
  }, [props.shownSim]);

  if (props.shownSim) {
    return (
      <>
        <Card>
          <Card.Header>
            <Row>
              <Col xxl="6">
                <Card.Title className="mb-0">SIM {t("Information")}</Card.Title>
              </Col>
              <Col xxl="6">
                <CloseButton
                  className="float-end"
                  onClick={() => {
                    props.setSingleIsShown(false);
                    setTimeout(function () {
                      props.setOpen(false);
                    }, 200);
                  }}
                />
              </Col>
            </Row>
          </Card.Header>
          <Card.Body>
            <Row className="g-0">
              <strong>Actions</strong>
              <div className="d-flex flex-wrap mt-2" id="simsActionsPanel">
                {(props.shownSim.status === "OnStock" ||
                  props.shownSim.status === "TestProductive") && (
                  <>
                    <Button
                      variant="success"
                      className="me-2 mb-1 px-2"
                      id="simsActionActivate"
                      style={{
                        "--dynamic-bg-color": !impersonationUser.id
                          ? user.backgroundColor
                          : impersonationUser.backgroundColor,
                        border: "none",
                      }}
                      onClick={async () => {
                        setOpenActivateModal(true);
                        await new Promise((resolve) => {
                          const interval = setInterval(() => {
                            if (!openActivateModalRef.current) {
                              clearInterval(interval);
                              resolve();
                            }
                          }, 500);
                        });
                      }}
                    >
                      <span> {t("Activate")}</span>
                    </Button>
                    {props.shownSim && (
                      <ActivateSimModal
                        openActivateModal={openActivateModal}
                        selectedSims={[props.shownSim]}
                        setOpenActivateModal={setOpenActivateModal}
                        page={props.page}
                        pageSize={props.pageSize}
                        filters={props.filters}
                      />
                    )}
                  </>
                )}
                {props.shownSim.status === "Productive" && (
                  <>
                    <Button
                      variant="warning"
                      className="me-2 mb-1 px-2"
                      id="simsActionSuspend"
                      onClick={async () => {
                        setOpenSuspendModal(true);
                      }}
                      style={{
                        "--dynamic-bg-color": !impersonationUser.id
                          ? user.backgroundColor
                          : impersonationUser.backgroundColor,
                        border: "none",
                      }}
                    >
                      <span> {t("Suspend")}</span>
                    </Button>
                    {props.shownSim && (
                      <SuspendSimModal
                        openSuspendModal={openSuspendModal}
                        sims={[props.shownSim]}
                        setOpenSuspendModal={setOpenSuspendModal}
                        page={props.page}
                        pageSize={props.pageSize}
                        filters={props.filters}
                      />
                    )}
                  </>
                )}
                {props.shownSim.status === "Suspended" && (
                  <>
                    <Button
                      variant="primary"
                      className="me-2 mb-1 px-2"
                      id="simsActionResume"
                      onClick={async () => {
                        setOpenResumeModal(true);
                        await new Promise((resolve) => {
                          const interval = setInterval(() => {
                            if (!openResumeModalRef.current) {
                              clearInterval(interval);
                              resolve();
                            }
                          }, 500);
                        });
                      }}
                      style={{
                        "--dynamic-bg-color": !impersonationUser.id
                          ? user.backgroundColor
                          : impersonationUser.backgroundColor,
                        border: "none",
                      }}
                    >
                      <span> {t("Resume")}</span>
                    </Button>
                    <ResumeSimModal
                      openResumeModal={openResumeModal}
                      sims={[props.shownSim]}
                      setOpenResumeModal={setOpenResumeModal}
                      page={props.page}
                      pageSize={props.pageSize}
                      filters={props.filters}
                    />
                  </>
                )}
                {(props.shownSim.status === "Productive" ||
                  props.shownSim.status === "Suspended") && (
                  <>
                    <Button
                      variant="danger"
                      className="me-2 mb-1 px-2"
                      id="simsActionTerminate"
                      onClick={async () => {
                        setOpenTerminateModal(true);
                        await new Promise((resolve) => {
                          const interval = setInterval(() => {
                            if (!openResumeModalRef.current) {
                              clearInterval(interval);
                              resolve();
                            }
                          }, 500);
                        });
                      }}
                      style={{
                        "--dynamic-bg-color": !impersonationUser.id
                          ? user.backgroundColor
                          : impersonationUser.backgroundColor,
                        border: "none",
                      }}
                    >
                      <span> {t("Terminate")}</span>
                    </Button>
                    <TerminateSimModal
                      openTerminateModal={openTerminateModal}
                      sims={[props.shownSim]}
                      setOpenTerminateModal={setOpenTerminateModal}
                      page={props.page}
                      pageSize={props.pageSize}
                      filters={props.filters}
                    />
                  </>
                )}
                {props.shownSim.status === "OnStock" && (
                  <>
                    <Button
                      variant="info"
                      className="me-2 mb-1 px-2"
                      id="simsActionTest"
                      onClick={() => setOpenTestModeModal(true)}
                      style={{
                        "--dynamic-bg-color": !impersonationUser.id
                          ? user.backgroundColor
                          : impersonationUser.backgroundColor,
                        border: "none",
                      }}
                    >
                      {t("Test Mode")}
                    </Button>
                    {/* <TestModeModal
                      openTestModeModal={openTestModeModal}
                      setOpenTestModeModal={setOpenTestModeModal}
                      sims={[props.shownSim]}
                      page={props.page}
                      pageSize={props.pageSize}
                      filters={props.filters}
                    /> */}
                  </>
                )}
              </div>
            </Row>
            <Table size="sm" className="my-2">
              <tbody>
                <tr>
                  <th>{t("Usage")}</th>
                  <td>
                    {props.shownSim.totalBytes !== null
                      ? `${parseFloat(props.shownSim.totalBytes).toFixed(3)}MB`
                      : "0.00MB"}
                  </td>
                </tr>
                <tr>
                  <th>{t("IP Address")}</th>
                  <td>
                    {props.shownSim.ip !== null ? props.shownSim.ip : "N/A"}
                  </td>
                </tr>
                {/* <tr>
              <th>{t("SMS Inbox")}</th>
              <td>{props.shownSim.smsInbox}</td>
            </tr> */}
                <tr>
                  <th>IMEI</th>
                  <td>
                    {props.shownSim.imei !== null ? props.shownSim.imei : "N/A"}
                  </td>
                </tr>
                <tr>
                  <th>{t("Status")}</th>
                  <td>
                    <Badge
                      bg={
                        props.shownSim.status === "Productive"
                          ? "primary"
                          : props.shownSim.status === "Active"
                          ? "success"
                          : props.shownSim.status === "Suspended"
                          ? "warning"
                          : props.shownSim.status === "Terminated"
                          ? "danger"
                          : props.shownSim.status === "OnStock"
                          ? "light"
                          : props.shownSim.status === "Deleted"
                          ? "danger"
                          : props.shownSim.status === "TestProductive"
                          ? "primary"
                          : props.shownSim.status === "AutoSuspended"
                          ? "warning"
                          : null
                      }
                    >
                      {t(props.shownSim.status)}
                    </Badge>
                  </td>
                </tr>
                <tr>
                  <th>{t("Visit Profile")}</th>
                  <td>
                    <span
                      onClick={() => {
                        navigate("/sims/profile", {
                          state: {
                            imsi: props.shownSim.imsi,
                          },
                        });
                      }}
                    >
                      <ExternalLink size={18} />
                    </span>
                  </td>
                </tr>
              </tbody>
            </Table>

            <hr />
            <strong>{t("Map")}</strong>
            <SimsMap
              lat={props.cellInfo.lat}
              lon={props.cellInfo.lon}
              className="my-3"
            />
            <Table size="sm">
              <tbody>
                <tr>
                  <th>{t("Provider")}</th>
                  <td>{props.shownSim.sku}</td>
                </tr>
                <tr>
                  <th>ICCID</th>
                  <td>{props.shownSim.iccid}</td>
                </tr>
                <tr>
                  <th>MSISDN</th>
                  <td>
                    {props.shownSim.msisdn !== ""
                      ? props.shownSim.msisdn
                      : "N/A"}
                  </td>
                </tr>
                <tr>
                  <th>EID</th>
                  <td>
                    {props.shownSim.eid !== "" ? props.shownSim.eid : "N/A"}
                  </td>
                </tr>
                <tr>
                  <th>IMSI</th>
                  <td>
                    {props.shownSim.imsi !== "" ? props.shownSim.imsi : "N/A"}
                  </td>
                </tr>
                {props.shownSim.offer && (
                  <tr>
                    <th>Offer</th>
                    <td>
                      {props.shownSim.offer.name !== ""
                        ? props.shownSim.offer
                        : "N/A"}
                    </td>
                  </tr>
                )}
                <tr>
                  <th>{t("Country")}</th>
                  <td>{props.shownSim.country}</td>
                </tr>
                <tr>
                  <th>{t("Network Operator")}</th>
                  <td>{props.shownSim.operator}</td>
                </tr>
                <tr>
                  <th>{t("Area")}</th>
                  <td>
                    {props.cellInfo.area !== "" ? props.cellInfo.area : "N/A"}
                  </td>
                </tr>
                <tr>
                  <th>{t("Cell")}</th>
                  <td>
                    {props.cellInfo.cell !== "" ? props.cellInfo.cell : "N/A"}
                  </td>
                </tr>
                <tr>
                  <th>{t("Longitude")}</th>
                  <td>
                    {props.cellInfo.lon !== "" ? props.cellInfo.lon : "N/A"}
                  </td>
                </tr>
                <tr>
                  <th>{t("Latitude")}</th>
                  <td>
                    {props.cellInfo.lat !== "" ? props.cellInfo.lat : "N/A"}
                  </td>
                </tr>
                <tr>
                  <th>{t("Cell Range")}</th>
                  <td>
                    {props.cellInfo.range !== "" ? props.cellInfo.range : "N/A"}
                  </td>
                </tr>
                <tr>
                  <th>{t("Unit")}</th>
                  <td>
                    {props.cellInfo.unit !== "" ? props.cellInfo.unit : "N/A"}
                  </td>
                </tr>
              </tbody>
            </Table>
            <hr />

            <strong>{t("Activity")}</strong>

            <ul className="timeline mt-2">
              {activityRecords.slice(0, 5).map((activityElement, index) => (
                <React.Fragment key={index}>
                  {activityElement.type === "ACTIVATE" && (
                    <li className="timeline-item">
                      <strong>Activated</strong>
                      <span className="float-end text-muted text-sm">
                        {formatDistanceToNow(
                          new Date(activityElement.timestamp),
                          {
                            addSuffix: true,
                          }
                        )}
                      </span>
                      <p>SIM {props.shownSim.imsi} was activated</p>
                    </li>
                  )}
                  {activityElement.type === "SUSPEND" && (
                    <li className="timeline-item">
                      <strong>Suspended</strong>
                      <span className="float-end text-muted text-sm">
                        {formatDistanceToNow(
                          new Date(activityElement.timestamp),
                          {
                            addSuffix: true,
                          }
                        )}
                      </span>
                      <p>SIM {props.shownSim.imsi} was suspended</p>
                    </li>
                  )}
                  {activityElement.type === "RESUME" && (
                    <li className="timeline-item">
                      <strong>Resumed</strong>
                      <span className="float-end text-muted text-sm">
                        {formatDistanceToNow(
                          new Date(activityElement.timestamp),
                          {
                            addSuffix: true,
                          }
                        )}
                      </span>
                      <p>SIM {props.shownSim.imsi} was resumed</p>
                    </li>
                  )}
                  {activityElement.type === "MODIFY" && (
                    <li className="timeline-item">
                      <strong>Modified</strong>
                      <span className="float-end text-muted text-sm">
                        {formatDistanceToNow(
                          new Date(activityElement.timestamp),
                          {
                            addSuffix: true,
                          }
                        )}
                      </span>
                      <p>SIM {props.shownSim.imsi} was modified</p>
                    </li>
                  )}
                  {activityElement.type === "TERMINATE" && (
                    <li className="timeline-item">
                      <strong>Terminated</strong>
                      <span className="float-end text-muted text-sm">
                        {formatDistanceToNow(
                          new Date(activityElement.timestamp),
                          {
                            addSuffix: true,
                          }
                        )}
                      </span>
                      <p>SIM {props.shownSim.imsi} was terminated</p>
                    </li>
                  )}
                </React.Fragment>
              ))}
            </ul>
          </Card.Body>
        </Card>
      </>
    );
  } else {
    return <Card></Card>;
  }
}

export default Single;
