import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAdmins } from "../../redux/slices/admins";
import { getTenants } from "../../redux/slices/tenants";
import { getManagers } from "../../redux/slices/managers";
import { getCustomers } from "../../redux/slices/customers";

import { Card, Col, Row, Table, CloseButton } from "react-bootstrap";
import { formatDistanceToNow } from "date-fns";
import { FaCog } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import CrmConfig from "./configuration/CrmConfig";
import { ExternalLink } from "react-feather";
import useAuth from "../../hooks/useAuth";

export default function Single(props) {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const { managers } = useSelector((state) => state.managers);
  const { tenants } = useSelector((state) => state.tenants);
  const { customers } = useSelector((state) => state.customers);
  const { startImpersonation } = useAuth();

  const dispatch = useDispatch();
  const [client, setClient] = useState({
    id: "",
    fullName: "",
    address: "",
    city: "",
    country: "",
    postcode: "",
    telephone: "",
    mobileNumber: "",
    email: "",
    occupation: "",
    company: "",
    tin: "",
    username: "",
    privilege: "",
    activities: {},
  });
  const [showClientOffCanvas, setShowClientOffCanvas] = useState(false);

  useEffect(() => {
    if (!props.shownClient) {
      return;
    }
    setClient({
      id: props.shownClient.id,
      fullName: props.shownClient.fullName,
      address: props.shownClient.address,
      city: props.shownClient.city,
      country: props.shownClient.country,
      postcode: props.shownClient.postcode,
      telephone: props.shownClient.telNumber,
      mobileNumber: props.shownClient.mobileNumber,
      email: props.shownClient.email,
      occupation: props.shownClient.occupation,
      company: props.shownClient.company,
      tin: props.shownClient.tinNumber,
      username: props.shownClient.username,
      privilege: props.shownClient.privilege,
      activities: props.shownClient.activities,
      image: props.shownClient.image,
    });
  }, [props.shownClient]);
  const keys = Object.keys(client.activities);
  const activityRecords = [];
  for (let i = 0; i < keys.length; i++) {
    activityRecords.push(client.activities[keys[i]]);
  }
  activityRecords.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));

  useEffect(() => {
    if (user) {
      switch (user.privilege) {
        case "ADMIN":
          dispatch(getAdmins(user.id));
          dispatch(getManagers(user.id));
          dispatch(getTenants(user.id));
          dispatch(getCustomers(user.id));
          break;
        case "TENANT":
          dispatch(getManagers(user.id));
          dispatch(getCustomers(user.id));
          break;
        case "MANAGER":
          dispatch(getCustomers(user.id));
          break;
        default:
          break;
      }
    }
  }, [dispatch, user]);

  return (
    <Card>
      <Card.Header>
        <Row>
          <Col xxl="6">
            <Card.Title className="mb-0">{client.fullName}</Card.Title>
          </Col>
          <Col xxl="6">
            <CloseButton
              className="float-end"
              onClick={() => {
                props.setSingleIsShown(false);
                setTimeout(function () {
                  props.setOpen(false);
                }, 200);
              }}
            />
          </Col>
        </Row>
      </Card.Header>
      <Card.Body>
        <Row className="g-0">
          <Col sm="3" xl="12" className="col-xxl-3 text-center">
            <img
              src={`data:image/png;base64,${client.image}`}
              width="64"
              height="64"
              className="rounded-circle mt-2"
              alt="Angelica Ramos"
            />
          </Col>
        </Row>

        <Table size="sm" className="my-2">
          <tbody>
            <tr>
              <th>Full Name</th>
              <td>{client.fullName}</td>
            </tr>
            <tr>
              <th>Address</th>
              <td>{client.address}</td>
            </tr>
            <tr>
              <th>City</th>
              <td>{client.city}</td>
            </tr>
            <tr>
              <th>Country</th>
              <td>{client.country}</td>
            </tr>
            <tr>
              <th>Postcode</th>
              <td>{client.postcode}</td>
            </tr>
            <tr>
              <th>Tel.</th>
              <td>{client.telephone}</td>
            </tr>
            <tr>
              <th>Mobile</th>
              <td>{client.mobileNumber}</td>
            </tr>
            <tr>
              <th>Email</th>
              <td>{client.email}</td>
            </tr>
            <tr>
              <th>Occupation</th>
              <td>{client.occupation}</td>
            </tr>
            <tr>
              <th>Company</th>
              <td>{client.company}</td>
            </tr>
            <tr>
              <th>TIN</th>
              <td>{client.tin}</td>
            </tr>
            <tr>
              <th>Privilege</th>
              <td>{client.privilege}</td>
            </tr>
            {user.privilege !== "SUBACCOUNT" && (
              <tr>
                <th>Actions</th>
                <td>
                  <FaCog
                    onClick={() => {
                      setShowClientOffCanvas(true);
                    }}
                    className="me-3"
                  />
                  {user.privilege === "ADMIN" && (
                    <span
                      onClick={async () => {
                        try {
                          await startImpersonation(client.username);
                          navigate("/");
                        } catch (error) {}
                      }}
                    >
                      <ExternalLink size={18} />
                    </span>
                  )}
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        <CrmConfig
          showOffCanvas={showClientOffCanvas}
          setShowOffCanvas={setShowClientOffCanvas}
          configUser={props.shownClient}
          tenants={tenants}
          managers={managers}
          customers={customers}
        />
        <hr />

        <strong>Activity</strong>

        {
          <ul className="timeline mt-2">
            {activityRecords.slice(0, 5).map((activityElement, index) => (
              <React.Fragment key={index}>
                {activityElement.type === "SIGN_IN" && (
                  <li className="timeline-item">
                    <strong>Signed in</strong>
                    <span className="float-end text-muted text-sm">
                      {formatDistanceToNow(
                        new Date(activityElement.timestamp),
                        {
                          addSuffix: true,
                        }
                      )}
                    </span>
                    <p>Sed aliquam ultrices mauris. Integer ante arcu...</p>
                  </li>
                )}
                {activityElement.type === "SIGN_OUT" && (
                  <li
                    className="timeline-item"
                    style={{
                      "--dynamic-bg-color": impersonationUser.id
                        ? user.backgroundColor
                        : impersonationUser.backgroundColor,
                    }}
                  >
                    <strong>Signed out</strong>
                    <span className="float-end text-muted text-sm">
                      {formatDistanceToNow(
                        new Date(activityElement.timestamp),
                        {
                          addSuffix: true,
                        }
                      )}
                    </span>
                    <p>Sed aliquam ultrices mauris. Integer ante arcu...</p>
                  </li>
                )}
                {activityElement.type === "SIGN_UP" && (
                  <li className="timeline-item">
                    <strong>Signed up</strong>
                    <span className="float-end text-muted text-sm">
                      {formatDistanceToNow(
                        new Date(activityElement.timestamp),
                        {
                          addSuffix: true,
                        }
                      )}
                    </span>
                    <p>Sed aliquam ultrices mauris. Integer ante arcu...</p>
                  </li>
                )}
              </React.Fragment>
            ))}
          </ul>
        }
      </Card.Body>
    </Card>
  );
}
