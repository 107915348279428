import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";

import { Col, Container, Row } from "react-bootstrap";

import Main from "../components/Main";
import { useDispatch, useSelector } from "react-redux";
import { fetchWhitelabel } from "../redux/slices/whitelabel";

const Auth = ({ children }) => {
  const { whitelabel } = useSelector((state) => state.whitelabel);
  const dispatch = useDispatch();
  const currentUrl = window.location.hostname;

  useEffect(() => {
    dispatch(fetchWhitelabel(currentUrl));
  }, []);

  return (
    <React.Fragment>
      <div
        className="main d-flex w-100 justify-content-center"
        style={{ background: whitelabel.backgroundColor }}
      >
        <Container className="d-flex flex-column">
          <Row className="h-100">
            <Col sm="10" md="8" lg="6" className="mx-auto d-table h-100">
              <div className="d-table-cell align-middle">
                {children}
                <Outlet />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {/* <Settings /> */}
    </React.Fragment>
  );
};

export default Auth;
