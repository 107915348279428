import { FaCheck } from "react-icons/fa";

const invoicesColumns = [
  {
    Header: "Invoice No",
    accessor: "invoiceNumber",
    sortable: true,
  },
  {
    Header: "Date",
    accessor: "creationDate",
    sortable: true,
  },
  {
    Header: "Amount (Incl. VAT)",
    accessor: "amount",
    Cell: ({ row: { original } }) => <>{original.amount}€</>,
  },
  {
    Header: "Description",
    accessor: "description",
    sortable: true,
  },
  {
    Header: "Paid",
    accessor: "isPaid",
    sortable: true,
    Cell: ({ row: { original } }) => (
      <>{original.isPaid === "no" ? "" : <FaCheck />}</>
    ),
  },
];

export default invoicesColumns;
