import { Formik, Field } from "formik";
import React, { useState, useEffect } from "react";
import { Modal, Form, Collapse, Button, Table, Spinner } from "react-bootstrap";
import Select from "react-select";
import { FaPlus, FaMinus } from "react-icons/fa";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { getDistributionEmails } from "../../../../redux/slices/distribution";
import {
  getSimWatchdogs,
  modifySimWatchdog,
} from "../../../../redux/slices/watchdogs";
import { FaCheckCircle, FaExclamationCircle } from "react-icons/fa";

const validationSchema = Yup.object().shape({
  watchdogType: Yup.string().required("Required"),
  autoInterval: Yup.string().required("Required"),
  suspendInterval: Yup.string().when("actionType", {
    is: "suspend",
    then: Yup.string().required("Required"),
    otherwise: Yup.string().notRequired(),
  }),
  amount: Yup.number().required("Required"),
  service: Yup.string().when("watchdogType", {
    is: "Cost",
    then: Yup.string().notRequired(),
    otherwise: Yup.string().required("Required"),
  }),
  unitType: Yup.string().when("watchdogType", {
    is: "Cost",
    then: Yup.string().notRequired(),
    otherwise: Yup.string().required("Required"),
  }),
  warning1: Yup.number().notRequired(),
  warning2: Yup.number().notRequired(),
  warning3: Yup.number().notRequired(),
  distributionList: Yup.array().notRequired(),
});

const ModifyForm = ({
  errors,
  handleBlur,
  setFieldTouched,
  handleChange,
  handleSubmit,
  isSubmitting,
  touched,
  values,
  setValues,
  validateForm,
  setFieldValue,
  nextStep,
  distributionList,
  setShowModifyWatchdogModal,
  setTypeOfWatchdog,
}) => {
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const [showWarning2Field, setShowWarning2Field] = useState(false);
  const [showWarning3Field, setShowWarning3Field] = useState(false);

  useEffect(() => {
    if (values.watchdogType === "Live Usage") {
      setFieldValue("autoInterval", "1 months");
    }
  }, [setFieldValue, values.watchdogType]);

  useEffect(() => {
    const isServiceGroup =
      values.service &&
      (values.service === "All Data" || values.service.includes("-"));
    if (values.watchdogType === "Rated Usage") {
      if (isServiceGroup) {
        setTypeOfWatchdog("servicegroup");
      } else {
        setTypeOfWatchdog("service");
      }
    } else if (values.watchdogType === "Live Usage") {
      if (isServiceGroup) {
        setTypeOfWatchdog("externalservicegroup");
      } else {
        setTypeOfWatchdog("externalservice");
      }
    } else if (values.watchdogType === "Cost") {
      setTypeOfWatchdog("cost");
    }
  }, [values.watchdogType, values.service, setTypeOfWatchdog]);

  const options = distributionList.map((item, index) => ({
    key: index + 1,
    value: item.address,
    label: `${index + 1}. ${item.name} - ${item.address}`,
  }));

  return (
    <Form>
      <Form.Group className="mb-3">
        <Form.Label>Action Type</Form.Label>
        <Form.Select
          id="actionTypeSelect"
          name="actionType"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.actionType}
          isInvalid={touched.actionType && !!errors.actionType}
        >
          <option value="suspend">Suspend</option>
          <option value="none">None</option>
        </Form.Select>
        {!!touched.actionType && (
          <Form.Control.Feedback type="invalid">
            {errors.actionType}
          </Form.Control.Feedback>
        )}
      </Form.Group>
      <Collapse in={values.actionType === "suspend"} dimension="height">
        <Form.Group className="mb-3">
          <Form.Label>Suspension Interval</Form.Label>
          <Form.Select
            id="suspensionIntervalSelect"
            name="suspendInterval"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.suspendInterval}
            isInvalid={touched.suspendInterval && !!errors.suspendInterval}
          >
            <option value="">Select interval</option>
            <option value="none">None</option>
            <option value="1 days">1 Day</option>
            <option value="7 days">7 Days</option>
            <option value="1 months">1 Month</option>
          </Form.Select>
          {!!touched.suspendInterval && (
            <Form.Control.Feedback type="invalid">
              {errors.suspendInterval}
            </Form.Control.Feedback>
          )}
        </Form.Group>
      </Collapse>
      <Form.Group className="mb-3">
        <Form.Label>Watch Type</Form.Label>
        <Form.Select
          id="watchdogTypeSelect"
          name="watchdogType"
          onChange={(e) => {
            handleChange(e);
            if (e.target.value === "Live Usage") {
              setFieldValue("autoInterval", "1 months");
            } else {
              setFieldValue("autoInterval", "");
            }
          }}
          onBlur={handleBlur}
          value={values.watchdogType}
          isInvalid={touched.watchdogType && !!errors.watchdogType}
        >
          <option value="">Select Watchdog Type</option>
          <option value="Rated Usage">Rated Usage</option>
          <option value="Live Usage">Live Usage</option>
          <option value="Cost">Cost</option>
        </Form.Select>
        {!!touched.watchdogType && (
          <Form.Control.Feedback type="invalid">
            {errors.watchdogType}
          </Form.Control.Feedback>
        )}
      </Form.Group>
      <Collapse
        in={values.watchdogType !== "Live Usage" && values.watchdogType !== ""}
        dimension="height"
      >
        <Form.Group className="mb-3">
          <Form.Label>Auto Interval</Form.Label>
          <Form.Select
            id="suspensionIntervalSelect"
            name="autoInterval"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.autoInterval}
            isInvalid={touched.autoInterval && !!errors.autoInterval}
          >
            <option value="">Select interval</option>
            <option value="none">None</option>
            <option value="1 days">1 Day</option>
            <option value="7 days">7 Days</option>
            <option value="1 months">1 Month</option>
          </Form.Select>
          {!!touched.autoInterval && (
            <Form.Control.Feedback type="invalid">
              {errors.autoInterval}
            </Form.Control.Feedback>
          )}
        </Form.Group>
      </Collapse>
      <Form.Group className="mb-3">
        <Form.Label>Amount</Form.Label>
        <Form.Control
          id="amountField"
          name="amount"
          type="text"
          value={values.amount}
          onChange={handleChange}
          onBlur={handleBlur}
          isInvalid={touched.amount && !!errors.amount}
        />
        {!!touched.amount && (
          <Form.Control.Feedback type="invalid">
            {errors.amount}
          </Form.Control.Feedback>
        )}
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Service</Form.Label>
        <Form.Select
          id="serviceSelect"
          name="service"
          onChange={(e) => {
            handleChange(e);
          }}
          onBlur={handleBlur}
          value={values.service}
          isInvalid={touched.service && !!errors.service}
        >
          <option value="">Select Service</option>
          <option value="All Data">All Data</option>
          <option value="Sms">Sms MO</option>
          <option value="Zone 1">Zone 1</option>
          <option value="Zone 2">Zone 2</option>
          <option value="Zone 3">Zone 3</option>
          <option value="Zone 4">Zone 4</option>
          <option value="Zone 5">Zone 5</option>
          <option value="Zone 6">Zone 6</option>
          <option value="Zone 7">Zone 7</option>
          <option value="Zone 8">Zone 8</option>
          <option value="Zone 9">Zone 9</option>
          <option value="Zone1-2">Zone 1-2</option>
          <option value="Zone1-3">Zone 1-3</option>
          <option value="Zone1-4">Zone 1-4</option>
          <option value="Zone1-5">Zone 1-5</option>
          <option value="Zone1-6">Zone 1-6</option>
          <option value="Zone1-7">Zone 1-7</option>
          <option value="Zone1-8">Zone 1-8</option>
          <option value="Zone1-9">Zone 1-9</option>
          <option value="Zone2-9">Zone 2-9</option>
          <option value="Zone3-9">Zone 3-9</option>
          <option value="Zone4-9">Zone 4-9</option>
          <option value="Zone5-9">Zone 5-9</option>
          <option value="Zone6-9">Zone 6-9</option>
          <option value="Zone7-9">Zone 7-9</option>
          <option value="Zone8-9">Zone 8-9</option>
        </Form.Select>
        {!!touched.service && (
          <Form.Control.Feedback type="invalid">
            {errors.service}
          </Form.Control.Feedback>
        )}
      </Form.Group>
      <Collapse in={values.service !== ""} dimension="height">
        <Form.Group className="mb-3">
          <Form.Label>Unit Type</Form.Label>
          <Form.Select
            id="unitType"
            name="unitType"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.unitType}
            isInvalid={touched.unitType && !!errors.unitType}
          >
            {values.service === "Sms" && (
              <>
                <option value="">Select unit type</option>
                <option value="unit">Unit</option>
              </>
            )}
            {values.service !== "Sms" && (
              <>
                <option value="">Select unit type</option>
                <option value="byte">Byte</option>
                <option value="KB">KByte</option>
                <option value="MB">MByte</option>
                <option value="GB">GByte</option>
              </>
            )}
          </Form.Select>
          {!!touched.unitType && (
            <Form.Control.Feedback type="invalid">
              {errors.unitType}
            </Form.Control.Feedback>
          )}
        </Form.Group>
      </Collapse>
      <Form.Group className="mb-3">
        <Form.Label>Notification</Form.Label>
        <Form.Select
          id="notificationTypeSelect"
          name="notification"
          value={values.notification}
          onChange={(event) => {
            handleChange(event);
            if (event.target.value === "none" || event.target.value === "") {
              setFieldValue("warning1", "");
              setFieldValue("warning2", "");
              setFieldValue("warning3", "");
              setShowWarning2Field(false);
              setShowWarning3Field(false);
            } else {
              setFieldValue("warning1", "0.0");
              setFieldValue("warning2", "0.0");
              setFieldValue("warning3", "0.0");
            }
          }}
          onBlur={handleBlur}
          isInvalid={touched.notification && !!errors.notification}
        >
          <option value="">Select Notification Type</option>
          <option value="none">None</option>
          <option value="email">Email</option>
        </Form.Select>
        {!!touched.notification && (
          <Form.Control.Feedback type="invalid">
            {errors.notification}
          </Form.Control.Feedback>
        )}
      </Form.Group>
      <Collapse
        in={values.notification !== "" && values.notification !== "none"}
        dimension="height"
      >
        <Form.Group className="mb-3">
          <Form.Label>Warning 1</Form.Label>
          <div className="d-flex">
            <Form.Control
              id="warning1Field"
              className="me-2"
              name="warning1"
              type="text"
              value={values.warning1}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={!!errors.warning1 && touched.warning1}
            />
            <Button
              id="addWarningButton"
              className="rounded-pill d-flex justify-content-center align-items-center"
              style={{
                height: "34px",
                width: "34px",
                "--dynamic-bg-color": !impersonationUser.id
                  ? user.backgroundColor
                  : impersonationUser.backgroundColor,
                "--dynamic-font-color": !impersonationUser.id
                  ? user.backgroundColor
                  : impersonationUser.backgroundColor,
                border: "none",
              }}
              variant="primary"
              disabled={errors.warning1}
              onClick={() => setShowWarning2Field(true)}
            >
              <FaPlus />
            </Button>
          </div>
          {!!touched.warning1 && (
            <Form.Control.Feedback type="invalid">
              {errors.warning1}
            </Form.Control.Feedback>
          )}
        </Form.Group>
      </Collapse>
      <Collapse in={showWarning2Field} dimension="height">
        <Form.Group className="mb-3">
          <Form.Label>Warning 2</Form.Label>
          <div className="d-flex">
            <Form.Control
              id="warning2Field"
              className="me-2"
              name="warning2"
              type="text"
              value={values.warning2}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={touched.warning2 && !!errors.warning2}
            />
            <Button
              id="addWarningButton"
              className="rounded-pill d-flex justify-content-center align-items-center me-2"
              style={{
                height: "34px",
                width: "34px",
                "--dynamic-bg-color": !impersonationUser.id
                  ? user.backgroundColor
                  : impersonationUser.backgroundColor,
                "--dynamic-font-color": !impersonationUser.id
                  ? user.backgroundColor
                  : impersonationUser.backgroundColor,
                border: "none",
              }}
              variant="primary"
              disabled={errors.warning2}
              onClick={() => setShowWarning3Field(true)}
            >
              <FaPlus />
            </Button>
            <Button
              id="addWarningButton"
              className="rounded-pill d-flex justify-content-center align-items-center"
              style={{
                height: "34px",
                width: "34px",
                "--dynamic-bg-color": !impersonationUser.id
                  ? user.backgroundColor
                  : impersonationUser.backgroundColor,
                "--dynamic-font-color": !impersonationUser.id
                  ? user.backgroundColor
                  : impersonationUser.backgroundColor,
                border: "none",
              }}
              variant="primary"
              onClick={() => {
                if (showWarning3Field) {
                  setShowWarning3Field(false);
                  setFieldValue("warning3", "");
                }
                setShowWarning2Field(false);
                setFieldValue("warning2", "");
              }}
            >
              <FaMinus />
            </Button>
          </div>
          {!!touched.warning2 && (
            <Form.Control.Feedback type="invalid">
              {errors.warning2}
            </Form.Control.Feedback>
          )}
        </Form.Group>
      </Collapse>
      <Collapse in={showWarning3Field} dimension="height">
        <Form.Group className="mb-3">
          <Form.Label>Warning 3</Form.Label>
          <div className="d-flex">
            <Form.Control
              id="warning2Field"
              className="me-2"
              name="warning3"
              type="text"
              value={values.warning3}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={!!errors.warning3 && touched.warning3}
            />
            <Button
              id="addWarningButton"
              className="rounded-pill d-flex justify-content-center align-items-center"
              style={{
                height: "34px",
                width: "34px",
                "--dynamic-bg-color": !impersonationUser.id
                  ? user.backgroundColor
                  : impersonationUser.backgroundColor,
                "--dynamic-font-color": !impersonationUser.id
                  ? user.backgroundColor
                  : impersonationUser.backgroundColor,
                border: "none",
              }}
              variant="primary"
              onClick={() => {
                setShowWarning3Field(false);
                setFieldValue("warning3", "");
              }}
            >
              <FaMinus />
            </Button>
          </div>
          {!!touched.warning3 && (
            <Form.Control.Feedback type="invalid">
              {errors.warning3}
            </Form.Control.Feedback>
          )}
        </Form.Group>
      </Collapse>
      <Collapse
        in={values.notification !== "" && values.notification !== "none"}
        dimension="height"
      >
        <Form.Group className="mb-3">
          <Form.Label>Distribution List</Form.Label>
          <Field name="distributionList">
            {({ field, form }) => (
              <Select
                name="distributionList"
                isMulti
                options={options}
                value={
                  form.values.distributionList
                    ? form.values.distributionList.map((address) =>
                        options.find((option) => option.value === address)
                      )
                    : []
                }
                onChange={(selectedOptions) => {
                  form.setFieldValue(
                    "distributionList",
                    selectedOptions
                      ? selectedOptions.map((option) => option.value)
                      : []
                  );
                }}
                onBlur={field.onBlur}
                isInvalid={
                  form.touched.distributionList &&
                  !!form.errors.distributionList
                }
              />
            )}
          </Field>
          {!!touched.distributionList && (
            <Form.Control.Feedback type="invalid">
              {errors.distributionList}
            </Form.Control.Feedback>
          )}
        </Form.Group>
      </Collapse>
      <Modal.Footer>
        <Button
          variant="secondary"
          style={{ border: "none" }}
          onClick={() => {
            setShowModifyWatchdogModal(false);
          }}
        >
          Cancel
        </Button>
        <Button
          style={{
            "--dynamic-bg-color": !impersonationUser.id
              ? user.backgroundColor
              : impersonationUser.backgroundColor,
            "--dynamic-font-color": !impersonationUser.id
              ? user.backgroundColor
              : impersonationUser.backgroundColor,
            border: "none",
          }}
          onClick={() => {
            nextStep();
          }}
        >
          Next
        </Button>
      </Modal.Footer>
    </Form>
  );
};

const ModifySummary = ({ watchdog, handleSubmit }) => {
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  return (
    <>
      <Table>
        <thead>
          <th>Action Type</th>
          <th>Amount</th>
          <th>Service</th>
        </thead>
        <tbody>
          <tr>
            <td>{watchdog.actionType}</td>
            <td>
              {watchdog.amount}
              {watchdog.unitType}
            </td>
            <td>{watchdog.service}</td>
          </tr>
        </tbody>
      </Table>
      <Modal.Footer>
        <Button
          style={{
            "--dynamic-bg-color": !impersonationUser.id
              ? user.backgroundColor
              : impersonationUser.backgroundColor,
            "--dynamic-font-color": !impersonationUser.id
              ? user.backgroundColor
              : impersonationUser.backgroundColor,
            border: "none",
          }}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </Modal.Footer>
    </>
  );
};

const ModifyOrder = () => {
  return (
    <div className="text-center">
      <Spinner
        animation="border"
        variant="primary"
        className="mt-5 mb-3"
        style={{ width: "5rem", height: "5rem" }}
      />
      <h3>Waiting for completion</h3>
    </div>
  );
};

const ModifyResult = ({
  getStatus,
  setShowModifyWatchdogModal,
  setCurrentStep,
  dispatch,
  userId,
  imsi,
}) => {
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  if (getStatus === "Success") {
    return (
      <>
        <div className="text-center">
          <FaCheckCircle size={120} className="mt-3" color="#4BBF73" />
          <h3 className="mt-3">Order Complete</h3>
        </div>
        <Modal.Footer>
          <Button
            onClick={async () => {
              await dispatch(getSimWatchdogs(userId, imsi));
              setShowModifyWatchdogModal(false);
              setCurrentStep(1);
            }}
            style={{
              "--dynamic-bg-color": !impersonationUser.id
                ? user.backgroundColor
                : impersonationUser.backgroundColor,
              "--dynamic-font-color": !impersonationUser.id
                ? user.backgroundColor
                : impersonationUser.backgroundColor,
              border: "none",
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </>
    );
  } else if (getStatus === "Error") {
    return (
      <>
        <div className="text-center">
          <FaExclamationCircle size={120} className="mt-3" color="#FF9494" />
          <h3 className="mt-3">Order Error</h3>
          <p>Please contact the administrator.</p>
        </div>
        <Modal.Footer>
          <Button
            onClick={async () => {
              await dispatch(getSimWatchdogs(userId, imsi));
              setShowModifyWatchdogModal(false);
              setCurrentStep(1);
            }}
            style={{
              "--dynamic-bg-color": !impersonationUser.id
                ? user.backgroundColor
                : impersonationUser.backgroundColor,
              "--dynamic-font-color": !impersonationUser.id
                ? user.backgroundColor
                : impersonationUser.backgroundColor,
              border: "none",
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </>
    );
  }
};

const ModifyWatchdogForm = ({
  userId,
  imsi,
  watchdog,
  currentStep,
  setCurrentStep,
  distributionList,
  setShowModifyWatchdogModal,
  setRequestStatus,
  getStatus,
  dispatch,
  typeOfWatchdog,
  setTypeOfWatchdog,
}) => {
  return (
    <Formik
      initialValues={{
        actionType: watchdog.actionType,
        watchdogType:
          watchdog.watchdogType === "cost"
            ? "Cost"
            : watchdog.watchdogType.includes("external")
            ? "Live Usage"
            : "Rated Usage",
        autoInterval: watchdog.watchDogInterval,
        suspendInterval: watchdog.suspendInterval,
        amount: parseFloat(watchdog.amount.replace(/[^0-9.]/g, "")),
        service: watchdog.service,
        unitType: watchdog.unitType,
        notification: watchdog.notificationType,
        warning1: parseFloat(watchdog.warning),
        warning2: parseFloat(watchdog.warning1),
        warning3: parseFloat(watchdog.warning2),
        distributionList: watchdog.emailList,
        submit: false,
      }}
      validationSchema={validationSchema}
      onSubmit={async (
        values,
        { setErrors, setSubmitting, nextStep, previousStep }
      ) => {
        setCurrentStep(3);
        await modifySimWatchdog(
          userId,
          imsi,
          watchdog.id,
          values.actionType,
          values.amount,
          values.notification,
          values.service,
          values.suspendInterval,
          values.unitType,
          values.warning1,
          values.warning2,
          values.warning3,
          values.autoInterval,
          typeOfWatchdog,
          values.distributionList
        )
          .then((response) => {
            setRequestStatus("Success");
            setCurrentStep(4);
          })
          .catch((response) => {
            setRequestStatus("Error");
            setCurrentStep(4);
          });
      }}
    >
      {({
        errors,
        handleBlur,
        setFieldTouched,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setValues,
        validateForm,
        setFieldValue,
      }) => {
        const nextStep = () => {
          if (currentStep === 1) {
            validateForm().then((errors) => {
              if (Object.keys(errors).length === 0) {
                setCurrentStep(2);
              } else {
                Object.keys(values).forEach((field) => {
                  setFieldTouched(field);
                });
              }
            });
          }
        };
        if (currentStep === 1) {
          return (
            <ModifyForm
              errors={errors}
              handleBlur={handleBlur}
              setFieldTouched={setFieldTouched}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              isSubmitting={isSubmitting}
              touched={touched}
              values={values}
              setValues={setValues}
              validateForm={validateForm}
              setFieldValue={setFieldValue}
              nextStep={nextStep}
              distributionList={distributionList}
              setShowModifyWatchdogModal={setShowModifyWatchdogModal}
              setTypeOfWatchdog={setTypeOfWatchdog}
            />
          );
        } else if (currentStep === 2) {
          return (
            <ModifySummary watchdog={watchdog} handleSubmit={handleSubmit} />
          );
        } else if (currentStep === 3) {
          return <ModifyOrder />;
        } else if (currentStep === 4) {
          return (
            <ModifyResult
              getStatus={getStatus}
              setCurrentStep={setCurrentStep}
              setShowModifyWatchdogModal={setShowModifyWatchdogModal}
              dispatch={dispatch}
              userId={userId}
              imsi={imsi}
            />
          );
        }
      }}
    </Formik>
  );
};

const ModifyWatchdogModal = ({
  watchdog,
  imsi,
  showModifyWatchdogModal,
  setShowModifyWatchdogModal,
}) => {
  const handleClose = () => {
    setShowModifyWatchdogModal(false);
    setCurrentStep(1);
  };
  const [currentStep, setCurrentStep] = useState(1);
  const [getStatus, setRequestStatus] = useState("");
  const [distributionList, setDistributionList] = useState([]);
  const { distribution } = useSelector((state) => state.distribution);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const [typeOfWatchdog, setTypeOfWatchdog] = useState("");

  useEffect(() => {
    setDistributionList(distribution);
  }, [distribution]);

  useEffect(() => {
    const fetchDistribution = async (id) => {
      await dispatch(getDistributionEmails(user.id));
    };
    if (!impersonationUser.id) {
      fetchDistribution(user.id);
    } else {
      fetchDistribution(impersonationUser.id);
    }
  }, [user, impersonationUser.id, dispatch]);

  return (
    <Modal
      show={showModifyWatchdogModal}
      centered
      backdrop="static"
      onHide={handleClose}
      scrollable
    >
      <Modal.Header>Modify Watchdog</Modal.Header>
      <Modal.Body style={{ height: "fit-content" }}>
        <ModifyWatchdogForm
          userId={!impersonationUser.id ? user.id : impersonationUser.id}
          imsi={imsi}
          watchdog={watchdog}
          distributionList={distributionList}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          setShowModifyWatchdogModal={setShowModifyWatchdogModal}
          setRequestStatus={setRequestStatus}
          getStatus={getStatus}
          dispatch={dispatch}
          typeOfWatchdog={typeOfWatchdog}
          setTypeOfWatchdog={setTypeOfWatchdog}
        />
      </Modal.Body>
    </Modal>
  );
};

export default ModifyWatchdogModal;
