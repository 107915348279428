import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { Card, Dropdown, Col, Spinner } from "react-bootstrap";

import { MoreHorizontal } from "react-feather";

import { useDispatch, useSelector } from "react-redux";
import { getDistribution } from "../../redux/slices/dataSessions";

const DataVolumeBarChart = ({ period }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const { t } = useTranslation();
  const [type, setType] = useState("dataVolume");
  const [isLoading, setIsLoading] = useState(false);
  const [barData, setBarData] = useState([]);

  useEffect(() => {
    const fetchDistribution = async (id) => {
      setIsLoading(true);
      const response = await getDistribution(id, type, period);
      setBarData(response);
      setIsLoading(false);
    };

    if (!impersonationUser.id) {
      if (user && user.id) {
        fetchDistribution(user.id);
      }
    } else {
      fetchDistribution(impersonationUser.id);
    }
  }, [user, impersonationUser.id, type, period, dispatch]);

  function calculateData(item, type) {
    switch (type) {
      case "dataVolume":
        return parseFloat(item.totalBytes / 1024.0 / 1024.0).toFixed(2);
      case "dataSessions":
        return item.dataSessions;
      case "zeroSessions":
        return item.dataSessions;
      case "alerts":
        return item.alerts;
      default:
        return 0;
    }
  }

  const data = {
    labels: barData.map((item) => item.period),
    datasets: [
      {
        label: "Data Volume",
        backgroundColor: impersonationUser.id
          ? impersonationUser.backgroundColor
          : user.backgroundColor,
        borderColor: impersonationUser.id
          ? impersonationUser.backgroundColor
          : user.backgroundColor,
        hoverBackgroundColor: impersonationUser.id
          ? impersonationUser.backgroundColor
          : user.backgroundColor,
        hoverBorderColor: impersonationUser.id
          ? impersonationUser.backgroundColor
          : user.backgroundColor,
        data: barData.map((item) => calculateData(item, type)),
        barPercentage: 0.5,
        categoryPercentage: 0.5,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    cornerRadius: 15,
    legend: {
      display: false,
    },
    scales: {
      yAxes: [
        {
          gridLines: {
            display: false,
          },

          stacked: true,
        },
      ],
      xAxes: [
        {
          gridLines: {
            color: "transparent",
          },
          stacked: true,
        },
      ],
    },
  };

  return (
    <Card className="flex-fill w-100">
      <Card.Header>
        <Card.Title className="mb-0">
          {type === "dataVolume"
            ? t("Data Volume")
            : type === "dataSessions"
            ? t("Data Sessions")
            : type === "alerts"
            ? t("Alerts")
            : type === "zeroSessions"
            ? t("Zero Sessions")
            : type === "extraCharge"
            ? t("Extra Charge")
            : null}
        </Card.Title>
        <div className="card-actions float-end">
          <Dropdown align="end">
            <Dropdown.Toggle as="a" bsPrefix="-">
              <MoreHorizontal />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => setType("dataVolume")}>
                {t("Data Volume")}
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setType("alerts")}>
                {t("Alerts")}
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setType("dataSessions")}>
                {t("Data Sessions")}
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setType("zeroSessions")}>
                {t("Zero Sessions")}
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setType("extraCharge")}>
                {t("Extra Charge")}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </Card.Header>
      <Card.Body className="d-flex">
        <Col>
          {!isLoading && (
            <div className="align-self-center w-100">
              <div className="chart">
                <Bar data={data} options={options} />
              </div>
            </div>
          )}
          {isLoading && (
            <div className="text-center">
              <Spinner animation="border" variant="primary" />
            </div>
          )}
        </Col>
      </Card.Body>
    </Card>
  );
};

export default DataVolumeBarChart;
