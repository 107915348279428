import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Container } from "react-bootstrap";

import Clients from "./Clients";

import { useDispatch, useSelector } from "react-redux";
import { getManagers } from "../../redux/slices/managers";
import Loader from "../../components/spinners/Loader";

const Managers = () => {
  const dispatch = useDispatch();
  const { managers } = useSelector((state) => state.managers);
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchManagers = async () => {
      if (!impersonationUser.id) {
        if (!user.id) return;
        await dispatch(getManagers(user.id));
      } else {
        await dispatch(getManagers(impersonationUser.id));
      }

      setIsLoading(false);
    };

    fetchManagers();
  }, [dispatch, user.id, user.privilege, impersonationUser.id]);

  if (isLoading) {
    return <Loader />;
  } else {
    return (
      <React.Fragment>
        <Helmet title="Managers" />
        <Container fluid className="p-0">
          <Clients title="Managers" clientList={managers} />
        </Container>
      </React.Fragment>
    );
  }
};

export default Managers;
