import axios from "../../utils/axios";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  statements: [],
};

const statementSlice = createSlice({
  name: "statements",
  initialState,
  reducers: {
    setStatements(state, action) {
      state.statements = action.payload;
    },
  },
});

export function getStatements(id) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    try {
      await axios
        .get(
          `https://dev.pos2cloud.com:5000/api/statements/${id}/getStatements`,
          {
            headers: {
              accessToken: `Bearer ${accessToken}`,
            },
          }
        )
        .then((response) => {
          dispatch(statementSlice.actions.setStatements(response.data));
        });
    } catch (error) {
      if (
        error.response &&
        (error.response.status === 404 || error.response.status === 204)
      ) {
        dispatch(statementSlice.actions.setStatements([]));
      } else {
        dispatch(statementSlice.actions.setStatements([]));
      }
    }
  };
}

export const { setStatements } = statementSlice.actions;
export default statementSlice.reducer;
