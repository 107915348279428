import { createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";

const initialState = {
  distribution: [],
};

const distributionSlice = createSlice({
  name: "distribution",
  initialState,
  reducers: {
    setDistributionList: (state, action) => {
      state.distribution = action.payload;
    },
    addEmailToDistributionList(state, action) {
      return {
        ...state,
        distribution: [...state.distribution, action.payload],
      };
    },
    removeEmailFromDistribution(state, action) {
      return {
        ...state,
        distribution: state.distribution.filter(
          (email) => email.id !== action.payload
        ),
      };
    },
  },
});

export function getDistributionEmails(id) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    await axios
      .get(`https://dev.pos2cloud.com:5000/api/distribution/${id}/getEmails`, {
        headers: {
          accessToken: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        dispatch(distributionSlice.actions.setDistributionList(response.data));
      })
      .catch((error) => {
        dispatch(distributionSlice.actions.setDistributionList([]));
      });
  };
}

export async function addDistributionEmail(id, name, category, address) {
  const accessToken = window.localStorage.getItem("accessToken");
  const formData = new FormData();
  formData.append("name", name);
  formData.append("category", category);
  formData.append("address", address);
  await axios
    .post(
      `https://dev.pos2cloud.com:5000/api/distribution/${id}/addEmail`,
      formData,
      {
        headers: {
          accessToken: `Bearer ${accessToken}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw new Error(error.response);
    });
}

export async function removeDistributionEmail(id, emailId) {
  const accessToken = window.localStorage.getItem("accessToken");
  const formData = new FormData();
  formData.append("emailId", emailId);
  await axios
    .put(
      `https://dev.pos2cloud.com:5000/api/distribution/${id}/removeEmail`,
      formData,
      {
        headers: {
          accessToken: `Bearer ${accessToken}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw new Error(error.response);
    });
}

export const {
  setDistributionList,
  addEmailToDistributionList,
  removeEmailFromDistribution,
} = distributionSlice.actions;

export default distributionSlice.reducer;
