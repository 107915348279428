import React from "react";
import { Card, Form, Row, Col, Button } from "react-bootstrap";
import { useSelector } from "react-redux";

const PrivateInfo = ({ username }) => {
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  return (
    <Card>
      <Card.Header>
        <Card.Title className="mb-0">Private info</Card.Title>
      </Card.Header>
      <Card.Body>
        <Form>
          <Row>
            <Col md="12">
              <Form.Group className="mb-3">
                <Form.Label htmlFor="inputUsername">Username</Form.Label>
                <Form.Control
                  type="text"
                  id="inputUsername"
                  placeholder="Username"
                  value={username}
                />
              </Form.Group>
            </Col>
          </Row>

          <Button
            style={{
              "--dynamic-bg-color": !impersonationUser.id
                ? user.backgroundColor
                : impersonationUser.backgroundColor,
              border: "none",
            }}
          >
            Save changes
          </Button>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default PrivateInfo;
