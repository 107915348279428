import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { getSims, getSimInventoryCount } from "../../../redux/slices/sims.js";
import { Container, Row, Col, Fade } from "react-bootstrap";

import { useDispatch, useSelector } from "react-redux";

import Loader from "../../../components/spinners/Loader.js";
import Single from "./SimSingle";
import SimsList from "./SimsList.js";
import { getCustomFields } from "../../../redux/slices/customFields.js";

function Sims() {
  const dispatch = useDispatch();
  const sims = useSelector((state) => state.sims.sims);
  const count = useSelector((state) => state.sims.count);
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const { customFields } = useSelector((state) => state.customFields);
  const [open, setOpen] = useState(false);
  const [shownSim, setShownSim] = useState();
  const [singleIsShown, setSingleIsShown] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isCustomFieldsLoading, setIsCustomFieldsLoading] = useState(false);
  const [numberOfRecordsRetrieved, setNumberOfRecordsRetrieved] =
    useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(0);
  const [numberOfRecords, setNumberOfRecords] = useState(null);
  const [cellInfo, setCellInfo] = useState({
    area: "",
    cell: "",
    cellId: "",
    lat: "",
    lon: "",
    mcc: "",
    range: "",
    unit: "",
  });
  const [filters] = useState({
    status: "",
    imsi: "",
    iccid: "",
    msisdn: "",
    eid: "",
    minData: "",
    maxData: "",
  });
  const [mergedFilters, setMergedFilters] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    const fetchSims = async (id) => {
      if (!id) {
        return;
      }
      await dispatch(getSims(id, page + 1, pageSize, mergedFilters));
    };

    const fetchNumberOfSims = async (id) => {
      if (!id) {
        return;
      }
      setNumberOfRecordsRetrieved(false);
      await dispatch(getSimInventoryCount(id, mergedFilters));
      if (count === -1) {
        setNumberOfRecords(0);
      } else {
        setNumberOfRecords(count);
      }
      setNumberOfRecordsRetrieved(true);
    };

    if (!impersonationUser.id) {
      setIsLoading(true);
      fetchSims(user.id);
      fetchNumberOfSims(user.id);
      setIsLoading(false);
    } else {
      setIsLoading(true);
      fetchSims(impersonationUser.id);
      fetchNumberOfSims(impersonationUser.id);
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, page, pageSize, count, user, impersonationUser.id]);

  useEffect(() => {
    if (sims.length > 0) {
      setShownSim(sims[0]);
    }
  }, [sims]);

  useEffect(() => {
    const fetchCustomFields = async (id) => {
      if (!id) return;
      setIsCustomFieldsLoading(true);
      await dispatch(getCustomFields(id));
      setIsCustomFieldsLoading(false);
    };

    if (!impersonationUser.id) {
      fetchCustomFields(user.id);
    } else {
      fetchCustomFields(impersonationUser.id);
    }
  }, [user.id, impersonationUser.id, dispatch]);

  useEffect(() => {
    setMergedFilters(
      customFields.reduce(
        (acc, field) => {
          acc[field.displayName.trim()] = "";
          return acc;
        },
        { ...filters }
      )
    );
  }, [customFields, filters]);

  const result = Math.ceil(numberOfRecords / pageSize);

  console.log(sims);

  if (isLoading || isCustomFieldsLoading || !numberOfRecordsRetrieved) {
    return <Loader />;
  } else {
    return (
      <React.Fragment>
        <Helmet title="SIMs">
          <link
            rel="icon"
            href={`data:image/x-icon;base64,${
              !impersonationUser.id ? user.favicon : impersonationUser.favicon
            }`}
          />
        </Helmet>
        <Container fluid className="p-0">
          <h1 className="h3 mb-3">SIMs</h1>
          <Row>
            <Col
              md="12"
              xl={open ? "8" : "12"}
              style={{ transition: "all 0.5s" }}
            >
              <SimsList
                setOpen={setOpen}
                setSingleIsShown={setSingleIsShown}
                shownSim={shownSim}
                setShownSim={setShownSim}
                data={sims}
                page={page}
                setPage={setPage}
                pageSize={pageSize}
                setPageSize={setPageSize}
                numberOfRecords={result}
                setCellInfo={setCellInfo}
                filters={mergedFilters}
                setFilters={setMergedFilters}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                customFields={customFields}
              />
            </Col>
            <Fade in={singleIsShown}>
              <Col md="12" xl="4">
                <Single
                  setOpen={setOpen}
                  setSingleIsShown={setSingleIsShown}
                  shownSim={shownSim}
                  cellInfo={cellInfo}
                  page={page}
                  pageSize={pageSize}
                  filters={mergedFilters}
                />
              </Col>
            </Fade>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default Sims;
