import React from "react";
import RowSelectingTable from "./RowSelectingTable";
import { clientColumns } from "../../inputData/clientData";

const ClientsList = (props) => {
  if (props.clientList) {
    return (
      <RowSelectingTable
        columns={clientColumns}
        data={props.clientList}
        shownClient={props.shownClient}
        setShownClient={props.setShownClient}
        setOpen={props.setOpen}
        setSingleIsShown={props.setSingleIsShown}
        title={props.title}
        showCreateEntityModal={props.showCreateEntityModal}
        setShowCreateEntityModal={props.setShowCreateEntityModal}
      />
    );
  }
};

export default ClientsList;
