import React, { useState, useRef, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Form, Alert, Button } from "react-bootstrap";
import * as Yup from "yup";
import { Formik } from "formik";

import NotyfContext from "../../../contexts/NotyfContext";
import { addNewCustomer } from "../../../redux/slices/customers";
import { addNewManager } from "../../../redux/slices/managers";
import { addNewTenant } from "../../../redux/slices/tenants";
import { addNewSubaccount } from "../../../redux/slices/subaccounts";
import defaultUser from "../../../assets/img/avatars/default-user.png";
import Basic from "./steps/Basic";
import Contact from "./steps/Contact";
import EmploymentAndTax from "./steps/Employment";
import OffersStep from "./steps/Offers";
import Billing from "./steps/Billing";

function CreateEntityModal(props) {
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const notyf = useContext(NotyfContext);
  const [image, setImage] = useState(defaultUser);

  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const [currentStep, setCurrentStep] = useState(1);
  const [offers, setOffers] = useState([]);

  const handleUpload = () => {
    inputRef.current?.click();
  };

  const handleDisplayFileDetails = () => {
    const file = inputRef.current?.files[0];
    if (!file) return;
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setImage(reader.result);
      }
    };
    reader.onerror = () => {
      console.log(reader.error);
    };
    reader.readAsDataURL(inputRef.current.files[0]);
  };

  const BasicValidation = Yup.object().shape({
    fullName: Yup.string().max(255).required("Full Name is required"),
    address: Yup.string().max(255).required("Address is required"),
    postcode: Yup.string()
      .max(12, "Must be at most 12 characters")
      .required("Required"),
    city: Yup.string().required("City is required"),
    country: Yup.string().required("Country is required"),
    portfolioId: Yup.string().matches(
      /^[0-9]*$/,
      "Portfolio ID must contain only numbers"
    ),
    photo: Yup.mixed().required("A file is required"),
  });

  const ContactValidation = Yup.object().shape({
    email: Yup.string()
      .email("Must be a valid email")
      .max(255)
      .required("Email is required"),
    telephoneNumber: Yup.string().required("Telephone Number is Required"),
    mobileNumber: Yup.string().required("Mobile Number is required"),
  });

  const EmploymentValidation = Yup.object().shape({
    occupation: Yup.string().required("Occupation is required"),
    tin: Yup.string().required("TIN is required"),
  });

  const BillingValidation = Yup.object().shape({
    minimumAmountToInvoice: Yup.string().notRequired(),
    invoiceType: Yup.string().required("Required"),
    invoiceHandlerType: Yup.string().notRequired(),
    currency: Yup.string().required(),
    invoiceConfiguration: Yup.string().required(),
    preferredLanguage: Yup.string().required("Required"),
    billingEmail: Yup.string().notRequired(),
  });

  return (
    <Modal show={props.showCreateEntityModal} centered>
      <Modal.Header>
        {props.entityType === "Tenant"
          ? "New Tenant"
          : props.entityType === "Manager"
          ? "New Manager"
          : props.entityType === "Customer"
          ? "New Customer"
          : props.entityType === "Subaccount"
          ? "New Subaccount"
          : null}
      </Modal.Header>
      <Modal.Body>
        <Formik
          formikProps
          initialValues={{
            fullName: "",
            address: "",
            email: "",
            postcode: "",
            city: "",
            country: "",
            telephoneNumber: "",
            mobileNumber: "",
            status: "",
            occupation: "",
            company: "",
            tin: "",
            photo: inputRef.current?.files[0],
            portfolioId: "",
            minimumAmountToInvoice: "20",
            invoiceType: "",
            invoiceHandlerType: "auto",
            currency: "EUR",
            invoiceConfiguration: "skytrackInvoice",
            preferredLanguage: "english",
            billingEmail: [""],
            submit: false,
          }}
          validationSchema={
            currentStep === 1
              ? BasicValidation
              : currentStep === 2
              ? ContactValidation
              : currentStep === 3
              ? EmploymentValidation
              : currentStep === 4
              ? BillingValidation
              : null
          }
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              if (props.entityType === "Tenant") {
                dispatch(
                  addNewTenant(
                    !impersonationUser.id ? user.id : impersonationUser.id,
                    values.fullName,
                    values.address,
                    values.city,
                    values.country,
                    values.postcode,
                    values.telephoneNumber,
                    values.mobileNumber,
                    values.email,
                    values.occupation,
                    values.company,
                    values.tin,
                    values.portfolioId,
                    values.photo,
                    values.minimumAmountToInvoice,
                    values.invoiceType,
                    values.invoiceHandlerType,
                    values.currency,
                    values.invoiceConfiguration,
                    values.preferredLanguage,
                    values.billingEmail,
                    offers
                  )
                );
              } else if (props.entityType === "Manager") {
                dispatch(
                  addNewManager(
                    !impersonationUser.id ? user.id : impersonationUser.id,
                    values.fullName,
                    values.address,
                    values.city,
                    values.country,
                    values.postcode,
                    values.telephoneNumber,
                    values.mobileNumber,
                    values.email,
                    values.occupation,
                    values.company,
                    values.tin,
                    values.portfolioId,
                    values.photo,
                    values.minimumAmountToInvoice,
                    values.invoiceType,
                    values.invoiceHandlerType,
                    values.currency,
                    values.invoiceConfiguration,
                    values.preferredLanguage,
                    values.billingEmail,
                    offers
                  )
                );
              } else if (props.entityType === "Customer") {
                dispatch(
                  addNewCustomer(
                    !impersonationUser.id ? user.id : impersonationUser.id,
                    values.fullName,
                    values.address,
                    values.city,
                    values.country,
                    values.postcode,
                    values.telephoneNumber,
                    values.mobileNumber,
                    values.email,
                    values.occupation,
                    values.company,
                    values.tin,
                    values.portfolioId,
                    values.photo,
                    values.minimumAmountToInvoice,
                    values.invoiceType,
                    values.invoiceHandlerType,
                    values.currency,
                    values.invoiceConfiguration,
                    values.preferredLanguage,
                    values.billingEmail,
                    offers
                  )
                );
              } else if (props.entityType === "Subaccount") {
                dispatch(
                  addNewSubaccount(
                    !impersonationUser.id ? user.id : impersonationUser.id,
                    values.fullName,
                    values.address,
                    values.city,
                    values.country,
                    values.postcode,
                    values.telephoneNumber,
                    values.mobileNumber,
                    values.email,
                    values.occupation,
                    values.company,
                    values.tin,
                    values.photo,
                    offers
                  )
                );
              }
              notyf.open({
                type: "success",
                message: "Customer successfully created",
                duration: 2500,
                ripple: true,
                dismissible: false,
                position: {
                  x: "center",
                  y: "top",
                },
              });
            } catch (error) {
              const message = error.message || "Something went wrong";

              setStatus({ success: false });
              setErrors({ submit: message });
              setSubmitting(false);
              notyf.open({
                type: "danger",
                message: message,
                duration: 2500,
                ripple: true,
                dismissible: false,
                position: {
                  x: "center",
                  y: "top",
                },
              });
            }
            props.setShowCreateEntityModal(false);
          }}
        >
          {({
            errors,
            handleBlur,
            setFieldTouched,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            setValues,
            validateForm,
            setFieldValue,
          }) => {
            const nextStep = () => {
              validateForm().then((errors) => {
                // Only transition to the next step if there are no errors
                if (Object.keys(errors).length === 0) {
                  setCurrentStep(currentStep + 1);
                } else {
                  // Force all fields to touched
                  Object.keys(values).forEach((field) => {
                    setFieldTouched(field);
                  });
                }
              });
            };
            if (props.entityType !== "Subaccount") {
              return (
                <>
                  <Form onSubmit={handleSubmit}>
                    {errors.submit && (
                      <Alert className="my-3" variant="danger">
                        {errors.submit}
                      </Alert>
                    )}
                    {currentStep === 1 && (
                      <Basic
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        values={values}
                        errors={errors}
                        touched={touched}
                        entityType={props.entityType}
                        image={image}
                        inputRef={inputRef}
                        handleDisplayFileDetails={handleDisplayFileDetails}
                        handleUpload={handleUpload}
                        setFieldValue={setFieldValue}
                      />
                    )}
                    {currentStep === 2 && (
                      <Contact
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        values={values}
                        errors={errors}
                        touched={touched}
                      />
                    )}
                    {currentStep === 3 && (
                      <EmploymentAndTax
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        values={values}
                        errors={errors}
                        touched={touched}
                      />
                    )}
                    {currentStep === 4 && (
                      <Billing
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        values={values}
                        errors={errors}
                        touched={touched}
                      />
                    )}
                    {currentStep === 5 && (
                      <OffersStep
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        values={values}
                        errors={errors}
                        touched={touched}
                        offers={offers}
                        setOffers={setOffers}
                      />
                    )}

                    <Modal.Footer>
                      {currentStep === 1 && (
                        <>
                          <Button
                            variant="primary"
                            className="me-2"
                            onClick={nextStep}
                            style={{
                              "--dynamic-bg-color": !impersonationUser.id
                                ? user.backgroundColor
                                : impersonationUser.backgroundColor,
                              border: "none",
                            }}
                          >
                            Next
                          </Button>
                          <Button
                            variant="primary"
                            onClick={() =>
                              props.setShowCreateEntityModal(false)
                            }
                            style={{
                              "--dynamic-bg-color": !impersonationUser.id
                                ? user.backgroundColor
                                : impersonationUser.backgroundColor,
                              border: "none",
                            }}
                          >
                            Cancel
                          </Button>
                        </>
                      )}
                      {currentStep === 2 && (
                        <>
                          <Button
                            variant="primary"
                            className="me-2"
                            onClick={nextStep}
                            style={{
                              "--dynamic-bg-color": !impersonationUser.id
                                ? user.backgroundColor
                                : impersonationUser.backgroundColor,
                              border: "none",
                            }}
                          >
                            Next
                          </Button>
                          <Button
                            variant="primary"
                            onClick={() => {
                              props.setShowCreateEntityModal(false);
                              setCurrentStep(1);
                            }}
                            style={{
                              "--dynamic-bg-color": !impersonationUser.id
                                ? user.backgroundColor
                                : impersonationUser.backgroundColor,
                              border: "none",
                            }}
                          >
                            Cancel
                          </Button>
                        </>
                      )}
                      {currentStep === 3 && (
                        <>
                          <Button
                            variant="primary"
                            className="me-2"
                            onClick={nextStep}
                            style={{
                              "--dynamic-bg-color": !impersonationUser.id
                                ? user.backgroundColor
                                : impersonationUser.backgroundColor,
                              border: "none",
                            }}
                          >
                            Next
                          </Button>
                          <Button
                            variant="primary"
                            onClick={() => {
                              props.setShowCreateEntityModal(false);
                              setCurrentStep(1);
                            }}
                            style={{
                              "--dynamic-bg-color": !impersonationUser.id
                                ? user.backgroundColor
                                : impersonationUser.backgroundColor,
                              border: "none",
                            }}
                          >
                            Cancel
                          </Button>
                        </>
                      )}
                      {currentStep === 4 && (
                        <>
                          <Button
                            variant="primary"
                            className="me-2"
                            onClick={nextStep}
                            style={{
                              "--dynamic-bg-color": !impersonationUser.id
                                ? user.backgroundColor
                                : impersonationUser.backgroundColor,
                              border: "none",
                            }}
                          >
                            Next
                          </Button>
                          <Button
                            variant="primary"
                            onClick={() => {
                              props.setShowCreateEntityModal(false);
                              setCurrentStep(1);
                            }}
                            style={{
                              "--dynamic-bg-color": !impersonationUser.id
                                ? user.backgroundColor
                                : impersonationUser.backgroundColor,
                              border: "none",
                            }}
                          >
                            Cancel
                          </Button>
                        </>
                      )}
                      {currentStep === 5 && (
                        <>
                          <Button
                            type="submit"
                            variant="primary"
                            className="me-2"
                            disabled={isSubmitting}
                            style={{
                              "--dynamic-bg-color": !impersonationUser.id
                                ? user.backgroundColor
                                : impersonationUser.backgroundColor,
                              border: "none",
                            }}
                          >
                            Create
                          </Button>
                          <Button
                            variant="primary"
                            onClick={() => {
                              props.setShowCreateEntityModal(false);
                              setCurrentStep(1);
                            }}
                            style={{
                              "--dynamic-bg-color": !impersonationUser.id
                                ? user.backgroundColor
                                : impersonationUser.backgroundColor,
                              border: "none",
                            }}
                          >
                            Cancel
                          </Button>
                        </>
                      )}
                    </Modal.Footer>
                  </Form>
                </>
              );
            } else {
              return (
                <>
                  <Form onSubmit={handleSubmit}>
                    {errors.submit && (
                      <Alert className="my-3" variant="danger">
                        {errors.submit}
                      </Alert>
                    )}
                    {currentStep === 1 && (
                      <Basic
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        values={values}
                        errors={errors}
                        touched={touched}
                        entityType={props.entityType}
                        image={image}
                        inputRef={inputRef}
                        handleDisplayFileDetails={handleDisplayFileDetails}
                        handleUpload={handleUpload}
                        setFieldValue={setFieldValue}
                      />
                    )}
                    {currentStep === 2 && (
                      <Contact
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        values={values}
                        errors={errors}
                        touched={touched}
                      />
                    )}
                    {currentStep === 3 && (
                      <EmploymentAndTax
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        values={values}
                        errors={errors}
                        touched={touched}
                      />
                    )}
                    {currentStep === 4 && (
                      <OffersStep
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        values={values}
                        errors={errors}
                        touched={touched}
                        offers={offers}
                        setOffers={setOffers}
                      />
                    )}

                    <Modal.Footer>
                      {currentStep === 1 && (
                        <>
                          <Button
                            variant="primary"
                            className="me-2"
                            onClick={nextStep}
                            style={{
                              "--dynamic-bg-color": !impersonationUser.id
                                ? user.backgroundColor
                                : impersonationUser.backgroundColor,
                              border: "none",
                            }}
                          >
                            Next
                          </Button>
                          <Button
                            variant="primary"
                            onClick={() =>
                              props.setShowCreateEntityModal(false)
                            }
                            style={{
                              "--dynamic-bg-color": !impersonationUser.id
                                ? user.backgroundColor
                                : impersonationUser.backgroundColor,
                              border: "none",
                            }}
                          >
                            Cancel
                          </Button>
                        </>
                      )}
                      {currentStep === 2 && (
                        <>
                          <Button
                            variant="primary"
                            className="me-2"
                            onClick={nextStep}
                            style={{
                              "--dynamic-bg-color": !impersonationUser.id
                                ? user.backgroundColor
                                : impersonationUser.backgroundColor,
                              border: "none",
                            }}
                          >
                            Next
                          </Button>
                          <Button
                            variant="primary"
                            onClick={() => {
                              props.setShowCreateEntityModal(false);
                              setCurrentStep(1);
                            }}
                            style={{
                              "--dynamic-bg-color": !impersonationUser.id
                                ? user.backgroundColor
                                : impersonationUser.backgroundColor,
                              border: "none",
                            }}
                          >
                            Cancel
                          </Button>
                        </>
                      )}
                      {currentStep === 3 && (
                        <>
                          <Button
                            variant="primary"
                            className="me-2"
                            onClick={nextStep}
                            style={{
                              "--dynamic-bg-color": !impersonationUser.id
                                ? user.backgroundColor
                                : impersonationUser.backgroundColor,
                              border: "none",
                            }}
                          >
                            Next
                          </Button>
                          <Button
                            variant="primary"
                            onClick={() => {
                              props.setShowCreateEntityModal(false);
                              setCurrentStep(1);
                            }}
                            style={{
                              "--dynamic-bg-color": !impersonationUser.id
                                ? user.backgroundColor
                                : impersonationUser.backgroundColor,
                              border: "none",
                            }}
                          >
                            Cancel
                          </Button>
                        </>
                      )}
                      {currentStep === 4 && (
                        <>
                          <Button
                            variant="primary"
                            className="me-2"
                            onClick={nextStep}
                            style={{
                              "--dynamic-bg-color": !impersonationUser.id
                                ? user.backgroundColor
                                : impersonationUser.backgroundColor,
                              border: "none",
                            }}
                          >
                            Next
                          </Button>
                          <Button
                            variant="primary"
                            onClick={() => {
                              props.setShowCreateEntityModal(false);
                              setCurrentStep(1);
                            }}
                            style={{
                              "--dynamic-bg-color": !impersonationUser.id
                                ? user.backgroundColor
                                : impersonationUser.backgroundColor,
                              border: "none",
                            }}
                          >
                            Cancel
                          </Button>
                        </>
                      )}
                      {currentStep === 5 && (
                        <>
                          <Button
                            type="submit"
                            variant="primary"
                            className="me-2"
                            disabled={isSubmitting}
                            style={{
                              "--dynamic-bg-color": !impersonationUser.id
                                ? user.backgroundColor
                                : impersonationUser.backgroundColor,
                              border: "none",
                            }}
                          >
                            Create
                          </Button>
                          <Button
                            variant="primary"
                            onClick={() => {
                              props.setShowCreateEntityModal(false);
                              setCurrentStep(1);
                            }}
                            style={{
                              "--dynamic-bg-color": !impersonationUser.id
                                ? user.backgroundColor
                                : impersonationUser.backgroundColor,
                              border: "none",
                            }}
                          >
                            Cancel
                          </Button>
                        </>
                      )}
                    </Modal.Footer>
                  </Form>
                </>
              );
            }
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}

export default CreateEntityModal;
