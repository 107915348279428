import React, { useState, useEffect } from "react";
import {
  Form,
  DropdownButton,
  Dropdown,
  Collapse,
  Button,
} from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useFormikContext } from "formik";

const ReportsFilterForm = ({
  user,
  impersonationUser,
  tenantsList,
  managersList,
  customersList,
  setGroupByPeriod,
  setUsageType,
}) => {
  const {
    values,
    handleChange,
    handleBlur,
    touched,
    errors,
    initialValues,
    setValues,
    setFieldValue,
    handleSubmit,
  } = useFormikContext();
  const [nextCollapse, setNextCollapse] = useState(null);
  const [reportTypeCollapse, setReportTypeCollapse] = useState(null);
  const [prevReportType, setPrevReportType] = useState(null);

  useEffect(() => {
    // Close all collapses
    setNextCollapse(null);

    // Open the relevant collapse after a delay
    setTimeout(() => {
      setNextCollapse(values.simSearchCriteriaType);
    }, 300);
  }, [values.simSearchCriteriaType]);

  useEffect(() => {
    // Check if transitioning between "invoiceDetail" and "invoiceOverview"
    const isInvoiceTransition =
      (values.reportType === "invoiceDetail" &&
        prevReportType === "invoiceOverview") ||
      (values.reportType === "invoiceOverview" &&
        prevReportType === "invoiceDetail");

    // If transitioning between "invoiceDetail" and "invoiceOverview", do not reset collapse
    if (!isInvoiceTransition) {
      setReportTypeCollapse(null);
      setTimeout(() => {
        setReportTypeCollapse(values.reportType);
      }, 400);
    }

    // Update the previous reportType value
    setPrevReportType(values.reportType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.reportType]);

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        <Form.Label>Report Type</Form.Label>
        <Form.Select
          name="reportType"
          value={values.reportType}
          onChange={(e) => {
            setValues(initialValues);
            handleChange(e);
          }}
          onBlur={handleBlur}
          isInvalid={Boolean(touched.reportType && errors.reportType)}
        >
          <option key="usage" value="usage">
            Usage
          </option>
          <option key="ipAllocation" value="ipAllocation">
            IP Allocation
          </option>
          <option key="invoiceDetail" value="invoiceDetail">
            Invoice Detail
          </option>
          <option key="invoiceOverview" value="invoiceOverview">
            Invoice Overview
          </option>
          <option key="orders" value="orders">
            Orders
          </option>
          <option key="offersOnTestModeSims" value="offersOnTestModeSims">
            Offers On Test Mode SIMs
          </option>
          <option key="productOverview" value="productOverview">
            Product Overview
          </option>
        </Form.Select>
      </Form.Group>
      <Collapse in={reportTypeCollapse === "usage"} dimension="height">
        <Form.Group className="mb-3">
          <Form.Label>Usage Type</Form.Label>
          <Form.Select
            name="usageType"
            value={values.usageType}
            onChange={(e) => {
              setUsageType(e.target.value);
              handleChange(e);
            }}
            onBlur={handleBlur}
            isInvalid={Boolean(touched.usageType && errors.usageType)}
          >
            <option key="" value="">
              Select Usage Type
            </option>
            <option key="data" value="data">
              Data
            </option>
            <option key="sms" value="sms">
              SMS
            </option>
          </Form.Select>
        </Form.Group>
      </Collapse>
      <Collapse in={reportTypeCollapse === "usage"} dimension="height">
        <Form.Group className="mb-3">
          <Form.Label>
            Group by <small>field</small>
          </Form.Label>
          <Form.Select
            name="groupByField"
            value={values.groupByField}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={Boolean(touched.groupByField && errors.groupByField)}
          >
            <option key="all" value="all">
              All
            </option>
            <option key="imsi" value="imsi">
              IMSI
            </option>
            <option key="network" value="network">
              Network
            </option>
            <option key="service" value="service">
              Service
            </option>
          </Form.Select>
        </Form.Group>
      </Collapse>
      <Collapse in={reportTypeCollapse === "usage"} dimension="height">
        <Form.Group className="mb-3">
          <Form.Label>
            Group by <small>period</small>
          </Form.Label>
          <Form.Select
            name="groupByPeriod"
            value={values.groupByPeriod}
            onChange={(e) => {
              setGroupByPeriod(e.target.value);
              handleChange(e);
            }}
            onBlur={handleBlur}
            isInvalid={Boolean(touched.groupByPeriod && errors.groupByPeriod)}
          >
            <option key="day" value="day">
              Day
            </option>
            <option key="month" value="month">
              Month
            </option>
            <option key="year" value="year">
              Year
            </option>
          </Form.Select>
        </Form.Group>
      </Collapse>
      <Collapse in={reportTypeCollapse === "usage"} dimension="height">
        <Form.Group className="mb-3">
          <Form.Label>Period</Form.Label>
          <div className="d-flex">
            <ReactDatePicker
              selected={values.usageStartDate}
              onChange={(update) => {
                // Check if the update is an array
                if (Array.isArray(update)) {
                  // Update both startDate and endDate
                  setFieldValue("usageStartDate", update[0]);
                  setFieldValue("usageEndDate", update[1]);
                }
              }}
              startDate={values.usageStartDate}
              endDate={values.usageEndDate}
              selectsRange
              withPortal
              dateFormat="dd/MM/yyyy"
            />
            <DropdownButton
              title="Period"
              className="ms-4"
              style={{
                "--dynamic-bg-color": !impersonationUser.id
                  ? user.backgroundColor
                  : impersonationUser.backgroundColor,
                border: "none",
              }}
            >
              <Dropdown.Item
                eventKey="1"
                onClick={() => {
                  const today = new Date();
                  setFieldValue("usageStartDate", today);
                  setFieldValue("usageEndDate", today);
                }}
              >
                Today
              </Dropdown.Item>
              <Dropdown.Item
                eventKey="2"
                onClick={() => {
                  const today = new Date();
                  const startOfWeek = new Date(today);
                  startOfWeek.setDate(today.getDate() - today.getDay());
                  setFieldValue("usageStartDate", startOfWeek);
                  setFieldValue("usageEndDate", today);
                }}
              >
                This week
              </Dropdown.Item>
              <Dropdown.Item
                eventKey="3"
                onClick={() => {
                  const today = new Date();
                  const startOfMonth = new Date(
                    today.getFullYear(),
                    today.getMonth(),
                    1
                  );
                  setFieldValue("usageStartDate", startOfMonth);
                  setFieldValue("usageEndDate", today);
                }}
              >
                This month
              </Dropdown.Item>
              <Dropdown.Item
                eventKey="4"
                onClick={() => {
                  const today = new Date();
                  const startDate = new Date(today.getFullYear(), 1, 1);
                  setFieldValue("usageStartDate", startDate);
                  setFieldValue("usageEndDate", today);
                }}
              >
                This year
              </Dropdown.Item>
              <Dropdown.Item
                eventKey="5"
                onClick={() => {
                  const today = new Date();
                  const startDate = new Date(
                    today.getFullYear(),
                    today.getMonth() - 1,
                    1
                  );
                  const endDate = new Date(
                    today.getFullYear(),
                    today.getMonth(),
                    0
                  );
                  setFieldValue("usageStartDate", startDate);
                  setFieldValue("usageEndDate", endDate);
                }}
              >
                Last Month
              </Dropdown.Item>
              <Dropdown.Item
                eventKey="6"
                onClick={() => {
                  const today = new Date();
                  const startDate = new Date(today.getFullYear() - 1, 0, 1);
                  const endDate = new Date(today.getFullYear(), 11, 31);
                  setFieldValue("usageStartDate", startDate);
                  setFieldValue("usageEndDate", endDate);
                }}
              >
                Last Year
              </Dropdown.Item>
            </DropdownButton>
          </div>
        </Form.Group>
      </Collapse>
      <Collapse in={reportTypeCollapse === "usage"} dimension="height">
        <Form.Group className="mb-3">
          <Form.Label>Customer</Form.Label>
          <Form.Select
            name="customer"
            value={values.customer}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={Boolean(touched.customer && errors.customer)}
          >
            <option key="" value="">
              Select...
            </option>
            {!impersonationUser.id && (
              <option key={user.id} value={user.id}>
                {user.fullName} ({user.company})
              </option>
            )}
            {impersonationUser.id && (
              <option key={impersonationUser.id} value={impersonationUser.id}>
                {impersonationUser.fullName} ({impersonationUser.company})
              </option>
            )}
            {tenantsList?.map((tenant) => (
              <option key={tenant.id} value={tenant.id}>
                {tenant.fullName} ({tenant.company})
              </option>
            ))}
            {managersList?.map((manager) => (
              <option key={manager.id} value={manager.id}>
                {manager.fullName} ({manager.company})
              </option>
            ))}
            {customersList?.map((customer) => (
              <option key={customer.id} value={customer.id}>
                {customer.fullName} ({customer.company})
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      </Collapse>
      <Collapse in={reportTypeCollapse === "usage"} dimension="height">
        <Form.Group className="mb-3">
          <Form.Label>SIM Type</Form.Label>
          <Form.Select
            name="simType"
            value={values.simType}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={Boolean(touched.simType && errors.simType)}
          >
            <option key="1" value="all">
              All
            </option>
            <option key="2" value="NextM2M SIM T1">
              NextM2M SIM T1
            </option>
            <option key="3" value="NextM2M SIM T1 Embedded">
              NextM2M SIM T1 Embedded
            </option>
            <option key="4" value="NextM2M SIM T1 Micro">
              NextM2M SIM T1 Micro
            </option>
            <option key="5" value="NextM2M SIM T2">
              NextM2M SIM T2
            </option>
            <option key="6" value=" NextM2M SIM T3">
              NextM2M SIM T3
            </option>
            <option key="7" value="Jersey Embedded Sim">
              Jersey Embedded Sim
            </option>
          </Form.Select>
        </Form.Group>
      </Collapse>
      <Collapse in={reportTypeCollapse === "usage"} dimension="height">
        <Form.Group className="mb-3">
          <Form.Label>SIM Search Criteria</Form.Label>
          <Form.Select
            name="simSearchCriteriaType"
            value={values.simSearchCriteriaType}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={Boolean(
              touched.simSearchCriteriaType && errors.simSearchCriteriaType
            )}
          >
            <option key="1" value="all">
              All
            </option>
            <option key="2" value="imsi">
              By specific IMSI
            </option>
            <option key="3" value="imsiRange">
              By IMSI range
            </option>
            <option key="4" value="iccid">
              By ICCID
            </option>
          </Form.Select>
        </Form.Group>
      </Collapse>
      <Collapse
        in={nextCollapse === "imsi" && reportTypeCollapse === "usage"}
        dimension="height"
      >
        <Form.Group className="mb-3">
          <Form.Label>IMSI</Form.Label>
          <Form.Control
            type="text"
            name="imsi"
            value={values.imsi}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={Boolean(touched.imsi && errors.imsi)}
          />
        </Form.Group>
      </Collapse>
      <Collapse
        in={nextCollapse === "imsiRange" && reportTypeCollapse === "usage"}
        dimension="height"
      >
        <Form.Group className="mb-3">
          <Form.Label>Start IMSI</Form.Label>
          <Form.Control
            type="text"
            name="startImsi"
            value={values.startImsi}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={Boolean(touched.startImsi && errors.startImsi)}
          />
        </Form.Group>
      </Collapse>
      <Collapse
        in={nextCollapse === "imsiRange" && reportTypeCollapse === "usage"}
        dimension="height"
      >
        <Form.Group className="mb-3">
          <Form.Label>End IMSI</Form.Label>
          <Form.Control
            type="text"
            name="endImsi"
            value={values.endImsi}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={Boolean(touched.endImsi && errors.endImsi)}
          />
        </Form.Group>
      </Collapse>
      <Collapse
        in={nextCollapse === "iccid" && reportTypeCollapse === "usage"}
        dimension="height"
      >
        <Form.Group className="mb-3">
          <Form.Label>ICCID</Form.Label>
          <Form.Control
            type="text"
            name="iccid"
            value={values.iccid}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={Boolean(touched.iccid && errors.iccid)}
          />
        </Form.Group>
      </Collapse>
      <Collapse
        in={
          reportTypeCollapse === "invoiceDetail" ||
          reportTypeCollapse === "invoiceOverview"
        }
        dimension="height"
      >
        <Form.Group className="mb-3">
          <Form.Label>Invoice Number</Form.Label>
          <Form.Control
            type="text"
            name="invoiceNumber"
            value={values.invoiceNumber}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={Boolean(touched.invoiceNumber && errors.invoiceNumber)}
          />
        </Form.Group>
      </Collapse>
      <Collapse in={reportTypeCollapse === "orders"} dimension="height">
        <Form.Group className="mb-3">
          <Form.Label>Period</Form.Label>
          <div className="d-flex">
            <ReactDatePicker
              selected={values.orderStartDate}
              onChange={(update) => {
                // Check if the update is an array
                if (Array.isArray(update)) {
                  // Update both startDate and endDate
                  setFieldValue("orderStartDate", update[0]);
                  setFieldValue("orderEndDate", update[1]);
                }
              }}
              startDate={values.orderStartDate}
              endDate={values.orderEndDate}
              selectsRange
              withPortal
              dateFormat="dd/MM/yyyy"
            />
            <DropdownButton title="Period" className="ms-4">
              <Dropdown.Item
                eventKey="1"
                onClick={() => {
                  const today = new Date();
                  setFieldValue("orderStartDate", today);
                  setFieldValue("orderEndDate", today);
                }}
              >
                Today
              </Dropdown.Item>
              <Dropdown.Item
                eventKey="2"
                onClick={() => {
                  const today = new Date();
                  const startOfWeek = new Date(today);
                  startOfWeek.setDate(today.getDate() - today.getDay());
                  setFieldValue("orderStartDate", startOfWeek);
                  setFieldValue("orderEndDate", today);
                }}
              >
                This week
              </Dropdown.Item>
              <Dropdown.Item
                eventKey="3"
                onClick={() => {
                  const today = new Date();
                  const startOfMonth = new Date(
                    today.getFullYear(),
                    today.getMonth(),
                    1
                  );
                  setFieldValue("orderStartDate", startOfMonth);
                  setFieldValue("orderEndDate", today);
                }}
              >
                This month
              </Dropdown.Item>
              <Dropdown.Item
                eventKey="4"
                onClick={() => {
                  const today = new Date();
                  const startDate = new Date(today.getFullYear(), 1, 1);
                  setFieldValue("orderStartDate", startDate);
                  setFieldValue("orderEndDate", today);
                }}
              >
                This year
              </Dropdown.Item>
              <Dropdown.Item
                eventKey="5"
                onClick={() => {
                  const today = new Date();
                  const startDate = new Date(
                    today.getFullYear(),
                    today.getMonth() - 1,
                    1
                  );
                  const endDate = new Date(
                    today.getFullYear(),
                    today.getMonth(),
                    0
                  );
                  setFieldValue("orderStartDate", startDate);
                  setFieldValue("orderEndDate", endDate);
                }}
              >
                Last Month
              </Dropdown.Item>
              <Dropdown.Item
                eventKey="6"
                onClick={() => {
                  const today = new Date();
                  const startDate = new Date(today.getFullYear() - 1, 0, 1);
                  const endDate = new Date(today.getFullYear(), 11, 31);
                  setFieldValue("orderStartDate", startDate);
                  setFieldValue("orderEndDate", endDate);
                }}
              >
                Last Year
              </Dropdown.Item>
            </DropdownButton>
          </div>
        </Form.Group>
      </Collapse>
      <Button
        type="submit"
        style={{
          "--dynamic-bg-color": !impersonationUser.id
            ? user.backgroundColor
            : impersonationUser.backgroundColor,
          border: "none",
        }}
      >
        View
      </Button>
    </Form>
  );
};

export default ReportsFilterForm;
