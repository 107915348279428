const simWatchdogColumns = [
  {
    Header: "Watchdog ID",
    accessor: "watchdogId",
  },
  {
    Header: "Type",
    accessor: "actionType",
  },
  {
    Header: "Amount",
    accessor: "amount",
    Cell: ({ row: { original } }) => (
      <>
        {original.amount}/{original.service}
      </>
    ),
  },
];

export { simWatchdogColumns };
