const clientData = [
  {
    id: 1,
    name: "Ioannis Livakas",
    position: "Lampraki 46",
    postcode: "56728",
    city: "Thessaloniki",
    phone: "2315538057",
    email: "livakas_giannis@yahoo.com",
    vat: "EL028838285",
  },
  {
    id: 2,
    name: "Theodoros Mposkos",
    position: "11o km Thessalonikis-Katerinis",
    postcode: "57400",
    city: "Thessaloniki",
    phone: "2310796800",
    email: "boskostheodoros@hotmail.com",
    vat: "EL800614835",
  },
  {
    id: 3,
    name: "Ioannis Mpekas",
    position: "Sindos",
    postcode: "",
    city: "Sindos",
    phone: "",
    email: "",
    vat: "",
  },
];

const clientColumns = [
  {
    Header: "",
    accessor: "image",
    Cell: ({ value }) => (
      <img
        className="avatar rounded-circle"
        src={`data:image/png;base64,${value}`}
        alt=""
      />
    ),
    sortable: false,
    Filter: () => null,
    canRenderFilter: false,
  },
  {
    Header: "Full Name",
    accessor: "fullName",
    sortable: true,
    canRenderFilter: true,
  },
  {
    Header: "Email",
    accessor: "email",
    sortable: true,
    canRenderFilter: true,
  },
];

export { clientData, clientColumns };
