import React from "react";
import { Card, Form, Row } from "react-bootstrap";
import FilterGroup from "./FilterGroup";

const FiltersCard = ({ headerGroups }) => {
  return (
    <Card>
      <Card.Header>
        <Card.Title>Filters</Card.Title>
      </Card.Header>
      <Card.Body>
        <Form>
          <Row>
            {headerGroups.map((headerGroup, idx) =>
              headerGroup.headers.map((column, index) => (
                <FilterGroup key={index} column={column} />
              ))
            )}
          </Row>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default FiltersCard;
