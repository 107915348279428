import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getInvoices } from "../../redux/slices/invoices";
import Loader from "../../components/spinners/Loader";
import { Helmet } from "react-helmet-async";
import { Container, Row, Col, Table } from "react-bootstrap";
import InvoicesList from "./InvoicesList";
import BalanceCard from "./BalanceCard";

const Invoices = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { invoices } = useSelector((state) => state.invoices);
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchInvoices = async (id) => {
      if (!id) {
        return;
      }
      setIsLoading(true);
      await dispatch(getInvoices(id));
      setIsLoading(false);
    };

    if (!impersonationUser.id) {
      fetchInvoices(user.id);
    } else {
      fetchInvoices(impersonationUser.id);
    }

    fetchInvoices();
  }, [dispatch, user, impersonationUser]);

  console.log(user);

  if (isLoading) {
    return <Loader />;
  } else {
    if (invoices.length > 0) {
      return (
        <React.Fragment>
          <Helmet title="Invoices">
            <link
              rel="icon"
              href={`data:image/x-icon;base64,${user.favicon}`}
            />
          </Helmet>
          <Container fluid className="p-0">
            <h1 className="h3 mb-3">Billing</h1>
            <Row>
              <Col
                md={
                  impersonationUser.id
                    ? impersonationUser.privilege === "TENANT" ||
                      impersonationUser.privilege === "MANAGER" ||
                      impersonationUser.privilege === "CUSTOMER"
                      ? 8
                      : 12
                    : user.privilege === "TENANT" ||
                      user.privilege === "MANAGER" ||
                      user.privilege === "CUSTOMER"
                    ? 8
                    : 12
                }
              >
                <InvoicesList data={invoices} />
              </Col>
              {(impersonationUser.id
                ? impersonationUser.privilege === "TENANT" ||
                  impersonationUser.privilege === "MANAGER" ||
                  impersonationUser.privilege === "CUSTOMER"
                : user.privilege === "TENANT" ||
                  user.privilege === "MANAGER" ||
                  user.privilege === "CUSTOMER") && (
                <Col md={4}>
                  <BalanceCard />
                </Col>
              )}
            </Row>
          </Container>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <Helmet title="Invoices">
            <link
              rel="icon"
              href={`data:image/x-icon;base64,${user.favicon}`}
            />
          </Helmet>
          <Container fluid className="p-0">
            <h1 className="h3 mb-3">Invoices</h1>
            <Row>
              <Col md="12">
                <Table>
                  <thead>
                    <tr>
                      <th>{t("No data found")}</th>
                    </tr>
                  </thead>
                </Table>
              </Col>
            </Row>
          </Container>
        </React.Fragment>
      );
    }
  }
};

export default Invoices;
