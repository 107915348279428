import React, { useState } from "react";
import { Card, Table, Form, Row, Col, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { FaPlus } from "react-icons/fa";
import DistributionModal from "./DistributionModal";
import { useEffect } from "react";
import { getDistributionEmails } from "../../../redux/slices/distribution";
import { Trash } from "react-feather";
import RemoveDistributionModal from "./RemoveDistributionModal";

const DistributionInfo = () => {
  const { distribution } = useSelector((state) => state.distribution);
  const [distributionList, setDistributionList] = useState([]);
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const dispatch = useDispatch();
  const [selectedCategory, setSelectedCategory] = useState("");
  const [showDistributionModal, setShowDistributionModal] = useState(false);
  const [showRemoveDistributionModal, setShowRemoveDistributionModal] =
    useState(false);
  const [emailToRemove, setEmailToRemove] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    setDistributionList(distribution);
  }, [distribution]);

  useEffect(() => {
    const fetchDistribution = async (id) => {
      if (!id) return;
      await dispatch(getDistributionEmails(id));
    };
    if (!impersonationUser.id) {
      fetchDistribution(user.id);
    } else {
      fetchDistribution(impersonationUser.id);
    }
  }, [user.id, impersonationUser.id, dispatch]);

  const handleSelectChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const filteredList = selectedCategory
    ? distributionList.filter((item) => item.category === selectedCategory)
    : distributionList;

  function toSentenceCase(str) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  if (distribution.length > 0) {
    return (
      <Card>
        <Card.Header>
          <Card.Title className="mb-0">Distribution List</Card.Title>
        </Card.Header>
        <Card.Body>
          <Row className="mb-5">
            <Col md={8}>
              <Form.Select
                aria-label="Select Category"
                onChange={handleSelectChange}
              >
                <option value="">All</option>
                {Array.from(
                  new Set(distributionList.map((item) => item.category))
                ).map((category, index) => (
                  <option key={index} value={category}>
                    {toSentenceCase(category)}
                  </option>
                ))}
              </Form.Select>
            </Col>
            <Col md={4}>
              <div className="float-end">
                <Button
                  style={{
                    "--dynamic-bg-color": !impersonationUser.id
                      ? user.backgroundColor
                      : impersonationUser.backgroundColor,
                    border: "none",
                  }}
                  onClick={() => setShowDistributionModal(true)}
                >
                  <FaPlus /> New Email
                </Button>
              </div>
              <DistributionModal
                showDistributionModal={showDistributionModal}
                setShowDistributionModal={setShowDistributionModal}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Table responsive hover>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Address</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredList.map((item, index) => (
                    <tr key={index}>
                      <td>{item.name}</td>
                      <td>{item.address}</td>
                      <td>
                        <Trash
                          className="align-middle"
                          size={18}
                          onClick={() => {
                            setEmailToRemove(item);
                            setShowRemoveDistributionModal(true);
                          }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
                <RemoveDistributionModal
                  showRemoveDistributionModal={showRemoveDistributionModal}
                  setShowRemoveDistributionModal={
                    setShowRemoveDistributionModal
                  }
                  emailToRemove={emailToRemove}
                />
              </Table>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  } else {
    return (
      <Card>
        <Card.Header>
          <Card.Title className="mb-0">Distribution List</Card.Title>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col>
              <div className="d-flex mt-2 mb-2 float-end">
                <Button
                  style={{
                    "--dynamic-bg-color": !impersonationUser.id
                      ? user.backgroundColor
                      : impersonationUser.backgroundColor,
                    border: "none",
                  }}
                  onClick={() => setShowDistributionModal(true)}
                >
                  <FaPlus /> New Email
                </Button>
                <DistributionModal
                  showDistributionModal={showDistributionModal}
                  setShowDistributionModal={setShowDistributionModal}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <div className="text-center">{t("No data found")}</div>
          </Row>
        </Card.Body>
      </Card>
    );
  }
};

export default DistributionInfo;
