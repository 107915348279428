import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  Button,
  Modal,
  Table,
  Pagination,
  Row,
  Col,
  Form,
} from "react-bootstrap";
import { getSimDataSessions } from "../../../../redux/slices/dataSessions";
import NotyfContext from "../../../../contexts/NotyfContext";

const SimDataSessionsModal = ({
  showSessionModal,
  setShowSessionModal,
  imsi,
}) => {
  const { t } = useTranslation();
  const notyf = useContext(NotyfContext);
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const [sessions, setSessions] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [numberOfPages, setNumberOfPages] = useState(0);

  useEffect(() => {
    const fetchSimSessions = async (id) => {
      try {
        const response = await getSimDataSessions(id, imsi, page, pageSize);
        console.log(response.dataSessions);
        setSessions(response.dataSessions);
        setNumberOfPages(response.metaData.totalPages);
      } catch (error) {
        notyf.open({
          type: "danger",
          message: "Error retrieving Data Sessions",
          duration: 5000,
          ripple: true,
          dismissible: false,
          position: {
            x: "center",
            y: "top",
          },
        });
      }
    };

    if (!impersonationUser.id) {
      if (user && user.id && imsi) {
        fetchSimSessions(user.id);
      }
    } else {
      fetchSimSessions(impersonationUser.id);
    }
  }, [imsi, user.id, impersonationUser.id, notyf, user, page, pageSize]);

  if (sessions.length > 0) {
    return (
      <Modal
        size="xl"
        centered
        scrollable
        backdrop="static"
        show={showSessionModal}
        onHide={() => setShowSessionModal(false)}
      >
        <Modal.Header>Live Data Sessions</Modal.Header>
        <Modal.Body>
          <>
            <Table responsive hover>
              <thead>
                <tr>
                  <th>{t("ID")}</th>
                  <th>{t("Open Date")}</th>
                  <th>{t("Close Date")}</th>
                  <th>{t("Consumption")}</th>
                  <th>{t("Operator")}</th>
                  <th>{t("Country")}</th>
                  <th>{t("Cell")}</th>
                  <th>{t("IMEI")}</th>
                </tr>
              </thead>
              <tbody>
                {sessions.map((session) => (
                  <tr key={session.id}>
                    <td>{session.id}</td>
                    <td>{session.openDate}</td>
                    <td>{session.closeDate}</td>
                    <td>{session.totalBytes}</td>
                    <td>{session.network}</td>
                    <td>{session.country}</td>
                    <td>{session.cellId}</td>
                    <td>{session.imei}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Row>
              <Col md="8">
                <span className="mx-2">
                  {t("Page")}{" "}
                  <strong>
                    {page + 1} {t("of")} {numberOfPages}
                  </strong>
                </span>
                <span className="ms-3 me-2">{t("Show")}:</span>
                <Form.Select
                  className="d-inline-block w-auto"
                  value={pageSize}
                  onChange={(e) => {
                    setPage(0);
                    setPageSize(Number(e.target.value));
                  }}
                >
                  {[5, 10, 20, 30, 40, 50].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      {pageSize}
                    </option>
                  ))}
                </Form.Select>

                <span className="ms-3 me-2">{t("Go to page")}:</span>
                <Form.Control
                  className="d-inline-block"
                  type="number"
                  defaultValue={page + 1}
                  onChange={(e) => {
                    const page = e.target.value
                      ? Number(e.target.value) - 1
                      : 0;
                    setPage(page);
                  }}
                  style={{ width: "75px" }}
                />
              </Col>
              <Col md="4">
                <Pagination className="float-end">
                  <Pagination.First
                    onClick={() => {
                      setPage(0);
                    }}
                    disabled={page === 0}
                  />
                  <Pagination.Prev
                    onClick={() => {
                      setPage(page - 1);
                    }}
                    disabled={page <= 0}
                  />
                  <Pagination.Next
                    onClick={() => {
                      setPage(page + 1);
                    }}
                    disabled={page + 1 >= numberOfPages}
                  />
                  <Pagination.Last
                    onClick={() => {
                      setPage(numberOfPages - 1);
                    }}
                    disabled={page + 1 === numberOfPages}
                  />
                </Pagination>
              </Col>
            </Row>
          </>
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{
              "--dynamic-bg-color": !impersonationUser.id
                ? user.backgroundColor
                : impersonationUser.backgroundColor,
              border: "none",
            }}
            onClick={() => setShowSessionModal(false)}
          >
            {t("Close")}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  } else {
    return (
      <Modal
        size="lg"
        centered
        scrollable
        backdrop="static"
        show={showSessionModal}
        onHide={() => setShowSessionModal(false)}
      >
        <Modal.Header>Live Data Sessions</Modal.Header>
        <Modal.Body>No data</Modal.Body>
        <Modal.Footer>
          <Button
            style={{ backgroundColor: "#31d5ca", border: "none" }}
            onClick={() => setShowSessionModal(false)}
          >
            {t("Close")}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
};

export default SimDataSessionsModal;
