import React from "react";
import {
  Card,
  Table,
  Pagination,
  Row,
  Col,
  Form,
  Container,
} from "react-bootstrap";

import { usePagination, useTable } from "react-table";

import { simWatchdogColumns } from "../../../../inputData/simWatchdogData";
import { useState } from "react";
import { useEffect } from "react";
import { getSimWatchdogs } from "../../../../redux/slices/watchdogs";
import { useDispatch, useSelector } from "react-redux";
import { Edit, Trash } from "react-feather";
import RemoveWatchdogModal from "../modals/RemoveWatchdogModal";
import ModifyWatchdogModal from "../modals/ModifyWatchdogModal";

const SimWatchdogTable = ({
  columns,
  data,
  imsi,
  showRemoveWatchdog,
  setShowRemoveWatchdog,
  showModifyWatchdog,
  setShowModifyWatchdog,
}) => {
  const [selectedRow, setSelectedRow] = useState(null);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    usePagination,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        ...columns,
        {
          id: "",
          sortable: false,
          Header: "Action",
          Cell: ({ row }) => (
            <div>
              <Edit
                className="align-middle me-2"
                size={18}
                onClick={() => {
                  setSelectedRow(row.original);
                  setShowModifyWatchdog(true);
                }}
              />
              <Trash
                className="align-middle"
                size={18}
                onClick={() => {
                  setSelectedRow(row.original);
                  setShowRemoveWatchdog(true);
                }}
              />
            </div>
          ),
        },
      ]);
    }
  );

  return (
    <Card>
      <Card.Header>
        <Card.Title>Watchdog</Card.Title>
      </Card.Header>
      <Card.Body>
        <Table hover responsive {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
          <RemoveWatchdogModal
            watchdog={selectedRow}
            imsi={imsi}
            showRemoveWatchdogModal={showRemoveWatchdog}
            setShowRemoveWatchdogModal={setShowRemoveWatchdog}
          />
          <ModifyWatchdogModal
            watchdog={selectedRow}
            imsi={imsi}
            showModifyWatchdogModal={showModifyWatchdog}
            setShowModifyWatchdogModal={setShowModifyWatchdog}
          />
        </Table>

        <Row>
          <Col md="6">
            <span className="mx-2">
              Page{" "}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>
            </span>
            <span className="ms-3 me-2">Show:</span>
            <Form.Select
              className="d-inline-block w-auto"
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </Form.Select>

            <span className="ms-3 me-2">Go to page:</span>
            <Form.Control
              className="d-inline-block"
              type="number"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
              style={{ width: "75px" }}
            />
          </Col>
          <Col md="6">
            <Pagination className="float-end">
              <Pagination.First
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              />
              <Pagination.Prev
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              />
              <Pagination.Next
                onClick={() => nextPage()}
                disabled={!canNextPage}
              />
              <Pagination.Last
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              />
            </Pagination>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

const SimWatchdogs = ({ sim }) => {
  const [showModifyWatchdog, setShowModifyWatchdog] = useState(false);
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const simWatchdogs = useSelector((state) => state.watchdogs.simWatchdogs);
  const [showRemoveWatchdog, setShowRemoveWatchdog] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchSimWatchdogs = async (id) => {
      if (!id) {
        return;
      }
      await dispatch(getSimWatchdogs(id, sim.imsi));
    };
    if (!impersonationUser.id) {
      if (user && user.id && sim.imsi) {
        fetchSimWatchdogs(user.id);
      }
    } else {
      fetchSimWatchdogs(impersonationUser.id);
    }
  }, [sim.imsi, user, impersonationUser.id, dispatch]);

  return (
    <Container fluid className="p-0">
      <SimWatchdogTable
        columns={simWatchdogColumns}
        data={simWatchdogs}
        imsi={sim.imsi}
        user={user}
        dispatch={dispatch}
        showRemoveWatchdog={showRemoveWatchdog}
        setShowRemoveWatchdog={setShowRemoveWatchdog}
        showModifyWatchdog={showModifyWatchdog}
        setShowModifyWatchdog={setShowModifyWatchdog}
      />
    </Container>
  );
};

export default SimWatchdogs;
