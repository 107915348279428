import axios from "../../utils/axios";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  alerts: [],
};

const alertSlice = createSlice({
  name: "alertSlice",
  initialState,
  reducers: {
    setAlerts(state, action) {
      state.alerts = action.payload;
    },
  },
});

export function getAlerts(id) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    await axios
      .get(`https://dev.pos2cloud.com:5000/api/alerts/${id}/getAlerts`, {
        headers: {
          accessToken: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        dispatch(alertSlice.actions.setAlerts(response.data));
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          dispatch(alertSlice.actions.setAlerts([]));
          throw new Error("Unauthorized");
        } else if (error.response && error.response.status === 500) {
          dispatch(alertSlice.actions.setAlerts([]));
          throw new Error("Internal Server Error");
        }
      });
  };
}

export async function getAlertComparison(id, period) {
  try {
    const accessToken = window.localStorage.getItem("accessToken");
    const response = await axios.get(
      `https://dev.pos2cloud.com:5000/api/alerts/${id}/count?period=${period}`,
      {
        headers: {
          accessToken: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.response);
  }
}

export async function getAlertCountForSim(id, period, imsi) {
  try {
    const accessToken = window.localStorage.getItem("accessToken");
    const response = await axios.get(
      `https://dev.pos2cloud.com:5000/api/alerts/${id}/countForSim?period=${period}&imsi=${imsi}`,
      {
        headers: {
          accessToken: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.response);
  }
}

export const { setAlerts } = alertSlice.actions;

export default alertSlice.reducer;
