import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Container } from "react-bootstrap";

import Clients from "./Clients";

import { useDispatch, useSelector } from "react-redux";
import { getViewers } from "../../redux/slices/viewers";
import Loader from "../../components/spinners/Loader";

const Viewers = () => {
  const dispatch = useDispatch();
  const { viewers } = useSelector((state) => state.viewers);
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchViewers = async () => {
      if (!impersonationUser.id) {
        if (!user.id) return;
        await dispatch(getViewers(user.id));
      } else {
        await dispatch(getViewers(impersonationUser.id));
      }

      setIsLoading(false);
    };
    fetchViewers();
  }, [dispatch, user.id, impersonationUser.id]);

  if (isLoading) {
    return <Loader />;
  } else {
    return (
      <React.Fragment>
        <Helmet title="Viewers" />
        <Container fluid className="p-0">
          <Clients title="Viewers" clientList={viewers} />
        </Container>
      </React.Fragment>
    );
  }
};

export default Viewers;
