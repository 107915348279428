import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Container } from "react-bootstrap";

import Clients from "./Clients";

import { useDispatch, useSelector } from "react-redux";
import { getCustomers } from "../../redux/slices/customers";
import Loader from "../../components/spinners/Loader";

const Customers = () => {
  const dispatch = useDispatch();
  const { customers } = useSelector((state) => state.customers);
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchCustomers = async () => {
      if (!impersonationUser.id) {
        if (!user.id) return;
        await dispatch(getCustomers(user.id));
      } else {
        await dispatch(getCustomers(impersonationUser.id));
      }

      setIsLoading(false);
    };

    fetchCustomers();
  }, [dispatch, user.id, impersonationUser.id]);

  if (isLoading) {
    return <Loader />;
  } else {
    return (
      <React.Fragment>
        <Helmet title="Customers" />
        <Container fluid className="p-0">
          <Clients title="Customers" clientList={customers} />
        </Container>
      </React.Fragment>
    );
  }
};

export default Customers;
