import React from "react";
import { Pie } from "react-chartjs-2";

import { Card, Col, Form, Row, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import countryList from "react-select-country-list";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getNetworkDistribution } from "../../redux/slices/dataSessions";
import chroma from "chroma-js";
import SimsByNetworkModal from "../../pages/pages/dashboard/modals/SimsByNetworkModal";

const NetworksDistributionPieChart = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [networkDistribution, setNetworkDistribution] = useState([]);
  const [country, setCountry] = useState("Greece");
  const [isLoading, setIsLoading] = useState(true);
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const countries = countryList().getData();
  const userBgColor =
    user && user.backgroundColor ? user.backgroundColor : "white"; // assuming white as default
  const impersonationUserBgColor =
    impersonationUser &&
    impersonationUser.id &&
    impersonationUser.backgroundColor
      ? impersonationUser.backgroundColor
      : "white";
  const scale = chroma
    .scale([
      chroma(impersonationUser.id ? impersonationUserBgColor : userBgColor).set(
        "hsl.h",
        "-30"
      ),
      impersonationUser.id ? impersonationUserBgColor : userBgColor,
      chroma(impersonationUser.id ? impersonationUserBgColor : userBgColor).set(
        "hsl.h",
        "+30"
      ),
    ])
    .mode("rgb");
  const [colors, setColors] = useState([]);
  const [showSimsByNetworkModal, setShowSimsByNetworkModal] = useState(false);
  const [network, setNetwork] = useState("");

  useEffect(() => {
    const fetchNetworkDistribution = async (id) => {
      if (!id) {
        return;
      }
      const response = await getNetworkDistribution(id, country);
      setNetworkDistribution(response);
    };
    setIsLoading(true);
    if (!impersonationUser.id) {
      fetchNetworkDistribution(user.id);
    } else {
      fetchNetworkDistribution(impersonationUser.id);
    }

    setIsLoading(false);
  }, [user, impersonationUser.id, country, dispatch]);

  useEffect(() => {
    setColors(
      Array.from({ length: networkDistribution.length }, (_, i) =>
        scale(i / networkDistribution.length).hex()
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [networkDistribution, user.id, impersonationUser.id]);

  const data = {
    labels: networkDistribution.map((item) => item.network),
    datasets: [
      {
        data: networkDistribution.map((item) => item.count),
        backgroundColor: colors,
        borderColor: "transparent",
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    legend: {
      display: true,
      position: "right",
    },
    onClick: (e, element) => {
      if (element.length > 0) {
        const newNetwork = element[0]._model.label;
        setNetwork(newNetwork);
        setShowSimsByNetworkModal(true);
      }
    },
  };

  if (isLoading) {
    return (
      <Card className="w-100">
        <Card.Header>
          <Card.Title>{t("Networks Distribution")}</Card.Title>
        </Card.Header>
        <Card.Body className="d-flex align-items-center justify-content-center">
          <Form.Select
            id="countrySelect"
            name="country"
            className="mb-1"
            onChange={(e) => setCountry(e.target.value)}
            value={country}
          >
            {countries.map((country, index) => (
              <option key={index} value={country.label}>
                {country.label}
              </option>
            ))}
          </Form.Select>
          <div className="text-center">
            <Spinner animation="border" variant="primary" className="me-2" />
          </div>
        </Card.Body>
      </Card>
    );
  } else if (networkDistribution.length === 0 && !isLoading) {
    return (
      <Card className="w-100">
        <Card.Header>
          <Card.Title>{t("Networks Distribution")}</Card.Title>
        </Card.Header>
        <Card.Body className="align-items-center justify-content-center">
          <Row>
            <Form.Group as={Col}>
              <Form.Label>Select Country</Form.Label>
              <Form.Select
                id="countrySelect"
                name="country"
                className="mb-5"
                onChange={(e) => setCountry(e.target.value)}
                value={country}
              >
                {countries.map((country, index) => (
                  <option key={index} value={country.label}>
                    {country.label}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Row>
          <Row>
            <Col>
              <div className="text-center">{t("No data found")}</div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  } else {
    return (
      <Card id="pieChart">
        <Card.Header>
          <Card.Title>{t("Networks Distribution")}</Card.Title>
        </Card.Header>
        <Card.Body>
          <Row>
            <Form.Group as={Col}>
              <Form.Label>Select Country</Form.Label>
              <Form.Select
                id="countrySelect"
                name="country"
                className="mb-5"
                onChange={(e) => setCountry(e.target.value)}
                value={country}
              >
                {countries.map((country, index) => (
                  <option key={index} value={country.label}>
                    {country.label}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Row>
          <Row>
            <div className="chart chart-sm">
              <Pie data={data} options={options} />
            </div>
            <SimsByNetworkModal
              showSimsByNetworkModal={showSimsByNetworkModal}
              setShowSimsByNetworkModal={setShowSimsByNetworkModal}
              network={network}
            />
          </Row>
        </Card.Body>
      </Card>
    );
  }
};

export default NetworksDistributionPieChart;
