import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button, Form, Spinner, Table } from "react-bootstrap";
import { getAvailableOffers } from "../../../../redux/slices/offers";
import { activateSims, getSims } from "../../../../redux/slices/sims";
import { FaCheckCircle, FaExclamationCircle } from "react-icons/fa";

const ActivateSimModal = (props) => {
  const handleClose = () => props.setOpenActivateModal(false);
  const dispatch = useDispatch();
  const [selectedOffer, setSelectedOffer] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [activeTab, setActiveTab] = useState("Offer");
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const availableOffers = useSelector((state) => state.offers.availableOffers);
  const [now, setNow] = useState(30);
  const [selectedSims, setSelectedSims] = useState([]);
  const [status, setStatus] = useState("");

  const validateOption = () => {
    if (selectedOffer === null) {
      setHasError(true);
      return false;
    }
    setHasError(false);
    return true;
  };

  const handleInputChange = (event) => {
    const selectedOption = availableOffers.find(
      (option) => option === event.target.value
    );
    setSelectedOffer(selectedOption ? selectedOption : null);
  };

  useEffect(() => {
    const fetchAvailableOffers = async (id) => {
      setIsLoading(true);
      try {
        await dispatch(getAvailableOffers(id));
      } catch (error) {
        console.error(error);
      }
      setIsLoading(false);
    };

    if (!impersonationUser.id) {
      fetchAvailableOffers(user.id);
    } else {
      fetchAvailableOffers(impersonationUser.id);
    }
  }, [user, impersonationUser.id, dispatch]);

  useEffect(() => {
    const formattedSelectedSims = props.selectedSims.map((sim) => ({
      imsi: sim.imsi,
      iccid: sim.iccid,
    }));
    setSelectedSims(formattedSelectedSims);
  }, [props.selectedSims]);

  useEffect(() => {
    if (!props.openActivateModal) {
      // If the modal is not open
      setSelectedOffer(null);
      setIsLoading(false);
      setHasError(false);
      setActiveTab("Offer");
      setNow(30);
    }
  }, [props.openActivateModal]);

  if (selectedSims.length > 0) {
    return (
      <Modal
        show={props.openActivateModal}
        onHide={handleClose}
        backdrop="static"
      >
        <Modal.Header>Activate SIM</Modal.Header>
        <Modal.Body>
          {!isLoading && now === 30 && (
            <Form.Group className="mb-3">
              <Form.Label>Offer:</Form.Label>
              <Form.Select
                id="offerSelect"
                name="offerSelect"
                onChange={handleInputChange}
              >
                <option value="">Select Offer...</option>
                {availableOffers.map((option, i) => (
                  <option key={i} value={option}>
                    {option}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          )}
          {isLoading && (
            <div className="text-center">
              <Spinner animation="border" variant="primary" className="me-2" />
            </div>
          )}
          {hasError && (
            <div className="text-danger">Please select a valid option.</div>
          )}

          {now === 60 && (
            <Table>
              <thead>
                <tr>
                  <th>IMSI</th>
                  <th>ICCID</th>
                  <th>Offer</th>
                </tr>
              </thead>
              <tbody>
                {selectedSims.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item.imsi}</td>
                      <td>{item.iccid}</td>
                      <td>{selectedOffer}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          )}
          {now === 90 && (
            <div className="text-center">
              <Spinner
                animation="border"
                variant="primary"
                className="mt-5 mb-3"
                style={{ width: "5rem", height: "5rem" }}
              />
              <h3>Waiting for completion</h3>
            </div>
          )}
          {now === 100 && status === "Success" && (
            <div className="text-center">
              <FaCheckCircle size={120} className="mt-3" color="#4BBF73" />
              <h3 className="mt-3">Order Complete</h3>
            </div>
          )}
          {now === 100 && status === "Error" && (
            <div className="text-center">
              <FaExclamationCircle
                size={120}
                className="mt-3"
                color="#FF9494"
              />
              <h3 className="mt-3">Order Error</h3>
              <p>Please contact the administrator.</p>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          {now === 30 && (
            <>
              <Button
                variant="secondary"
                disabled={isLoading ? true : false}
                onClick={() => {
                  props.setOpenActivateModal(false);
                }}
                style={{
                  "--dynamic-bg-color": !impersonationUser.id
                    ? user.backgroundColor
                    : impersonationUser.backgroundColor,
                  "--dynamic-font-color": !impersonationUser.id
                    ? user.backgroundColor
                    : impersonationUser.backgroundColor,
                  border: "none",
                }}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                disabled={isLoading ? true : false}
                onClick={() => {
                  if (validateOption()) {
                    setNow(60);
                  }
                }}
                style={{
                  "--dynamic-bg-color": !impersonationUser.id
                    ? user.backgroundColor
                    : impersonationUser.backgroundColor,
                  "--dynamic-font-color": !impersonationUser.id
                    ? user.backgroundColor
                    : impersonationUser.backgroundColor,
                  border: "none",
                }}
              >
                Next
              </Button>
            </>
          )}
          {now === 60 && (
            <>
              <Button
                variant="secondary"
                disabled={isLoading ? true : false}
                onClick={() => props.setOpenActivateModal(false)}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                disabled={isLoading ? true : false}
                onClick={() => {
                  setNow(30);
                  setSelectedOffer(null);
                }}
                style={{
                  "--dynamic-bg-color": !impersonationUser.id
                    ? user.backgroundColor
                    : impersonationUser.backgroundColor,
                  "--dynamic-font-color": !impersonationUser.id
                    ? user.backgroundColor
                    : impersonationUser.backgroundColor,
                  border: "none",
                }}
              >
                Previous
              </Button>
              <Button
                variant="success"
                disabled={isLoading ? true : false}
                onClick={async () => {
                  setNow(90);
                  if (!impersonationUser.id) {
                    await activateSims(
                      selectedSims.map((sim) => sim.imsi),
                      user.id,
                      selectedOffer
                    )
                      .then(async (response) => {
                        setStatus("Success");
                      })
                      .catch((error) => setStatus("Error"));
                  } else {
                    await activateSims(
                      selectedSims.map((sim) => sim.imsi),
                      impersonationUser.id,
                      selectedOffer
                    )
                      .then(async (response) => {
                        setStatus("Success");
                      })
                      .catch((error) => setStatus("Error"));
                  }

                  setNow(100);
                  //props.setOpenActivateModal(false);
                }}
                style={{
                  "--dynamic-bg-color": !impersonationUser.id
                    ? user.backgroundColor
                    : impersonationUser.backgroundColor,
                  "--dynamic-font-color": !impersonationUser.id
                    ? user.backgroundColor
                    : impersonationUser.backgroundColor,
                  border: "none",
                }}
              >
                Activate
              </Button>
            </>
          )}
          {now === 100 && (
            <Button
              variant="primary"
              onClick={async () => {
                props.setOpenActivateModal(false);
                if (!impersonationUser.id) {
                  await dispatch(
                    getSims(
                      user.id,
                      props.page + 1,
                      props.pageSize,
                      props.filters
                    )
                  );
                } else {
                  await dispatch(
                    getSims(
                      impersonationUser.id,
                      props.page + 1,
                      props.pageSize,
                      props.filters
                    )
                  );
                }
              }}
              style={{
                "--dynamic-bg-color": !impersonationUser.id
                  ? user.backgroundColor
                  : impersonationUser.backgroundColor,
                "--dynamic-font-color": !impersonationUser.id
                  ? user.backgroundColor
                  : impersonationUser.backgroundColor,
                border: "none",
              }}
            >
              Close
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    );
  } else {
    return (
      <Modal show={props.openActivateModal}>
        <Modal.Header>Activate SIMs</Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <Spinner animation="border" variant="primary" className="me-2" />
          </div>
        </Modal.Body>
      </Modal>
    );
  }
};

export default ActivateSimModal;
