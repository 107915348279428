import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Card } from "react-bootstrap";

import SignUp from "../../components/auth/SignUp";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { fetchWhitelabel } from "../../redux/slices/whitelabel";

const SignUpPage = () => {
  const { whitelabel } = useSelector((state) => state.whitelabel);
  const currentUrl = window.location.hostname;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchWhitelabel(currentUrl));
  }, []);
  return (
    <React.Fragment>
      <Helmet title="Sign Up">
        <link
          rel="icon"
          href={`data:${whitelabel.faviconMimeType};base64,${whitelabel.favicon}`}
        />
      </Helmet>

      <Card>
        <Card.Body>
          <div className="m-sm-4" id="SignUpCard">
            <SignUp />
          </div>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

export default SignUpPage;
