import SelectColumnFilter from "../utils/SelectColumnFilter";

const alertColumns = [
  {
    Header: "ID",
    accessor: (row, i) => i + 1,
    sortable: true,
    Filter: () => null,
    canRenderFilter: false,
  },
  {
    Header: "Alert",
    accessor: (row) => `${row.context} ${row.action}`,
    sortable: true,
    Filter: SelectColumnFilter,
    filter: "includes",
    canRenderFilter: true,
    Cell: ({ row: { original } }) => (
      <>
        {original.context} {original.action}
      </>
    ),
  },
  {
    Header: "Date",
    accessor: "alertDate",
    sortable: true,
    canRenderFilter: true,
    Cell: ({ row: { original } }) => (
      <>{original.alertDate.replace("T", " ")}</>
    ),
  },
  {
    Header: "IMSI",
    accessor: "imsi",
    sortable: true,
    canRenderFilter: true,
  },
];

export { alertColumns };
