import React from "react";
import { useTranslation } from "react-i18next";
import { Badge, Col, Card, Row, Spinner } from "react-bootstrap";
import {
  getDataVolumeComparison,
  getSessionComparison,
  getZeroSessionComparison,
} from "../../redux/slices/dataSessions";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import CountUp from "react-countup";
import { useRef } from "react";
import { getAlertComparison } from "../../redux/slices/alerts";

const Statistics = ({ type }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const comparison = useSelector((state) => state.dataSessions.comparison);
  const zeroSessionComparison = useSelector(
    (state) => state.dataSessions.zeroSessionComparison
  );
  const dataVolumeComparison = useSelector(
    (state) => state.dataSessions.dataVolumeComparison
  );
  const [alertComparison, setAlertComparison] = useState([]);
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const [isLoading, setIsLoading] = useState(false);

  const [dataSessionComparison, setDataSessionComparison] = useState(0);
  const [zeroSessionPercentage, setZeroSessionPercentage] = useState(0);
  const [dataVolumePercentage, setDataVolumePercentage] = useState(0);
  const [alertPercentage, setAlertPercentage] = useState(0);
  const dataSessionComparisonRef = useRef(dataSessionComparison);
  const zeroSessionPercentageRef = useRef(zeroSessionPercentage);
  const dataVolumePercentageRef = useRef(dataVolumePercentage);
  const alertPercentageRef = useRef(alertPercentage);

  useEffect(() => {
    const fetchComparison = async (id) => {
      await dispatch(getSessionComparison(id, type));
    };
    const fetchZeroSessionComparison = async (id) => {
      await dispatch(getZeroSessionComparison(id, type));
    };
    const fetchDataVolumeComparison = async (id) => {
      await dispatch(getDataVolumeComparison(id, type));
    };
    const fetchAlertComparison = async (id) => {
      const alertResponse = await getAlertComparison(id, type);
      setAlertComparison(alertResponse);
    };
    setIsLoading(true);
    if (!impersonationUser.id) {
      if (user.id) {
        fetchComparison(user.id);
        fetchZeroSessionComparison(user.id);
        fetchDataVolumeComparison(user.id);
        fetchAlertComparison(user.id);
      }
    } else {
      fetchComparison(impersonationUser.id);
      fetchZeroSessionComparison(impersonationUser.id);
      fetchDataVolumeComparison(impersonationUser.id);
      fetchAlertComparison(impersonationUser.id);
    }

    setIsLoading(false);
  }, [type, user.id, impersonationUser.id, dispatch]);

  useEffect(() => {
    const result =
      ((comparison[0] - comparison[1]) / Math.abs(comparison[1])) * 100;
    setDataSessionComparison(parseFloat(result.toFixed(2)));
  }, [comparison]);

  useEffect(() => {
    dataSessionComparisonRef.current = dataSessionComparison;
  }, [dataSessionComparison]);

  useEffect(() => {
    const result =
      ((zeroSessionComparison[0] - zeroSessionComparison[1]) /
        Math.abs(zeroSessionComparison[1])) *
      100;
    setZeroSessionPercentage(parseFloat(result.toFixed(2)));
  }, [zeroSessionComparison]);

  useEffect(() => {
    zeroSessionPercentageRef.current = zeroSessionPercentage;
  }, [zeroSessionPercentage]);

  useEffect(() => {
    const result =
      ((dataVolumeComparison[0] - dataVolumeComparison[1]) /
        Math.abs(dataVolumeComparison[1])) *
      100;
    setDataVolumePercentage(parseFloat(result.toFixed(2)));
  }, [dataVolumeComparison]);

  useEffect(() => {
    dataVolumePercentageRef.current = dataVolumePercentage;
  }, [dataVolumePercentage]);

  useEffect(() => {
    const result =
      ((alertComparison[0] - alertComparison[1]) /
        Math.abs(alertComparison[1])) *
      100;
    setAlertPercentage(parseFloat(result.toFixed(2)));
  }, [alertComparison]);

  useEffect(() => {
    alertPercentageRef.current = alertPercentage;
  }, [alertPercentage]);

  return (
    <div className="w-100">
      <Row>
        <Col sm="6" lg="12" className="d-flex col-xxl-6">
          <Card className="flex-fill" style={{ height: "160px" }}>
            {!isLoading ? (
              <Card.Body>
                <Row>
                  <Col className="mt-0">
                    <h5 className="card-title">{t("Data Volume")}</h5>
                  </Col>
                </Row>
                <span className="h1 d-inline-block mt-3 mb-4">
                  <CountUp
                    start={0}
                    end={parseFloat(
                      dataVolumeComparison[0] / 1024.0 / 1024.0
                    ).toFixed(2)}
                    duration={2.75}
                  ></CountUp>
                  MB
                </span>
                <div className="mb-0">
                  <Badge
                    bg=""
                    className={
                      dataVolumePercentage > 0
                        ? "badge-soft-success me-2"
                        : "badge-soft-danger me-2"
                    }
                  >
                    {dataVolumePercentage}%
                  </Badge>
                  <span className="text-muted">{t("Since last week")}</span>
                </div>
              </Card.Body>
            ) : (
              <Card.Body>
                <Spinner animation="border" variant="primary" />
              </Card.Body>
            )}
          </Card>
        </Col>
        <Col sm="6" lg="12" className="d-flex col-xxl-6">
          <Card className="flex-fill" style={{ height: "160px" }}>
            <Card.Body>
              <Row>
                <Col className="mt-0">
                  <h5 className="card-title">{t("Data Sessions")}</h5>
                </Col>
              </Row>
              <span className="h1 d-inline-block mt-3 mb-4">
                {isLoading ? (
                  <Spinner animation="border" variant="primary" />
                ) : (
                  <CountUp start={0} end={comparison[0]} duration={2.75} />
                )}
              </span>
              <div className="mb-0">
                <Badge
                  bg=""
                  className={
                    dataSessionComparison > 0
                      ? "badge-soft-success me-2"
                      : "badge-soft-danger me-2"
                  }
                >
                  {dataSessionComparison}%
                </Badge>
                {type === "day" && (
                  <span className="text-muted">{t("Since yesterday")}</span>
                )}
                {type === "month" && (
                  <span className="text-muted">{t("Since last month")}</span>
                )}
                {type === "year" && (
                  <span className="text-muted">{t("Since year")}</span>
                )}
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col sm="6" lg="12" className="d-flex col-xxl-6">
          <Card className="flex-fill" style={{ height: "160px" }}>
            <Card.Body>
              <Row>
                <Col className="mt-0">
                  <h5 className="card-title">{t("Alerts")}</h5>
                </Col>
              </Row>
              <span className="h1 d-inline-block mt-3 mb-4">
                {isLoading ? (
                  <Spinner animation="border" variant="primary" />
                ) : (
                  <CountUp start={0} end={alertComparison[0]} duration={2.75} />
                )}
              </span>
              <div className="mb-0">
                <Badge
                  bg=""
                  className={
                    alertPercentageRef.current > 0
                      ? "badge-soft-success me-2"
                      : "badge-soft-danger me-2"
                  }
                >
                  {alertPercentageRef.current}%
                </Badge>
                <span className="text-muted">{t("Since last week")}</span>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col sm="6" lg="12" className="d-flex col-xxl-6">
          <Card className="flex-fill" style={{ height: "160px" }}>
            <Card.Body>
              <Row>
                <Col className="mt-0">
                  <h5 className="card-title">{t("Zero Sessions")}</h5>
                </Col>
              </Row>
              <span className="h1 d-inline-block mt-3 mb-4">
                <CountUp
                  start={0}
                  end={zeroSessionComparison[0]}
                  duration={2.75}
                />
              </span>
              <div className="mb-0">
                <Badge
                  bg=""
                  className={
                    zeroSessionPercentage > 0
                      ? "badge-soft-success me-2"
                      : "badge-soft-danger me-2"
                  }
                >
                  {zeroSessionPercentage}%
                </Badge>
                <span className="text-muted">{t("Since last week")}</span>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Statistics;
