import React from "react";
import { useState } from "react";
import { Modal, Table, Spinner, Button } from "react-bootstrap";
import { FaCheckCircle, FaExclamationCircle } from "react-icons/fa";
import {
  removeDistributionEmail,
  getDistributionEmails,
} from "../../../redux/slices/distribution";
import { useDispatch, useSelector } from "react-redux";

const RemoveDistributionModal = ({
  showRemoveDistributionModal,
  setShowRemoveDistributionModal,
  emailToRemove,
}) => {
  const dispatch = useDispatch();
  const [currentStep, setCurrentStep] = useState(1);
  const [getStatus, setRequestStatus] = useState("");
  const handleClose = () => setShowRemoveDistributionModal(false);
  const { user } = useSelector((state) => state.user);
  return (
    <Modal show={showRemoveDistributionModal} onHide={handleClose}>
      <Modal.Header>Remove Disribution Email</Modal.Header>
      <Modal.Body>
        {currentStep === 1 && emailToRemove && (
          <Table responsive hover>
            <thead>
              <tr>
                <th>Name</th>
                <th>Address</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{emailToRemove.name}</td>
                <td>{emailToRemove.address}</td>
              </tr>
            </tbody>
          </Table>
        )}
        {currentStep === 2 && (
          <div className="text-center">
            <Spinner
              animation="border"
              variant="primary"
              className="mt-5 mb-3"
              style={{ width: "5rem", height: "5rem" }}
            />
            <h3>Waiting for completion</h3>
          </div>
        )}
        {currentStep === 3 && getStatus === "Success" && (
          <div className="text-center">
            <FaCheckCircle size={120} className="mt-3" color="#4BBF73" />
            <h3 className="mt-3">Order Complete</h3>
          </div>
        )}
        {currentStep === 3 && getStatus === "Error" && (
          <div className="text-center">
            <FaExclamationCircle size={120} className="mt-3" color="#FF9494" />
            <h3 className="mt-3">Order Error</h3>
            <p>Please contact the administrator.</p>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        {currentStep === 1 && (
          <>
            <Button
              variant="secondary"
              onClick={() => setShowRemoveDistributionModal(false)}
            >
              Cancel
            </Button>
            <Button
              style={{ background: "#31d5ca", border: "none" }}
              onClick={async () => {
                setCurrentStep(2);
                await removeDistributionEmail(user.id, emailToRemove.id)
                  .then((response) => {
                    setRequestStatus("Success");
                    setCurrentStep(3);
                  })
                  .catch((error) => {
                    setRequestStatus("Error");
                    setCurrentStep(3);
                  });
              }}
            >
              Submit
            </Button>
          </>
        )}
        {currentStep === 3 && (
          <Button
            style={{ backgroundColor: "#31d5ca", border: "none" }}
            onClick={async () => {
              await dispatch(getDistributionEmails(user.id));
              setShowRemoveDistributionModal(false);
              setCurrentStep(1);
            }}
          >
            Close
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default RemoveDistributionModal;
