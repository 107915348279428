import React, { useState, useEffect } from "react";
import { Button, Modal, Table, Col, Form, Pagination } from "react-bootstrap";
import { useSelector } from "react-redux";
import Loader from "../../../../components/spinners/Loader";
import { getSimsByOffer } from "../../../../redux/slices/sims";

const SimsByOfferModal = ({
  showSimsByOfferModal,
  setShowSimsByOfferModal,
  offer,
}) => {
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const [simsByOffer, setSimsByOffer] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const fetchSimsByStatus = async (id, page) => {
      if (!id) return;
      try {
        setIsLoading(true);
        // Note: The API should return the total number of pages or items to calculate it
        const response = await getSimsByOffer(
          id,
          page - 1,
          pageSize,
          offer,
          searchQuery
        );
        console.log(response);
        setSimsByOffer(response.sims);
        setTotalPages(response.meta.totalPages);
      } catch (error) {
        setSimsByOffer([]);
      } finally {
        setIsLoading(false);
      }
    };

    const id = impersonationUser.id || user.id;
    fetchSimsByStatus(id, currentPage);
  }, [
    user.id,
    impersonationUser.id,
    offer,
    currentPage,
    pageSize,
    searchQuery,
  ]);

  // if (!Array.isArray(simsByStatus)) {
  //   console.error("simsByStatus is not an array:", simsByStatus);
  // }

  return (
    <Modal
      show={showSimsByOfferModal}
      onHide={() => setShowSimsByOfferModal(false)}
      centered
      size="lg"
      backdrop="static"
    >
      <Modal.Header>
        <Col md={9}>SIMs By Offer: {offer}</Col>
        <Col md={3}>
          <Form.Control
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="my-3"
          />
        </Col>
      </Modal.Header>
      <Modal.Body style={{ height: "600px", overflowY: "auto" }}>
        {isLoading && <Loader />}
        {/* {!isLoading && simsByStatus.length === 0 && (
          <Table responsive hover>
            <thead>No data</thead>
          </Table>
        )} */}
        {!isLoading && simsByOffer && simsByOffer.length > 0 && (
          <>
            <Table responsive hover>
              <thead>
                <tr>
                  <th>IMSI</th>
                  <th>ICCID</th>
                  <th>MSISDN</th>
                  <th>SKU</th>
                  <th>Offer</th>
                </tr>
              </thead>
              <tbody>
                {simsByOffer.map((session) => {
                  return (
                    <tr key={session.imsi}>
                      <td>{session.imsi}</td>
                      <td>{session.iccid}</td>
                      <td>{session.msisdn}</td>
                      <td>{session.sku}</td>
                      <td>{session.offer}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <span className="mx-2">
          Page{" "}
          <strong>
            {currentPage} of {totalPages}
          </strong>
        </span>
        <span className="ms-3 me-2">Show:</span>
        <Form.Select
          className="d-inline-block w-auto"
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[5, 10, 20, 30, 40, 50].map((pageRecords) => (
            <option key={pageRecords} value={pageRecords}>
              {pageRecords}
            </option>
          ))}
        </Form.Select>
        <Pagination>
          <Pagination.First onClick={() => setCurrentPage(1)} />
          <Pagination.Prev
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
          />
          <Pagination.Next
            onClick={() => {
              setCurrentPage((prev) => Math.min(prev + 1, totalPages));
            }}
            disabled={currentPage === totalPages}
          />
          <Pagination.Last onClick={() => setCurrentPage(totalPages)} />
        </Pagination>
        <Button
          style={{
            "--dynamic-bg-color": impersonationUser.id
              ? impersonationUser.backgroundColor
              : user.backgroundColor,
            "--dynamic-font-color": impersonationUser.id
              ? impersonationUser.fontColor
              : user.fontColor,
            border: "none",
          }}
          onClick={() => {
            setShowSimsByOfferModal(false);
            setPageSize(10);
            setCurrentPage(1);
          }}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SimsByOfferModal;
