import React from "react";
import { Helmet } from "react-helmet-async";
import { Container, Row, Col } from "react-bootstrap";
import AlertsTable from "./AlertsTable";
import { alertColumns } from "../../../inputData/alertData";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import { getAlerts } from "../../../redux/slices/alerts";

const AlertsPage = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const { alerts } = useSelector((state) => state.alerts);
  const [alertData, setAlertData] = useState([]);

  useEffect(() => {
    const fetchWatchdogAlerts = async (id) => {
      await dispatch(getAlerts(id));
    };

    if (!impersonationUser.id) {
      if (user) {
        fetchWatchdogAlerts(user.id);
      }
    } else {
      fetchWatchdogAlerts(impersonationUser.id);
    }
  }, [user, impersonationUser.id, dispatch]);

  useEffect(() => {
    setAlertData(alerts);
  }, [alerts]);

  return (
    <React.Fragment>
      <Helmet title="Alerts" />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">Alerts</h1>
        <Row>
          <Col>
            <AlertsTable columns={alertColumns} data={alertData} />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default AlertsPage;
