import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/spinners/Loader";
import { Helmet } from "react-helmet-async";
import { Container, Row, Col, Table } from "react-bootstrap";
import { getStatements } from "../../redux/slices/statements";
import StatementsList from "./StatementsList";

const Statements = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { statements } = useSelector((state) => state.statements);
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchStatements = async (id) => {
      setIsLoading(true);
      await dispatch(getStatements(id));
      setIsLoading(false);
    };

    if (!impersonationUser.id) {
      fetchStatements(user.id);
    } else {
      fetchStatements(impersonationUser.id);
    }
  }, [dispatch, user, impersonationUser]);

  if (isLoading) {
    return <Loader />;
  } else {
    if (statements.length > 0) {
      return (
        <React.Fragment>
          <Helmet title="Statements">
            <link
              rel="icon"
              href={`data:image/x-icon;base64,${user.favicon}`}
            />
          </Helmet>
          <Container fluid className="p-0">
            <h1 className="h3 mb-3">Statements</h1>
            <Row>
              <Col md="12">
                <StatementsList data={statements} />
              </Col>
            </Row>
          </Container>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <Helmet title="Statements">
            <link
              rel="icon"
              href={`data:image/x-icon;base64,${user.favicon}`}
            />
          </Helmet>
          <Container fluid className="p-0">
            <h1 className="h3 mb-3">Statements</h1>
            <Row>
              <Col md="12">
                <Table>
                  <thead>
                    <tr>
                      <th>{t("No data found")}</th>
                    </tr>
                  </thead>
                </Table>
              </Col>
            </Row>
          </Container>
        </React.Fragment>
      );
    }
  }
};

export default Statements;
