import axios from "../../utils/axios";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  customFields: [],
};

const customFieldsSlice = createSlice({
  name: "customFields",
  initialState,
  reducers: {
    setCustomFields(state, action) {
      state.customFields = action.payload;
    },
    addCustomFieldToSlice(state, action) {
      const newCustomField = action.payload;
      return {
        ...state,
        customFields: [...state.customFields, newCustomField],
      };
    },
    removeCustomFieldFromSlice(state, action) {
      const idToRemove = action.payload;
      const newCustomFields = state.customFields.filter(
        (field) => field.id !== idToRemove
      );
      return {
        ...state,
        customFields: newCustomFields,
      };
    },
  },
});

export function getCustomFields(id) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    await axios
      .get(`https://dev.pos2cloud.com:5000/api/customField/${id}`, {
        headers: {
          accessToken: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        dispatch(customFieldsSlice.actions.setCustomFields(response.data));
      })
      .catch((error) => {
        dispatch(customFieldsSlice.actions.setCustomFields([]));
        throw new Error("Error retrieving customFields");
      });
  };
}

export function addCustomField(id, displayName, type) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const data = {
      displayName: displayName,
      type: type,
    };
    await axios
      .post(`https://dev.pos2cloud.com:5000/api/customField/${id}/add`, data, {
        headers: {
          accessToken: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        dispatch(customFieldsSlice.actions.addCustomFieldToSlice(data));
        dispatch(getCustomFields(id));
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          throw new Error("Unauthorized");
        } else if (error.response && error.response.status === 500) {
          throw new Error("Internal Server Error");
        }
      });
  };
}

export async function modifyCustomFieldValue(
  id,
  imsiList,
  customFieldId,
  value
) {
  const accessToken = window.localStorage.getItem("accessToken");
  const data = {
    imsiList: imsiList,
    customFieldId: customFieldId,
    value: value,
  };
  await axios
    .put(`https://dev.pos2cloud.com:5000/api/customField/${id}/modify`, data, {
      headers: {
        accessToken: `Bearer ${accessToken}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw new Error(error.response);
    });
}

export function removeCustomField(id, customFieldId) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    await axios
      .delete(
        `https://dev.pos2cloud.com:5000/api/customField/${id}/delete/${customFieldId}`,
        {
          headers: {
            accessToken: `Bearer ${accessToken}`,
          },
        }
      )
      .then((response) => {
        dispatch(
          customFieldsSlice.actions.removeCustomFieldFromSlice(customFieldId)
        );
        dispatch(getCustomFields(id));
      })
      .catch((error) => {
        throw new Error("Custom Field Removal Failed");
      });
  };
}

export async function modifyCustomField(id, customFieldId, value, imsiList) {
  const accessToken = window.localStorage.getItem("accessToken");
  const data = {
    imsiList: imsiList,
    customFieldId: customFieldId,
    value: value,
  };
  await axios
    .put(`https://dev.pos2cloud.com:5000/api/customField/${id}/modify`, data, {
      headers: {
        accessToken: `Bearer ${accessToken}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        throw new Error("Unauthorized");
      } else if (error.response && error.response.status === 500) {
        throw new Error("Internal Server Error");
      }
    });
}

export const {
  setCustomFields,
  addCustomFieldToSlice,
  removeCustomFieldFromSlice,
} = customFieldsSlice.actions;

export default customFieldsSlice.reducer;
