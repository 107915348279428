import React, { useMemo, useContext, useRef, useEffect, useState } from "react";
import NotyfContext from "../../../contexts/NotyfContext";
import {
  Form,
  Modal,
  Nav,
  Tab,
  Button,
  Collapse,
  Image,
  Row,
  Col,
  Card,
  Pagination,
  Table,
  Alert,
} from "react-bootstrap";
import { MdSend } from "react-icons/md";
import { AiOutlineClear } from "react-icons/ai";
import * as Yup from "yup";
import { Formik } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import countryList from "react-select-country-list";
import CountrySelectContext from "../../../utils/CountrySelectContext";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import {
  GetAddEntityFunction,
  GetRemoveEntityFunction,
  GetUpdateAddressFunction,
  GetUpdateBackgroundColorFunction,
  GetUpdateCityFunction,
  GetUpdateCompanyFunction,
  GetUpdateCountryFunction,
  GetUpdateFaviconFunction,
  GetUpdateFontColorFunction,
  GetUpdateMobileFunction,
  GetUpdateNameFunction,
  GetUpdateOccupationFunction,
  GetUpdatePostcodeFunction,
  GetUpdatePrivilegeFunction,
  GetUpdateSidebarPositionFunction,
  GetUpdateTINFunction,
  GetUpdateTelephoneFunction,
  GetUpdateOffersFunction,
  GetUpdateWhitelabelFunction,
  performFunctionality,
} from "./updateFunctions";
import {
  updateCurrentUserAddress,
  updateCurrentUserCity,
  updateCurrentUserCompany,
  updateCurrentUserCountry,
  updateCurrentUserFullName,
  updateCurrentUserMobile,
  updateCurrentUserOccupation,
  updateCurrentUserPostcode,
  updateCurrentUserTIN,
  updateCurrentUserTelephone,
} from "../../../redux/slices/user";
import { SIDEBAR_POSITION } from "../../../constants";
import { getAvailableOffers } from "../../../redux/slices/offers";
import { getDomainSims, shipSims } from "../../../redux/slices/sims";
import axios from "../../../utils/axios";

const CrmConfig = ({
  showOffCanvas,
  setShowOffCanvas,
  configUser,
  tenants,
  managers,
  customers,
}) => {
  const [formErrors, setFormErrors] = useState({});
  const [activeTab, setActiveTab] = useState("Basic");
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const domainSims = useSelector((state) => state.sims.domainSims);
  const notyf = useContext(NotyfContext);
  const dispatch = useDispatch();
  // eslint-disable-next-line no-unused-vars
  const [selectedUpperEntityId, setSelectedUpperEntityId] = useState();
  const selectedUpperEntityIdRef = useRef(null);
  const [faviconImage, setFaviconImage] = useState("");
  const [whitelabelImage, setWhitelabelImage] = useState("");
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const faviconInputRef = useRef();
  const whitelabelInputRef = useRef();
  const availableOffers = useSelector((state) => state.offers.availableOffers);
  const offerOptions = availableOffers.map((offer) => {
    return { value: offer, label: offer };
  });
  const [search, setSearch] = useState("");
  const [sims, setSims] = useState([]);
  const [vat, setVat] = useState(0.0);
  const [unitPrice, setUnitPrice] = useState(0.0);
  const [totalPrice, setTotalPrice] = useState(0.0);
  const [showShipSimsModal, setShowShipSimsModal] = useState(false);
  const countryOptions = useMemo(() => {
    const options = countryList().getData();
    const transformedOptions = options.map((option) => {
      return { value: option.label, label: option.label };
    });
    return [{ label: "Select a country", value: "" }, ...transformedOptions];
  }, []);

  const [confirming, setConfirming] = useState(false);
  const shipButtonRef = useRef(null);

  const sidebarPositionOptions = [
    {
      name: "Left",
      value: SIDEBAR_POSITION.LEFT,
    },
    {
      name: "Right",
      value: SIDEBAR_POSITION.RIGHT,
    },
  ];

  const handleClose = () => {
    setShowOffCanvas(false);
  };

  const handleUpload = (inputRef) => {
    inputRef.current?.click();
  };

  const handleDisplayFileDetails = (e, setFieldValue, field, inputRef) => {
    let file = inputRef.current?.files[0];
    if (!file) return;
    setFieldValue(field, file);
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        // setImage(reader.result);
        if (field === "favicon") {
          setFaviconImage(reader.result);
        } else if (field === "whitelabelImage") {
          setWhitelabelImage(reader.result);
        }
      }
    };
    reader.onerror = () => {
      console.log(reader.error);
    };
    reader.readAsDataURL(file);
  };

  const handleSimChange = (event, imsi) => {
    if (event.target.checked) {
      setSims((prev) => [...prev, imsi]);
    } else {
      setSims((prev) => prev.filter((id) => id !== imsi));
    }
  };

  const handleCancel2FA = async (id) => {
    const requestingUserId = user.id;
    const accessToken = window.localStorage.getItem("accessToken");
    await axios
      .delete(
        `https://dev.pos2cloud.com:5000/api/2fa/${requestingUserId}/reset/${id}`,
        {
          headers: {
            accessToken: `Bearer ${accessToken}`,
          },
        }
      )
      .then((response) => {
        notyf.open({
          type: "success",
          message: "2FA Disabled Successfully",
          duration: 5000,
          ripple: true,
          dismissible: false,
          position: {
            x: "center",
            y: "top",
          },
        });
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          notyf.open({
            type: "danger",
            message: "Unauthorized",
            duration: 5000,
            ripple: true,
            dismissible: false,
            position: {
              x: "center",
              y: "top",
            },
          });
        }
      });
  };

  useEffect(() => {
    selectedUpperEntityIdRef.current = selectedUpperEntityId;
  }, [selectedUpperEntityId]);

  useEffect(() => {
    if (!impersonationUser.id) {
      if (user.id) {
        dispatch(getAvailableOffers(user.id));
      }
    } else {
      dispatch(getAvailableOffers(impersonationUser.id));
    }
  }, [user.id, impersonationUser.id, dispatch]);

  useEffect(() => {
    setTotalPrice(sims.length * unitPrice * (1 + vat / 100));
  }, [sims, vat, unitPrice]);

  useEffect(() => {
    const retrieveDomainSims = (privilege) => {
      if (privilege !== "ADMIN") return;
      dispatch(getDomainSims(page, pageSize, search));
    };

    retrieveDomainSims(user.privilege);
  }, [user.privilege, page, pageSize, dispatch, search]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        shipButtonRef.current &&
        !shipButtonRef.current.contains(event.target)
      ) {
        setConfirming(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  const showNotyfMessage = (type, message) => {
    notyf.open({
      type: type,
      message: message,
      duration: 2500,
      ripple: true,
      dismissible: false,
      position: {
        x: "center",
        y: "top",
      },
    });
  };

  const getPrivilegeOptions = (privilege, existingPrivilege) => {
    switch (privilege) {
      case "ADMIN":
        if (existingPrivilege === "SUBACCOUNT") {
          return ["SUBACCOUNT"];
        } else {
          return ["TENANT", "MANAGER", "CUSTOMER"];
        }

      case "TENANT":
        if (existingPrivilege === "SUBACCOUNT") {
          return ["SUBACCOUNT"];
        }
        return ["MANAGER", "CUSTOMER"];
      case "MANAGER":
        if (existingPrivilege === "SUBACCOUNT") {
          return ["SUBACCOUNT"];
        }
        return ["CUSTOMER"];
      case "CUSTOMER":
        return ["SUBACCOUNT"];
      default:
        return [];
    }
  };

  return (
    <Modal
      show={showOffCanvas}
      onHide={handleClose}
      backdrop="static"
      centered
      size="lg"
    >
      <Modal.Header>
        <Modal.Title>{configUser.fullName}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ maxHeight: "600px", overflowY: "auto" }}>
        <Formik
          initialValues={{
            fullName: configUser.fullName,
            address: configUser.address,
            city: configUser.city,
            country: configUser.country,
            postcode: configUser.postcode,
            telephone: configUser.telNumber,
            mobile: configUser.mobileNumber,
            email: configUser.email,
            occupation: configUser.occupation,
            company: configUser.company,
            tin: configUser.tinNumber,
            privilege: configUser.privilege,
            upperEntity: configUser.admin
              ? configUser.admin.id
              : configUser.tenant
              ? configUser.tenant.id
              : configUser.manager
              ? configUser.manager.id
              : configUser.customer
              ? configUser.customer.id
              : null,
            offers: configUser.offers,
            favicon: configUser.favicon,
            whitelabelImage: configUser.whitelabelImage,
            sidebarPosition: configUser.sidebarPosition,
            backgroundColor: configUser.backgroundColor,
            fontColor: configUser.fontColor,
            submit: false,
          }}
          validationSchema={Yup.object().shape({
            fullName: Yup.string().max(255).required("Full Name is required"),
            address: Yup.string().max(255).required("Address is required"),
            email: Yup.string()
              .email("Must be an email")
              .required("Email is required"),
            postcode: Yup.string()
              .max(12, "Must be at most 12 characters")
              .required("Postcode is required"),
            city: Yup.string().required("City is required"),
            country: Yup.string().required("Country is required"),
            telephone: Yup.string().required("Landline is required"),
            mobile: Yup.string().required("Mobile is required"),
            occupation: Yup.string().required("Occupation is required"),
            tin: Yup.string().required("TIN is required"),
          })}
          onSubmit={async (
            values,
            formikBag,
            { setErrors, setStatus, setSubmitting, validateForm }
          ) => {
            const validationErrors = await validateForm();
            if (Object.keys(validationErrors).length === 0) {
              try {
                if (activeTab !== "Sims") {
                  if (values.fullName !== configUser.fullName) {
                    const updateFunction = GetUpdateNameFunction(
                      configUser.privilege,
                      configUser.id,
                      dispatch
                    );
                    await updateFunction(values.fullName);
                    if (configUser.id === user.id) {
                      dispatch(updateCurrentUserFullName(values.fullName));
                    }
                  }
                  if (values.address !== configUser.address) {
                    const updateFunction = GetUpdateAddressFunction(
                      configUser.privilege,
                      configUser.id,
                      dispatch
                    );
                    await updateFunction(values.address);
                    if (configUser.id === user.id) {
                      dispatch(updateCurrentUserAddress(values.address));
                    }
                  }
                  if (values.city !== configUser.city) {
                    const updateFunction = GetUpdateCityFunction(
                      configUser.privilege,
                      configUser.id,
                      dispatch
                    );
                    await updateFunction(values.city);
                    if (configUser.id === user.id) {
                      dispatch(updateCurrentUserCity(values.city));
                    }
                  }
                  if (values.country !== configUser.country) {
                    const updateFunction = GetUpdateCountryFunction(
                      configUser.privilege,
                      configUser.id,
                      dispatch
                    );
                    await updateFunction(values.country);
                    if (configUser.id === user.id) {
                      dispatch(updateCurrentUserCountry(values.country));
                    }
                  }
                  if (values.postcode !== configUser.postcode) {
                    const updateFunction = GetUpdatePostcodeFunction(
                      configUser.privilege,
                      configUser.id,
                      dispatch
                    );
                    await updateFunction(values.postcode);
                    if (configUser.id === user.id) {
                      dispatch(updateCurrentUserPostcode(values.postcode));
                    }
                  }
                  if (values.telephone !== configUser.telNumber) {
                    const updateFunction = GetUpdateTelephoneFunction(
                      configUser.privilege,
                      configUser.id,
                      dispatch
                    );
                    await updateFunction(values.telephone);
                    if (configUser.id === user.id) {
                      dispatch(updateCurrentUserTelephone(values.telephone));
                    }
                  }
                  if (values.mobile !== configUser.mobileNumber) {
                    const updateFunction = GetUpdateMobileFunction(
                      configUser.privilege,
                      configUser.id,
                      dispatch
                    );
                    await updateFunction(values.mobile);
                    if (configUser.id === user.id) {
                      dispatch(updateCurrentUserMobile(values.mobile));
                    }
                  }
                  if (values.occupation !== configUser.occupation) {
                    const updateFunction = GetUpdateOccupationFunction(
                      configUser.privilege,
                      configUser.id,
                      dispatch
                    );
                    await updateFunction(values.occupation);
                    if (configUser.id === user.id) {
                      dispatch(updateCurrentUserOccupation(values.occupation));
                    }
                  }
                  if (values.company !== configUser.company) {
                    const updateFunction = GetUpdateCompanyFunction(
                      configUser.privilege,
                      configUser.id,
                      dispatch
                    );
                    await updateFunction(values.company);
                    if (configUser.id === user.id) {
                      dispatch(updateCurrentUserCompany(values.company));
                    }
                  }
                  if (values.tin !== configUser.tinNumber) {
                    const updateFunction = GetUpdateTINFunction(
                      configUser.privilege,
                      configUser.id,
                      dispatch
                    );
                    await updateFunction(values.tin);
                    if (configUser.id === user.id) {
                      dispatch(updateCurrentUserTIN(values.tin));
                    }
                  }
                  if (values.offers !== configUser.offers) {
                    const updateFunction = GetUpdateOffersFunction(
                      configUser.privilege,
                      configUser.id,
                      dispatch
                    );
                    await updateFunction(values.offers);
                  }
                  if (
                    values.favicon &&
                    values.favicon.name !== configUser.favicon.name
                  ) {
                    const updateFunction = GetUpdateFaviconFunction(
                      configUser.privilege,
                      configUser.id,
                      dispatch
                    );
                    await updateFunction(values.favicon);
                  }
                  if (
                    values.whitelabelImage &&
                    values.whitelabelImage.name !==
                      configUser.whitelabelImage.name
                  ) {
                    const updateFunction = GetUpdateWhitelabelFunction(
                      configUser.privilege,
                      configUser.id,
                      dispatch
                    );
                    await updateFunction(values.whitelabelImage);
                  }
                  if (values.backgroundColor !== configUser.backgroundColor) {
                    const updateFunction = GetUpdateBackgroundColorFunction(
                      configUser.privilege,
                      configUser.id,
                      dispatch
                    );
                    await updateFunction(values.backgroundColor);
                  }
                  if (values.fontColor !== configUser.fontColor) {
                    const updateFunction = GetUpdateFontColorFunction(
                      configUser.privilege,
                      configUser.id,
                      dispatch
                    );
                    await updateFunction(values.fontColor);
                  }
                  if (values.sidebarPosition !== configUser.sidebarPosition) {
                    const updateFunction = GetUpdateSidebarPositionFunction(
                      configUser.privilege,
                      configUser.id,
                      dispatch
                    );
                    await updateFunction(values.sidebarPosition);
                  }
                  if (
                    values.privilege !== configUser.privilege ||
                    (configUser.admin &&
                      values.upperEntity !== configUser.admin.id) ||
                    (configUser.tenant &&
                      values.upperEntity !== configUser.tenant.id) ||
                    (configUser.manager &&
                      values.upperEntity !== configUser.manager.id) ||
                    (configUser.customer &&
                      values.upperEntity !== configUser.customer.id)
                  ) {
                    if (values.upperEntity) {
                      await GetUpdatePrivilegeFunction(
                        user.id,
                        configUser.privilege,
                        configUser.id,
                        values.upperEntity,
                        values.privilege,
                        dispatch
                      );
                      if (configUser.privilege !== "SUBACCOUNT") {
                        GetRemoveEntityFunction(
                          configUser.privilege,
                          configUser.id,
                          dispatch
                        );
                        GetAddEntityFunction(
                          values.privilege,
                          configUser,
                          dispatch
                        );
                      }
                    }
                  }
                  showNotyfMessage("success", "Details Updated");
                  setShowOffCanvas(false);
                  setSubmitting(false);
                } else {
                  if (confirming) {
                    await dispatch(shipSims(configUser.id, sims, totalPrice));
                    showNotyfMessage(
                      "success",
                      "SIM Cards successfully shipped"
                    );
                    setShowOffCanvas(false);
                    setSubmitting(false);
                  } else {
                    setConfirming(true);
                  }
                }
              } catch (error) {
                showNotyfMessage("danger", error.message);
              }
            } else {
              Object.keys(validationErrors).forEach((errorKey) => {
                showNotyfMessage("danger", validationErrors[errorKey]);
              });
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            setValues,
            setFieldValue,
            validateForm,
            submitForm,
          }) => {
            const handleSelectChange = (selected) => {
              setFieldValue(
                "offers",
                selected.map((option) => option.value)
              );
            };
            const handleButtonClick = async () => {
              const validationErrors = await validateForm();

              if (Object.keys(validationErrors).length === 0) {
                // Manually trigger form submission
                submitForm();
              } else {
                // Show validation errors using Notyf
                Object.keys(validationErrors).forEach((errorKey) => {
                  showNotyfMessage("danger", validationErrors[errorKey]);
                });
              }
            };
            return (
              <div className="tab">
                <Tab.Container id="crmConfigTabs" defaultActiveKey="Basic">
                  <Nav variant="tabs">
                    <Nav.Item>
                      <Nav.Link
                        eventKey="Basic"
                        onClick={() => setActiveTab("Basic")}
                      >
                        Basic
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="Contact"
                        onClick={() => setActiveTab("Contact")}
                      >
                        Contact
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="Employment and Tax"
                        onClick={() => setActiveTab("Employment")}
                      >
                        Employment
                      </Nav.Link>
                    </Nav.Item>
                    {configUser.privilege !== "ADMIN" && (
                      <Nav.Item>
                        <Nav.Link
                          eventKey="Privileges"
                          onClick={() => setActiveTab("Privileges")}
                        >
                          Privileges
                        </Nav.Link>
                      </Nav.Item>
                    )}
                    {configUser.privilege !== "SUBACCOUNT" &&
                      configUser.privilege !== "ADMIN" && (
                        <Nav.Item>
                          <Nav.Link
                            eventKey="Offers"
                            onClick={() => setActiveTab("Offers")}
                          >
                            Offers
                          </Nav.Link>
                        </Nav.Item>
                      )}
                    {configUser.privilege !== "ADMIN" &&
                      user.privilege === "ADMIN" &&
                      configUser.privilege !== "CUSTOMER" && (
                        <Nav.Item>
                          <Nav.Link
                            eventKey="WhiteLabel"
                            onClick={() => setActiveTab("Whitelabel")}
                          >
                            Whitelabeling
                          </Nav.Link>
                        </Nav.Item>
                      )}
                    {configUser.privilege !== "ADMIN" &&
                      user.privilege === "ADMIN" && (
                        <Nav.Item>
                          <Nav.Link
                            eventKey="MFA"
                            onClick={() => setActiveTab("MFA")}
                          >
                            MFA
                          </Nav.Link>
                        </Nav.Item>
                      )}
                    {configUser.privilege !== "ADMIN" &&
                      configUser.privilege !== "SUBACCOUNT" &&
                      configUser.privilege !== "VIEWER" &&
                      user.privilege === "ADMIN" && (
                        <Nav.Item>
                          <Nav.Link
                            eventKey="Sims"
                            onClick={() => setActiveTab("Sims")}
                          >
                            SIMs
                          </Nav.Link>
                        </Nav.Item>
                      )}
                  </Nav>

                  <Form onSubmit={handleSubmit}>
                    <Tab.Content className="mt-3 p-2">
                      <Tab.Pane eventKey="Basic">
                        <Form.Group className="mb-3">
                          <Form.Label>Full Name</Form.Label>
                          <Form.Control
                            type="text"
                            name="fullName"
                            value={values.fullName}
                            isInvalid={Boolean(
                              touched.fullName && errors.fullName
                            )}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          {!!touched.fullName && (
                            <Form.Control.Feedback type="invalid">
                              {errors.fullName}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>
                        <Form.Group className="mb-2">
                          <Form.Label>Address</Form.Label>
                          <Form.Control
                            type="text"
                            name="address"
                            value={values.address}
                            isInvalid={Boolean(
                              touched.address && errors.address
                            )}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          {touched.address && errors.address && (
                            <Form.Control.Feedback type="invalid">
                              {errors.address}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>
                        <Form.Group className="mb-2">
                          <Form.Label>City</Form.Label>
                          <Form.Control
                            type="text"
                            name="city"
                            value={values.city}
                            isInvalid={Boolean(touched.city && errors.city)}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          {touched.city && errors.city && (
                            <Form.Control.Feedback type="invalid">
                              {errors.city}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>
                        <Form.Group className="mb-2">
                          <Form.Label>Country</Form.Label>
                          <CountrySelectContext.Provider value={countryOptions}>
                            <CountrySelectContext.Consumer>
                              {(countryOptions) => (
                                <Form.Select
                                  value={values.country}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  name="country"
                                  isInvalid={
                                    touched.country && !!errors.country
                                  }
                                >
                                  {countryOptions.map((option) => (
                                    <option
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </option>
                                  ))}
                                </Form.Select>
                              )}
                            </CountrySelectContext.Consumer>
                          </CountrySelectContext.Provider>
                          {touched.country && errors.country && (
                            <Form.Control.Feedback type="invalid">
                              {errors.country}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>
                        <Form.Group className="mb-2">
                          <Form.Label>Postcode</Form.Label>
                          <Form.Control
                            type="text"
                            name="postcode"
                            value={values.postcode}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.postcode && errors.postcode}
                          />
                          {touched.postcode && errors.postcode && (
                            <Form.Control.Feedback type="invalid">
                              {errors.postcode}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>
                      </Tab.Pane>
                      <Tab.Pane eventKey="Contact">
                        <Form.Group className="mb-2">
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            type="email"
                            name="email"
                            value={values.email}
                            isInvalid={touched.email && errors.email}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          {touched.email && errors.email && (
                            <Form.Control.Feedback type="invalid">
                              {errors.email}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>
                        <Form.Group className="mb-2">
                          <Form.Label>Landline Number</Form.Label>
                          <Form.Control
                            type="text"
                            name="telephone"
                            value={values.telephone}
                            isInvalid={touched.telephone && errors.telephone}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          {touched.telephone && errors.telephone && (
                            <Form.Control.Feedback type="invalid">
                              {errors.telephone}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>
                        <Form.Group className="mb-2">
                          <Form.Label>Mobile Number</Form.Label>
                          <Form.Control
                            type="text"
                            name="mobile"
                            value={values.mobile}
                            isInvalid={touched.mobile && errors.mobile}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          {touched.mobile && errors.mobile && (
                            <Form.Control.Feedback type="invalid">
                              {errors.mobile}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>
                      </Tab.Pane>
                      <Tab.Pane eventKey="Employment and Tax">
                        <Form.Group className="mb-2">
                          <Form.Label>Occupation</Form.Label>
                          <Form.Control
                            type="text"
                            name="occupation"
                            value={values.occupation}
                            isInvalid={Boolean(
                              touched.occupation && errors.occupation
                            )}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          {touched.occupation && errors.occupation && (
                            <Form.Control.Feedback type="invalid">
                              {errors.occupation}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>
                        <Form.Group className="mb-2">
                          <Form.Label>TIN</Form.Label>
                          <Form.Control
                            type="text"
                            name="tin"
                            value={values.tin}
                            isInvalid={Boolean(touched.tin && errors.tin)}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          {touched.tin && errors.tin && (
                            <Form.Control.Feedback type="invalid">
                              {errors.tin}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>
                      </Tab.Pane>
                      {configUser.privilege !== "ADMIN" && (
                        <Tab.Pane eventKey="Privileges">
                          <Form.Group className="mb-3">
                            <Form.Label>Privilege</Form.Label>
                            <Form.Select
                              id="privilegeSelect"
                              name="privilege"
                              value={values.privilege}
                              isInvalid={Boolean(
                                touched.privilege && !!errors.privilege
                              )}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              disabled={
                                configUser.privilege === "SUBACCOUNT"
                                  ? true
                                  : false
                              }
                            >
                              {getPrivilegeOptions(
                                user.privilege,
                                configUser.privilege
                              ).map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </Form.Select>
                          </Form.Group>
                          <Collapse
                            in={
                              values.privilege !== "TENANT" &&
                              values.privilege !== "VIEWER"
                            }
                            dimension="height"
                          >
                            <Form.Group className="mb-3">
                              <Form.Label>Upper Entity</Form.Label>
                              <Form.Select
                                id="upperEntitySelect"
                                name="upperEntity"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={
                                  user.privilege === "ADMIN" ? false : true
                                }
                                value={values.upperEntity}
                              >
                                <option key="" value="">
                                  Select Upper Entity
                                </option>
                                {performFunctionality(
                                  user,
                                  values.privilege,
                                  configUser,
                                  tenants,
                                  managers,
                                  customers
                                ).map((option) => {
                                  return (
                                    <option key={option.id} value={option.id}>
                                      {option.fullName}
                                    </option>
                                  );
                                })}
                              </Form.Select>
                            </Form.Group>
                          </Collapse>
                        </Tab.Pane>
                      )}
                      {configUser.privilege !== "ADMIN" &&
                        user.privilege === "ADMIN" && (
                          <Tab.Pane eventKey="Offers">
                            <Form.Group className="mb-2">
                              <Form.Label>Offers</Form.Label>
                              <Select
                                id="offersSelect"
                                name="offersSelect"
                                value={values.offers.map((offer) => ({
                                  label: offer,
                                  value: offer,
                                }))}
                                isMulti
                                options={offerOptions}
                                onChange={handleSelectChange}
                              />
                            </Form.Group>
                          </Tab.Pane>
                        )}
                      {configUser.privilege !== "ADMIN" &&
                        user.privilege === "ADMIN" && (
                          <Tab.Pane eventKey="WhiteLabel">
                            <Row
                              className="mb-4"
                              style={{ justifyContent: "space-evenly" }}
                            >
                              <Card.Title className="mb-4">Images</Card.Title>
                              <Col>
                                <Form.Label>Favicon</Form.Label>
                                <Form.Group
                                  className="mb-2"
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    border: "1px dashed",
                                  }}
                                >
                                  <Image
                                    src={
                                      faviconImage ||
                                      `data:${configUser.faviconMimeType};base64,${values.favicon}`
                                    }
                                    className="img-responsive mt-2 mb-3"
                                    width={100}
                                    height={100}
                                  />
                                  <input
                                    className="form-control"
                                    ref={faviconInputRef}
                                    name="favicon"
                                    onChange={(e) => {
                                      handleDisplayFileDetails(
                                        e,
                                        setFieldValue,
                                        "favicon",
                                        faviconInputRef
                                      );
                                    }}
                                    type="file"
                                    id="upload-image"
                                    style={{ display: "none" }}
                                  />
                                </Form.Group>
                                <Button
                                  onClick={(e) => {
                                    handleUpload(faviconInputRef);
                                  }}
                                  style={{
                                    "--dynamic-bg-color": user.backgroundColor,
                                    border: "none",
                                  }}
                                >
                                  <FontAwesomeIcon icon={faUpload} /> Upload
                                </Button>
                              </Col>
                            </Row>
                            <Row className="mb-4">
                              <Col>
                                <Form.Label>Whitelabel Image</Form.Label>
                                <Form.Group
                                  className="mb-2"
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    border: "1px dashed",
                                  }}
                                >
                                  <Image
                                    src={
                                      whitelabelImage ||
                                      `data:${configUser.whitelabelMimeType};base64,${values.whitelabelImage}`
                                    }
                                    className="img-responsive mt-2 mb-3"
                                    width={100}
                                    height={100}
                                  />
                                  <input
                                    className="form-control"
                                    ref={whitelabelInputRef}
                                    name="whitelabelImage"
                                    onChange={(e) => {
                                      handleDisplayFileDetails(
                                        e,
                                        setFieldValue,
                                        "whitelabelImage",
                                        whitelabelInputRef
                                      );
                                    }}
                                    type="file"
                                    id="upload-image"
                                    style={{ display: "none" }}
                                  />
                                </Form.Group>
                                <Button
                                  onClick={(e) => {
                                    handleUpload(whitelabelInputRef);
                                  }}
                                  style={{
                                    "--dynamic-bg-color": user.backgroundColor,
                                    border: "none",
                                  }}
                                >
                                  <FontAwesomeIcon icon={faUpload} /> Upload
                                </Button>
                              </Col>
                            </Row>

                            <Row>
                              <Col md={6}>
                                <Row>
                                  <Card.Title className="mb-4">
                                    Colors
                                  </Card.Title>
                                </Row>
                                <Row>
                                  <Col md={6}>
                                    <Form.Group>
                                      <Form.Label>Background Color</Form.Label>
                                      <Form.Control
                                        type="color"
                                        name="backgroundColor"
                                        value={values.backgroundColor}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col md={6}>
                                    <Form.Group>
                                      <Form.Label>Font Color</Form.Label>
                                      <Form.Control
                                        type="color"
                                        name="fontColor"
                                        value={values.fontColor}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                    </Form.Group>
                                  </Col>
                                </Row>
                              </Col>
                              <Col md={6}>
                                <Row>
                                  <Card.Title className="mb-4">
                                    Sidebar
                                  </Card.Title>
                                </Row>
                                <Row>
                                  <Col>
                                    <Form.Group>
                                      <Form.Label>Position</Form.Label>
                                      <Form.Select
                                        value={values.sidebarPosition}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        name="sidebarPosition"
                                      >
                                        {sidebarPositionOptions.map(
                                          ({ name, value }) => (
                                            <option key={name} value={value}>
                                              {name}
                                            </option>
                                          )
                                        )}
                                      </Form.Select>
                                    </Form.Group>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Tab.Pane>
                        )}
                      {configUser.privilege !== "ADMIN" &&
                        user.privilege === "ADMIN" && (
                          <Tab.Pane eventKey="MFA">
                            <div className="text-center">
                              <Form.Group className="mb-3">
                                <Form.Label className="me-3">
                                  Reset MFA:
                                </Form.Label>
                                <Button
                                  style={{
                                    "--dynamic-bg-color": impersonationUser.id
                                      ? impersonationUser.backgroundColor
                                      : user.backgroundColor,
                                    "--dynamic-font-color": impersonationUser.id
                                      ? impersonationUser.fontColor
                                      : user.fontColor,
                                    border: "none",
                                  }}
                                  onClick={() => {
                                    handleCancel2FA(configUser.id);
                                  }}
                                  disabled={
                                    configUser.is2FAEnabled === false
                                      ? true
                                      : false
                                  }
                                >
                                  Reset
                                </Button>
                              </Form.Group>
                            </div>
                          </Tab.Pane>
                        )}
                      {configUser.privilege !== "ADMIN" &&
                        user.privilege === "ADMIN" && (
                          <Tab.Pane eventKey="Sims">
                            <Row className="justify-content-between align-items-end">
                              <Col md={"auto"}>
                                <Button
                                  className="me-3"
                                  style={{
                                    backgroundColor: impersonationUser.id
                                      ? impersonationUser.backgroundColor
                                      : user.backgroundColor,
                                    fontColor: impersonationUser.id
                                      ? impersonationUser.fontColor
                                      : user.fontColor,
                                    border: "none",
                                  }}
                                  onClick={() => setShowShipSimsModal(true)}
                                  disabled={sims.length === 0}
                                >
                                  <MdSend /> Ship SIMs
                                </Button>
                                <Button
                                  className="float-end"
                                  style={{
                                    backgroundColor: impersonationUser.id
                                      ? impersonationUser.backgroundColor
                                      : user.backgroundColor,
                                    fontColor: impersonationUser.id
                                      ? impersonationUser.fontColor
                                      : user.fontColor,
                                    border: "none",
                                  }}
                                  disabled={sims.length === 0}
                                  onClick={() => {
                                    setSims([]);
                                    setShowShipSimsModal(false);
                                  }}
                                >
                                  <AiOutlineClear /> Clear
                                </Button>
                              </Col>

                              <Col md={"auto"} className="float-end">
                                <Form.Control
                                  type="text"
                                  value={search}
                                  onChange={(e) => setSearch(e.target.value)}
                                  placeholder="Search"
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Table responsive hover>
                                  <thead>
                                    <tr>
                                      <th></th>
                                      <th>IMSI</th>
                                      <th>ICCID</th>
                                      <th>MSISDN</th>
                                      <th>Type</th>
                                      <th>State</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {domainSims.sims.map((domainSim) => (
                                      <tr>
                                        <td>
                                          <Form.Check
                                            type="checkbox"
                                            value={domainSim.imsi}
                                            checked={sims.includes(
                                              domainSim.imsi
                                            )}
                                            onChange={(e) =>
                                              handleSimChange(e, domainSim.imsi)
                                            }
                                          />
                                        </td>
                                        <td>{domainSim.imsi}</td>
                                        <td>{domainSim.iccid}</td>
                                        <td>{domainSim.msisdn}</td>
                                        <td>{domainSim.simType}</td>
                                        <td>{domainSim.state}</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </Table>
                              </Col>
                            </Row>
                            <Row>
                              <Col md="8">
                                <span className="mx-2">
                                  Page{" "}
                                  <strong>
                                    {page + 1} of{" "}
                                    {domainSims.metaData.totalPages}
                                  </strong>
                                </span>
                                <span className="ms-3 me-2">Show:</span>
                                <Form.Select
                                  className="d-inline-block w-auto"
                                  value={pageSize}
                                  onChange={(e) => {
                                    setPageSize(Number(e.target.value));
                                  }}
                                >
                                  {[5, 10, 20, 30, 40, 50].map(
                                    (pageRecords) => (
                                      <option
                                        key={pageRecords}
                                        value={pageRecords}
                                      >
                                        {pageRecords}
                                      </option>
                                    )
                                  )}
                                </Form.Select>

                                <span className="ms-3 me-2">Go to Page:</span>
                                <Form.Control
                                  className="d-inline-block"
                                  type="number"
                                  defaultValue={page + 1}
                                  onChange={(e) => {
                                    const page = e.target.value
                                      ? Number(e.target.value) - 1
                                      : 0;

                                    setPage(page);
                                  }}
                                  style={{ width: "75px" }}
                                />
                              </Col>
                              <Col md={4}>
                                <Pagination className="float-end">
                                  <Pagination.First
                                    onClick={() => {
                                      setPage(0);
                                    }}
                                    disabled={page === 0}
                                  />
                                  <Pagination.Prev
                                    onClick={() => {
                                      setPage(page - 1);
                                    }}
                                    disabled={page === 0}
                                  />
                                  <Pagination.Next
                                    onClick={() => {
                                      setPage(page + 1);
                                    }}
                                    disabled={domainSims.sims.length < pageSize}
                                  />
                                  <Pagination.Last
                                    onClick={() => {
                                      setPage(
                                        domainSims.metaData.totalPages - 1
                                      );
                                    }}
                                    disabled={domainSims.sims.length < pageSize}
                                  />
                                </Pagination>
                              </Col>
                            </Row>
                            <Collapse in={showShipSimsModal} dimension="height">
                              <Form className="p-4 shadow-lg rounded bg-white my-4">
                                {" "}
                                {/* Adding padding, shadow, and rounded corners */}
                                <Row className="mb-4">
                                  {/* Unit Price Field */}
                                  <Col md={6}>
                                    <Form.Group>
                                      <Form.Label className="font-weight-bold">
                                        Unit Price
                                      </Form.Label>
                                      <Form.Control
                                        type="number"
                                        value={unitPrice}
                                        onChange={(e) =>
                                          setUnitPrice(e.target.value)
                                        }
                                      />
                                    </Form.Group>
                                  </Col>

                                  {/* VAT Field */}
                                  <Col md={6}>
                                    <Form.Group>
                                      <Form.Label className="font-weight-bold">
                                        VAT
                                      </Form.Label>
                                      <Form.Control
                                        type="number"
                                        value={vat}
                                        onChange={(e) => setVat(e.target.value)}
                                      />
                                    </Form.Group>
                                  </Col>
                                </Row>
                                {/* Total Section */}
                                <Row>
                                  <Table size="sm" className="my-2">
                                    <thead>
                                      <tr>
                                        <th>Product</th>
                                        <th>Quantity</th>
                                        <th>Unit Price</th>
                                        <th>Vat</th>
                                        <th>Subtotal</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>SIM Card Shipment</td>
                                        <td>{sims.length}</td>
                                        <td>{unitPrice}</td>
                                        <td>{vat}%</td>
                                        <td>
                                          {parseFloat(totalPrice.toFixed(2))}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </Table>
                                </Row>
                              </Form>
                            </Collapse>
                          </Tab.Pane>
                        )}
                    </Tab.Content>
                    <Modal.Footer>
                      {activeTab !== "Sims" && (
                        <Button
                          onClick={(e) => {
                            e.preventDefault();
                            handleButtonClick();
                          }}
                          className="me-2"
                          style={{
                            "--dynamic-bg-color": user.backgroundColor,
                            border: "none",
                          }}
                        >
                          Save
                        </Button>
                      )}
                      {activeTab === "Sims" && (
                        <Button
                          onClick={(e) => {
                            e.preventDefault();
                            handleButtonClick();
                          }}
                          ref={shipButtonRef}
                          className="me-2"
                          style={{
                            "--dynamic-bg-color": user.backgroundColor,
                            border: "none",
                            transition: "width 0.3s ease-out",
                            cursor: "pointer",
                            width: confirming ? "150px" : "50px",
                          }}
                        >
                          {confirming ? "Are you sure?" : "Ship"}
                        </Button>
                      )}
                      <Button
                        variant="secondary"
                        onClick={() => {
                          setShowShipSimsModal(false);
                          setSims([]);
                          setPage(0);
                          setPageSize(10);
                          setShowOffCanvas(false);
                        }}
                        style={{
                          "--dynamic-bg-color": user.backgroundColor,
                          border: "none",
                        }}
                      >
                        Cancel
                      </Button>
                    </Modal.Footer>
                  </Form>
                </Tab.Container>
              </div>
            );
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default CrmConfig;
