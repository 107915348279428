import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import { Alert, Button, Form } from "react-bootstrap";

import useAuth from "../../hooks/useAuth";
import GoogleLoginComponent from "../../pages/ui/GoogleSignIn";
import { useDispatch, useSelector } from "react-redux";
import { fetchWhitelabel } from "../../redux/slices/whitelabel";

function SignIn() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { signIn } = useAuth();
  const { user } = useSelector((state) => state.user);
  const { whitelabel } = useSelector((state) => state.whitelabel);

  return (
    <Formik
      initialValues={{
        emailOrUsername: "",
        password: "",
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        emailOrUsername: Yup.string()
          .max(255)
          .required("Username or Email is required"),
        password: Yup.string().max(255).required("Password is required"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          await signIn(values.emailOrUsername, values.password);
          if (user.is2FAEnabled) {
            navigate("/auth/2fa");
          } else {
            navigate("/");
          }
        } catch (error) {
          const message = error.message || "Something went wrong";

          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Email or Username</Form.Label>
            <Form.Control
              size="lg"
              type="text"
              name="emailOrUsername"
              placeholder="Enter your email/username"
              value={values.emailOrUsername}
              isInvalid={Boolean(
                touched.emailOrUsername && errors.emailOrUsername
              )}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {!!touched.email && (
              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Password</Form.Label>
            <Form.Control
              size="lg"
              type="password"
              name="password"
              placeholder="Enter your password"
              value={values.password}
              isInvalid={Boolean(touched.password && errors.password)}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {!!touched.password && (
              <Form.Control.Feedback type="invalid">
                {errors.password}
              </Form.Control.Feedback>
            )}
            <small>
              <Link to="/auth/reset-password">Forgot password?</Link>
            </small>
          </Form.Group>

          <div>
            <Form.Check
              type="checkbox"
              id="rememberMe"
              label="Remember me next time"
              defaultChecked
            />
          </div>

          <div className="text-center mt-2">
            <Button
              type="submit"
              vartiant="primary"
              size="lg"
              disabled={isSubmitting}
              style={{
                background: whitelabel.backgroundColor,
                border: whitelabel.backgroundColor,
                color: whitelabel.fontColor,
              }}
            >
              Sign in
            </Button>
          </div>
          <GoogleLoginComponent className="rounded" />
          <div className="mt-4">
            <Link to="/auth/sign-up" className="float-end">
              No Account? Register
            </Link>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default SignIn;
