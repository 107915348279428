import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
  defaultColumn,
} from "react-table";
import { FaSortUp, FaSortDown, FaSort } from "react-icons/fa";
import { Col, Table, Form, Row, Pagination } from "react-bootstrap";
import { getInvoice } from "../../redux/slices/invoices";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useRef } from "react";

const InvoicesTable = ({ columns: invoiceColumns, data }) => {
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.user);
  // eslint-disable-next-line no-unused-vars
  const [invoiceLoading, setInvoiceLoading] = useState(false);
  const invoiceLoadingRef = useRef(invoiceLoading);

  const handleOnClick = async (userId, downloadUrl) => {
    if (!invoiceLoadingRef.current) {
      invoiceLoadingRef.current = true;

      try {
        getInvoice(userId, downloadUrl).then((data) => {
          const blob = new Blob([data.data], { type: "application/pdf" });
          let url = URL.createObjectURL(blob);
          window.open(url, "_blank");
        });
      } catch (error) {
        console.error(error);
      } finally {
        invoiceLoadingRef.current = false;
      }
    }
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    prepareRow,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns: invoiceColumns,
      data,
      initialState: { pageIndex: 0 },
      defaultColumn,
    },
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        ...columns,
        {
          id: "Download",
          sortable: false,
          Header: "Download",
          Cell: ({ row }) => (
            <Link
              to="#"
              onClick={() => {
                handleOnClick(user.id, row.original.downloadUrl);
              }}
            >
              Download
            </Link>
          ),
        },
      ]);
    }
  );
  return (
    <>
      <Table id="invoicesTable" hover responsive {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>
                  <span {...column.getSortByToggleProps()}>
                    {column.render("Header")}
                    <span>
                      {column.sortable ? (
                        column.isSorted ? (
                          column.isSortedDesc ? (
                            <FaSortUp className="ms-2 " />
                          ) : (
                            <FaSortDown className="ms-2 " />
                          )
                        ) : (
                          <FaSort className="ms-2" />
                        )
                      ) : null}
                    </span>
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
      <Row>
        <Col md="8">
          <span className="mx-2">
            {t("Page")}{" "}
            <strong>
              {pageIndex + 1} {t("of")} {pageOptions.length}
            </strong>
          </span>
          <span className="ms-3 me-2">{t("Show")}:</span>
          <Form.Select
            className="d-inline-block w-auto"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[5, 10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </Form.Select>

          <span className="ms-3 me-2">{t("Go to page")}:</span>
          <Form.Control
            className="d-inline-block"
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
            style={{ width: "75px" }}
          />
        </Col>
        <Col md="4">
          <Pagination className="float-end">
            <Pagination.First
              onClick={() => {
                gotoPage(0);
              }}
              disabled={!canPreviousPage}
            />
            <Pagination.Prev
              onClick={() => {
                previousPage();
              }}
              disabled={!canPreviousPage}
            />
            <Pagination.Next
              onClick={() => {
                nextPage();
              }}
              disabled={!canNextPage}
            />
            <Pagination.Last
              onClick={() => {
                gotoPage(pageCount - 1);
              }}
              disabled={!canNextPage}
            />
          </Pagination>
        </Col>
      </Row>
    </>
  );
};

export default InvoicesTable;
