const billingProductsColumns = [
  {
    Header: "Name",
    accessor: "name",
    sortable: true,
  },
  {
    Header: "Price Plan",
    accessor: "pricePlan",
    sortable: true,
  },
  {
    Header: "Price",
    accessor: "price",
    sortable: true,
    Cell: ({ row: { original } }) => (
      <>
        {original.price}€/{original.pricePeriod}
      </>
    ),
  },
  {
    Header: "Number",
    accessor: "amount",
    sortable: true,
  },
];

export { billingProductsColumns };
