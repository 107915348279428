import React, { useState, useMemo, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import { Alert, Button, Form, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import countryList from "react-select-country-list";
import useAuth from "../../hooks/useAuth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import defaultUser from "../../assets/img/avatars/default-user.png";
import CountrySelectContext from "../../utils/CountrySelectContext";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { fetchWhitelabel } from "../../redux/slices/whitelabel";

function SignUp() {
  const { whitelabel } = useSelector((state) => state.whitelabel);
  const dispatch = useDispatch();
  const currentUrl = window.location.hostname;
  const navigate = useNavigate();
  const { signUp } = useAuth();
  const countryOptions = useMemo(() => {
    const options = countryList().getData();
    return [{ label: "Select a country", value: "" }, ...options];
  }, []);
  useEffect(() => {
    dispatch(fetchWhitelabel(currentUrl));
  }, []);

  const [image, setImage] = useState(defaultUser);

  const inputRef = useRef(null);

  const handleUpload = () => {
    inputRef.current?.click();
  };

  const handleDisplayFileDetails = () => {
    const file = inputRef.current?.files[0];
    if (!file) return;
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setImage(reader.result);
      }
    };
    reader.onerror = () => {
      console.log(reader.error);
    };
    reader.readAsDataURL(inputRef.current.files[0]);
  };

  return (
    <Formik
      initialValues={{
        fullName: "",
        address: "",
        email: "",
        username: "",
        password: "",
        postcode: "",
        city: "",
        country: "",
        telephoneNumber: "",
        mobileNumber: "",
        status: "",
        occupation: "",
        company: "",
        tin: "",
        photo: inputRef.current?.files[0],
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        fullName: Yup.string().max(255).required("Full Name is required"),
        address: Yup.string().max(255).required("Address is required"),
        email: Yup.string()
          .email("Must be a valid email")
          .max(255)
          .required("Email is required"),
        password: Yup.string()
          .min(8, "Must be at least 12 characters")
          .max(255)
          .required("Required"),
        postcode: Yup.string()
          .max(12, "Must be at most 12 characters")
          .required("Required"),
        city: Yup.string().required("City is required"),
        country: Yup.string().required("Country is required"),
        telephoneNumber: Yup.string().required("Telephone Number is Required"),
        occupation: Yup.string().required("Occupation is required"),
        tin: Yup.string().required("TIN is required"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          signUp(
            values.fullName,
            values.address,
            values.city,
            values.country,
            values.postcode,
            values.telephoneNumber,
            values.mobileNumber,
            values.email,
            values.occupation,
            values.company,
            values.tin,
            values.username,
            values.password,
            inputRef.current.files[0]
          );
          navigate("/auth/sign-in");
        } catch (error) {
          const message = error.message || "Something went wrong";

          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setValues,
      }) => (
        <Form onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert className="my-3" variant="danger">
              {errors.submit}
            </Alert>
          )}
          <Row>
            <Col md={8}>
              <Form.Group className="mb-3">
                <Form.Label>Full name</Form.Label>
                <Form.Control
                  type="text"
                  name="fullName"
                  placeholder="Full name"
                  value={values.fullName}
                  isInvalid={Boolean(touched.fullName && errors.fullName)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {!!touched.fullName && (
                  <Form.Control.Feedback type="invalid">
                    {errors.fullName}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Address</Form.Label>
                <Form.Control
                  type="text"
                  name="address"
                  placeholder="Address"
                  value={values.address}
                  isInvalid={Boolean(touched.address && errors.address)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {!!touched.address && (
                  <Form.Control.Feedback type="invalid">
                    {errors.address}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            <Col md="4">
              <div className="text-center">
                <img
                  alt=""
                  src={image}
                  className="rounded-circle img-responsive mt-2"
                  width="100"
                  height="100"
                />
                <div className="mt-2">
                  <input
                    className="form-control"
                    ref={inputRef}
                    name="image"
                    onChange={handleDisplayFileDetails}
                    type="file"
                    id="upload-image"
                    style={{ display: "none" }}
                  />
                  <Button
                    variant="primary"
                    onClick={handleUpload}
                    style={{
                      background: whitelabel.backgroundColor,
                      border: whitelabel.backgroundColor,
                      color: whitelabel.fontColor,
                    }}
                  >
                    <FontAwesomeIcon icon={faUpload} /> Upload
                  </Button>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Label>City</Form.Label>
                <Form.Control
                  type="text"
                  name="city"
                  placeholder="City"
                  value={values.city}
                  isInvalid={Boolean(touched.city && errors.city)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {!!touched.city && (
                  <Form.Control.Feedback type="invalid">
                    {errors.city}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Label>Country</Form.Label>
                <CountrySelectContext.Provider value={countryOptions}>
                  <CountrySelectContext.Consumer>
                    {(countryOptions) => (
                      <Form.Select
                        value={values.country}
                        onChange={handleChange}
                        name="country"
                        isInvalid={Boolean(touched.country && errors.country)}
                      >
                        {countryOptions.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </Form.Select>
                    )}
                  </CountrySelectContext.Consumer>
                </CountrySelectContext.Provider>
                {!!touched.country && (
                  <Form.Control.Feedback type="invalid">
                    {errors.country}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Label>Postcode</Form.Label>
                <Form.Control
                  type="text"
                  name="postcode"
                  value={values.postcode}
                  isInvalid={Boolean(touched.postcode && errors.postcode)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {!!touched.postcode && (
                  <Form.Control.Feedback type="invalid">
                    {errors.postcode}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Telephone Number</Form.Label>
                <Form.Control
                  type="text"
                  value={values.telephoneNumber}
                  name="telephoneNumber"
                  placeholder="Telephone Number"
                  isInvalid={Boolean(
                    touched.telephoneNumber && errors.telephoneNumber
                  )}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {!!touched.telephoneNumber && (
                  <Form.Control.Feedback type="invalid">
                    {errors.telephoneNumber}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Mobile Number</Form.Label>
                <Form.Control
                  type="text"
                  value={values.mobileNumber}
                  name="mobileNumber"
                  placeholder="Mobile Number"
                  isInvalid={Boolean(
                    touched.mobileNumber && errors.mobileNumber
                  )}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {!!touched.mobileNumber && (
                  <Form.Control.Feedback type="invalid">
                    {errors.mobileNumber}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Form.Group className="mb-3">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                name="email"
                placeholder="Email address"
                value={values.email}
                isInvalid={Boolean(touched.email && errors.email)}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {!!touched.email && (
                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Row>
          <Row>
            <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Label>Occupation</Form.Label>
                <Form.Control
                  type="text"
                  name="occupation"
                  value={values.occupation}
                  placeholder="Occupation"
                  isInvalid={Boolean(touched.occupation && errors.occupation)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {!!touched.occupation && (
                  <Form.Control.Feedback type="invalid">
                    {errors.occupation}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Label>Company</Form.Label>
                <Form.Control
                  type="text"
                  name="company"
                  value={values.company}
                  placeholder="Company"
                  isInvalid={Boolean(touched.company && errors.company)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {!touched.company && (
                  <Form.Control.Feedback type="invalid">
                    {errors.company}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Label>TIN</Form.Label>
                <Form.Control
                  type="text"
                  name="tin"
                  value={values.tin}
                  placeholder="TIN"
                  isInvalid={Boolean(touched.tin && errors.tin)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {!!touched.tin && (
                  <Form.Control.Feedback type="invalid">
                    {errors.tin}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Username</Form.Label>
                <Form.Control
                  type="text"
                  name="username"
                  placeholder="Username"
                  value={values.username}
                  isInvalid={Boolean(touched.username && errors.username)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {!!touched.username && (
                  <Form.Control.Feedback type="invalid">
                    {errors.username}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  name="password"
                  placeholder="Password"
                  value={values.password}
                  isInvalid={Boolean(touched.password && errors.password)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {!!touched.password && (
                  <Form.Control.Feedback type="invalid">
                    {errors.password}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
          </Row>
          <div className="text-center mt-3">
            <Button
              type="submit"
              variant="primary"
              size="lg"
              disabled={isSubmitting}
              style={{
                background: whitelabel.backgroundColor,
                border: whitelabel.backgroundColor,
                color: whitelabel.fontColor,
              }}
            >
              Sign up
            </Button>
          </div>
          <div className="row mt-3 text-center">
            <Link to="/auth/sign-in">Back to Sign In</Link>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default SignUp;
