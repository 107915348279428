import React from "react";
import { Card } from "react-bootstrap";
import InvoicesTable from "./InvoicesTable";
import invoicesColumns from "../../inputData/invoiceData";

const InvoicesList = (props) => {
  return (
    <Card>
      <Card.Body>
        <InvoicesTable data={props.data} columns={invoicesColumns} />
      </Card.Body>
    </Card>
  );
};

export default InvoicesList;
