import React from "react";
import { Container } from "react-bootstrap";
import { simColumns } from "../../../inputData/simData";
import RowSelectingTable from "./RowSelectingTable";

const RowExpanding = (props) => {
  const generateCustomFieldColumns = (customFields) => {
    return customFields.map((field) => {
      return {
        id: field.displayName,
        Header: field.displayName,
        accessor: (sim) => {
          const customValue = sim.customFieldValues.find(
            (v) => v.customFieldId === field.id
          );

          return customValue ? customValue.value : "";
        },

        filterType: field.type,
        canRenderFilter: true,
        sortable: true,
      };
    });
  };

  const customColumns = generateCustomFieldColumns(props.customFields);
  const allColumns = [...simColumns, ...customColumns];

  return (
    <React.Fragment>
      <Container fluid className="p-0">
        <RowSelectingTable
          columns={allColumns}
          data={props.data}
          setOpen={props.setOpen}
          setSingleIsShown={props.setSingleIsShown}
          shownSim={props.shownSim}
          setShownSim={props.setShownSim}
          pageNumber={props.page}
          setPageNumber={props.setPage}
          pageRecords={props.pageSize}
          setPageRecords={props.setPageSize}
          numberOfRecords={props.numberOfRecords}
          setCellInfo={props.setCellInfo}
          filters={props.filters}
          setFilters={props.setFilters}
          isLoading={props.isLoading}
          setIsLoading={props.setIsLoading}
          selectedRows={props.selectedRows}
          setSelectedRows={props.setSelectedRows}
          customFields={props.customFields}
        />
      </Container>
    </React.Fragment>
  );
};

export default RowExpanding;
