import React from "react";
import { Card, Row, Button, Table, Badge, Col } from "react-bootstrap";
import SimsMap from "../SimsMap";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { formatDistanceToNow } from "date-fns";
import SimDataSessionsModal from "./SimDataSessionsModal";
import SmsUsageModal from "../modals/SmsUsageModal";
import UpdateLocationModal from "../modals/UpdateLocationModal";
import ImeiLockModal from "../modals/ImeiLockModal";
import HlrProfileModal from "../../../hlr/HlrProfileModal";
import { useSelector } from "react-redux";

const Single = ({ sim, cellInfo, setCellInfo }) => {
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const [activityRecords, setActivityRecords] = useState([]);
  const [showSessionModal, setShowSessionModal] = useState(false);
  const [showSmsModal, setShowSmsModal] = useState(false);
  const [showUpdateLocationModal, setShowUpdateLocationModal] = useState(false);
  const [showImeiLockModal, setShowImeiLockModal] = useState(false);
  const [showHlrProfileModal, setShowHlrProfileModal] = useState(false);

  useEffect(() => {
    if (sim && sim.simActivity && sim.simActivity.length > 0) {
      const newActivityRecords = [...sim.simActivity].sort(
        (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
      );
      setActivityRecords(newActivityRecords);
    }
  }, [sim]);

  return (
    <Card>
      <Card.Body>
        <Row className="g-0 mb-2">
          <strong>Actions</strong>
        </Row>
        <Row className="mb-3">
          <Col>
            <Button
              className="me-2 mb-1 px-2"
              id="simsHLR"
              style={{
                "--dynamic-bg-color": !impersonationUser.id
                  ? user.backgroundColor
                  : impersonationUser.backgroundColor,
                border: "none",
              }}
              onClick={() => setShowHlrProfileModal(true)}
            >
              <span> HLR</span>
            </Button>
            <HlrProfileModal
              showHlrProfileModal={showHlrProfileModal}
              setShowHlrProfileModal={setShowHlrProfileModal}
              sim={sim}
            />
            <Button
              variant="warning"
              className="me-2 mb-1 px-2"
              id="simsHLRProfile"
              style={{
                "--dynamic-bg-color": !impersonationUser.id
                  ? user.backgroundColor
                  : impersonationUser.backgroundColor,
                border: "none",
              }}
              onClick={() => setShowSessionModal(true)}
            >
              <span> Session</span>
            </Button>
            <SimDataSessionsModal
              imsi={sim.imsi}
              showSessionModal={showSessionModal}
              setShowSessionModal={setShowSessionModal}
            />
            <Button
              variant="success"
              className="me-2 mb-1 px-2"
              id="simsSMSUsage"
              style={{
                "--dynamic-bg-color": !impersonationUser.id
                  ? user.backgroundColor
                  : impersonationUser.backgroundColor,
                border: "none",
              }}
              onClick={() => setShowSmsModal(true)}
            >
              <span> SMS Usage</span>
            </Button>
            <SmsUsageModal
              imsi={sim.imsi}
              showSmsModal={showSmsModal}
              setShowSmsModal={setShowSmsModal}
            />
            {sim.status === "Active" && (
              <Button
                variant="warning"
                className="me-2 mb-1 px-2"
                id="simsUpdateLoc"
                style={{
                  "--dynamic-bg-color": !impersonationUser.id
                    ? user.backgroundColor
                    : impersonationUser.backgroundColor,
                  border: "none",
                }}
                onClick={() => {
                  setShowUpdateLocationModal(true);
                }}
              >
                <span> Update Loc</span>
              </Button>
            )}
            <UpdateLocationModal
              showUpdateLocationModal={showUpdateLocationModal}
              setShowUpdateLocationModal={setShowUpdateLocationModal}
              sim={sim}
              setCellInfo={setCellInfo}
            />
            {sim.status === "Active" && (
              <Button
                variant="info"
                className="me-2 mb-1 px-2"
                id="simsActionTest"
                style={{
                  "--dynamic-bg-color": !impersonationUser.id
                    ? user.backgroundColor
                    : impersonationUser.backgroundColor,
                  border: "none",
                }}
                onClick={() => setShowImeiLockModal(true)}
              >
                IMEI Lock
              </Button>
            )}
            <ImeiLockModal
              sim={sim}
              showImeiLockModal={showImeiLockModal}
              setShowImeiLockModal={setShowImeiLockModal}
            />
          </Col>
        </Row>
        <Row>
          <Col></Col>
        </Row>
        <Table size="sm" className="my-2">
          <tbody>
            <tr>
              <th>Usage</th>
              <td>
                {sim.totalBytes !== null
                  ? `${parseFloat(sim.totalBytes).toFixed(3)}MB`
                  : "0.00MB"}
              </td>
            </tr>
            <tr>
              <th>SMS Inbox</th>
              <td>angelica@ramos.com</td>
            </tr>
            <tr>
              <th>IMEI</th>
              <td>{sim.imei}</td>
            </tr>
            <tr>
              <th>Data Session</th>
              <td>
                <div
                  className={`circle ${
                    sim.status === "Active"
                      ? "pulse green"
                      : sim.status === "Productive" ||
                        sim.status === "TestProductive"
                      ? "blue"
                      : sim.status === "Suspended"
                      ? "yellow"
                      : sim.status === "OnStock"
                      ? "grey"
                      : sim.status === "Deleted"
                      ? "red"
                      : null
                  }`}
                ></div>
              </td>
            </tr>
            <tr>
              <th>Status</th>
              <td>
                <Badge
                  bg={
                    sim.status === "Productive"
                      ? "primary"
                      : sim.status === "Active"
                      ? "success"
                      : sim.status === "Suspended"
                      ? "warning"
                      : sim.status === "Terminated"
                      ? "danger"
                      : sim.status === "OnStock"
                      ? "light"
                      : sim.status === "Deleted"
                      ? "danger"
                      : sim.status === "TestProductive"
                      ? "primary"
                      : sim.status === "AutoSuspended"
                      ? "warning"
                      : null
                  }
                >
                  {t(sim.status)}
                </Badge>
              </td>
            </tr>
          </tbody>
        </Table>

        <hr />
        <strong>Map</strong>
        <SimsMap className="my-3" lat={cellInfo.lat} lon={cellInfo.lon} />
        <Table size="sm">
          <tbody>
            <tr>
              <th>{t("Provider")}</th>
              <td>{sim.sku}</td>
            </tr>
            <tr>
              <th>ICCID</th>
              <td>{sim.iccid}</td>
            </tr>
            <tr>
              <th>MSISDN</th>
              <td>{sim.msisdn}</td>
            </tr>
            <tr>
              <th>EID</th>
              <td>{sim.eid ? sim.eid : "N/A"}</td>
            </tr>
            <tr>
              <th>IMSI</th>
              <td>{sim.imsi}</td>
            </tr>
            <tr>
              <th>Country</th>
              <td>{sim.country ? sim.country : "N/A"}</td>
            </tr>
            <tr>
              <th>Network Operator</th>
              <td>{sim.operator ? sim.operator : "N/A"}</td>
            </tr>
            <tr>
              <th>Area</th>
              <td>{cellInfo.area ? cellInfo.area : "N/A"}</td>
            </tr>
            <tr>
              <th>Cell</th>
              <td>{cellInfo.cell ? cellInfo.cell : "N/A"}</td>
            </tr>
            <tr>
              <th>Longitude</th>
              <td>{cellInfo.lon ? cellInfo.lon : "N/A"}</td>
            </tr>
            <tr>
              <th>Latitude</th>
              <td>{cellInfo.lat ? cellInfo.lat : "N/A"}</td>
            </tr>
            <tr>
              <th>Cell Range</th>
              <td>{cellInfo.range ? cellInfo.range : "N/A"}</td>
            </tr>
          </tbody>
        </Table>
        <hr />

        <strong>Activity</strong>

        <ul className="timeline mt-2">
          {activityRecords.slice(0, 5).map((activityElement, index) => (
            <React.Fragment key={index}>
              {activityElement.type === "ACTIVATE" && (
                <li className="timeline-item">
                  <strong>Activated</strong>
                  <span className="float-end text-muted text-sm">
                    {formatDistanceToNow(new Date(activityElement.timestamp), {
                      addSuffix: true,
                    })}
                  </span>
                  <p>SIM {sim.imsi} was activated</p>
                </li>
              )}
              {activityElement.type === "SUSPEND" && (
                <li className="timeline-item">
                  <strong>Suspended</strong>
                  <span className="float-end text-muted text-sm">
                    {formatDistanceToNow(new Date(activityElement.timestamp), {
                      addSuffix: true,
                    })}
                  </span>
                  <p>SIM {sim.imsi} was suspended</p>
                </li>
              )}
              {activityElement.type === "RESUME" && (
                <li className="timeline-item">
                  <strong>Resumed</strong>
                  <span className="float-end text-muted text-sm">
                    {formatDistanceToNow(new Date(activityElement.timestamp), {
                      addSuffix: true,
                    })}
                  </span>
                  <p>SIM {sim.imsi} was resumed</p>
                </li>
              )}
              {activityElement.type === "MODIFY" && (
                <li className="timeline-item">
                  <strong>Modified</strong>
                  <span className="float-end text-muted text-sm">
                    {formatDistanceToNow(new Date(activityElement.timestamp), {
                      addSuffix: true,
                    })}
                  </span>
                  <p>SIM {sim.imsi} was modified</p>
                </li>
              )}
              {activityElement.type === "TERMINATE" && (
                <li className="timeline-item">
                  <strong>Terminated</strong>
                  <span className="float-end text-muted text-sm">
                    {formatDistanceToNow(new Date(activityElement.timestamp), {
                      addSuffix: true,
                    })}
                  </span>
                  <p>SIM {sim.imsi} was terminated</p>
                </li>
              )}
            </React.Fragment>
          ))}
        </ul>
      </Card.Body>
    </Card>
  );
};

export default Single;
