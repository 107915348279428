import React from "react";
import { Row, Col, Card, Table, Button } from "react-bootstrap";
import { FaSortUp, FaSortDown, FaSort } from "react-icons/fa";
import { IoPersonAddSharp } from "react-icons/io5";
import ClientPagination from "./ClientPagination";
import { useSelector } from "react-redux";

const DataCard = ({
  getTableProps,
  getTableBodyProps,
  headerGroups,
  page,
  prepareRow,
  setShownClient,
  setOpen,
  setSingleIsShown,
  pageIndex,
  pageSize,
  canPreviousPage,
  canNextPage,
  pageOptions,
  pageCount,
  gotoPage,
  nextPage,
  previousPage,
  setPageSize,
  t,
  title,
  showCreateEntityModal,
  setShowCreateEntityModal,
}) => {
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  return (
    <Card>
      <Card.Body>
        <Row className="g-0">
          <Col sm="12" xl="12" className="col-xxl-12">
            <div className="d-flex mt-2 float-end">
              {title !== "Admins" && title !== "Viewers" && (
                <Button
                  style={{
                    "--dynamic-bg-color": !impersonationUser.id
                      ? user.backgroundColor
                      : impersonationUser.backgroundColor,
                    border: "none",
                  }}
                  onClick={() => setShowCreateEntityModal(true)}
                >
                  <IoPersonAddSharp />{" "}
                  {title === "Admins"
                    ? "New Admin"
                    : title === "Tenants"
                    ? "New Tenant"
                    : title === "Managers"
                    ? "New Manager"
                    : title === "Customers"
                    ? "New Customer"
                    : title === "Subaccounts"
                    ? "New Subaccount"
                    : null}
                </Button>
              )}
            </div>
          </Col>
        </Row>
        <Table id="clientsTable" hover responsive {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    <span {...column.getSortByToggleProps()}>
                      {column.render("Header")}
                      <span>
                        {column.sortable ? (
                          column.isSorted ? (
                            column.isSortedDesc ? (
                              <FaSortUp className="ms-2 " />
                            ) : (
                              <FaSortDown className="ms-2 " />
                            )
                          ) : (
                            <FaSort className="ms-2" />
                          )
                        ) : null}
                      </span>
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  onClick={() => {
                    setShownClient(row.original);
                    setOpen(true);
                    setTimeout(function () {
                      setSingleIsShown(true);
                    }, 500);
                  }}
                >
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
        <ClientPagination
          pageIndex={pageIndex}
          pageOptions={pageOptions}
          pageSize={pageSize}
          setPageSize={setPageSize}
          pageCount={pageCount}
          gotoPage={gotoPage}
          previousPage={previousPage}
          canPreviousPage={canPreviousPage}
          nextPage={nextPage}
          canNextPage={canNextPage}
          t={t}
        />
      </Card.Body>
    </Card>
  );
};

export default DataCard;
