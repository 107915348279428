import axios from "../../utils/axios";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  invoices: [],
};

const invoiceSlice = createSlice({
  name: "invoices",
  initialState,
  reducers: {
    setInvoices(state, action) {
      state.invoices = action.payload;
    },
  },
});

export function getInvoices(id) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    try {
      await axios
        .get(`https://dev.pos2cloud.com:5000/api/invoice/${id}/getInvoices`, {
          headers: {
            accessToken: `Bearer ${accessToken}`,
          },
        })
        .then((response) => {
          dispatch(invoiceSlice.actions.setInvoices(response.data));
        });
    } catch (error) {
      if (
        error.response &&
        (error.response.status === 404 || error.response.status === 204)
      ) {
        dispatch(invoiceSlice.actions.setInvoices([]));
      } else {
        dispatch(invoiceSlice.actions.setInvoices([]));
      }
    }
  };
}

export function getInvoice(id, downloadUrl) {
  const accessToken = window.localStorage.getItem("accessToken");
  return axios.get(
    `https://dev.pos2cloud.com:5000/api/invoice/${id}/getInvoice`,
    {
      headers: {
        accessToken: `Bearer ${accessToken}`,
        url: downloadUrl,
      },
      responseType: "arraybuffer",
    }
  );
}

export const { setInvoices } = invoiceSlice.actions;
export default invoiceSlice.reducer;
