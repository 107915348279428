import axios from "../../utils/axios";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  whitelabel: [],
};

const whitelabelSlice = createSlice({
  name: "whitelabelSlice",
  initialState,
  reducers: {
    setWhitelabel(state, action) {
      state.whitelabel = action.payload;
    },
  },
});

export function fetchWhitelabel(url) {
  return async (dispatch) => {
    await axios
      .get(
        `https://dev.pos2cloud.com:5000/api/serverdetails/fetchServerDetails?url=${url}`
      )
      .then((response) => {
        dispatch(whitelabelSlice.actions.setWhitelabel(response.data));
      })
      .catch((error) => {
        if (error.response && error.response.status === 500) {
          throw new Error("Error retrieving Whitelabel information");
        }
      });
  };
}

export const { setWhitelabel } = whitelabelSlice.actions;

export default whitelabelSlice.reducer;
