import React from "react";
import { Container } from "react-bootstrap";
import SearchBar from "./SupportSearch";
import { useSelector } from "react-redux";

export const SupportHeader = () => {
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  return (
    <div
      className="hero"
      style={{
        "--dynamic-bg-color": !impersonationUser.id
          ? user.backgroundColor
          : impersonationUser.backgroundColor,
        "--dynamic-font-color": !impersonationUser.id
          ? user.fontColor
          : impersonationUser.fontColor,
        border: "none",
      }}
    >
      <div className="overlay"></div>
      <Container>
        <p
          className="support-welcome-text"
          style={{
            "--dynamic-font-color": !impersonationUser.id
              ? user.fontColor
              : impersonationUser.fontColor,
          }}
        >
          Welcome to POS2Cloud Help Center
        </p>
        <h1
          className="support-question"
          style={{
            "--dynamic-font-color": !impersonationUser.id
              ? user.fontColor
              : impersonationUser.fontColor,
          }}
        >
          What can we help you find?
        </h1>
        <SearchBar />
      </Container>
    </div>
  );
};

export default SupportHeader;
