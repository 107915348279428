import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Container } from "react-bootstrap";

import Clients from "./Clients";

import { useDispatch, useSelector } from "react-redux";
import { getAdmins } from "../../redux/slices/admins";
import Loader from "../../components/spinners/Loader";

const Admins = () => {
  const dispatch = useDispatch();
  const { admins } = useSelector((state) => state.admins);
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchAdmins = async () => {
      if (!impersonationUser.id) {
        if (!user.id) return;
        await dispatch(getAdmins(user.id));
      } else {
        await dispatch(getAdmins(impersonationUser.id));
      }

      setIsLoading(false);
    };
    fetchAdmins();
  }, [dispatch, user.id, impersonationUser.id]);

  if (isLoading) {
    return <Loader />;
  } else {
    return (
      <React.Fragment>
        <Helmet title="Admins" />
        <Container fluid className="p-0">
          <Clients title="Admins" clientList={admins} />
        </Container>
      </React.Fragment>
    );
  }
};

export default Admins;
