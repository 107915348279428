import axios from "../../utils/axios";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  generalPreferences: [],
  notificationPreferences: [],
};

const preferencesSlice = createSlice({
  name: "generalPreferences",
  initialState,
  reducers: {
    setGeneralPreferences(state, action) {
      state.generalPreferences = action.payload;
    },
    setNotificationPreferences(state, action) {
      state.notificationPreferences = action.payload;
    },
  },
});

export async function getGeneralPreferences(id) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    try {
      const response = await axios.get(
        `https://dev.pos2cloud.com:5000/api/userPreferences/${id}/getPreferences`,
        {
          headers: {
            accessToken: `Bearer ${accessToken}`,
          },
        }
      );
      dispatch(preferencesSlice.actions.setGeneralPreferences(response.data));
    } catch (error) {
      if (error.response && error.response.status === 400) {
        throw new Error("Bad Request");
      } else if (error.response && error.response.status === 401) {
        throw new Error("Unauthorized");
      } else if (error.response && error.response.status === 500) {
        throw new Error("Internal Server Error");
      }
    }
  };
}

export const { setGeneralPreferences } = preferencesSlice.actions;

export default preferencesSlice.reducer;
