import React from "react";
import pos2cloud from "../../../assets/img/pos2cloud.png";
import { Image } from "react-bootstrap";

const SuccessPage = () => {
  return (
    <div className="success-container">
      <Image src={pos2cloud} width={300} height={300} />
    </div>
  );
};

export default SuccessPage;
