import React, { useState, useEffect, useContext } from "react";
import TimezoneSelect from "react-timezone-select";
import { format } from "date-fns";
import { Card, Form, Col, Row, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getGeneralPreferences } from "../../../redux/slices/preferences";
import NotyfContext from "../../../contexts/NotyfContext";

const GeneralSettings = ({ generalPreferences }) => {
  const [selectedTimezone, setSelectedTimezone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  const current = new Date();
  const [dateFormat, setDateFormat] = useState("MMM d, yyyy");
  const [timeFormat, setTimeFormat] = useState("HH:mm");
  const currentFullFormat = format(current, "MMMM d, yyyy");
  const currentShortReverseFormat = format(current, "yyyy/MM/dd");
  const currentShortFormatMonthFirst = format(current, "MM/dd/yyyy");
  const currentShortFormat = format(current, "dd/MM/yyyy");
  const currentTimeFormat = format(current, "h:mm a");
  const currentTimeFormatNoPM = format(current, "H:mm");
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const notyf = useContext(NotyfContext);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchGeneralPreferences = (id) => {
      if (!id) return;
      try {
        dispatch(getGeneralPreferences(id));
      } catch (error) {
        notyf.open({
          type: "danger",
          message: "Error Retreiving General Preferences",
          duration: 5000,
          ripple: true,
          dismissible: false,
          position: {
            x: "center",
            y: "top",
          },
        });
      }
    };

    fetchGeneralPreferences(
      !impersonationUser.id ? user.id : impersonationUser.id
    );
  }, [user.id, impersonationUser.id, dispatch, notyf]);

  console.log(generalPreferences);
  return (
    <Card>
      <Card.Header>
        <Card.Title>General Settings</Card.Title>
      </Card.Header>
      <Card.Body>
        <Form>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={2} className="text-sm-right">
              Time Zone
            </Form.Label>
            <Col sm={10}>
              <TimezoneSelect
                value={selectedTimezone}
                onChange={setSelectedTimezone}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={2} className="text-sm-right">
              Date Format
            </Form.Label>
            <Col sm={10}>
              <div className="custom-controls-stacked">
                <Form.Check
                  type="radio"
                  id="fullDate"
                  name="fullDate"
                  value="MMM d, yyyy"
                  label={currentFullFormat}
                  className="mb-2"
                  checked={dateFormat === "MMM d, yyyy"}
                  onChange={(e) => {
                    setDateFormat(e.target.value);
                  }}
                />
                <Form.Check
                  type="radio"
                  id="shortReverseDate"
                  name="shortReverseDate"
                  value="yyyy/MM/dd"
                  label={currentShortReverseFormat}
                  className="mb-2"
                  checked={dateFormat === "yyyy/MM/dd"}
                  onChange={(e) => {
                    setDateFormat(e.target.value);
                  }}
                />
                <Form.Check
                  type="radio"
                  id="shortFormatMonthFirst"
                  name="shortFormatMonthFirst"
                  value="MM/dd/yyyy"
                  label={currentShortFormatMonthFirst}
                  className="mb-2"
                  checked={dateFormat === "MM/dd/yyyy"}
                  onChange={(e) => {
                    setDateFormat(e.target.value);
                  }}
                />
                <Form.Check
                  type="radio"
                  id="shortFormat"
                  name="shortFormat"
                  value="dd/MM/yyyy"
                  label={currentShortFormat}
                  className="mb-2"
                  checked={dateFormat === "dd/MM/yyyy"}
                  onChange={(e) => {
                    setDateFormat(e.target.value);
                  }}
                />
              </div>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={2} className="text-sm-right">
              Time Format
            </Form.Label>
            <Col sm={10}>
              <div className="custom-controls-stacked">
                <Form.Check
                  type="radio"
                  id="fullTimeFormat"
                  name="fullTimeFormat"
                  label={currentTimeFormat}
                  className="mb-2"
                  value="HH:mm"
                  checked={timeFormat === "HH:mm"}
                  onChange={(e) => {
                    setTimeFormat(e.target.value);
                  }}
                />
                <Form.Check
                  type="radio"
                  id="AmPmTimeFormat"
                  name="AmPmTimeFormat"
                  label={currentTimeFormatNoPM}
                  value="hh:mm a"
                  checked={timeFormat === "hh:mm a"}
                  onChange={(e) => {
                    setTimeFormat(e.target.value);
                  }}
                  className="mb-2"
                />
              </div>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Col sm={{ size: 10, offset: 2 }}>
              <Button
                style={{
                  "--dynamic-bg-color": !impersonationUser.id
                    ? user.backgroundColor
                    : impersonationUser.backgroundColor,
                  "--dynamic-font-color": !impersonationUser.id
                    ? user.fontColor
                    : impersonationUser.fontColor,
                  border: "none",
                }}
                id="generalSettingsSubmit"
              >
                Submit
              </Button>
            </Col>
          </Form.Group>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default GeneralSettings;
