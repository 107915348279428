import { createContext, useEffect, useReducer } from "react";

import axios from "../utils/axios";
import { setSession } from "../utils/jwt";
import countryList from "react-select-country-list";
import { useDispatch } from "react-redux";
import { loginUser, logoutUser } from "../redux/slices/user";
import {
  impersonateUser,
  exitImpersonationMode,
} from "../redux/slices/impersonationUser";

const INITIALIZE = "INITIALIZE";
const SIGN_IN = "SIGN_IN";
const SIGN_OUT = "SIGN_OUT";
const SIGN_UP = "SIGN_UP";
const START_IMPERSONATION = "START_IMPERSONATION";
const STOP_IMPERSONATION = "STOP_IMPERSONATION";
const MFA_VERIFIED = "MFA_VERIFIED";

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  isMfaAuthenticated: false,
  user: null,
  impersonationUser: null,
};

const JWTReducer = (state, action) => {
  switch (action.type) {
    case INITIALIZE:
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isMfaAuthenticated: action.payload.isMfaAuthenticated,
        isInitialized: true,
        user: action.payload.user,
      };
    case SIGN_IN:
      return {
        ...state,
        isMfaAuthenticated: action.payload.isMfaAuthenticated,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case SIGN_OUT:
      return {
        ...state,
        isAuthenticated: false,
        isMfaAuthenticated: false,
        user: null,
      };
    case SIGN_UP:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case START_IMPERSONATION:
      return {
        ...state,
        impersonationUser: action.payload.impersonationUser,
      };
    case STOP_IMPERSONATION:
      return {
        ...state,
        impersonationUser: null,
      };
    case MFA_VERIFIED:
      return {
        ...state,
        isMfaAuthenticated: action.payload.isMfaAuthenticated,
      };

    default:
      return state;
  }
};

const AuthContext = createContext(null);

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);
  const usrDispatch = useDispatch();
  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem("accessToken");

        if (accessToken) {
          await axios
            .get("https://dev.pos2cloud.com:5000/api/auth/get-account", {
              headers: {
                accessToken: "Bearer " + accessToken,
              },
            })
            .then(function (response) {
              console.log(response.data);
              dispatch({
                type: INITIALIZE,
                payload: {
                  isAuthenticated: true,
                  isMfaAuthenticated: true,
                  user: response.data,
                },
              });
              usrDispatch(
                loginUser({
                  isMfaEnabled: response.data.isMfaEnabled,
                  userData: { ...response.data },
                })
              );
              const storedImpersonationUser =
                window.localStorage.getItem("impersonationUser");
              if (storedImpersonationUser) {
                const impersonationUserData = JSON.parse(
                  storedImpersonationUser
                );
                dispatch({
                  type: START_IMPERSONATION,
                  payload: {
                    impersonationUser: impersonationUserData,
                  },
                });
                usrDispatch(impersonateUser(impersonationUserData));
              }
            })
            .catch(function (error) {
              console.log("Error " + error);
              dispatch({
                type: INITIALIZE,
                payload: {
                  isAuthenticated: false,
                  isMfaAuthenticated: false,
                  user: null,
                },
              });
              usrDispatch(logoutUser());
            });
        } else {
          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: false,
              isMfaAuthenticated: false,
              user: null,
            },
          });
          usrDispatch(logoutUser());
        }
      } catch (err) {
        dispatch({
          type: INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: null,
            impersonationUser: null,
          },
        });
        usrDispatch(logoutUser());
      }
    };

    initialize();
  }, [usrDispatch]);

  const signIn = async (emailOrUsername, password) => {
    try {
      const data = {
        usernameOrEmail: emailOrUsername,
        password: password,
      };
      const response = await axios.post(
        "https://dev.pos2cloud.com:5000/api/auth/signin",
        data
      );
      const { isMfaEnabled, id, accessToken, ...userData } = response.data;
      if (isMfaEnabled[0] === true) {
        console.log("Mphkaaaaaaa");
        dispatch({
          type: SIGN_IN,
          payload: {
            user: { id },
            isMfaAuthenticated: false,
          },
        });
        usrDispatch(loginUser({ isMfaEnabled, userData: { id: id } }));
      } else {
        dispatch({
          type: SIGN_IN,
          payload: {
            user: { isMfaEnabled, userData: { id: id, ...userData } },
            isMfaAuthenticated: true,
          },
        });
        console.log(id);
        setSession(accessToken);
        usrDispatch(
          loginUser({ isMfaEnabled, userData: { id: id, ...userData } })
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const signOut = async () => {
    const accessToken = window.localStorage.getItem("accessToken");
    await axios
      .post(
        "https://dev.pos2cloud.com:5000/api/auth/signout",
        {},
        {
          headers: {
            accessToken: "Bearer " + accessToken,
          },
        }
      )
      .then(function (response) {
        setSession(null);
        dispatch({ type: SIGN_OUT });
        usrDispatch(logoutUser());
      })
      .catch(function (error) {
        setSession(null);
        dispatch({ type: SIGN_OUT });
        usrDispatch(logoutUser());
      });
  };

  const startImpersonation = async (usernameToImpersonate) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const response = await axios.post(
      "https://dev.pos2cloud.com:5000/api/admins/startImpersonation",
      { usernameToImpersonate: usernameToImpersonate },
      {
        headers: {
          accessToken: `Bearer ${accessToken}`,
        },
      }
    );
    dispatch({
      type: START_IMPERSONATION,
      payload: {
        impersonationUser: response.data,
      },
    });
    usrDispatch(impersonateUser(response.data));
    window.localStorage.setItem(
      "impersonationUser",
      JSON.stringify(response.data)
    );
  };

  const stopImpersonation = async () => {
    dispatch({ type: STOP_IMPERSONATION });
    await usrDispatch(exitImpersonationMode());
    window.localStorage.removeItem("impersonationUser");
  };

  const signUp = async (
    fullName,
    address,
    city,
    country,
    postcode,
    telephoneNumber,
    mobileNumber,
    email,
    occupation,
    company,
    tin,
    username,
    password,
    photo
  ) => {
    const options = countryList().getData();
    const abbreviation = country;
    const countryRecord = options.find((c) => c.value === abbreviation);
    const formData = new FormData();
    formData.append("fullName", fullName);
    formData.append("address", address);
    formData.append("city", city);
    formData.append("country", countryRecord.label);
    formData.append("postcode", postcode);
    formData.append("telephoneNumber", telephoneNumber);
    formData.append("mobileNumber", mobileNumber);
    formData.append("email", email);
    formData.append("occupation", occupation);
    formData.append("company", company);
    formData.append("tin", tin);
    formData.append("username", username);
    formData.append("password", password);
    formData.append("photo", photo);
    await axios.post(
      "https://dev.pos2cloud.com:5000/api/auth/signup",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  };

  const verifyMfa = async (id, code) => {
    console.log(id);
    try {
      const data = {
        userId: id,
        mfaCode: code,
      };
      const response = await axios.post(
        `https://dev.pos2cloud.com:5000/api/auth/verify-mfa`,
        data
      );
      console.log(response.data);
      const { accessToken, isMfaEnabled, ...userData } = response.data;
      dispatch({
        type: MFA_VERIFIED,
        payload: {
          user: userData,
          isMfaAuthenticated: true,
        },
      });
      setSession(accessToken);
      usrDispatch(loginUser({ isMfaEnabled, userData }));
    } catch (error) {
      dispatch({
        type: MFA_VERIFIED,
        payload: {
          isMfaAuthenticated: false,
        },
      });
    }
  };

  const resetPassword = (email) => console.log(email);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        signIn,
        signOut,
        signUp,
        resetPassword,
        startImpersonation,
        stopImpersonation,
        verifyMfa,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
