import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet-async";
import { Container, Row, Col } from "react-bootstrap";
import Header from "./Header";
import Statistics from "./statistics";
import WorldMap from "./WorldMap";
import DataVolumeBarChart from "./DataVolumeBarChart";
import TopMetricsBarChart from "./TopMetricsBarChart";
import WatchdogAlerts from "./WatchdogTable";
import NotyfContext from "../../contexts/NotyfContext";
import SimInventoryPieChart from "./SimInventoryPie";
import OffersDistributionBar from "./OffersDistributionPie";
import NetworksDistributionPieChart from "./NetworksDistributionPieChart";
import DataVolumeNetworkPieChart from "./DataVolumeNetworPieChart";

const Dashboard = () => {
  const notyf = useContext(NotyfContext);
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const [type, setType] = useState("day");

  console.log(user);

  if (!impersonationUser) {
    if (user && user.privilege === "VIEWER") {
      notyf.open({
        type: "warning",
        message:
          "This platform is just a Demo. For increased functionality, contact the Administrator",
        duration: 10000,
        ripple: true,
        dismissible: false,
        position: {
          x: "center",
          y: "top",
        },
      });
    }
  }

  return (
    <React.Fragment>
      <Helmet title="Analytics Dashboard">
        <link
          rel="icon"
          href={`data:image/x-icon;base64,${
            !impersonationUser.id ? user.favicon : impersonationUser.favicon
          }`}
        />
      </Helmet>
      <Container fluid className="p-0">
        <Header type={type} setType={setType} />

        <Row>
          <Col lg="6" xl="5" className="d-flex">
            <Statistics type={type} />
          </Col>
          <Col lg="6" xl="7" className="d-flex">
            <WorldMap period={type} />
          </Col>
        </Row>
        <Row>
          <Col lg="8" className="d-flex">
            <DataVolumeBarChart period={type} />
          </Col>
          <Col lg="4" className="d-flex">
            <TopMetricsBarChart period={type} />
          </Col>
        </Row>
        <Row>
          <Col xs="12" sm="12" md="12" lg xl xxl="6" className="d-flex">
            <OffersDistributionBar />
          </Col>
          <Col xs="12" sm="12" md="12" lg xl xxl="6" className="d-flex">
            <SimInventoryPieChart />
          </Col>
        </Row>
        <Row>
          <Col xs="12" sm="12" md="12" lg xl xxl="6" className="d-flex">
            <DataVolumeNetworkPieChart />
          </Col>
          <Col xs="12" sm="12" md="12" lg xl xxl="6" className="d-flex">
            <NetworksDistributionPieChart />
          </Col>
        </Row>
        <Row>
          <Col>
            <WatchdogAlerts />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default Dashboard;
