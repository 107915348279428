import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal, Button, Spinner, ProgressBar, Table } from "react-bootstrap";
import { useMemo } from "react";
import { FaCheckCircle, FaExclamationCircle } from "react-icons/fa";
import { getSims, resumeSims } from "../../../../redux/slices/sims";

const ResumeSimModal = ({
  sims,
  openResumeModal,
  setOpenResumeModal,
  page,
  pageSize,
  filters,
}) => {
  const handleClose = () => setOpenResumeModal(false);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const [now, setNow] = useState(30);
  const [selectedSims, setSelectedSims] = useState([]);
  const [status, setStatus] = useState("");
  const label = useMemo(() => computeLabel(now), [now]);

  function computeLabel(num) {
    if (num === 30) {
      return "1/3 Confirm";
    } else if (num === 60) {
      return "2/3 Order";
    } else if (num === 100) {
      return "3/3 Completed";
    }
  }

  useEffect(() => {
    const formattedSelectedSims = sims.map((sim) => ({
      imsi: sim.imsi,
      iccid: sim.iccid,
    }));
    setSelectedSims(formattedSelectedSims);
  }, [sims]);

  useEffect(() => {
    if (!openResumeModal) {
      // If the modal is not open
      setNow(30);
    }
  }, [openResumeModal]);

  if (selectedSims.length > 0) {
    return (
      <Modal show={openResumeModal} onHide={handleClose}>
        <Modal.Header>Resume SIMs</Modal.Header>
        <Modal.Body>
          <div className="progress-tabs">
            <ProgressBar
              className="mb-3"
              striped
              animated
              variant="primary"
              now={now}
              label={label}
            />
          </div>
          {now === 30 && (
            <Table>
              <thead>
                <tr>
                  <th>IMSI</th>
                  <th>ICCID</th>
                </tr>
              </thead>
              <tbody>
                {selectedSims.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item.imsi}</td>
                      <td>{item.iccid}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          )}

          {now === 60 && (
            <div className="text-center">
              <Spinner
                animation="border"
                variant="primary"
                className="mt-5 mb-3"
                style={{ width: "5rem", height: "5rem" }}
              />
              <h3>Waiting for completion</h3>
            </div>
          )}
          {now === 100 && status === "Success" && (
            <div className="text-center">
              <FaCheckCircle size={120} className="mt-3" color="#4BBF73" />
              <h3 className="mt-3">Order Complete</h3>
            </div>
          )}
          {now === 100 && status === "Error" && (
            <div className="text-center">
              <FaExclamationCircle
                size={120}
                className="mt-3"
                color="#FF9494"
              />
              <h3 className="mt-3">Order Error</h3>
              <p>Please contact the administrator.</p>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          {now === 30 && (
            <>
              <Button
                onClick={() => setOpenResumeModal(false)}
                style={{
                  "--dynamic-bg-color": !impersonationUser.id
                    ? user.backgroundColor
                    : impersonationUser.backgroundColor,
                  "--dynamic-font-color": !impersonationUser.id
                    ? user.backgroundColor
                    : impersonationUser.backgroundColor,
                  border: "none",
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={async () => {
                  setNow(60);
                  if (!impersonationUser.id) {
                    await resumeSims(
                      selectedSims.map((sim) => sim.imsi),
                      user.id
                    )
                      .then(async (response) => {
                        setStatus("Success");
                      })
                      .catch((error) => setStatus("Error"));
                  } else {
                    await resumeSims(
                      selectedSims.map((sim) => sim.imsi),
                      impersonationUser.id
                    )
                      .then(async (response) => {
                        setStatus("Success");
                      })
                      .catch((error) => setStatus("Error"));
                  }

                  setNow(100);
                }}
                style={{
                  "--dynamic-bg-color": !impersonationUser.id
                    ? user.backgroundColor
                    : impersonationUser.backgroundColor,
                  "--dynamic-font-color": !impersonationUser.id
                    ? user.backgroundColor
                    : impersonationUser.backgroundColor,
                  border: "none",
                }}
              >
                Resume
              </Button>
            </>
          )}
          {now === 100 && (
            <Button
              onClick={async () => {
                setOpenResumeModal(false);
                if (!impersonationUser.id) {
                  await dispatch(getSims(user.id, page + 1, pageSize, filters));
                } else {
                  await dispatch(
                    getSims(impersonationUser.id, page + 1, pageSize, filters)
                  );
                }
              }}
              style={{
                "--dynamic-bg-color": !impersonationUser.id
                  ? user.backgroundColor
                  : impersonationUser.backgroundColor,
                "--dynamic-font-color": !impersonationUser.id
                  ? user.backgroundColor
                  : impersonationUser.backgroundColor,
                border: "none",
              }}
            >
              Close
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    );
  } else {
    return (
      <Modal show={openResumeModal}>
        <Modal.Header>Resume SIMs</Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <Spinner animation="border" variant="primary" className="me-2" />
          </div>
        </Modal.Body>
      </Modal>
    );
  }
};

export default ResumeSimModal;
