import axios from "../../utils/axios";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  orders: [],
};

const ordersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    setOrders(state, action) {
      state.orders = action.payload;
    },
  },
});

export function getOrders(id) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    try {
      await axios
        .get(`https://dev.pos2cloud.com:5000/api/orders/${id}/getOrders`, {
          headers: {
            accessToken: `Bearer ${accessToken}`,
          },
        })
        .then((response) => {
          dispatch(ordersSlice.actions.setOrders(response.data));
        });
    } catch (error) {
      if (
        error.response &&
        (error.response.status === 404 || error.response.status === 204)
      ) {
        dispatch(ordersSlice.actions.setOrders([]));
      } else {
        dispatch(ordersSlice.actions.setOrders([]));
      }
    }
  };
}

export const { setOrders } = ordersSlice.actions;
export default ordersSlice.reducer;
