import React, { useEffect } from "react";
import {
  Container,
  Row,
  Col,
  ButtonGroup,
  Button,
  Form,
} from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import SIMBarChart from "./SimBarChart";
import Single from "./SimSingle";
import SimStatistics from "./SimStatistics";
import SimWatchdogs from "./SimWatchdogTable";
import SimProducts from "./SimProductTable";
import { useSelector } from "react-redux";
import { getSim, getSimCellInfo } from "../../../../redux/slices/sims";
import Loader from "../../../../components/spinners/Loader";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const SIMProfile = () => {
  const location = useLocation();
  const imsi = location.state.imsi;
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const [period, setPeriod] = useState("day");
  const [cellInfo, setCellInfo] = useState({
    area: "",
    cell: "",
    cellId: "",
    lat: "",
    lon: "",
    mcc: "",
    range: "",
    unit: "",
  });
  const [sim, setSim] = useState({
    id: "",
    imsi: "",
    iccid: "",
    msisdn: "",
    eid: "",
    sku: "",
    ip: "",
    status: "",
    state: "",
    offer: "",
    totalBytes: "",
    imei: "",
    networkCode: "",
    operator: "",
    country: "",
    simActivity: [],
    offerItems: [],
  });

  useEffect(() => {
    const fetchSimInformation = async (id) => {
      try {
        const simData = await getSim(id, imsi);
        const cellInfo = await getSimCellInfo(imsi);
        setSim(simData);
        if (cellInfo) {
          setCellInfo(cellInfo);
        }
      } catch (error) {}
    };

    if (!impersonationUser.id) {
      if (user && user.id) {
        fetchSimInformation(user.id);
      }
    } else {
      fetchSimInformation(impersonationUser.id);
    }
  }, [user, impersonationUser.id, imsi]);

  if (sim.imsi) {
    return (
      <React.Fragment>
        <Helmet title={imsi} />
        <Container fluid className="p-0">
          <Row>
            <Col>
              <h1 className="h3 mb-3">SIM Details</h1>
            </Col>
            <Col xs="auto" className="ms-auto text-end mt-n1">
              <Form.Label className="me-3">Select period:</Form.Label>
              <ButtonGroup className="shadow-sm">
                <Button
                  key={1}
                  variant="primary"
                  onClick={() => setPeriod("day")}
                  className={"day" === period ? "active" : null}
                  style={{
                    "--dynamic-bg-color": !impersonationUser.id
                      ? user.backgroundColor
                      : impersonationUser.backgroundColor,
                    border: "none",
                  }}
                >
                  {t("Daily")}
                </Button>
                <Button
                  key={2}
                  variant="primary"
                  onClick={() => setPeriod("month")}
                  className={"month" === period ? "active" : null}
                  style={{
                    "--dynamic-bg-color": !impersonationUser.id
                      ? user.backgroundColor
                      : impersonationUser.backgroundColor,
                    border: "none",
                  }}
                >
                  {t("Monthly")}
                </Button>
                <Button
                  key={3}
                  variant="primary"
                  onClick={() => setPeriod("year")}
                  className={"year" === period ? "active" : null}
                  style={{
                    "--dynamic-bg-color": !impersonationUser.id
                      ? user.backgroundColor
                      : impersonationUser.backgroundColor,
                    border: "none",
                  }}
                >
                  {t("Yearly")}
                </Button>
              </ButtonGroup>
            </Col>
          </Row>

          <Row>
            <Col md="12" lg="4" xl="4">
              <Single sim={sim} cellInfo={cellInfo} setCellInfo={setCellInfo} />
            </Col>
            <Col md="12" lg="8" xl="8">
              <Row>
                <Col className="d-flex">
                  <SimStatistics sim={sim} period={period} />
                </Col>
              </Row>
              <Row>
                <Col className="d-flex">
                  <SIMBarChart sim={sim} period={period} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <SimWatchdogs sim={sim} />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              <SimProducts sim={sim} />
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  } else {
    return <Loader />;
  }
};
export default SIMProfile;
