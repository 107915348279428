import React from "react";
import PerfectScrollbar from "react-perfect-scrollbar";

import useSidebar from "../../hooks/useSidebar";
import SidebarNav from "./SidebarNav";
import { Image } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useEffect } from "react";

const Sidebar = ({ items, showFooter = true }) => {
  const { isOpen, position, setPosition } = useSidebar();
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);

  useEffect(() => {
    if (!impersonationUser.id) {
      if (!user.id) {
        return;
      }
      setPosition(user.sidebarPosition);
    } else {
      setPosition(impersonationUser.sidebarPosition);
    }
  }, [
    user.id,
    impersonationUser.id,
    user.sidebarPosition,
    impersonationUser.sidebarPosition,
    setPosition,
  ]);

  return (
    <nav className={`sidebar ${position} ${!isOpen ? "collapsed" : ""}`}>
      <div
        className="sidebar-content"
        style={{
          "--dynamic-bg-color": !impersonationUser.id
            ? user.backgroundColor
            : impersonationUser.backgroundColor,
          "--dynamic-font-color": !impersonationUser.id
            ? user.fontColor
            : impersonationUser.fontColor,
        }}
      >
        <PerfectScrollbar>
          <a className="sidebar-brand text-center" href="/">
            <Image
              src={`data:${
                !impersonationUser.id
                  ? user.whitelabelMimeType
                  : impersonationUser.whitelabelMimeType
              };base64,${
                !impersonationUser.id
                  ? user.whitelabelImage
                  : impersonationUser.whitelabelImage
              }`}
              alt=""
              style={{ maxWidth: "250px", maxHeight: "100px" }}
            />
          </a>

          <SidebarNav items={items} />
        </PerfectScrollbar>
      </div>
    </nav>
  );
};

export default Sidebar;
