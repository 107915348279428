import SelectColumnFilter from "../utils/SelectColumnFilter";
import NumberRangeColumnFilter from "../utils/NumberRangeColumnFilter";

const simColumns = [
  {
    Header: "SIM Status",
    accessor: "status",
    sortable: true,
    Filter: SelectColumnFilter,
    filter: "includes",
    filterType: "select",
    canRenderFilter: true,
    Cell: ({ row: { original } }) => (
      <div
        className={`circle ${
          original.status === "Active"
            ? "pulse green"
            : original.status === "Suspended"
            ? "yellow"
            : original.status === "Productive"
            ? "blue"
            : original.status === "OnStock"
            ? "grey"
            : original.status === "Deleted"
            ? "red"
            : original.status === "TestProductive"
            ? "blue"
            : null
        }`}
      ></div>
    ),
  },
  {
    Header: "IMSI",
    accessor: "imsi",
    sortable: true,
    canRenderFilter: true,
    filterType: "text",
  },
  {
    Header: "ICCID",
    accessor: "iccid",
    sortable: true,
    canRenderFilter: true,
    filterType: "text",
  },
  {
    Header: "MSISDN",
    accessor: "msisdn",
    sortable: true,
    Cell: ({ value }) => (value ? value : "N/A"),
    canRenderFilter: true,
    filterType: "text",
  },
  {
    Header: "EID",
    accessor: "eid",
    sortable: true,
    canRenderFilter: true,
    filterType: "text",
  },
  {
    Header: "Data",
    accessor: "data",
    sortable: true,
    Filter: NumberRangeColumnFilter,
    filter: "between",
    canRenderFilter: true,
    filterType: "between",
    Cell: ({ row: { original } }) => (
      <>
        {original.totalBytes === null
          ? ""
          : `${parseFloat(original.totalBytes).toFixed(3)}MB`}
      </>
    ),
  },
];

export { simColumns };
