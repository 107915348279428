import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  DropdownButton,
  ButtonGroup,
  Dropdown,
  Badge,
} from "react-bootstrap";
import { DropdownSubmenu } from "react-bootstrap-submenu";
import ActivateSimModal from "../sims/modals/ActivateSimModal";
import SuspendSimModal from "./modals/SuspendSimModal";
import ResumeSimModal from "./modals/ResumeSimModal";
import TerminateSimModal from "./modals/TerminateSimModal";
import { getSimInventoryCount, getSims } from "../../../redux/slices/sims";
import { useDispatch, useSelector } from "react-redux";
import ModifySimModal from "./modals/ModifySimModal";
import AddWatchdogModal from "./modals/AddWatchdogModal";
import RemoveAllWatchdogsModal from "./modals/RemoveAllWatchdogsModal";
import SmsModal from "../sms/SmsModal";
import AddCustomFieldModal from "./modals/AddCustomFieldModal";
import RemoveCustomFieldModal from "./modals/RemoveCustomFieldModal";
import ModifyCustomFieldModal from "./modals/ModifyCustomFieldModal";

const SimActionsDropdown = ({
  selectedRows,
  clearSelectedRows,
  pageIndex,
  pageRecords,
  filters,
  setIsLoading,
  sims,
}) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const { customFields } = useSelector((state) => state.customFields);
  const [customFieldsLength, setCustomFieldsLength] = useState();
  const [isBulkActivateAvailable, setIsBulkActivateAvailable] = useState(false);
  const [isBulkSuspendAvailable, setIsBulkSuspendAvailable] = useState(false);
  const [isBulkResumeAvailable, setIsBulkResumeAvailable] = useState(false);
  const [isBulkTerminateAvailable, setIsBulkTerminateAvailable] =
    useState(false);
  const [isBulkTestModeAvailable, setIsBulkTestModeAvailable] = useState(false);
  const [isSmsAvailable, setIsSmsAvailable] = useState(true);
  const [isClearSelectionAvailable, setIsClearSelectionAvailable] =
    useState(false);
  const [isModifyProductAvailable, setIsModifyProductAvailable] =
    useState(false);
  const [isBulkAddWatchDogAvailable, setIsBulkAddWatchDogAvailable] =
    useState(false);
  const [isBulkRemoveWatchdogAvailable, setIsBulkRemoveWatchdogAvailable] =
    useState(false);
  const [isAddCustomFieldAvailable, setIsAddCustomFieldAvailable] =
    useState(false);
  const [isRemoveCustomFieldAvailable, setIsRemoveCustomFieldAvailable] =
    useState(false);
  const [isModifyCustomFieldAvailable, setIsModifyCustomFieldAvailable] =
    useState(false);

  const [openActivateModal, setOpenActivateModal] = useState(false);
  const [openSuspendModal, setOpenSuspendModal] = useState(false);
  const [openResumeModal, setOpenResumeModal] = useState(false);
  const [openTerminateModal, setOpenTerminateModal] = useState(false);
  const [openModifyProductModal, setOpenModifyProductModal] = useState(false);
  const [openAddWatchdogModal, setOpenAddWatchdogModal] = useState(false);
  const [openRemoveWatchdogModal, setOpenRemoveWatchdogModal] = useState(false);
  const [openSmsModal, setOpenSmsModal] = useState(false);
  const [openAddCustomFieldModal, setOpenAddCustomFieldModal] = useState(false);
  const [openRemoveCustomFieldModal, setOpenRemoveCustomFieldModal] =
    useState(false);
  const [openModifyCustomFieldModal, setOpenModifyCustomFieldModal] =
    useState(false);

  useEffect(() => {
    if (selectedRows.length === 0) {
      setIsBulkActivateAvailable(false);
    } else {
      const allOnStockOrTestProductive = selectedRows.every(
        (row) => row.status === "OnStock" || row.status === "TestProductive"
      );
      setIsBulkActivateAvailable(allOnStockOrTestProductive);
    }
  }, [selectedRows]);

  useEffect(() => {
    if (selectedRows.length === 0) {
      setIsBulkSuspendAvailable(false);
    } else {
      const allProductive = selectedRows.every(
        (row) => row.state === "Productive"
      );
      setIsBulkSuspendAvailable(allProductive);
    }
  }, [selectedRows]);

  useEffect(() => {
    if (selectedRows.length === 0) {
      setIsBulkResumeAvailable(false);
    } else {
      const allSuspended = selectedRows.every(
        (row) => row.status === "Suspended"
      );
      setIsBulkResumeAvailable(allSuspended);
    }
  }, [selectedRows]);

  useEffect(() => {
    if (selectedRows.length === 0) {
      setIsBulkTerminateAvailable(false);
    } else {
      const allProductiveOrSuspended = selectedRows.every(
        (row) => row.status === "Productive" || row.status === "Suspended"
      );
      setIsBulkTerminateAvailable(allProductiveOrSuspended);
    }
  }, [selectedRows]);

  useEffect(() => {
    const allOnStock = selectedRows.every((row) => row.status === "OnStock");
    setIsBulkTestModeAvailable(allOnStock);
  }, [selectedRows]);

  useEffect(() => {
    const allActive = selectedRows.every(
      (row) => row.status === "Active" || row.status === "Productive"
    );
    setIsSmsAvailable(allActive);
  }, [selectedRows]);

  useEffect(() => {
    setIsClearSelectionAvailable(selectedRows.length > 0);
  }, [selectedRows]);

  useEffect(() => {
    setIsModifyProductAvailable(selectedRows.length !== 1);
  }, [selectedRows]);

  useEffect(() => {
    if (selectedRows.length === 0) {
      setIsBulkAddWatchDogAvailable(false);
      setIsBulkRemoveWatchdogAvailable(false);
    } else {
      const firstSku = selectedRows[0].sku;
      const allSameProvider = selectedRows.every(
        (row) => row.sku === firstSku && row.state === "Productive"
      );
      setIsBulkAddWatchDogAvailable(allSameProvider);
      setIsBulkRemoveWatchdogAvailable(allSameProvider);
    }
  }, [selectedRows]);

  useEffect(() => {
    setCustomFieldsLength(customFields.length);
  }, [customFields]);

  useEffect(() => {
    if (customFieldsLength < 10) {
      setIsAddCustomFieldAvailable(true);
    } else {
      setIsAddCustomFieldAvailable(false);
    }
  }, [customFieldsLength]);

  useEffect(() => {
    if (customFieldsLength > 0) {
      setIsRemoveCustomFieldAvailable(true);
    } else {
      setIsRemoveCustomFieldAvailable(false);
    }
  }, [customFieldsLength]);

  useEffect(() => {
    if (customFieldsLength > 0 && selectedRows.length > 0) {
      setIsModifyCustomFieldAvailable(true);
    } else {
      setIsModifyCustomFieldAvailable(false);
    }
  }, [customFieldsLength, selectedRows]);

  return (
    <Row className="g-0">
      <Col sm="12" xl="12" className="col-xxl-12">
        <div className="d-flex mt-2 float-end" id="tableActionsPanel">
          {selectedRows.length > 0 && (
            <Badge
              className="me-3 text-center d-flex align-items-center custom-badge"
              style={{
                "--dynamic-bg-color": !impersonationUser.id
                  ? user.backgroundColor
                  : impersonationUser.backgroundColor,
                "--dynamic-font-color": !impersonationUser.id
                  ? user.fontColor
                  : impersonationUser.fontColor,
                border: "none",
              }}
            >
              {selectedRows.length} rows selected
            </Badge>
          )}
          <DropdownButton
            as={ButtonGroup}
            // style={{
            //   background: "#31d5ca",
            //   border: "none",
            // }}
            title="Actions"
            className="me-3"
            style={{
              "--dynamic-bg-color": !impersonationUser.id
                ? user.backgroundColor
                : impersonationUser.backgroundColor,
              border: "none",
            }}
          >
            <DropdownSubmenu title="Modify">
              <Dropdown.Item
                eventKey="2"
                disabled={isModifyProductAvailable}
                onClick={() => setOpenModifyProductModal(true)}
              >
                Product
              </Dropdown.Item>
              <Dropdown.Item eventKey="3">Custom Fields</Dropdown.Item>
              <Dropdown.Item eventKey="4">IMEI Lock</Dropdown.Item>
            </DropdownSubmenu>
            <DropdownSubmenu title="Watchdog">
              <Dropdown.Item
                disabled={!isBulkAddWatchDogAvailable}
                onClick={() => setOpenAddWatchdogModal(true)}
                eventKey="5"
              >
                Add Watchdog
              </Dropdown.Item>
              <Dropdown.Item
                eventKey="6"
                disabled={!isBulkRemoveWatchdogAvailable}
                onClick={() => setOpenRemoveWatchdogModal(true)}
              >
                Remove all Watchdogs
              </Dropdown.Item>
            </DropdownSubmenu>
            <DropdownSubmenu title="Custom Field">
              <Dropdown.Item
                eventKey="addCustomField"
                disabled={!isAddCustomFieldAvailable}
                onClick={() => setOpenAddCustomFieldModal(true)}
              >
                Add
              </Dropdown.Item>
              <Dropdown.Item
                eventKey="removeCustomField"
                disabled={!isRemoveCustomFieldAvailable}
                onClick={() => setOpenRemoveCustomFieldModal(true)}
              >
                Remove
              </Dropdown.Item>
              <Dropdown.Item
                eventKey="modifyCustomField"
                disabled={!isModifyCustomFieldAvailable}
                onClick={() => setOpenModifyCustomFieldModal(true)}
              >
                Modify
              </Dropdown.Item>
            </DropdownSubmenu>
            <Dropdown.Item
              eventKey="7"
              disabled={!isSmsAvailable}
              onClick={() => setOpenSmsModal(true)}
            >
              Send SMS
            </Dropdown.Item>
            <Dropdown.Item
              eventKey="refresh"
              onClick={async () => {
                setIsLoading(true);
                if (!impersonationUser.id) {
                  await dispatch(
                    getSims(user.id, pageIndex + 1, pageRecords, filters)
                  );
                  await dispatch(getSimInventoryCount(user.id, filters));
                } else {
                  await dispatch(
                    getSims(
                      impersonationUser.id,
                      pageIndex + 1,
                      pageRecords,
                      filters
                    )
                  );
                  await dispatch(
                    getSimInventoryCount(impersonationUser.id, filters)
                  );
                }

                setIsLoading(false);
              }}
            >
              Refresh
            </Dropdown.Item>
            <Dropdown.Item
              eventKey="clearSelection"
              disabled={!isClearSelectionAvailable}
              onClick={clearSelectedRows}
            >
              Clear Selection
            </Dropdown.Item>
          </DropdownButton>
          <DropdownButton
            as={ButtonGroup}
            title="Bulk"
            style={{
              "--dynamic-bg-color": !impersonationUser.id
                ? user.backgroundColor
                : impersonationUser.backgroundColor,
              border: "none",
            }}
          >
            <Dropdown.Item
              eventKey="bulkActivate"
              disabled={!isBulkActivateAvailable}
              onClick={() => setOpenActivateModal(true)}
            >
              Activate
            </Dropdown.Item>
            <Dropdown.Item
              eventKey="bulkSuspend"
              disabled={!isBulkSuspendAvailable}
              onClick={() => setOpenSuspendModal(true)}
            >
              Suspend
            </Dropdown.Item>
            <Dropdown.Item
              eventKey="bulkResume"
              disabled={!isBulkResumeAvailable}
              onClick={() => setOpenResumeModal(true)}
            >
              Resume
            </Dropdown.Item>
            <Dropdown.Item
              eventKey="bulkTerminate"
              disabled={!isBulkTerminateAvailable}
              onClick={() => setOpenTerminateModal(true)}
            >
              Terminate
            </Dropdown.Item>
            <Dropdown.Item
              eventKey="bulkTerminate"
              disabled={!isBulkTestModeAvailable}
            >
              Test Mode
            </Dropdown.Item>
          </DropdownButton>
        </div>
        <ActivateSimModal
          openActivateModal={openActivateModal}
          selectedSims={selectedRows}
          setOpenActivateModal={setOpenActivateModal}
          page={pageIndex}
          pageSize={pageRecords}
          filters={filters}
        />
        <SuspendSimModal
          openSuspendModal={openSuspendModal}
          sims={selectedRows}
          setOpenSuspendModal={setOpenSuspendModal}
          page={pageIndex}
          pageSize={pageRecords}
          filters={filters}
        />
        <ResumeSimModal
          openResumeModal={openResumeModal}
          sims={selectedRows}
          setOpenResumeModal={setOpenResumeModal}
          page={pageIndex}
          pageSize={pageRecords}
          filters={filters}
        />
        <TerminateSimModal
          openTerminateModal={openTerminateModal}
          sims={selectedRows}
          setOpenTerminateModal={setOpenTerminateModal}
          page={pageIndex}
          pageSize={pageRecords}
          filters={filters}
        />
        <ModifySimModal
          sim={selectedRows[0]}
          setOpenModifyProductModal={setOpenModifyProductModal}
          openModifyProductModal={openModifyProductModal}
          page={pageIndex}
          pageSize={pageRecords}
          filters={filters}
        />
        <AddWatchdogModal
          openAddWatchdogModal={openAddWatchdogModal}
          setOpenAddWatchdogModal={setOpenAddWatchdogModal}
          selectedSims={selectedRows}
          page={pageIndex}
          pageSize={pageRecords}
          filters={filters}
        />
        <RemoveAllWatchdogsModal
          openRemoveWatchdogModal={openRemoveWatchdogModal}
          setOpenRemoveWatchdogModal={setOpenRemoveWatchdogModal}
          selectedSims={selectedRows}
          page={pageIndex}
          pageSize={pageRecords}
          filters={filters}
        />
        <SmsModal
          openSmsModal={openSmsModal}
          setOpenSmsModal={setOpenSmsModal}
          sims={selectedRows}
        />
        <AddCustomFieldModal
          openAddCustomFieldModal={openAddCustomFieldModal}
          setOpenAddCustomFieldModal={setOpenAddCustomFieldModal}
        />
        <RemoveCustomFieldModal
          openRemoveCustomFieldModal={openRemoveCustomFieldModal}
          setOpenRemoveCustomFieldModal={setOpenRemoveCustomFieldModal}
        />
        <ModifyCustomFieldModal
          openModifyCustomField={openModifyCustomFieldModal}
          setOpenModifyCustomField={setOpenModifyCustomFieldModal}
          selectedRows={selectedRows}
          page={pageIndex}
          pageSize={pageRecords}
          filters={filters}
        />
      </Col>
    </Row>
  );
};

export default SimActionsDropdown;
