import React, { useState, useEffect, useMemo } from "react";
import { Card, Table, Pagination, Row, Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useTable, usePagination } from "react-table";

const UsageTable = ({ usageType, groupByField, data }) => {
  const [tableData, setTableData] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    setTableData(data);
  }, [data]);

  const columns = useMemo(() => {
    const baseCols = {
      customerID: { Header: "Customer ID", accessor: "portfolioId" },
      date: { Header: "Date", accessor: "date" },
      imsi: { Header: "IMSI", accessor: "imsi" },
      iccid: { Header: "ICCID", accessor: "iccid" },
      simType: { Header: "SIM Type", accessor: "simType" },
      networkCode: { Header: "Network Code", accessor: "networkCode" },
      network: { Header: "Network Name", accessor: "network" },
      country: { Header: "Country", accessor: "country" },
      service: { Header: "Service Code", accessor: "service" },
      totalBytes: { Header: "Total Bytes", accessor: "totalBytes" },
      roundedBytes: {
        Header: "Rounded Bytes",
        accessor: (data) => Math.ceil(data.totalBytes / 1000) * 1000,
      },
      sessions: { Header: "Sessions", accessor: "sessions" },
      zeroSessions: { Header: "Zero Sessions", accessor: "zeroSessions" },
      source: { Header: "Source", accessor: "source" },
      destination: { Header: "Destination", accessor: "destination" },
      events: { Header: "Events", accessor: "events" },
    };

    if (usageType === "data") {
      switch (groupByField) {
        case "all":
          return [
            baseCols.customerID,
            baseCols.imsi,
            baseCols.iccid,
            baseCols.simType,
            baseCols.networkCode,
            baseCols.network,
            baseCols.country,
            baseCols.service,
            baseCols.date,
            baseCols.totalBytes,
            baseCols.roundedBytes,
            baseCols.sessions,
            baseCols.zeroSessions,
          ];
        case "imsi":
          return [
            baseCols.customerID,
            baseCols.imsi,
            baseCols.iccid,
            baseCols.simType,
            baseCols.date,
            baseCols.totalBytes,
            baseCols.roundedBytes,
            baseCols.sessions,
            baseCols.zeroSessions,
          ];
        case "network":
          return [
            baseCols.customerID,
            baseCols.networkCode,
            baseCols.network,
            baseCols.country,
            baseCols.date,
            baseCols.totalBytes,
            baseCols.roundedBytes,
            baseCols.sessions,
            baseCols.zeroSessions,
          ];
        case "service":
          return [
            baseCols.customerID,
            baseCols.service,
            baseCols.date,
            baseCols.totalBytes,
            baseCols.roundedBytes,
            baseCols.sessions,
            baseCols.zeroSessions,
          ];
        default:
          return [];
      }
    } else if (usageType === "sms") {
      switch (groupByField) {
        case "all":
          return [
            baseCols.customerID,
            baseCols.imsi,
            baseCols.iccid,
            baseCols.simType,
            baseCols.networkCode,
            baseCols.network,
            baseCols.country,
            baseCols.service,
            baseCols.date,
            baseCols.source,
            baseCols.destination,
            baseCols.events,
          ];
        case "imsi":
          return [
            baseCols.customerID,
            baseCols.imsi,
            baseCols.iccid,
            baseCols.simType,
            baseCols.date,
            baseCols.events,
          ];
        case "network":
          return [
            baseCols.customerID,
            baseCols.networkCode,
            baseCols.network,
            baseCols.country,
            baseCols.date,
            baseCols.events,
          ];
        case "service":
          return [
            baseCols.customerID,
            baseCols.service,
            baseCols.date,
            baseCols.events,
          ];
        default:
          return [];
      }
    }
  }, [usageType, groupByField]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: tableData,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    usePagination
  );

  return (
    <Card>
      <Card.Body>
        <Table {...getTableProps()} striped hover responsive>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </Table>
        {tableData.length > 0 && (
          <Row>
            <Col md="9">
              <span className="mx-2">
                {t("Page")}{" "}
                <strong>
                  {pageIndex + 1} {t("of")} {pageOptions.length}
                </strong>
              </span>
              <span className="ms-3 me-2">{t("Show")}:</span>
              <Form.Select
                className="d-inline-block w-auto"
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {[5, 10, 20, 30, 40, 50].map((size) => (
                  <option key={size} value={size}>
                    {size}
                  </option>
                ))}
              </Form.Select>

              <span className="ms-3 me-2">{t("Go to page")}:</span>
              <Form.Control
                className="d-inline-block"
                type="number"
                defaultValue={pageIndex + 1}
                min={1}
                max={pageCount}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0;
                  gotoPage(page);
                }}
                style={{ width: "75px" }}
              />
            </Col>
            <Col md="3">
              <Pagination className="float-end">
                <Pagination.First
                  onClick={() => gotoPage(0)}
                  disabled={!canPreviousPage}
                />
                <Pagination.Prev
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                />
                <Pagination.Next
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                />
                <Pagination.Last
                  onClick={() => gotoPage(pageCount - 1)}
                  disabled={!canNextPage}
                />
              </Pagination>
            </Col>
          </Row>
        )}
      </Card.Body>
    </Card>
  );
};

export default UsageTable;
