import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { Card, Dropdown, Col } from "react-bootstrap";

import { MoreHorizontal } from "react-feather";

import { getDistributionForSim } from "../../../../redux/slices/dataSessions";
import { useSelector } from "react-redux";

const SIMBarChart = ({ sim, period }) => {
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const [type, setType] = useState("dataVolume");
  // eslint-disable-next-line no-unused-vars
  const [isLoading, setIsLoading] = useState(false);
  const [barData, setBarData] = useState([]);

  useEffect(() => {
    const fetchSimDistribution = async (id) => {
      setIsLoading(true);
      const response = await getDistributionForSim(id, type, period, sim.imsi);
      setBarData(response);
      setIsLoading(false);
    };

    if (!impersonationUser.id) {
      if (user && user.id) {
        fetchSimDistribution(user.id);
      }
    } else {
      fetchSimDistribution(impersonationUser.id);
    }
  }, [user.id, impersonationUser.id, type, period, sim.imsi, user]);

  function calculateData(item, type) {
    switch (type) {
      case "dataVolume":
        return parseFloat(item.totalBytes / 1024.0 / 1024.0).toFixed(2);
      case "dataSessions":
        return item.dataSessions;
      case "zeroSessions":
        return item.dataSessions;
      case "alerts":
        return item.alerts;
      default:
        return 0;
    }
  }

  const data = {
    labels: barData.map((item) => item.period),
    datasets: [
      {
        label:
          type === "dataVolume"
            ? t("Data Volume")
            : type === "dataSessions"
            ? t("Data Sessions")
            : type === "alerts"
            ? t("Alerts")
            : type === "zeroSessions"
            ? t("Zero Sessions")
            : type === "extraCharge"
            ? t("Extra Charge")
            : null,
        backgroundColor: !impersonationUser.id
          ? user.backgroundColor
          : impersonationUser.backgroundColor,
        borderColor: !impersonationUser.id
          ? user.backgroundColor
          : impersonationUser.backgroundColor,
        hoverBackgroundColor: !impersonationUser.id
          ? user.backgroundColor
          : impersonationUser.backgroundColor,
        hoverBorderColor: !impersonationUser.id
          ? user.backgroundColor
          : impersonationUser.backgroundColor,
        data: barData.map((item) => calculateData(item, type)),
        barPercentage: 0.5,
        categoryPercentage: 0.5,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    cornerRadius: 15,
    legend: {
      display: false,
    },
    scales: {
      yAxes: [
        {
          gridLines: {
            display: false,
          },
          ticks: {
            stepSize: 20,
          },
          stacked: true,
        },
      ],
      xAxes: [
        {
          gridLines: {
            color: "transparent",
          },
          stacked: true,
        },
      ],
    },
  };

  return (
    <Card className="flex-fill w-100">
      <Card.Header>
        <Card.Title className="mb-0">
          {type === "dataVolume"
            ? t("Data Volume")
            : type === "dataSessions"
            ? t("Data Sessions")
            : type === "alerts"
            ? t("Alerts")
            : type === "zeroSessions"
            ? t("Zero Sessions")
            : type === "extraCharge"
            ? t("Extra Charge")
            : null}
        </Card.Title>
        <div className="card-actions float-end">
          <Dropdown align="end">
            <Dropdown.Toggle as="a" bsPrefix="-">
              <MoreHorizontal />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => setType("dataVolume")}>
                {t("Data Volume")}
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setType("alerts")}>
                {t("Alerts")}
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setType("dataSessions")}>
                {t("Data Sessions")}
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setType("zeroSessions")}>
                {t("Zero Sessions")}
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setType("extraCharge")}>
                {t("Extra Charge")}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </Card.Header>
      <Card.Body className="d-flex">
        <Col>
          <div className="align-self-center w-100">
            <div className="chart">
              <Bar data={data} options={options} />
            </div>
          </div>
        </Col>
      </Card.Body>
    </Card>
  );
};

export default SIMBarChart;
