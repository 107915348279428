import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Row,
  Col,
  Collapse,
  Button,
  Table,
  Spinner,
} from "react-bootstrap";
import { useSelector } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import { useRef } from "react";
import { sendSms } from "../../../redux/slices/sims";
import { FaCheckCircle, FaExclamationCircle } from "react-icons/fa";

const SendSmsForm = ({
  errors,
  handleBlur,
  handleChange,
  setFieldTouched,
  values,
  setFieldValue,
  currentStep,
  setCurrentStep,
  touched,
  nextStep,
  formikProps,
  selectedSims,
  status,
}) => {
  useEffect(() => {
    const buf = Buffer.from(values.textContent);
    const base64 = buf.toString("base64");
    setFieldValue("base64Content", base64);
  }, [values.textContent, setFieldValue]);

  useEffect(() => {
    if (values.fromType === "platform") {
      setFieldValue("from", "447937405250");
    } else {
      setFieldValue("from", "");
    }
  }, [values.fromType, setFieldValue]);

  if (currentStep === 1) {
    return (
      <Form>
        <Row>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>From Type</Form.Label>
              <Form.Select
                value={values.fromType}
                name="fromType"
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={Boolean(touched.fromType && errors.fromType)}
              >
                <option value="">Select type</option>
                <option value="system">System</option>
                <option value="platform">Platform</option>
                <option value="free">Free</option>
              </Form.Select>
              {!!touched.fromType && (
                <Form.Control.Feedback type="invalid">
                  {errors.fromType}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>From</Form.Label>
              <Form.Control
                type="text"
                name="from"
                value={values.from}
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={
                  values.fromType === "system" || values.fromType === "platform"
                }
                isInvalid={Boolean(touched.from && errors.from)}
              />
              {!!touched.from && (
                <Form.Control.Feedback type="invalid">
                  {errors.from}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>SMS Type</Form.Label>
              <Form.Select
                value={values.messageType}
                name="messageType"
                onBlur={handleBlur}
                onChange={handleChange}
                isInvalid={Boolean(touched.messageType && errors.messageType)}
              >
                <option value="">Select SMS Type</option>
                <option value="text">Text</option>
                <option value="binary">Binary</option>
                <option value="unicode">Unicode</option>
              </Form.Select>
              {!!touched.messageType && (
                <Form.Control.Feedback type="invalid">
                  {errors.messageType}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>SMS</Form.Label>
              <Form.Control
                as="textarea"
                name="textContent"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.textContent}
                isInvalid={Boolean(touched.textContent && errors.textContent)}
              />
              <Form.Control.Feedback type="invalid">
                {errors.textContent}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Collapse in={values.messageType === "binary"} dimension="height">
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Base64 Encoded</Form.Label>
                <Form.Control
                  as="textarea"
                  name="base64Content"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.base64Content}
                  isInvalid={Boolean(
                    touched.base64Content && errors.base64Content
                  )}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.base64Content}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
        </Collapse>
      </Form>
    );
  } else if (currentStep === 2) {
    return (
      <Table responsive hover>
        <thead>
          <th>IMSI</th>
          <th>ICCID</th>
        </thead>
        <tbody>
          {selectedSims.map((item, index) => {
            return (
              <tr key={index}>
                <td>{item.imsi}</td>
                <td>{item.iccid}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  } else if (currentStep === 3) {
    return (
      <div className="text-center">
        <Spinner
          animation="border"
          variant="primary"
          className="mt-5 mb-3"
          style={{ width: "5rem", height: "5rem" }}
        />
        <h3>Waiting for completion</h3>
      </div>
    );
  } else if (currentStep === 4) {
    if (status === "Success") {
      return (
        <div className="text-center">
          <FaCheckCircle size={120} className="mt-3" color="#4BBF73" />
          <h3 className="mt-3">Order Complete</h3>
        </div>
      );
    } else if (status === "Error") {
      <div className="text-center">
        <FaExclamationCircle size={120} className="mt-3" color="#FF9494" />
        <h3 className="mt-3">Order Error</h3>
        <p>Please contact the administrator.</p>
      </div>;
    }
  }
};

const SmsModal = ({ openSmsModal, setOpenSmsModal, sims }) => {
  const handleClose = () => {
    setCurrentStep(1);
    setOpenSmsModal(false);
  };
  const [selectedSims, setSelectedSims] = useState([]);
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const [currentStep, setCurrentStep] = useState(1);
  const formikRef = useRef(null);
  const [status, setStatus] = useState("");

  useEffect(() => {
    const formattedSelectedSims = sims.map((sim) => ({
      imsi: sim.imsi,
      iccid: sim.iccid,
    }));
    setSelectedSims(formattedSelectedSims);
  }, [sims]);

  return (
    <Modal show={openSmsModal} backdrop="static" centered onHide={handleClose}>
      <Modal.Header>Send SMS</Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={{
            fromType: "",
            from: "",
            messageType: "",
            textContent: "",
            base64Content: "",
          }}
          validationSchema={Yup.object({
            fromType: Yup.string().required("Required"),
            from: Yup.string().max(20).required("Required"),
            messageType: Yup.string().required("Required"),
            textContent: Yup.string().when("messageType", {
              is: (val) => val === "text" || val === "unicode",
              then: Yup.string()
                .max(160, "Max 160 characters")
                .required("Required"),
              otherwise: Yup.string()
                .max(70, "Max 70 characters")
                .required("Required"),
            }),
            base64Content: Yup.string().when("messageType", {
              is: "binary",
              then: Yup.string().required("Required"),
              otherwise: Yup.string().notRequired(),
            }),
          })}
          onSubmit={async (values) => {
            setCurrentStep(currentStep + 1);
            if (!impersonationUser.id) {
              await sendSms(
                user.id,
                selectedSims.map((sim) => sim.imsi),
                values.fromType,
                values.from,
                values.messageType,
                values.textContent,
                values.base64Content
              )
                .then((response) => {
                  setStatus("Success");
                  setCurrentStep(4);
                })
                .catch((error) => {
                  setStatus("Error");
                  setCurrentStep(4);
                });
            } else {
              await sendSms(
                impersonationUser.id,
                selectedSims.map((sim) => sim.imsi),
                values.fromType,
                values.from,
                values.messageType,
                values.textContent,
                values.base64Content
              )
                .then((response) => {
                  setStatus("Success");
                  setCurrentStep(4);
                })
                .catch((error) => {
                  setStatus("Error");
                  setCurrentStep(4);
                });
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            setValues,
            validateForm,
            setFieldValue,
            setFieldTouched,
            formikProps,
          }) => {
            formikRef.current = formikProps;
            const nextStep = (
              currentStep,
              validateForm,
              values,
              setFieldTouched
            ) => {
              if (currentStep === 1) {
                validateForm().then((errors) => {
                  if (Object.keys(errors).length === 0) {
                    setCurrentStep(currentStep + 1);
                  } else {
                    Object.keys(values).forEach((field) => {
                      setFieldTouched(field);
                    });
                  }
                });
              } else if (currentStep === 2) {
                setCurrentStep(currentStep + 1);
              } else if (currentStep === 3) {
                setCurrentStep(currentStep + 1);
              }
            };
            return (
              <>
                <div>
                  <SendSmsForm
                    errors={errors}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    setFieldTouched={setFieldTouched}
                    values={values}
                    setFieldValue={setFieldValue}
                    setCurrentStep={setCurrentStep}
                    touched={touched}
                    currentStep={currentStep}
                    nextStep={nextStep}
                    selectedSims={selectedSims}
                    status={status}
                  />
                </div>
                <Modal.Footer>
                  {currentStep === 1 && (
                    <Button
                      style={{
                        background: "linear-gradient(#007281, #00918F)",
                        border: "none",
                      }}
                      onClick={() =>
                        nextStep(
                          currentStep,
                          validateForm,
                          values,
                          setFieldTouched
                        )
                      }
                    >
                      Next Step
                    </Button>
                  )}
                  {currentStep === 2 && (
                    <Button
                      type="submit"
                      style={{
                        background: "linear-gradient(#007281, #00918F)",
                        border: "none",
                      }}
                      onClick={handleSubmit}
                    >
                      Send
                    </Button>
                  )}
                  {currentStep === 4 && (
                    <Button
                      type="submit"
                      style={{
                        background: "linear-gradient(#007281, #00918F)",
                        border: "none",
                      }}
                      onClick={() => setOpenSmsModal(false)}
                    >
                      Close
                    </Button>
                  )}
                </Modal.Footer>
              </>
            );
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default SmsModal;
