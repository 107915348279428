import React, { useEffect } from "react";
import { getDistributionEmails } from "../../../../redux/slices/distribution";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { Field, Formik } from "formik";
import Select from "react-select";
import {
  Modal,
  ProgressBar,
  Form,
  Button,
  Collapse,
  Table,
  Spinner,
} from "react-bootstrap";
import {
  FaCheckCircle,
  FaExclamationCircle,
  FaMinus,
  FaPlus,
} from "react-icons/fa";
import { useState } from "react";
import { addWatchdog, getSims } from "../../../../redux/slices/sims";

const AddWatchdogForm = ({
  errors,
  handleBlur,
  handleChange,
  setFieldTouched,
  values,
  setFieldValue,
  touched,
  nextStep,
  showWarning2Field,
  setShowWarning2Field,
  showWarning3Field,
  setShowWarning3Field,
  options,
  setOpenAddWatchdogModal,
  typeOfWatchdog,
  setTypeOfWatchdog,
}) => {
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);

  useEffect(() => {
    if (values.watchdogType === "Live Usage") {
      setFieldValue("autoInterval", "1 months");
    }
  }, [setFieldValue, values.watchdogType]);

  useEffect(() => {
    const isServiceGroup =
      values.service &&
      (values.service === "All Data" || values.service.includes("-"));
    if (values.watchdogType === "Rated Usage") {
      if (isServiceGroup) {
        setTypeOfWatchdog("servicegroup");
      } else {
        setTypeOfWatchdog("service");
      }
    } else if (values.watchdogType === "Live Usage") {
      if (isServiceGroup) {
        setTypeOfWatchdog("externalservicegroup");
      } else {
        setTypeOfWatchdog("externalservice");
      }
    } else if (values.watchdogType === "Cost") {
      setTypeOfWatchdog("cost");
    }
  }, [values.watchdogType, values.service, setTypeOfWatchdog]);

  return (
    <Form>
      <Form.Group className="mb-3">
        <Form.Label>Action Type</Form.Label>
        <Form.Select
          id="actionTypeSelect"
          name="actionType"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.actionType}
          isInvalid={touched.actionType && !!errors.actionType}
        >
          <option value="suspend">Suspend</option>
          <option value="none">None</option>
        </Form.Select>
      </Form.Group>
      <Collapse in={values.actionType === "suspend"} dimension="height">
        <Form.Group className="mb-3">
          <Form.Label>Suspension Interval</Form.Label>
          <Form.Select
            id="suspensionIntervalSelect"
            name="suspensionInterval"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.suspensionInterval}
            isInvalid={
              touched.suspensionInterval && !!errors.suspensionInterval
            }
          >
            <option value="">Select interval</option>
            <option value="none">None</option>
            <option value="1 days">1 Day</option>
            <option value="7 days">7 Days</option>
            <option value="1 months">1 Month</option>
          </Form.Select>
        </Form.Group>
      </Collapse>
      <Form.Group className="mb-3">
        <Form.Label>Watch Type</Form.Label>
        <Form.Select
          id="watchdogTypeSelect"
          name="watchdogType"
          onChange={(e) => {
            handleChange(e);
            if (e.target.value === "Live Usage") {
              setFieldValue("autoInterval", "1 months");
            } else {
              setFieldValue("autoInterval", "");
            }
          }}
          onBlur={handleBlur}
          value={values.watchdogType}
          isInvalid={touched.watchdogType && !!errors.watchdogType}
        >
          <option value="">Select Watchdog Type</option>
          <option value="Rated Usage">Rated Usage</option>
          <option value="Live Usage">Live Usage</option>
          <option value="Cost">Cost</option>
        </Form.Select>
      </Form.Group>
      <Collapse
        in={values.watchdogType !== "Live Usage" && values.watchdogType !== ""}
        dimension="height"
      >
        <Form.Group className="mb-3">
          <Form.Label>Auto Interval</Form.Label>
          <Form.Select
            id="suspensionIntervalSelect"
            name="autoInterval"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.autoInterval}
            isInvalid={touched.autoInterval && !!errors.autoInterval}
          >
            <option value="">Select interval</option>
            <option value="none">None</option>
            <option value="1 days">1 Day</option>
            <option value="7 days">7 Days</option>
            <option value="1 months">1 Month</option>
          </Form.Select>
        </Form.Group>
      </Collapse>
      <Form.Group className="mb-3">
        <Form.Label>Amount</Form.Label>
        <Form.Control
          id="amountField"
          name="amount"
          type="text"
          value={values.amount}
          onChange={handleChange}
          onBlur={handleBlur}
          isInvalid={touched.amount && !!errors.amount}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Service</Form.Label>
        <Form.Select
          id="serviceSelect"
          name="service"
          onChange={(e) => {
            handleChange(e);
          }}
          onBlur={handleBlur}
          value={values.service}
          isInvalid={touched.service && !!errors.service}
        >
          <option value="">Select Service</option>
          <option value="All Data">All Data</option>
          <option value="Sms">Sms MO</option>
          <option value="Zone 1">Zone 1</option>
          <option value="Zone 2">Zone 2</option>
          <option value="Zone 3">Zone 3</option>
          <option value="Zone 4">Zone 4</option>
          <option value="Zone 5">Zone 5</option>
          <option value="Zone 6">Zone 6</option>
          <option value="Zone 7">Zone 7</option>
          <option value="Zone 8">Zone 8</option>
          <option value="Zone 9">Zone 9</option>
          <option value="Zone1-2">Zone 1-2</option>
          <option value="Zone1-3">Zone 1-3</option>
          <option value="Zone1-4">Zone 1-4</option>
          <option value="Zone1-5">Zone 1-5</option>
          <option value="Zone1-6">Zone 1-6</option>
          <option value="Zone1-7">Zone 1-7</option>
          <option value="Zone1-8">Zone 1-8</option>
          <option value="Zone1-9">Zone 1-9</option>
          <option value="Zone2-9">Zone 2-9</option>
          <option value="Zone3-9">Zone 3-9</option>
          <option value="Zone4-9">Zone 4-9</option>
          <option value="Zone5-9">Zone 5-9</option>
          <option value="Zone6-9">Zone 6-9</option>
          <option value="Zone7-9">Zone 7-9</option>
          <option value="Zone8-9">Zone 8-9</option>
        </Form.Select>
      </Form.Group>
      <Collapse in={values.service !== ""} dimension="height">
        <Form.Group className="mb-3">
          <Form.Label>Unit Type</Form.Label>
          <Form.Select
            id="unitType"
            name="unitType"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.unitType}
            isInvalid={touched.unitType && !!errors.unitType}
          >
            {values.service === "Sms" && (
              <>
                <option value="">Select unit type</option>
                <option value="unit">Unit</option>
              </>
            )}
            {values.service !== "Sms" && (
              <>
                <option value="">Select unit type</option>
                <option value="byte">Byte</option>
                <option value="KB">KByte</option>
                <option value="MB">MByte</option>
                <option value="GB">GByte</option>
              </>
            )}
          </Form.Select>
        </Form.Group>
      </Collapse>
      <Form.Group className="mb-3">
        <Form.Label>Notification</Form.Label>
        <Form.Select
          id="notificationTypeSelect"
          name="notification"
          value={values.notification}
          onChange={(event) => {
            handleChange(event);
            if (event.target.value === "none" || event.target.value === "") {
              setFieldValue("warning1", "");
              setFieldValue("warning2", "");
              setFieldValue("warning3", "");
              setShowWarning2Field(false);
              setShowWarning3Field(false);
            } else {
              setFieldValue("warning1", "0.0");
              setFieldValue("warning2", "0.0");
              setFieldValue("warning3", "0.0");
            }
          }}
          onBlur={handleBlur}
          isInvalid={touched.notification && !!errors.notification}
        >
          <option value="">Select Notification Type</option>
          <option value="none">None</option>
          <option value="email">Email</option>
        </Form.Select>
      </Form.Group>
      <Collapse
        in={values.notification !== "" && values.notification !== "none"}
        dimension="height"
      >
        <Form.Group className="mb-3">
          <Form.Label>Warning 1</Form.Label>
          <div className="d-flex">
            <Form.Control
              id="warning1Field"
              className="me-2"
              name="warning1"
              type="text"
              value={values.warning1}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={!!errors.warning1 && touched.warning1}
            />
            <Button
              id="addWarningButton"
              className="rounded-pill d-flex justify-content-center align-items-center"
              style={{
                height: "34px",
                width: "34px",
                "--dynamic-bg-color": !impersonationUser.id
                  ? user.backgroundColor
                  : impersonationUser.backgroundColor,
                "--dynamic-font-color": !impersonationUser.id
                  ? user.backgroundColor
                  : impersonationUser.backgroundColor,
                border: "none",
              }}
              variant="primary"
              disabled={errors.warning1}
              onClick={() => setShowWarning2Field(true)}
            >
              <FaPlus />
            </Button>
          </div>
        </Form.Group>
      </Collapse>
      <Collapse in={showWarning2Field} dimension="height">
        <Form.Group className="mb-3">
          <Form.Label>Warning 2</Form.Label>
          <div className="d-flex">
            <Form.Control
              id="warning2Field"
              className="me-2"
              name="warning2"
              type="text"
              value={values.warning2}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={touched.warning2 && !!errors.warning2}
            />
            <Button
              id="addWarningButton"
              className="rounded-pill d-flex justify-content-center align-items-center me-2"
              style={{
                height: "34px",
                width: "34px",
                "--dynamic-bg-color": !impersonationUser.id
                  ? user.backgroundColor
                  : impersonationUser.backgroundColor,
                "--dynamic-font-color": !impersonationUser.id
                  ? user.backgroundColor
                  : impersonationUser.backgroundColor,
                border: "none",
              }}
              variant="primary"
              disabled={errors.warning2}
              onClick={() => setShowWarning3Field(true)}
            >
              <FaPlus />
            </Button>
            <Button
              id="addWarningButton"
              className="rounded-pill d-flex justify-content-center align-items-center"
              style={{
                height: "34px",
                width: "34px",
                "--dynamic-bg-color": !impersonationUser.id
                  ? user.backgroundColor
                  : impersonationUser.backgroundColor,
                "--dynamic-font-color": !impersonationUser.id
                  ? user.backgroundColor
                  : impersonationUser.backgroundColor,
                border: "none",
              }}
              variant="primary"
              onClick={() => {
                if (showWarning3Field) {
                  setShowWarning3Field(false);
                  setFieldValue("warning3", "");
                }
                setShowWarning2Field(false);
                setFieldValue("warning2", "");
              }}
            >
              <FaMinus />
            </Button>
          </div>
        </Form.Group>
      </Collapse>
      <Collapse in={showWarning3Field} dimension="height">
        <Form.Group className="mb-3">
          <Form.Label>Warning 3</Form.Label>
          <div className="d-flex">
            <Form.Control
              id="warning2Field"
              className="me-2"
              name="warning3"
              type="text"
              value={values.warning3}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={!!errors.warning3 && touched.warning3}
            />
            <Button
              id="addWarningButton"
              className="rounded-pill d-flex justify-content-center align-items-center"
              style={{
                height: "34px",
                width: "34px",
                "--dynamic-bg-color": !impersonationUser.id
                  ? user.backgroundColor
                  : impersonationUser.backgroundColor,
                "--dynamic-font-color": !impersonationUser.id
                  ? user.backgroundColor
                  : impersonationUser.backgroundColor,
                border: "none",
              }}
              variant="primary"
              onClick={() => {
                setShowWarning3Field(false);
                setFieldValue("warning3", "");
              }}
            >
              <FaMinus />
            </Button>
          </div>
        </Form.Group>
      </Collapse>
      <Collapse
        in={values.notification !== "" && values.notification !== "none"}
        dimension="height"
      >
        <Form.Group className="mb-3">
          <Form.Label>Distribution List</Form.Label>
          <Field name="distributionList">
            {({ field, form }) => (
              <Select
                name="distributionList"
                isMulti
                options={options}
                value={
                  form.values.distributionList
                    ? form.values.distributionList.map((address) =>
                        options.find((option) => option.value === address)
                      )
                    : []
                }
                onChange={(selectedOptions) => {
                  form.setFieldValue(
                    "distributionList",
                    selectedOptions
                      ? selectedOptions.map((option) => option.value)
                      : []
                  );
                }}
                onBlur={field.onBlur}
                isInvalid={
                  form.touched.distributionList &&
                  !!form.errors.distributionList
                }
              />
            )}
          </Field>
        </Form.Group>
      </Collapse>
      <Modal.Footer>
        <>
          <Button
            variant="secondary"
            onClick={() => setOpenAddWatchdogModal(false)}
            style={{ border: "none" }}
          >
            Cancel
          </Button>
          <Button
            onClick={nextStep}
            style={{
              "--dynamic-bg-color": !impersonationUser.id
                ? user.backgroundColor
                : impersonationUser.backgroundColor,
              "--dynamic-font-color": !impersonationUser.id
                ? user.fontColor
                : impersonationUser.fontColor,
              border: "none",
            }}
          >
            Next
          </Button>
        </>
      </Modal.Footer>
    </Form>
  );
};

const AddWatchdogModal = ({
  openAddWatchdogModal,
  setOpenAddWatchdogModal,
  selectedSims,
  page,
  pageSize,
  filters,
}) => {
  const handleClose = () => setOpenAddWatchdogModal(false);
  const dispatch = useDispatch();
  const { distribution } = useSelector((state) => state.distribution);
  const [distributionList, setDistributionList] = useState([]);
  const [now, setNow] = useState(30);
  const [currentStep, setCurrentStep] = useState(1);
  const [status, setStatus] = useState("");
  const [showWarning2Field, setShowWarning2Field] = useState(false);
  const [showWarning3Field, setShowWarning3Field] = useState(false);
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const [typeOfWatchdog, setTypeOfWatchdog] = useState("");

  const options = distributionList.map((item, index) => ({
    key: index + 1,
    value: item.address,
    label: `${index + 1}. ${item.name} - ${item.address}`,
  }));

  useEffect(() => {
    setDistributionList(distribution);
  }, [distribution]);

  useEffect(() => {
    const fetchDistribution = async () => {
      await dispatch(getDistributionEmails(user.id));
    };
    if (user) {
      fetchDistribution();
    }
  }, [user, dispatch]);

  useEffect(() => {
    if (!openAddWatchdogModal) {
      setNow(30);
      setCurrentStep(1);
    }
  }, [openAddWatchdogModal]);

  const validationSchema = Yup.object().shape({
    watchdogType: Yup.string().required("Required"),
    autoInterval: Yup.string().required("Required"),
    suspensionInterval: Yup.string().when("actionType", {
      is: "suspend",
      then: Yup.string().required("Required"),
      otherwise: Yup.string().notRequired(),
    }),
    amount: Yup.number().required("Required"),
    service: Yup.string().when("watchdogType", {
      is: "Cost",
      then: Yup.string().notRequired(),
      otherwise: Yup.string().required("Required"),
    }),
    unitType: Yup.string().when("watchdogType", {
      is: "Cost",
      then: Yup.string().notRequired(),
      otherwise: Yup.string().required("Required"),
    }),
    warning1: Yup.number().notRequired(),
    warning2: Yup.number().notRequired(),
    warning3: Yup.number().notRequired(),
    distributionList: Yup.array().notRequired(),
  });

  if (selectedSims.length > 0) {
    return (
      <Modal
        show={openAddWatchdogModal}
        onHide={handleClose}
        backdrop="static"
        centered
      >
        <Modal.Header>Add Watchdog</Modal.Header>
        <Modal.Body>
          <div className="progress-tabs">
            <ProgressBar
              className="mb-3"
              striped
              animated
              variant="primary"
              now={now}
            />
          </div>
          <Formik
            formikProps
            initialValues={{
              actionType: "none",
              watchdogType: "",
              autoInterval: "",
              suspensionInterval: "",
              amount: "",
              service: "",
              unitType: "",
              notification: "",
              warning1: "",
              warning2: "",
              warning3: "",
              distributionList: [],
              submit: false,
            }}
            validationSchema={validationSchema}
            onSubmit={async (
              values,
              { setErrors, setSubmitting, nextStep, previousStep }
            ) => {
              try {
                setCurrentStep(3);
                setNow(90);
                await addWatchdog(
                  user.id,
                  selectedSims.map((sim) => sim.imsi),
                  values.actionType,
                  values.amount,
                  values.notification,
                  values.service,
                  values.suspensionInterval,
                  values.unitType,
                  values.warning1,
                  values.warning2,
                  values.warning3,
                  values.autoInterval,
                  typeOfWatchdog,
                  values.distributionList
                )
                  .then(async (response) => {
                    setStatus("Success");
                    setCurrentStep(4);
                    setNow(100);
                  })
                  .catch((error) => {
                    setStatus("Error");
                    setCurrentStep(4);
                    setNow(100);
                  });
              } catch (error) {
                alert(error.message);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              setFieldTouched,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              setValues,
              validateForm,
              setFieldValue,
            }) => {
              const nextStep = () => {
                if (currentStep === 1) {
                  validateForm().then((errors) => {
                    if (Object.keys(errors).length === 0) {
                      setCurrentStep(currentStep + 1);
                      setNow(60);
                    } else {
                      Object.keys(values).forEach((field) => {
                        setFieldTouched(field);
                      });
                    }
                  });
                } else if (currentStep === 2) {
                  setCurrentStep(currentStep + 1);
                  setNow(90);
                } else if (currentStep === 3) {
                  setCurrentStep(currentStep + 1);
                  setNow(100);
                }
              };

              const previousStep = () => {
                if (currentStep > 1) {
                  setNow(30);
                  setCurrentStep(currentStep - 1);
                }
              };
              if (currentStep === 1) {
                return (
                  <AddWatchdogForm
                    errors={errors}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    setFieldTouched={setFieldTouched}
                    values={values}
                    setFieldValue={setFieldValue}
                    currentStep={currentStep}
                    touched={touched}
                    nextStep={nextStep}
                    showWarning2Field={showWarning2Field}
                    setShowWarning2Field={setShowWarning2Field}
                    showWarning3Field={showWarning3Field}
                    setShowWarning3Field={setShowWarning3Field}
                    options={options}
                    setOpenAddWatchdogModal={setOpenAddWatchdogModal}
                    typeOfWatchdog={typeOfWatchdog}
                    setTypeOfWatchdog={setTypeOfWatchdog}
                  />
                );
              } else if (currentStep === 2) {
                return (
                  <>
                    <Table>
                      <thead>
                        <th>IMSI</th>
                        <th>ICCID</th>
                      </thead>
                      <tbody>
                        {selectedSims.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>{item.imsi}</td>
                              <td>{item.iccid}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                    <Modal.Footer>
                      <>
                        <Button
                          variant="secondary"
                          onClick={previousStep}
                          style={{
                            "--dynamic-bg-color": !impersonationUser.id
                              ? user.backgroundColor
                              : impersonationUser.backgroundColor,
                            "--dynamic-font-color": !impersonationUser.id
                              ? user.backgroundColor
                              : impersonationUser.backgroundColor,
                            border: "none",
                          }}
                        >
                          Previous
                        </Button>
                        <Button
                          variant="primary"
                          onClick={handleSubmit}
                          style={{
                            "--dynamic-bg-color": !impersonationUser.id
                              ? user.backgroundColor
                              : impersonationUser.backgroundColor,
                            "--dynamic-font-color": !impersonationUser.id
                              ? user.backgroundColor
                              : impersonationUser.backgroundColor,
                            border: "none",
                          }}
                        >
                          Add Watchdog
                        </Button>
                      </>
                    </Modal.Footer>
                  </>
                );
              } else if (currentStep === 3) {
                return (
                  <div className="text-center">
                    <Spinner
                      animation="border"
                      variant="primary"
                      className="mt-5 mb-3"
                      style={{ width: "5rem", height: "5rem" }}
                    />
                    <h3>Waiting for completion</h3>
                  </div>
                );
              } else if (currentStep === 4 && status === "Success") {
                return (
                  <>
                    <div className="text-center">
                      <FaCheckCircle
                        size={120}
                        className="mt-3"
                        color="#4BBF73"
                      />
                      <h3 className="mt-3">Order Complete</h3>
                    </div>
                    <Modal.Footer>
                      <Button
                        variant="primary"
                        onClick={async () => {
                          setOpenAddWatchdogModal(false);
                          setValues("");
                          await dispatch(
                            getSims(user.id, page + 1, pageSize, filters)
                          );
                        }}
                        style={{
                          "--dynamic-bg-color": !impersonationUser.id
                            ? user.backgroundColor
                            : impersonationUser.backgroundColor,
                          "--dynamic-font-color": !impersonationUser.id
                            ? user.backgroundColor
                            : impersonationUser.backgroundColor,
                          border: "none",
                        }}
                      >
                        Close
                      </Button>
                    </Modal.Footer>
                  </>
                );
              } else if (currentStep === 4 && status === "Error") {
                return (
                  <>
                    <div className="text-center">
                      <FaExclamationCircle
                        size={120}
                        className="mt-3"
                        color="#FF9494"
                      />
                      <h3 className="mt-3">Order Error</h3>
                      <p>Please contact the administrator.</p>
                    </div>
                    <Modal.Footer>
                      <Button
                        variant="primary"
                        onClick={async () => {
                          setOpenAddWatchdogModal(false);
                          setValues("");
                          await dispatch(
                            getSims(user.id, page + 1, pageSize, filters)
                          );
                        }}
                        style={{
                          "--dynamic-bg-color": !impersonationUser.id
                            ? user.backgroundColor
                            : impersonationUser.backgroundColor,
                          "--dynamic-font-color": !impersonationUser.id
                            ? user.backgroundColor
                            : impersonationUser.backgroundColor,
                          border: "none",
                        }}
                      >
                        Close
                      </Button>
                    </Modal.Footer>
                  </>
                );
              }
            }}
          </Formik>
        </Modal.Body>
      </Modal>
    );
  } else {
    return (
      <Modal show={openAddWatchdogModal}>
        <Modal.Header>Add Watchdog</Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <Spinner animation="border" variant="primary" className="me-2" />
          </div>
        </Modal.Body>
      </Modal>
    );
  }
};

export default AddWatchdogModal;
