import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import DefaultColumnFilter from "../../../utils/DefaultColumnFilter";
import {
  useTable,
  useRowSelect,
  usePagination,
  useSortBy,
  useFilters,
  useGlobalFilter,
} from "react-table";
import { getSimCellInfo } from "../../../redux/slices/sims.js";
import IndeterminateCheckbox from "../../../utils/IndeterminateCheckbox";
import FiltersCard from "./FiltersCard";
import DataCard from "./DataCard";
import StatisticsPanel from "./StatisticsCard";

const RowSelectingTable = ({
  columns: simColumns,
  data,
  shownSim,
  setShownSim,
  setOpen,
  setSingleIsShown,
  pageNumber,
  setPageNumber,
  pageRecords,
  setPageRecords,
  numberOfRecords,
  setCellInfo,
  filters,
  setFilters,
  isLoading,
  setIsLoading,
  selectedRows,
  setSelectedRows,
  customFields,
}) => {
  const { t } = useTranslation();
  const selectedRowsRef = useRef(selectedRows);

  const selectedRowIds = React.useMemo(() => {
    const rowIds = selectedRows.reduce(
      (prev, row) => ({ ...prev, [row.id]: true }),
      {}
    );
    return rowIds;
  }, [selectedRows]);

  const filterTypes = React.useMemo(
    () => ({
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    prepareRow,
    state: { pageIndex },
  } = useTable(
    {
      columns: simColumns,
      data,
      initialState: { pageIndex: 0, pageSize: pageRecords },
      defaultColumn,
      filterTypes,
      filters,
      selectedRowIds,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: "select",
          sortable: false,
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox
                {...getToggleAllRowsSelectedProps()}
                onClick={(e) => {
                  e.stopPropagation();
                  if (e.target.checked) {
                    const newSelectedRows = [];
                    page.forEach((row) => {
                      // Only add rows that aren't already selected
                      if (
                        !selectedRowsRef.current.find(
                          (r) => r.id === row.original.id
                        )
                      ) {
                        newSelectedRows.push(row.original);
                      }
                    });
                    setSelectedRows((prev) => [...prev, ...newSelectedRows]);
                  } else {
                    // Remove currently viewed rows from selected rows
                    setSelectedRows((prev) =>
                      prev.filter(
                        (r) => !page.some((row) => row.original.id === r.id)
                      )
                    );
                  }
                }}
              />
            </div>
          ),
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox
                {...row.getToggleRowSelectedProps()}
                onClick={(e) => {
                  e.stopPropagation();
                  if (e.target.checked) {
                    const newSelectedRows = [];
                    if (
                      !selectedRowsRef.current.find(
                        (r) => r.id === row.original.id
                      )
                    ) {
                      newSelectedRows.push(row.original);
                    }
                    setSelectedRows((prev) => [...prev, ...newSelectedRows]);
                  } else {
                    setSelectedRows((prev) =>
                      prev.filter((r) => r.id !== row.original.id)
                    );
                  }
                }}
              />
            </div>
          ),
        },
        ...columns,
      ]);
    }
  );

  useEffect(() => {
    selectedRowsRef.current = selectedRows;
  }, [selectedRows]);

  useEffect(() => {
    page.forEach((row) => {
      const exists = selectedRows.find(
        (selectedRow) => selectedRow.id === row.original.id
      );
      row.toggleRowSelected(!!exists);
    });
  }, [page, selectedRows]);

  const clearSelectedRows = () => {
    // Clear the selectedFlatRows array
    setSelectedRows([]);
  };

  return (
    <>
      <StatisticsPanel selectedRows={selectedRows} />
      <FiltersCard
        headerGroups={headerGroups}
        filters={filters}
        setFilters={setFilters}
        page={pageNumber}
        pageSize={pageRecords}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        customFields={customFields}
      />
      <DataCard
        getTableProps={getTableProps}
        getTableBodyProps={getTableBodyProps}
        headerGroups={headerGroups}
        page={page}
        prepareRow={prepareRow}
        pageNumber={pageNumber}
        pageRecords={pageRecords}
        numberOfRecords={numberOfRecords}
        setShownSim={setShownSim}
        getSimCellInfo={getSimCellInfo}
        setCellInfo={setCellInfo}
        setOpen={setOpen}
        setSingleIsShown={setSingleIsShown}
        t={t}
        pageIndex={pageIndex}
        setPageRecords={setPageRecords}
        setPageNumber={setPageNumber}
        gotoPage={gotoPage}
        nextPage={nextPage}
        previousPage={previousPage}
        setPageSize={setPageSize}
        selectedRows={selectedRows}
        clearSelectedRows={clearSelectedRows}
        filters={filters}
        setIsLoading={setIsLoading}
      />
    </>
  );
};

export default RowSelectingTable;
