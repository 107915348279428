import React from "react";
import { Pie } from "react-chartjs-2";

import { Card, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useState } from "react";
import { getSimInventory } from "../../redux/slices/sims";
import { useSelector, useDispatch } from "react-redux";
import SimsByStatusModal from "../../pages/pages/dashboard/modals/SimsByStatusModal";

const SimInventoryPieChart = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [simInventory, setSimInventory] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const inventory = useSelector((state) => state.sims.inventory);
  const [showSimsByStatusModal, setShowSimsByStatusModal] = useState(false);
  const [status, setStatus] = useState("Active");

  useEffect(() => {
    const fetchSimInventory = async (id) => {
      if (!id) {
        return;
      }
      await dispatch(getSimInventory(id));
    };

    if (!impersonationUser.id) {
      fetchSimInventory(user.id);
    } else {
      fetchSimInventory(impersonationUser.id);
    }
  }, [user, impersonationUser.id, dispatch]);

  useEffect(() => {
    setIsLoading(true);
    setSimInventory(inventory);
    setIsLoading(false);
  }, [inventory]);

  const data = {
    labels: simInventory.map((item) => item.status),
    datasets: [
      {
        data: simInventory.map((item) => item.count),
        backgroundColor: simInventory.map((item) => {
          let color = "";
          if (item.status === "Active") {
            color = "#00FF00";
          } else if (item.status === "Productive") {
            color = "#36A2EB";
          } else if (item.status === "OnStock") {
            color = "#808080";
          } else if (item.status === "Deleted") {
            color = "#FF0000";
          } else if (item.status === "Suspended") {
            color = "#FFCE56";
          } else if (item.status === "TestProductive") {
            color = "#2600ff";
          } else if (item.status === "AutoSuspended") {
            color = "#e1ff00";
          }
          return color;
        }),
        borderColor: "transparent",
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    legend: {
      display: true,
      position: "right",
    },
    onClick: (e, element) => {
      if (element.length > 0) {
        const newStatus = element[0]._model.label;
        setStatus(newStatus);
        setShowSimsByStatusModal(true);
      }
    },
  };

  console.log(status);

  if (isLoading || simInventory.length === 0) {
    return (
      <Card className="w-100">
        <Card.Header>
          <Card.Title>{t("SIM Inventory")}</Card.Title>
        </Card.Header>
        <Card.Body className="d-flex align-items-center justify-content-center">
          <div className="text-center">
            <Spinner animation="border" variant="primary" className="me-2" />
          </div>
        </Card.Body>
      </Card>
    );
  } else {
    return (
      <Card id="pieChart">
        <Card.Header>
          <Card.Title>{t("SIM Inventory")}</Card.Title>
        </Card.Header>
        <Card.Body>
          <div className="chart chart-sm">
            <Pie data={data} options={options} />
          </div>
          <SimsByStatusModal
            showSimsByStatusModal={showSimsByStatusModal}
            setShowSimsByStatusModal={setShowSimsByStatusModal}
            status={status}
          />
        </Card.Body>
      </Card>
    );
  }
};

export default SimInventoryPieChart;
