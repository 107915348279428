import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOrders } from "../../../redux/slices/orders";
import { Card, Button, Container, Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import Order from "./Order";
import Loader from "../../../components/spinners/Loader";
import OrderModal from "./modals/OrderModal";

const Lane = ({ name, children, showOrderModal, setShowOrderModal, user }) => {
  return (
    <Card>
      <Card.Header>
        <Card.Title>{name}</Card.Title>
      </Card.Header>
      <Card.Body>
        <div>{children && children.length > 0 ? children : "No Orders"}</div>
        <div className="d-grid">
          {children && children.length > 0 && (
            <Button
              style={{
                "--dynamic-bg-color": user.backgroundColor,
                border: "none",
              }}
              onClick={() => setShowOrderModal(true)}
            >
              Show all orders
            </Button>
          )}
        </div>
      </Card.Body>
    </Card>
  );
};

const Orders = () => {
  const { orders } = useSelector((state) => state.orders);
  const [openOrders, setOpenOrders] = useState([]);
  const [parkedOrders, setParkedOrders] = useState([]);
  const [finishedOrders, setFinishedOrders] = useState([]);
  const [errorOrders, setErrorOrders] = useState([]);
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const [isLoading, setIsLoading] = useState(false);
  const [showOpenOrderModal, setShowOpenOrderModal] = useState(false);
  const [showErrorOrderModal, setShowErrorOrderModal] = useState(false);
  const [showFinishedOrderModal, setShowFinishedOrderModal] = useState(false);
  const [showParkedOrderModal, setShowParkedOrderModal] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchOrders = async (id) => {
      setIsLoading(true);
      await dispatch(getOrders(id));
      setIsLoading(false);
    };

    if (!impersonationUser) {
      fetchOrders(user.id);
    } else {
      fetchOrders(impersonationUser.id);
    }
  }, [dispatch, user, impersonationUser]);

  useEffect(() => {
    const open = orders.filter((order) => order.status === "Open");
    const parked = orders.filter((order) => order.status === "Parked");
    const finished = orders.filter((order) => order.status === "Done");
    const error = orders.filter((order) => order.status === "Error");
    setOpenOrders(open);
    setParkedOrders(parked);
    setFinishedOrders(finished);
    setErrorOrders(error);
  }, [orders]);

  if (!isLoading) {
    return (
      <React.Fragment>
        <Helmet title="Orders" />
        <Container fluid className="p-0">
          <h1 className="h3 mb-3">Orders</h1>
          <Row>
            <Col lg="6">
              <Lane
                name="Open"
                showOrderModal={showOpenOrderModal}
                setShowOrderModal={setShowOpenOrderModal}
                user={impersonationUser.id ? impersonationUser : user}
              >
                {openOrders.map((order) => (
                  <Order key={order.id} order={order} />
                ))}
              </Lane>
              <OrderModal
                showOrderModal={showOpenOrderModal}
                setShowOrderModal={setShowOpenOrderModal}
                orders={openOrders}
              />
            </Col>
            <Col lg="6">
              <Lane
                name="Parked"
                showOrderModal={showParkedOrderModal}
                setShowOrderModal={setShowParkedOrderModal}
                user={impersonationUser.id ? impersonationUser : user}
              >
                {parkedOrders.map((order) => (
                  <Order key={order.id} order={order} />
                ))}
              </Lane>
              <OrderModal
                showOrderModal={showParkedOrderModal}
                setShowOrderModal={setShowParkedOrderModal}
                orders={parkedOrders}
              />
            </Col>
            <Col lg="6">
              <Lane
                name="Finished"
                showOrderModal={showFinishedOrderModal}
                setShowOrderModal={setShowFinishedOrderModal}
                user={impersonationUser.id ? impersonationUser : user}
              >
                {finishedOrders.slice(0, 5).map((order) => (
                  <Order key={order.id} order={order} />
                ))}
              </Lane>
              <OrderModal
                showOrderModal={showFinishedOrderModal}
                setShowOrderModal={setShowFinishedOrderModal}
                orders={finishedOrders}
              />
            </Col>
            <Col lg="6">
              <Lane
                name="Error"
                showOrderModal={showErrorOrderModal}
                setShowOrderModal={setShowErrorOrderModal}
                user={impersonationUser.id ? impersonationUser : user}
              >
                {errorOrders.slice(0, 5).map((order) => (
                  <Order key={order.id} order={order} />
                ))}
              </Lane>
              <OrderModal
                showOrderModal={showErrorOrderModal}
                setShowOrderModal={setShowErrorOrderModal}
                orders={errorOrders}
              />
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  } else {
    return <Loader />;
  }
};

export default Orders;
