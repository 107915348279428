import React from "react";
import { useTranslation } from "react-i18next";

import { Button, ButtonGroup, Col, Row } from "react-bootstrap";

import { RefreshCw } from "react-feather";
import { useSelector } from "react-redux";

const Header = ({ type, setType }) => {
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);

  return (
    <>
      <Row className="mb-2 mb-xl-3">
        <Col xs="auto" className="d-none d-sm-block">
          <h3>{t("Overview")}</h3>
        </Col>

        <Col xs="auto" className="ms-auto text-end mt-n1">
          <ButtonGroup className="shadow-sm me-2">
            <Button
              key={1}
              onClick={() => setType("day")}
              className={"day" === type ? "active" : null}
              style={{
                "--dynamic-bg-color": impersonationUser.id
                  ? impersonationUser.backgroundColor
                  : user.backgroundColor,
                border: "none",
              }}
            >
              {t("Daily")}
            </Button>
            <Button
              key={2}
              onClick={() => setType("month")}
              className={"month" === type ? "active" : null}
              style={{
                "--dynamic-bg-color": impersonationUser.id
                  ? impersonationUser.backgroundColor
                  : user.backgroundColor,
                border: "none",
              }}
            >
              {t("Monthly")}
            </Button>
            <Button
              key={3}
              onClick={() => setType("year")}
              className={"year" === type ? "active" : null}
              style={{
                "--dynamic-bg-color": impersonationUser.id
                  ? impersonationUser.backgroundColor
                  : user.backgroundColor,
                border: "none",
              }}
            >
              {t("Yearly")}
            </Button>
          </ButtonGroup>
          <Button
            className="shadow-sm border border-0"
            style={{
              "--dynamic-bg-color": impersonationUser.id
                ? impersonationUser.backgroundColor
                : user.backgroundColor,
              border: "none",
            }}
          >
            <RefreshCw className="feather" />
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default Header;
