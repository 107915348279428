import { Collapse } from "react-bootstrap";
import React from "react";
import { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet-async";

import ReportsFilter from "./ReportsFilter.js";
import UsageTable from "./tables/UsageTable.js";
import { InvoiceTable, SimTable, IPAllocationTable } from "./ReportsTable.js";
import { useSelector } from "react-redux";

const ReportsPage = () => {
  const [tableIsExpanded, setTableIsExpanded] = useState(false);
  const [tableIsShown, setTableIsShown] = useState(false);
  const [report, setReport] = useState("");
  const [usageType, setUsageType] = useState("");
  const [reportData, setReportData] = useState([]);
  const [groupByField, setGroupByField] = useState("");
  const [groupByPeriod, setGroupByPeriod] = useState("");
  const { user } = useSelector((state) => state.user);

  return (
    <React.Fragment>
      <Helmet title="Reports">
        <link rel="icon" href={`data:image/x-icon;base64,${user.favicon}`} />
      </Helmet>
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">Reports</h1>
        <Row>
          <Col md="12" xl="3">
            <ReportsFilter
              tableIsExpanded={tableIsExpanded}
              tableIsShown={tableIsShown}
              setTableIsExpanded={setTableIsExpanded}
              setTableIsShown={setTableIsShown}
              report={report}
              setReport={setReport}
              usageType={usageType}
              setUsageType={setUsageType}
              groupByField={groupByField}
              setGroupByField={setGroupByField}
              reportData={reportData}
              setReportData={setReportData}
              setGroupByPeriod={setGroupByPeriod}
            />
          </Col>
          <Col md="12" xl="9">
            {/* <Row>
              <Col>
                <TimeoutReportsTable columns={tableColumns} data={tableData} />
              </Col>
            </Row> */}
            <Collapse in={tableIsShown} dimension="height">
              <Row>
                <Col>
                  {report === "usage" ? (
                    <UsageTable
                      usageType={usageType}
                      groupByField={groupByField}
                      data={reportData}
                      groupByPeriod={groupByPeriod}
                    />
                  ) : report === "invoice_detail" ||
                    report === "invoice_overview" ? (
                    <InvoiceTable />
                  ) : report === "sim_list" ? (
                    <SimTable />
                  ) : report === "ip_allocation" ? (
                    <IPAllocationTable />
                  ) : null}
                </Col>
              </Row>
            </Collapse>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default ReportsPage;
