import React from "react";
import { Card, Form, Row, Col, Button } from "react-bootstrap";
import { useSelector } from "react-redux";

const ContactInfo = ({ email, landline, mobile }) => {
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  return (
    <Card>
      <Card.Header>
        <Card.Title className="mb-0">Contact info</Card.Title>
      </Card.Header>
      <Card.Body>
        <Form>
          <Row className="mb-3">
            <Col md={4}>
              <Form.Group>
                <Form.Label htmlFor="email">Email</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Email"
                  value={email}
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group>
                <Form.Label htmlFor="landline">Landline Number</Form.Label>
                <Form.Control
                  type="text"
                  name="landline"
                  id="landline"
                  value={landline}
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group>
                <Form.Label htmlFor="mobile">Mobile Number</Form.Label>
                <Form.Control
                  type="text"
                  name="mobile"
                  id="mobile"
                  value={mobile}
                />
              </Form.Group>
            </Col>
          </Row>
          <Button
            style={{
              "--dynamic-bg-color": !impersonationUser.id
                ? user.backgroundColor
                : impersonationUser.backgroundColor,
              border: "none",
            }}
          >
            Save changes
          </Button>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default ContactInfo;
