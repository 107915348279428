import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Modal,
  Button,
  Form,
  Spinner,
  ProgressBar,
  Table,
} from "react-bootstrap";
import { getSims, terminateSims } from "../../../../redux/slices/sims";
import { FaCheckCircle, FaExclamationCircle } from "react-icons/fa";

const TerminateSimModal = ({
  sims,
  openTerminateModal,
  setOpenTerminateModal,
  page,
  pageSize,
  filters,
}) => {
  const dispatch = useDispatch();
  const [selectedTerminateOption, setSelectedTerminateOption] = useState("");
  const [status, setStatus] = useState("");
  const [now, setNow] = useState(25);
  const handleClose = () => {
    setOpenTerminateModal(false);
  };

  const [selectedSims, setSelectedSims] = useState([]);
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const handleOptionChange = (event) => {
    setSelectedTerminateOption(event.target.value);
  };

  const label = useMemo(() => computeLabel(now), [now]);

  function computeLabel(num) {
    if (num === 25) {
      return "Terminate";
    } else if (num === 50) {
      return "Confirm";
    } else if (num === 75) {
      return "Order";
    } else if (num === 100) {
      return "Complete";
    }
  }

  useEffect(() => {
    const formattedSelectedSims = sims.map((sim) => ({
      imsi: sim.imsi,
      iccid: sim.iccid,
    }));
    setSelectedSims(formattedSelectedSims);
  }, [sims]);

  useEffect(() => {
    if (!openTerminateModal) {
      // If the modal is not open
      setNow(25);
      setSelectedTerminateOption("");
    }
  }, [openTerminateModal]);

  if (selectedSims.length > 0) {
    return (
      <Modal show={openTerminateModal} onHide={handleClose} backdrop="static">
        <Modal.Header>Terminate SIMs</Modal.Header>
        <Modal.Body>
          <div className="progress-tabs">
            <ProgressBar
              className="mb-3"
              striped
              animated
              variant="primary"
              now={now}
              label={label}
            />
          </div>
          {now === 25 && (
            <>
              <h3>SIM post status</h3>
              <p>
                After the SIMs are terminated choose what should happen to them
              </p>
              <Form.Group className="mt-3 mb-2">
                <Form.Check
                  inline
                  label="On Stock"
                  type="radio"
                  checked={selectedTerminateOption === "OnStock"}
                  value="OnStock"
                  onChange={handleOptionChange}
                />
                <Form.Check
                  inline
                  label="Terminated"
                  type="radio"
                  checked={selectedTerminateOption === "Deleted"}
                  value="Deleted"
                  onChange={handleOptionChange}
                />
                <Form.Check
                  inline
                  label="On Stock (Keep properties)"
                  type="radio"
                  checked={selectedTerminateOption === "OnStockKeepProperties"}
                  value="OnStockKeepProperties"
                  onChange={handleOptionChange}
                />
              </Form.Group>
            </>
          )}
          {now === 50 && (
            <Table>
              <thead>
                <tr>
                  <th>IMSI</th>
                  <th>ICCID</th>
                </tr>
              </thead>
              <tbody>
                {selectedSims.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item.imsi}</td>
                      <td>{item.iccid}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          )}

          {now === 75 && (
            <div className="text-center">
              <Spinner
                animation="border"
                variant="primary"
                className="mt-5 mb-3"
                style={{ width: "5rem", height: "5rem" }}
              />
              <h3>Waiting for completion</h3>
            </div>
          )}
          {now === 100 && status === "Success" && (
            <div className="text-center">
              <FaCheckCircle size={120} className="mt-3" color="#4BBF73" />
              <h3 className="mt-3">Order Complete</h3>
            </div>
          )}
          {now === 100 && status === "Error" && (
            <div className="text-center">
              <FaExclamationCircle
                size={120}
                className="mt-3"
                color="#FF9494"
              />
              <h3 className="mt-3">Order Error</h3>
              <p>Please contact the administrator.</p>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          {now === 25 && (
            <>
              <Button
                onClick={() => {
                  setOpenTerminateModal(false);
                }}
                style={{
                  "--dynamic-bg-color": !impersonationUser.id
                    ? user.backgroundColor
                    : impersonationUser.backgroundColor,
                  "--dynamic-font-color": !impersonationUser.id
                    ? user.backgroundColor
                    : impersonationUser.backgroundColor,
                  border: "none",
                }}
              >
                Cancel
              </Button>
              <Button
                disabled={selectedTerminateOption === ""}
                onClick={async () => {
                  setNow(50);
                }}
                style={{
                  "--dynamic-bg-color": !impersonationUser.id
                    ? user.backgroundColor
                    : impersonationUser.backgroundColor,
                  "--dynamic-font-color": !impersonationUser.id
                    ? user.backgroundColor
                    : impersonationUser.backgroundColor,
                  border: "none",
                }}
              >
                Next
              </Button>
            </>
          )}
          {now === 50 && (
            <>
              <Button
                onClick={() => setOpenTerminateModal(false)}
                style={{
                  "--dynamic-bg-color": !impersonationUser.id
                    ? user.backgroundColor
                    : impersonationUser.backgroundColor,
                  "--dynamic-font-color": !impersonationUser.id
                    ? user.backgroundColor
                    : impersonationUser.backgroundColor,
                  border: "none",
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={async () => {
                  setNow(75);
                  if (!impersonationUser.id) {
                    await terminateSims(
                      selectedSims.map((sim) => sim.imsi),
                      selectedTerminateOption,
                      user.id
                    )
                      .then(async (response) => {
                        setStatus("Success");
                      })
                      .catch((error) => setStatus("Error"));
                  } else {
                    await terminateSims(
                      selectedSims.map((sim) => sim.imsi),
                      selectedTerminateOption,
                      impersonationUser.id
                    )
                      .then(async (response) => {
                        setStatus("Success");
                      })
                      .catch((error) => setStatus("Error"));
                  }

                  setNow(100);
                }}
                style={{
                  height: "34px",
                  width: "34px",
                  "--dynamic-bg-color": !impersonationUser.id
                    ? user.backgroundColor
                    : impersonationUser.backgroundColor,
                  "--dynamic-font-color": !impersonationUser.id
                    ? user.backgroundColor
                    : impersonationUser.backgroundColor,
                  border: "none",
                }}
              >
                Terminate
              </Button>
            </>
          )}
          {now === 100 && (
            <Button
              onClick={async () => {
                setOpenTerminateModal(false);
                await dispatch(getSims(user.id, page + 1, pageSize, filters));
              }}
              style={{
                "--dynamic-bg-color": !impersonationUser.id
                  ? user.backgroundColor
                  : impersonationUser.backgroundColor,
                "--dynamic-font-color": !impersonationUser.id
                  ? user.backgroundColor
                  : impersonationUser.backgroundColor,
                border: "none",
              }}
            >
              Close
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    );
  } else {
    return (
      <Modal show={openTerminateModal}>
        <Modal.Header>Suspend SIMs</Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <Spinner animation="border" variant="primary" className="me-2" />
          </div>
        </Modal.Body>
      </Modal>
    );
  }
};

export default TerminateSimModal;
