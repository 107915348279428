import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { retrieveSms } from "../../../../redux/slices/sims";
import { Button, Modal, Table } from "react-bootstrap";
import { useTable, useExpanded } from "react-table";
import Loader from "../../../../components/spinners/Loader";
import { MinusCircle, PlusCircle } from "react-feather";

const SmsUsageModal = ({ imsi, showSmsModal, setShowSmsModal }) => {
  const handleClose = () => setShowSmsModal(false);
  const [smsUsage, setSmsUsage] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  useEffect(() => {
    const fetchSmsUsage = async (id) => {
      const response = await retrieveSms(id, imsi);
      setSmsUsage(response);
    };

    setIsLoading(true);
    if (!impersonationUser.id) {
      if (user && user.id) {
        fetchSmsUsage(user.id);
      }
    } else {
      fetchSmsUsage(impersonationUser.id);
    }
    setIsLoading(false);
  }, [imsi, user.id, impersonationUser.id, user]);
  const columns = React.useMemo(
    () => [
      {
        id: "expander",
        Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
          <span {...getToggleAllRowsExpandedProps()}>
            {isAllRowsExpanded ? (
              <MinusCircle className="feather" />
            ) : (
              <PlusCircle className="feather" />
            )}
          </span>
        ),
        Cell: ({ row }) => (
          <span
            {...row.getToggleRowExpandedProps({
              style: {
                paddingLeft: `${row.depth * 2}rem`,
              },
            })}
          >
            {row.isExpanded ? (
              <MinusCircle className="feather" />
            ) : (
              <PlusCircle className="feather" />
            )}
          </span>
        ),
      },
      // Your column definitions here
      { Header: "Direction", accessor: "direction" },
      { Header: "Date", accessor: "insertedDate" },
      { Header: "Sender", accessor: "sender" },
      { Header: "Message", accessor: "message" },
      { Header: "Status", accessor: "status" },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data: smsUsage,
      },
      useExpanded
    );

  return (
    <Modal
      backdrop="static"
      size="xl"
      centered
      show={showSmsModal}
      onHide={handleClose}
    >
      <Modal.Header closeButton>SMS Usage</Modal.Header>
      <Modal.Body style={{ maxHeight: "500px", overflowY: "auto" }}>
        {isLoading && <Loader />}
        {!isLoading && smsUsage.length === 0 && <p>No data</p>}
        {!isLoading && smsUsage.length > 0 && (
          <Table {...getTableProps()} responsive="true">
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <React.Fragment>
                    {/* Your original row */}
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      ))}
                    </tr>
                    {/* Expanded row content */}
                    {row.isExpanded ? (
                      <tr>
                        <td
                          colSpan={columns.length}
                          style={{ padding: "1rem", background: "#f7f7f7" }}
                        >
                          <div
                            style={{
                              display: "grid",
                              gridTemplateColumns: "1fr 1fr",
                              gap: "20px",
                            }}
                          >
                            <div>
                              <strong>Message Reference:</strong>{" "}
                              {row.original.messageReference}
                              <br />
                              <strong>Sender:</strong> {row.original.sender}
                              <br />
                              <strong>Date Inserted:</strong>{" "}
                              {row.original.insertedDate}
                            </div>
                            <div>
                              <strong>Message:</strong> {row.original.message}
                              <br />
                              <strong>Status:</strong> {row.original.status}{" "}
                              <br />
                              <strong>SMS Type:</strong> {row.original.smsType}
                            </div>
                          </div>
                        </td>
                      </tr>
                    ) : null}
                  </React.Fragment>
                );
              })}
            </tbody>
          </Table>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          style={{
            "--dynamic-bg-color": !impersonationUser.id
              ? user.backgroundColor
              : impersonationUser.backgroundColor,
            "--dynamic-font-color": !impersonationUser.id
              ? user.backgroundColor
              : impersonationUser.backgroundColor,
            border: "none",
          }}
          onClick={handleClose}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SmsUsageModal;
