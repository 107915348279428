import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: {
    id: "",
    fullName: "",
    address: "",
    city: "",
    country: "",
    postcode: "",
    telephoneNumber: "",
    mobileNumber: "",
    email: "",
    occupation: "",
    company: "",
    tin: "",
    username: "",
    privilege: "",
    photo: "",
    activities: null,
    is2FAEnabled: "",
    favicon: "",
    whitelabelImage: "",
    sidebarPosition: "",
    backgroundColor: "",
    fontColor: "",
  },
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      const data = {
        id: String(action.payload.userData.id),
        fullName: String(action.payload.userData.fullName),
        address: String(action.payload.userData.address),
        city: String(action.payload.userData.city),
        country: String(action.payload.userData.country),
        postcode: String(action.payload.userData.postcode),
        telephoneNumber: String(action.payload.userData.telephoneNumber),
        mobileNumber: String(action.payload.userData.mobileNumber),
        email: String(action.payload.userData.email),
        occupation: String(action.payload.userData.occupation),
        company: String(action.payload.userData.company),
        tin: String(action.payload.userData.tin),
        username: String(action.payload.userData.username),
        privilege: String(action.payload.userData.privilege),
        photo: String(action.payload.userData.photo),
        activities: action.payload.userData.activities,
        is2FAEnabled: String(action.payload.isMfaEnabled),
        favicon: String(action.payload.userData.favicon),
        faviconMimeType: String(action.payload.userData.faviconMimeType),
        whitelabelImage: String(action.payload.userData.whitelabelImage),
        whitelabelMimeType: String(action.payload.userData.whitelabelMimeType),
        sidebarPosition: String(action.payload.userData.sidebarPosition),
        backgroundColor: String(action.payload.userData.backgroundColor),
        fontColor: String(action.payload.userData.fontColor),
      };
      state.user = data;
    },
    clearUser: (state) => {
      state.user = initialState;
    },
    updateFullName: (state, action) => {
      return {
        ...state,
        user: {
          ...state.user,
          fullName: String(action.payload),
        },
      };
    },
    updateAddress: (state, action) => {
      return {
        ...state,
        user: {
          ...state.user,
          address: String(action.payload),
        },
      };
    },
    updateCity: (state, action) => {
      return {
        ...state,
        user: {
          ...state.user,
          city: String(action.payload),
        },
      };
    },
    updateCountry: (state, action) => {
      return {
        ...state,
        user: {
          ...state.user,
          country: String(action.payload),
        },
      };
    },
    updatePostcode: (state, action) => {
      return {
        ...state,
        user: {
          ...state.user,
          postcode: String(action.payload),
        },
      };
    },
    updateTelephone: (state, action) => {
      return {
        ...state,
        user: {
          ...state.user,
          telephoneNumber: String(action.payload),
        },
      };
    },
    updateMobile: (state, action) => {
      return {
        ...state,
        user: {
          ...state.user,
          mobileNumber: String(action.payload),
        },
      };
    },
    updateEmail: (state, action) => {
      return {
        ...state,
        user: {
          ...state.user,
          email: String(action.payload),
        },
      };
    },
    updateOccupation: (state, action) => {
      return {
        ...state,
        user: {
          ...state.user,
          occupation: String(action.payload),
        },
      };
    },
    updateCompany: (state, action) => {
      return {
        ...state,
        user: {
          ...state.user,
          company: String(action.payload),
        },
      };
    },
    updateTIN: (state, action) => {
      return {
        ...state,
        user: {
          ...state.user,
          tin: String(action.payload),
        },
      };
    },
    updatePrivilege: (state, action) => {
      return {
        ...state,
        privilege: String(action.payload),
      };
    },
    updateIs2FAEnabled: (state, action) => {
      console.log("Previous State", state); // Debugging line
      const newState = {
        ...state,
        user: {
          ...state.user,
          is2FAEnabled: String(action.payload),
        },
      };
      console.log("New State", newState); // Debugging line
      return newState;
    },
  },
});

export const {
  setUser,
  clearUser,
  updateFullName,
  updateAddress,
  updateCity,
  updateCountry,
  updatePostcode,
  updateTelephone,
  updateEmail,
  updateOccupation,
  updateCompany,
  updateTIN,
  updatePrivilege,
  updateIs2FAEnabled,
} = userSlice.actions;
export default userSlice.reducer;

export const loginUser = (userData) => (dispatch) => {
  console.log(userData);
  dispatch(setUser(userData));
};

export const logoutUser = () => (dispatch) => {
  dispatch(clearUser());
};

export const updateCurrentUserFullName = (fullName) => (dispatch) => {
  dispatch(userSlice.actions.updateFullName(fullName));
};

export const updateCurrentUserAddress = (address) => (dispatch) => {
  dispatch(userSlice.actions.updateAddress(address));
};

export const updateCurrentUserCity = (city) => (dispatch) => {
  dispatch(userSlice.actions.updateCity(city));
};

export const updateCurrentUserCountry = (country) => (dispatch) => {
  dispatch(userSlice.actions.updateCountry(country));
};

export const updateCurrentUserPostcode = (postcode) => (dispatch) => {
  dispatch(userSlice.actions.updatePostcode(postcode));
};

export const updateCurrentUserTelephone = (telephone) => (dispatch) => {
  dispatch(userSlice.actions.updateTelephone(telephone));
};

export const updateCurrentUserMobile = (mobile) => (dispatch) => {
  dispatch(userSlice.actions.updateMobile(mobile));
};

export const updateCurrentUserEmail = (email) => (dispatch) => {
  dispatch(userSlice.actions.updateEmail(email));
};

export const updateCurrentUserOccupation = (occupation) => (dispatch) => {
  dispatch(userSlice.actions.updateOccupation(occupation));
};

export const updateCurrentUserCompany = (company) => (dispatch) => {
  dispatch(userSlice.actions.updateCompany(company));
};

export const updateCurrentUserTIN = (tin) => (dispatch) => {
  dispatch(userSlice.actions.updateTIN(tin));
};

export const updateCurrentUserPrivilege = (privilege) => (dispatch) => {
  dispatch(userSlice.actions.updatePrivilege(privilege));
};

export const updateDistributionList = (distributionList) => (dispatch) => {
  dispatch(userSlice.actions.setDistributionList(distributionList));
};

export const updateCurrentUserIs2FAEnabled = (is2FAEnabled) => (dispatch) => {
  console.log(is2FAEnabled);
  dispatch(userSlice.actions.updateIs2FAEnabled(is2FAEnabled));
};
