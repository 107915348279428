import React from "react";
import { Col, Form } from "react-bootstrap";

const FilterGroup = ({ column }) => {
  if (!column.canRenderFilter) {
    return null;
  }
  return (
    <Col md={3}>
      <Form.Group className="mb-3">
        <Form.Label>{column.render("Header")}</Form.Label>
        {column.render("Filter")}
      </Form.Group>
    </Col>
  );
};

export default FilterGroup;
