import {
  updateAdminName,
  updateAdminAddress,
  updateAdminCity,
  updateAdminCountry,
  updateAdminPostcode,
  updateAdminTelephone,
  updateAdminEmail,
  updateAdminOccupation,
  updateAdminCompany,
  updateAdminTIN,
  addNewAdmin,
  removeExistingAdmin,
  updateAdminPrivilege,
  updateAdminMobile,
} from "../../../redux/slices/admins";
import {
  updateManagerName,
  updateManagerAddress,
  updateManagerCity,
  updateManagerCountry,
  updateManagerPostcode,
  updateManagerTelephone,
  updateManagerEmail,
  updateManagerOccupation,
  updateManagerCompany,
  updateManagerTIN,
  updateManagerOffers,
  updateManagerPrivilege,
  addNewManager,
  removeExistingManager,
  updateManagerMobile,
  updateManagerFavicon,
  updateManagerWhitelabel,
  updateManagerBackgroundColor,
  updateManagerFontColor,
  updateManagerSidebarPosition,
} from "../../../redux/slices/managers";
import {
  updateTenantName,
  updateTenantAddress,
  updateTenantCity,
  updateTenantCountry,
  updateTenantPostcode,
  updateTenantTelephone,
  updateTenantEmail,
  updateTenantOccupation,
  updateTenantCompany,
  updateTenantTIN,
  updateTenantOffers,
  updateTenantPrivilege,
  addNewTenant,
  removeExistingTenant,
  updateTenantMobile,
  updateTenantFavicon,
  updateTenantWhitelabel,
  updateTenantBackgroundColor,
  updateTenantFontColor,
  updateTenantSidebarPosition,
} from "../../../redux/slices/tenants";
import {
  updateCustomerName,
  updateCustomerAddress,
  updateCustomerCity,
  updateCustomerCountry,
  updateCustomerPostcode,
  updateCustomerTelephone,
  updateCustomerEmail,
  updateCustomerOccupation,
  updateCustomerCompany,
  updateCustomerTIN,
  updateCustomerOffers,
  updateCustomerPrivilege,
  addNewCustomer,
  removeExistingCustomer,
  updateCustomerMobile,
} from "../../../redux/slices/customers";
import {
  updateSubaccountName,
  updateSubaccountAddress,
  updateSubaccountCity,
  updateSubaccountCountry,
  updateSubaccountPostcode,
  updateSubaccountTelephone,
  updateSubaccountEmail,
  updateSubAccountOccupation,
  updateSubaccountCompany,
  updateSubaccountTIN,
  updateSubaccountMobile,
  addNewSubaccount,
  updateSubaccountPrivilege,
  removeExistingSubaccount,
} from "../../../redux/slices/subaccounts";
import {
  updateViewerName,
  updateViewerAddress,
  updateViewerCity,
  updateViewerCountry,
  updateViewerPostcode,
  updateViewerTelephone,
  updateViewerEmail,
  updateViewerOccupation,
  updateViewerCompany,
  updateViewerTIN,
  updateViewerPrivilege,
  addNewViewer,
  removeExistingViewer,
  updateViewerMobile,
} from "../../../redux/slices/viewers";

export function GetUpdateNameFunction(privilege, clientId, dispatch) {
  switch (privilege) {
    case "ADMIN":
      return (newName) => dispatch(updateAdminName(clientId, newName));
    case "MANAGER":
      return (newName) => dispatch(updateManagerName(clientId, newName));
    case "TENANT":
      return (newName) => dispatch(updateTenantName(clientId, newName));
    case "CUSTOMER":
      return (newName) => dispatch(updateCustomerName(clientId, newName));
    case "SUBACCOUNT":
      return (newName) => dispatch(updateSubaccountName(clientId, newName));
    default:
      return (newName) => dispatch(updateViewerName(clientId, newName));
  }
}

export function GetUpdateAddressFunction(privilege, clientId, dispatch) {
  switch (privilege) {
    case "ADMIN":
      return (newName) => dispatch(updateAdminAddress(clientId, newName));
    case "MANAGER":
      return (newName) => dispatch(updateManagerAddress(clientId, newName));
    case "TENANT":
      return (newName) => dispatch(updateTenantAddress(clientId, newName));
    case "CUSTOMER":
      return (newName) => dispatch(updateCustomerAddress(clientId, newName));
    case "SUBACCOUNT":
      return (newName) => dispatch(updateSubaccountAddress(clientId, newName));
    default:
      return (newName) => dispatch(updateViewerAddress(clientId, newName));
  }
}

export const GetUpdateCityFunction = (privilege, clientId, dispatch) => {
  switch (privilege) {
    case "ADMIN":
      return (newName) => dispatch(updateAdminCity(clientId, newName));
    case "MANAGER":
      return (newName) => dispatch(updateManagerCity(clientId, newName));
    case "TENANT":
      return (newName) => dispatch(updateTenantCity(clientId, newName));
    case "CUSTOMER":
      return (newName) => dispatch(updateCustomerCity(clientId, newName));
    case "SUBACCOUNT":
      return (newName) => dispatch(updateSubaccountCity(clientId, newName));
    default:
      return (newName) => dispatch(updateViewerCity(clientId, newName));
  }
};

export const GetUpdateCountryFunction = (privilege, clientId, dispatch) => {
  switch (privilege) {
    case "ADMIN":
      return (newName) => dispatch(updateAdminCountry(clientId, newName));
    case "MANAGER":
      return (newName) => dispatch(updateManagerCountry(clientId, newName));
    case "TENANT":
      return (newName) => dispatch(updateTenantCountry(clientId, newName));
    case "CUSTOMER":
      return (newName) => dispatch(updateCustomerCountry(clientId, newName));
    case "SUBACCOUNT":
      return (newName) => dispatch(updateSubaccountCountry(clientId, newName));
    default:
      return (newName) => dispatch(updateViewerCountry(clientId, newName));
  }
};

export const GetUpdatePostcodeFunction = (privilege, clientId, dispatch) => {
  switch (privilege) {
    case "ADMIN":
      return (newName) => dispatch(updateAdminPostcode(clientId, newName));
    case "MANAGER":
      return (newName) => dispatch(updateManagerPostcode(clientId, newName));
    case "TENANT":
      return (newName) => dispatch(updateTenantPostcode(clientId, newName));
    case "CUSTOMER":
      return (newName) => dispatch(updateCustomerPostcode(clientId, newName));
    case "SUBACCOUNT":
      return (newName) => dispatch(updateSubaccountPostcode(clientId, newName));
    default:
      return (newName) => dispatch(updateViewerPostcode(clientId, newName));
  }
};

export const GetUpdateTelephoneFunction = (privilege, clientId, dispatch) => {
  switch (privilege) {
    case "ADMIN":
      return (newName) => dispatch(updateAdminTelephone(clientId, newName));
    case "MANAGER":
      return (newName) => dispatch(updateManagerTelephone(clientId, newName));
    case "TENANT":
      return (newName) => dispatch(updateTenantTelephone(clientId, newName));
    case "CUSTOMER":
      return (newName) => dispatch(updateCustomerTelephone(clientId, newName));
    case "SUBACCOUNT":
      return (newName) =>
        dispatch(updateSubaccountTelephone(clientId, newName));
    default:
      return (newName) => dispatch(updateViewerTelephone(clientId, newName));
  }
};

export const GetUpdateMobileFunction = (privilege, clientId, dispatch) => {
  switch (privilege) {
    case "ADMIN":
      return (newName) => dispatch(updateAdminMobile(clientId, newName));
    case "MANAGER":
      return (newName) => dispatch(updateManagerMobile(clientId, newName));
    case "TENANT":
      return (newName) => dispatch(updateTenantMobile(clientId, newName));
    case "CUSTOMER":
      return (newName) => dispatch(updateCustomerMobile(clientId, newName));
    case "SUBACCOUNT":
      return (newName) => dispatch(updateSubaccountMobile(clientId, newName));
    default:
      return (newName) => dispatch(updateViewerMobile(clientId, newName));
  }
};

export const GetUpdateEmailFunction = (privilege, clientId, dispatch) => {
  switch (privilege) {
    case "ADMIN":
      return (newName) => dispatch(updateAdminEmail(clientId, newName));
    case "MANAGER":
      return (newName) => dispatch(updateManagerEmail(clientId, newName));
    case "TENANT":
      return (newName) => dispatch(updateTenantEmail(clientId, newName));
    case "CUSTOMER":
      return (newName) => dispatch(updateCustomerEmail(clientId, newName));
    case "SUBACCOUNT":
      return (newName) => dispatch(updateSubaccountEmail(clientId, newName));
    default:
      return (newName) => dispatch(updateViewerEmail(clientId, newName));
  }
};

export const GetUpdateOccupationFunction = (privilege, clientId, dispatch) => {
  switch (privilege) {
    case "ADMIN":
      return (newName) => dispatch(updateAdminOccupation(clientId, newName));
    case "MANAGER":
      return (newName) => dispatch(updateManagerOccupation(clientId, newName));
    case "TENANT":
      return (newName) => dispatch(updateTenantOccupation(clientId, newName));
    case "CUSTOMER":
      return (newName) => dispatch(updateCustomerOccupation(clientId, newName));
    case "SUBACCOUNT":
      return (newName) =>
        dispatch(updateSubAccountOccupation(clientId, newName));
    default:
      return (newName) => dispatch(updateViewerOccupation(clientId, newName));
  }
};

export const GetUpdateCompanyFunction = (privilege, clientId, dispatch) => {
  switch (privilege) {
    case "ADMIN":
      return (newName) => dispatch(updateAdminCompany(clientId, newName));
    case "MANAGER":
      return (newName) => dispatch(updateManagerCompany(clientId, newName));
    case "TENANT":
      return (newName) => dispatch(updateTenantCompany(clientId, newName));
    case "CUSTOMER":
      return (newName) => dispatch(updateCustomerCompany(clientId, newName));
    case "SUBACCOUNT":
      return (newName) => dispatch(updateSubaccountCompany(clientId, newName));
    default:
      return (newName) => dispatch(updateViewerCompany(clientId, newName));
  }
};

export const GetUpdateTINFunction = (privilege, clientId, dispatch) => {
  switch (privilege) {
    case "ADMIN":
      return (newName) => dispatch(updateAdminTIN(clientId, newName));
    case "MANAGER":
      return (newName) => dispatch(updateManagerTIN(clientId, newName));
    case "TENANT":
      return (newName) => dispatch(updateTenantTIN(clientId, newName));
    case "CUSTOMER":
      return (newName) => dispatch(updateCustomerTIN(clientId, newName));
    case "SUBACCOUNT":
      return (newName) => dispatch(updateSubaccountTIN(clientId, newName));
    default:
      return (newName) => dispatch(updateViewerTIN(clientId, newName));
  }
};

export const GetUpdateFaviconFunction = (privilege, clientId, dispatch) => {
  switch (privilege) {
    case "TENANT":
      return (newName) => dispatch(updateTenantFavicon(clientId, newName));
    default:
      return (newName) => dispatch(updateManagerFavicon(clientId, newName));
  }
};

export const GetUpdateWhitelabelFunction = (privilege, clientId, dispatch) => {
  switch (privilege) {
    case "TENANT":
      return (newName) => dispatch(updateTenantWhitelabel(clientId, newName));
    default:
      return (newName) => dispatch(updateManagerWhitelabel(clientId, newName));
  }
};

export const GetUpdateBackgroundColorFunction = (
  privilege,
  clientId,
  dispatch
) => {
  switch (privilege) {
    case "TENANT":
      return (newName) =>
        dispatch(updateTenantBackgroundColor(clientId, newName));
    default:
      return (newName) =>
        dispatch(updateManagerBackgroundColor(clientId, newName));
  }
};

export const GetUpdateFontColorFunction = (privilege, clientId, dispatch) => {
  switch (privilege) {
    case "TENANT":
      return (newName) => dispatch(updateTenantFontColor(clientId, newName));
    default:
      return (newName) => dispatch(updateManagerFontColor(clientId, newName));
  }
};

export const GetUpdateSidebarPositionFunction = (
  privilege,
  clientId,
  dispatch
) => {
  switch (privilege) {
    case "TENANT":
      return (newName) =>
        dispatch(updateTenantSidebarPosition(clientId, newName));
    default:
      return (newName) =>
        dispatch(updateManagerSidebarPosition(clientId, newName));
  }
};

export const GetUpdatePrivilegeFunction = (
  userId,
  privilege,
  clientId,
  upperEntityId,
  newName,
  dispatch
) => {
  switch (privilege) {
    case "ADMIN":
      return dispatch(
        updateAdminPrivilege(userId, clientId, newName, upperEntityId)
      );
    case "TENANT":
      return dispatch(
        updateTenantPrivilege(userId, clientId, newName, upperEntityId)
      );
    case "MANAGER":
      return dispatch(
        updateManagerPrivilege(userId, clientId, newName, upperEntityId)
      );
    case "CUSTOMER":
      return dispatch(
        updateCustomerPrivilege(userId, clientId, newName, upperEntityId)
      );
    case "SUBACCOUNT":
      return dispatch(
        updateSubaccountPrivilege(userId, clientId, newName, upperEntityId)
      );
    case "VIEWER":
      return dispatch(
        updateViewerPrivilege(userId, clientId, newName, upperEntityId)
      );
    default:
      return null;
  }
};

export const GetAddEntityFunction = (privilege, client, dispatch) => {
  switch (privilege) {
    case "TENANT":
      return dispatch(addNewTenant(client));
    case "MANAGER":
      return dispatch(addNewManager(client));
    case "ADMIN":
      return dispatch(addNewAdmin(client));
    case "CUSTOMER":
      return dispatch(addNewCustomer(client));
    case "SUBACCOUNT":
      return dispatch(addNewSubaccount(client));
    case "VIEWER":
      return dispatch(addNewViewer(client));
    default:
      throw new Error(`Invalid privilege: ${privilege}`);
  }
};

export const GetRemoveEntityFunction = (privilege, id, dispatch) => {
  switch (privilege) {
    case "ADMIN":
      return dispatch(removeExistingAdmin(id));
    case "TENANT":
      return dispatch(removeExistingTenant(id));
    case "MANAGER":
      return dispatch(removeExistingManager(id));
    case "CUSTOMER":
      return dispatch(removeExistingCustomer(id));
    case "SUBACCOUNT":
      return dispatch(removeExistingSubaccount(id));
    case "VIEWER":
      return dispatch(removeExistingViewer(id));
    default:
      throw new Error(`Invalid privilege: ${privilege}`);
  }
};

export const GetUpdateOffersFunction = (privilege, id, dispatch) => {
  switch (privilege) {
    case "TENANT":
      return (newName) => dispatch(updateTenantOffers(id, newName));
    case "MANAGER":
      return (newName) => dispatch(updateManagerOffers(id, newName));
    case "CUSTOMER":
      return (newName) => dispatch(updateCustomerOffers(id, newName));
    default:
      throw new Error(`Invalid privilege: ${privilege}`);
  }
};

// Privilege Upper Entity
const filterEntities = (entities, configUserId) => {
  return entities.length > 0
    ? entities.filter((entity) => entity.id !== configUserId)
    : [];
};

const userEntityHandler = (
  user,
  upperEntitySlice,
  entities,
  configUser,
  configPrivilege
) => {
  upperEntitySlice.push(user);
  if (
    configPrivilege === "MANAGER" ||
    configPrivilege === "CUSTOMER" ||
    configPrivilege === "SUBACCOUNT"
  ) {
    const filteredTenants = filterEntities(entities.tenants, configUser.id);
    if (filteredTenants.length > 0) {
      filteredTenants.map((tenant) => {
        return upperEntitySlice.push(tenant);
      });
    }
  }
  if (configPrivilege === "CUSTOMER" || configPrivilege === "SUBACCOUNT") {
    const filteredManagers = filterEntities(entities.managers, configUser.id);
    if (filteredManagers.length > 0) {
      filteredManagers.map((manager) => {
        return upperEntitySlice.push(manager);
      });
    }
  }
  if (configPrivilege === "SUBACCOUNT") {
    const filteredCustomers = filterEntities(entities.customers, configUser.id);
    if (filteredCustomers.length > 0) {
      filteredCustomers.map((customer) => {
        return upperEntitySlice.push(customer);
      });
    }
  }
};

const privilegeMap = {
  ADMIN: {
    TENANT: (user, upperEntitySlice, _, __, ___) => {
      upperEntitySlice.push(user);
    },
    MANAGER: userEntityHandler,
    CUSTOMER: userEntityHandler,
    SUBACCOUNT: userEntityHandler,
  },
  TENANT: {
    MANAGER: (user, upperEntitySlice, _, __, ___) => {
      upperEntitySlice.push(user);
    },
    CUSTOMER: userEntityHandler,
    SUBACCOUNT: userEntityHandler,
  },
  MANAGER: {
    CUSTOMER: (user, upperEntitySlice, _, __, ___) => {
      upperEntitySlice.push(user);
    },
    SUBACCOUNT: userEntityHandler,
  },
};

export const performFunctionality = (
  user,
  privilege,
  configUser,
  tenants,
  managers,
  customers
) => {
  const upperEntitySlice = [];

  const privilegeFunction =
    privilegeMap[user.privilege] && privilegeMap[user.privilege][privilege];

  const entities = {
    tenants,
    managers,
    customers,
  };

  if (privilegeFunction) {
    privilegeFunction(
      user,
      upperEntitySlice,
      entities,
      configUser,
      configUser.privilege
    );
  }

  return upperEntitySlice;
};
