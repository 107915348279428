import axios from "../../utils/axios";
import { createSlice } from "@reduxjs/toolkit";
import countryList from "react-select-country-list";

const initialState = {
  customers: [],
};

const customersSlice = createSlice({
  name: "customers",
  initialState,
  reducers: {
    setCustomers(state, action) {
      state.customers = action.payload;
    },
    clearCustomers(state, action) {
      state.customers = [];
    },
    updateFullName(state, action) {
      const { id, fullName } = action.payload;
      const updatedCustomers = state.customers.map((customer) => {
        if (customer.id === id) {
          return {
            ...customer,
            fullName: fullName,
          };
        }
        return customer;
      });
      return {
        ...state,
        customers: updatedCustomers,
      };
    },
    updateAddress(state, action) {
      const { id, address } = action.payload;
      const updatedCustomers = state.customers.map((customer) => {
        if (customer.id === id) {
          return {
            ...customer,
            address: address,
          };
        }
        return customer;
      });
      return {
        ...state,
        customers: updatedCustomers,
      };
    },
    updateCity(state, action) {
      const { id, city } = action.payload;
      const updatedCustomers = state.customers.map((customer) => {
        if (customer.id === id) {
          return {
            ...customer,
            city: city,
          };
        }
        return customer;
      });
      return {
        ...state,
        customers: updatedCustomers,
      };
    },
    updateCountry(state, action) {
      const { id, country } = action.payload;
      const updatedCustomers = state.customers.map((customer) => {
        if (customer.id === id) {
          return {
            ...customer,
            country: country,
          };
        }
        return customer;
      });
      return {
        ...state,
        customers: updatedCustomers,
      };
    },
    updatePostcode(state, action) {
      const { id, postcode } = action.payload;
      const updatedCustomers = state.customers.map((customer) => {
        if (customer.id === id) {
          return {
            ...customer,
            postcode: postcode,
          };
        }
        return customer;
      });
      return {
        ...state,
        customers: updatedCustomers,
      };
    },
    updateTelephone(state, action) {
      const { id, telephone } = action.payload;
      const updatedCustomers = state.customers.map((customer) => {
        if (customer.id === id) {
          return {
            ...customer,
            telephone: telephone,
          };
        }
        return customer;
      });
      return {
        ...state,
        customers: updatedCustomers,
      };
    },
    updateMobile(state, action) {
      const { id, mobile } = action.payload;
      const updatedCustomers = state.customers.map((customer) => {
        if (customer.id === id) {
          return {
            ...customer,
            mobileNumber: mobile,
          };
        }
        return customer;
      });
      return {
        ...state,
        customers: updatedCustomers,
      };
    },
    updateEmail(state, action) {
      const { id, email } = action.payload;
      const updatedCustomers = state.customers.map((customer) => {
        if (customer.id === id) {
          return {
            ...customer,
            email: email,
          };
        }
        return customer;
      });
      return {
        ...state,
        customers: updatedCustomers,
      };
    },
    updateOccupation(state, action) {
      const { id, occupation } = action.payload;
      const updatedCustomers = state.customers.map((customer) => {
        if (customer.id === id) {
          return {
            ...customer,
            occupation: occupation,
          };
        }
        return customer;
      });
      return {
        ...state,
        customers: updatedCustomers,
      };
    },
    updateCompany(state, action) {
      const { id, company } = action.payload;
      const updatedCustomers = state.customers.map((customer) => {
        if (customer.id === id) {
          return {
            ...customer,
            company: company,
          };
        }
        return customer;
      });
      return {
        ...state,
        customers: updatedCustomers,
      };
    },
    updateTIN(state, action) {
      const { id, tin } = action.payload;
      const updatedCustomers = state.customers.map((customer) => {
        if (customer.id === id) {
          return {
            ...customer,
            tinNumber: tin,
          };
        }
        return customer;
      });
      return {
        ...state,
        customers: updatedCustomers,
      };
    },
    updateOffers(state, action) {
      const { id, offers } = action.payload;
      const updatedCustomers = state.customers.map((customer) => {
        if (customer.id === id) {
          return {
            ...customer,
            offers: offers,
          };
        }
        return customer;
      });
      return {
        ...state,
        customers: updatedCustomers,
      };
    },
    updatePrivilege(state, action) {
      const { id, privilege } = action.payload;
      const updatedCustomers = state.customers.map((customer) => {
        if (customer.id === id) {
          return {
            ...customer,
            privilege: privilege,
          };
        }
        return customer;
      });
      return {
        ...state,
        customers: updatedCustomers,
      };
    },
    addCustomer: (state, action) => {
      const newCustomer = action.payload;
      return {
        ...state,
        customers: [...state.customers, newCustomer],
      };
    },
    removeCustomer: (state, action) => {
      const idToRemove = action.payload;
      const newCustomers = state.customers.filter(
        (customer) => customer.id !== idToRemove
      );
      return {
        ...state,
        customers: newCustomers,
      };
    },
  },
});

export const clearAllCustomers = () => (dispatch) => {
  dispatch(customersSlice.actions.clearCustomers());
};

export function getCustomers(id) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    try {
      const response = await axios.get(
        "https://dev.pos2cloud.com:5000/api/customers/" + id + "/getCustomers",
        {
          headers: {
            accessToken: "Bearer " + accessToken,
          },
        }
      );
      dispatch(customersSlice.actions.setCustomers(response.data));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        dispatch(customersSlice.actions.setCustomers([]));
      }
    }
  };
}

export function updateCustomerName(id, fullName) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("fullName", fullName);
    try {
      await axios.put(
        "https://dev.pos2cloud.com:5000/api/systemusers/" +
          id +
          "/update/fullName",
        formData,
        {
          headers: {
            accessToken: "Bearer " + accessToken,
          },
        }
      );
      dispatch(customersSlice.actions.updateFullName({ id, fullName }));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.log("User not found");
      } else if (error.status === 500) {
        console.log("Internal Server Error");
      }
    }
  };
}

export function updateCustomerAddress(id, address) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("address", address);
    try {
      await axios.put(
        "https://dev.pos2cloud.com:5000/api/systemusers/" +
          id +
          "/update/address",
        formData,
        {
          headers: {
            accessToken: "Bearer " + accessToken,
          },
        }
      );
      dispatch(customersSlice.actions.updateAddress({ id, address }));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.log("User not found");
      } else if (error.status === 500) {
        console.log("Internal Server Error");
      }
    }
  };
}

export function updateCustomerCity(id, city) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("city", city);
    try {
      await axios.put(
        "https://dev.pos2cloud.com:5000/api/systemusers/" + id + "/update/city",
        formData,
        {
          headers: {
            accessToken: "Bearer " + accessToken,
          },
        }
      );
      dispatch(customersSlice.actions.updateCity({ id, city }));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.log("User not found");
      } else if (error.status === 500) {
        console.log("Internal Server Error");
      }
    }
  };
}

export function updateCustomerCountry(id, country) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("country", country);
    try {
      await axios.put(
        "https://dev.pos2cloud.com:5000/api/systemusers/" +
          id +
          "/update/country",
        formData,
        {
          headers: {
            accessToken: "Bearer " + accessToken,
          },
        }
      );
      dispatch(customersSlice.actions.updateCountry({ id, country }));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.log("User not found");
      } else if (error.status === 500) {
        console.log("Internal Server Error");
      }
    }
  };
}

export function updateCustomerPostcode(id, postcode) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("postcode", postcode);
    try {
      await axios.put(
        "https://dev.pos2cloud.com:5000/api/systemusers/" +
          id +
          "/update/postcode",
        formData,
        {
          headers: {
            accessToken: "Bearer " + accessToken,
          },
        }
      );
      dispatch(customersSlice.actions.updatePostcode({ id, postcode }));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.log("User not found");
      } else if (error.status === 500) {
        console.log("Internal Server Error");
      }
    }
  };
}

export function updateCustomerTelephone(id, telephone) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("telephone", telephone);
    try {
      await axios.put(
        "https://dev.pos2cloud.com:5000/api/systemusers/" +
          id +
          "/update/telephone",
        formData,
        {
          headers: {
            accessToken: "Bearer " + accessToken,
          },
        }
      );
      dispatch(customersSlice.actions.updateTelephone({ id, telephone }));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.log("User not found");
      } else if (error.status === 500) {
        console.log("Internal Server Error");
      }
    }
  };
}

export function updateCustomerMobile(id, mobile) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("mobile", mobile);
    try {
      await axios.put(
        "https://dev.pos2cloud.com:5000/api/systemusers/" +
          id +
          "/update/mobile",
        formData,
        {
          headers: {
            accessToken: "Bearer " + accessToken,
          },
        }
      );
      dispatch(customersSlice.actions.updateMobile({ id, mobile }));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.log("User not found");
      } else if (error.status === 500) {
        console.log("Internal Server Error");
      }
    }
  };
}

export function updateCustomerEmail(id, email) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("email", email);
    try {
      await axios.put(
        "https://dev.pos2cloud.com:5000/api/systemusers/" +
          id +
          "/update/email",
        formData,
        {
          headers: {
            accessToken: "Bearer " + accessToken,
          },
        }
      );
      dispatch(customersSlice.actions.updateEmail({ id, email }));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.log("User not found");
      } else if (error.status === 500) {
        console.log("Internal Server Error");
      }
    }
  };
}

export function updateCustomerOccupation(id, occupation) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("occupation", occupation);
    try {
      await axios.put(
        "https://dev.pos2cloud.com:5000/api/systemusers/" +
          id +
          "/update/occupation",
        formData,
        {
          headers: {
            accessToken: "Bearer " + accessToken,
          },
        }
      );
      dispatch(customersSlice.actions.updateOccupation({ id, occupation }));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.log("User not found");
      } else if (error.status === 500) {
        console.log("Internal Server Error");
      }
    }
  };
}

export function updateCustomerCompany(id, company) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("company", company);
    try {
      await axios.put(
        "https://dev.pos2cloud.com:5000/api/systemusers/" +
          id +
          "/update/company",
        formData,
        {
          headers: {
            accessToken: "Bearer " + accessToken,
          },
        }
      );
      dispatch(customersSlice.actions.updateCompany({ id, company }));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.log("User not found");
      } else if (error.status === 500) {
        console.log("Internal Server Error");
      }
    }
  };
}

export function updateCustomerTIN(id, tin) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("tin", tin);
    try {
      await axios.put(
        "https://dev.pos2cloud.com:5000/api/systemusers/" + id + "/update/tin",
        formData,
        {
          headers: {
            accessToken: "Bearer " + accessToken,
          },
        }
      );
      dispatch(customersSlice.actions.updateTIN({ id, tin }));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.log("User not found");
      } else if (error.status === 500) {
        console.log("Internal Server Error");
      }
    }
  };
}

export function updateCustomerOffers(id, offers) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("offers", offers);
    try {
      await axios.put(
        `https://dev.pos2cloud.com:5000/api/customers/${id}/update/offers`,
        formData,
        {
          headers: {
            accessToken: `Bearer ${accessToken}`,
          },
        }
      );
      dispatch(customersSlice.actions.updateOffers({ id, offers }));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        throw new Error("User not found");
      } else if (error.response && error.response.status === 500) {
        throw new Error("Internal Server Error");
      }
    }
  };
}

export function updateCustomerPrivilege(
  userId,
  clientId,
  privilege,
  upperEntityId
) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("entityId", clientId);
    formData.append("privilege", privilege);
    if (privilege !== "ADMIN" && privilege !== "VIEWER") {
      formData.append("upperEntityId", upperEntityId);
    } else {
      formData.append("upperEntityId", -1);
    }
    try {
      await axios.put(
        "https://dev.pos2cloud.com:5000/api/systemusers/" +
          userId +
          "/update/privilege",
        formData,
        {
          headers: {
            accessToken: "Bearer " + accessToken,
          },
        }
      );
      dispatch(customersSlice.actions.updatePrivilege({ clientId, privilege }));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.log("User not found");
      } else if (error.status === 500) {
        console.log("Internal Server Error");
      }
    }
  };
}

export function addNewCustomer(
  id,
  fullName,
  address,
  city,
  country,
  postcode,
  telephoneNumber,
  mobileNumber,
  email,
  occupation,
  company,
  tin,
  portfolioId,
  image,
  minimumAmountToInvoice,
  invoiceType,
  invoiceHandlerType,
  currency,
  invoiceConfiguration,
  preferredLanguage,
  billingEmail,
  offers
) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    if (accessToken) {
      const options = countryList().getData();
      const abbreviation = country;
      const countryRecord = options.find((c) => c.value === abbreviation);
      const formData = new FormData();
      formData.append("fullName", fullName);
      formData.append("address", address);
      formData.append("city", city);
      formData.append("country", countryRecord.label);
      formData.append("postcode", postcode);
      formData.append("telephoneNumber", telephoneNumber);
      formData.append("mobileNumber", mobileNumber);
      formData.append("email", email);
      formData.append("occupation", occupation);
      formData.append("company", company);
      formData.append("tin", tin);
      formData.append("portfolioId", portfolioId);
      formData.append("photo", image);
      formData.append("minimumAmountToInvoice", minimumAmountToInvoice);
      formData.append("invoiceType", invoiceType);
      formData.append("invoiceHandlerType", invoiceHandlerType);
      formData.append("currency", currency);
      formData.append("invoiceConfiguration", invoiceConfiguration);
      formData.append("preferredLanguage", preferredLanguage);
      formData.append("billingEmail", billingEmail);
      formData.append("offers", JSON.stringify(offers));
      try {
        await axios.post(
          "https://dev.pos2cloud.com:5000/api/customers/" + id + "/addCustomer",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              accessToken: "Bearer " + accessToken,
            },
          }
        );
        dispatch(getCustomers(id));
      } catch (error) {
        throw new Error("Error creating Customer");
      }
    }
  };
}

export function removeExistingCustomer(id) {
  return (dispatch) => {
    dispatch(customersSlice.actions.removeCustomer(id));
  };
}

export const {
  setCustomers,
  clearCustomers,
  updateFullName,
  updateAddress,
  updateCity,
  updateCountry,
  updatePostcode,
  updateTelephone,
  updateEmail,
  updateOccupation,
  updateCompany,
  updateTIN,
  updatePrivilege,
  addCustomer,
  removeCustomer,
} = customersSlice.actions;
export default customersSlice.reducer;
