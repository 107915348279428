import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import reduceChildRoutes from "./reduceChildRoutes";

const SidebarNavList = (props) => {
  const { pages, depth } = props;
  const router = useLocation();
  const currentRoute = router.pathname;
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const [privilege, setPrivilege] = useState("");

  useEffect(() => {
    if (!impersonationUser.id) {
      if (user) {
        setPrivilege(user.privilege);
      }
    } else {
      setPrivilege(impersonationUser.privilege);
    }
  }, [user, impersonationUser.id, impersonationUser.privilege, privilege]);

  const childRoutes = pages.reduce(
    (items, page) =>
      reduceChildRoutes({ items, page, currentRoute, depth, privilege }),
    []
  );

  return <React.Fragment>{childRoutes}</React.Fragment>;
};

export default SidebarNavList;
