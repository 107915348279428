import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col, Card } from "react-bootstrap";
import CountUp from "react-countup";
import { useSelector } from "react-redux";
import {
  countDataSessionsForImsiList,
  countZeroSessionsForImsiList,
  sumDataVolumeForImsiList,
} from "../../../redux/slices/dataSessions";
import NotyfContext from "../../../contexts/NotyfContext";

const StatisticsPanel = ({ selectedRows }) => {
  const { t } = useTranslation();
  const notyf = useContext(NotyfContext);
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const [dataVolumeSelectedRows, setDataVolumeSelectedRows] = useState(0);
  const [dataSessionsSelectedRows, setDataSessionsSelectedRows] = useState(0);
  const [zeroSessionsSelectedRows, setZeroSessionsSelectedRows] = useState(0);

  useEffect(() => {
    const fetchSelectedRowsStatistics = async (id) => {
      try {
        const sessions = await countDataSessionsForImsiList(
          id,
          selectedRows.map((row) => row.imsi)
        );
        setDataSessionsSelectedRows(sessions);
      } catch (error) {
        notyf.open({
          type: "danger",
          message: "Error calculating Data Sessions",
          duration: 5000,
          ripple: true,
          dismissible: false,
          position: {
            x: "center",
            y: "top",
          },
        });
      }
      try {
        const zeros = await countZeroSessionsForImsiList(
          id,
          selectedRows.map((row) => row.imsi)
        );
        setZeroSessionsSelectedRows(zeros);
      } catch (error) {
        notyf.open({
          type: "danger",
          message: "Error calculating Zero Sessions",
          duration: 5000,
          ripple: true,
          dismissible: false,
          position: {
            x: "center",
            y: "top",
          },
        });
      }

      try {
        const volume = await sumDataVolumeForImsiList(
          id,
          selectedRows.map((row) => row.imsi)
        );

        setDataVolumeSelectedRows(
          parseFloat(volume / 1024.0 / 1024.0).toFixed(2)
        );
      } catch (error) {
        notyf.open({
          type: "danger",
          message: error.message,
          duration: 5000,
          ripple: true,
          dismissible: false,
          position: {
            x: "center",
            y: "top",
          },
        });
      }
    };

    if (!impersonationUser.id) {
      if (user && user.id && selectedRows) {
        fetchSelectedRowsStatistics(user.id);
      }
    } else {
      fetchSelectedRowsStatistics(impersonationUser.id);
    }
  }, [selectedRows, user.id, impersonationUser.id, notyf, user]);

  return (
    <div className="w-100">
      <Row>
        <Col sm="4" lg="12" className="d-flex col-xxl-4">
          <Card className="flex-fill">
            <Card.Body>
              <Row>
                <Col className="mt-0">
                  <h5 className="card-title">{t("Data Volume")}</h5>
                </Col>
              </Row>
              <span className="h1 d-inline-block mt-3 mb-4">
                <CountUp
                  key={dataVolumeSelectedRows}
                  start={0}
                  end={dataVolumeSelectedRows}
                  duration={2.75}
                ></CountUp>
                MB
              </span>
            </Card.Body>
          </Card>
        </Col>
        <Col sm="4" lg="12" className="d-flex col-xxl-4">
          <Card className="flex-fill">
            <Card.Body>
              <Row>
                <Col className="mt-0">
                  <h5 className="card-title">{t("Data Sessions")}</h5>
                </Col>
              </Row>
              <span className="h1 d-inline-block mt-3 mb-4">
                <CountUp
                  start={0}
                  end={dataSessionsSelectedRows}
                  duration={2.75}
                ></CountUp>
              </span>
            </Card.Body>
          </Card>
        </Col>
        <Col sm="4" lg="12" className="d-flex col-xxl-4">
          <Card className="flex-fill">
            <Card.Body>
              <Row>
                <Col className="mt-0">
                  <h5 className="card-title">{t("Zero Sessions")}</h5>
                </Col>
              </Row>
              <span className="h1 d-inline-block mt-3 mb-4">
                <CountUp
                  start={0}
                  end={zeroSessionsSelectedRows}
                  duration={2.75}
                />
              </span>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default StatisticsPanel;
