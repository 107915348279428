import {
  //Bell,
  BookOpen,
  //Calendar,
  //CheckSquare,
  //Grid,
  //Heart,
  //Layout,
  //List,
  //PieChart,
  //Sliders,
  //MapPin,
  //Users,
  //Share,
  HelpCircle,
} from "react-feather";
import {
  FaSimCard,
  FaUsers,
  FaUser,
  FaHome,
  FaFile,
  FaExclamationTriangle,
  FaCog,
} from "react-icons/fa";
import { FiBox } from "react-icons/fi";

const pagesSection = [
  {
    href: "/dashboard/default",
    icon: FaHome,
    title: "Dashboard",
  },
  {
    href: "/crm",
    icon: FaUsers,
    title: "CRM",
    children: [
      {
        href: "/crm/admins",
        title: "Admins",
      },
      {
        href: "/crm/tenants",
        title: "Tenants",
      },
      {
        href: "/crm/managers",
        title: "Managers",
      },
      {
        href: "/crm/customers",
        title: "Customers",
      },
      {
        href: "/crm/users",
        title: "Subaccounts",
      },
      {
        href: "/crm/viewers",
        title: "Viewers",
      },
    ],
  },
  {
    href: "/sims",
    icon: FaSimCard,
    title: "SIMs",
  },
  {
    href: "/account",
    icon: FaUser,
    title: "Account",
    children: [
      {
        href: "/profile",
        title: "Profile",
      },
      {
        href: "/account/billingproducts",
        title: "Billing Products",
      },
      {
        href: "/account/invoices",
        title: "Invoices",
      },
      {
        href: "/account/statements",
        title: "Statements",
      },
      {
        href: "/account/orders",
        title: "Orders",
      },
    ],
  },
  {
    href: "/catalogue",
    icon: FiBox,
    title: "Catalog",
    children: [
      {
        href: "/catalogue/new",
        title: "New Offer",
      },
      {
        href: "/catalogue/offers",
        title: "View Offers",
      },
    ],
  },
  {
    href: "/reports",
    icon: FaFile,
    title: "Reports",
  },
  {
    href: "/alerts",
    icon: FaExclamationTriangle,
    title: "Alerts",
  },
  {
    href: "/support",
    icon: HelpCircle,
    title: "Support",
  },
  {
    href: "/pages/settings",
    icon: FaCog,
    title: "Settings",
  },

  /*{
    href: "/pages",
    icon: Layout,
    title: "Pages",
    children: [
      {
        href: "/pages/profile",
        title: "Profile",
      },
      {
        href: "/pages/settings",
        title: "Settings",
      },
      {
        href: "/pages/clients",
        title: "Clients",
      },
      {
        href: "/pages/projects",
        title: "Projects",
      },
      {
        href: "/pages/invoice",
        title: "Invoice",
      },
      {
        href: "/pages/pricing",
        title: "Pricing",
      },
      {
        href: "/pages/tasks",
        title: "Tasks",
      },
      {
        href: "/pages/chat",
        title: "Chat",
        badge: "New",
      },
      {
        href: "/pages/blank",
        title: "Blank Page",
      },
    ],
  },*/
  /*{
    href: "/auth",
    icon: Users,
    title: "Auth",
    children: [
      {
        href: "/auth/sign-in",
        title: "Sign In",
      },
      {
        href: "/auth/sign-up",
        title: "Sign Up",
      },
      {
        href: "/auth/reset-password",
        title: "Reset Password",
      },
      {
        href: "/auth/404",
        title: "404 Page",
      },
      {
        href: "/auth/500",
        title: "500 Page",
      },
    ],
  },*/
  {
    href: "/docs/introduction",
    icon: BookOpen,
    title: "Documentation",
  },
];

/*const componentsSection = [
  {
    href: "/ui",
    icon: Grid,
    title: "UI Elements",
    children: [
      {
        href: "/ui/alerts",
        title: "Alerts",
      },
      {
        href: "/ui/buttons",
        title: "Buttons",
      },
      {
        href: "/ui/cards",
        title: "Cards",
      },
      {
        href: "/ui/carousel",
        title: "Carousel",
      },
      {
        href: "/ui/embed-video",
        title: "Embed Video",
      },
      {
        href: "/ui/general",
        title: "General",
      },
      {
        href: "/ui/grid",
        title: "Grid",
      },
      {
        href: "/ui/modals",
        title: "Modals",
      },
      {
        href: "/ui/offcanvas",
        title: "Offcanvas",
      },
      {
        href: "/ui/tabs",
        title: "Tabs",
      },
      {
        href: "/ui/typography",
        title: "Typography",
      },
    ],
  },
  {
    href: "/icons",
    icon: Heart,
    title: "Icons",
    badge: "1500+",
    children: [
      {
        href: "/icons/feather",
        title: "Feather",
      },
      {
        href: "/icons/font-awesome",
        title: "Font Awesome",
      },
    ],
  },
  {
    href: "/forms",
    icon: CheckSquare,
    title: "Forms",
    children: [
      {
        href: "/forms/layouts",
        title: "Layouts",
      },
      {
        href: "/forms/basic-inputs",
        title: "Basic Inputs",
      },
      {
        href: "/forms/input-groups",
        title: "Input Groups",
      },
      {
        href: "/forms/floating-labels",
        title: "Floating Labels",
      },
    ],
  },
  {
    href: "/tables",
    icon: List,
    title: "Tables",
  },
];

const pluginsSection = [
  {
    href: "/form-plugins",
    icon: CheckSquare,
    title: "Form Plugins",
    children: [
      {
        href: "/form-plugins/advanced-inputs",
        title: "Advanced Inputs",
      },
      {
        href: "/form-plugins/formik",
        title: "Formik",
        badge: "New",
      },
      {
        href: "/form-plugins/editors",
        title: "Editors",
      },
    ],
  },
  {
    href: "/advanced-tables",
    icon: List,
    title: "Advanced Tables",
    children: [
      {
        href: "/advanced-tables/pagination",
        title: "Pagination",
      },
      {
        href: "/advanced-tables/column-sorting",
        title: "Column Sorting",
      },
      {
        href: "/advanced-tables/column-filtering",
        title: "Column Filtering",
      },
      {
        href: "/advanced-tables/row-expanding",
        title: "Row Expanding",
      },
      {
        href: "/advanced-tables/row-selection",
        title: "Row Selection",
      },
    ],
  },
  {
    href: "/charts",
    icon: PieChart,
    title: "Charts",
    badge: "New",
    children: [
      {
        href: "/charts/chartjs",
        title: "Chart.js",
      },
      {
        href: "/charts/apexcharts",
        title: "ApexCharts",
        badge: "New",
      },
    ],
  },
  {
    href: "/notifications",
    icon: Bell,
    title: "Notifications",
  },
  {
    href: "/maps",
    icon: MapPin,
    title: "Maps",
    children: [
      {
        href: "/maps/google-maps",
        title: "Google Maps",
      },
      {
        href: "/maps/vector-maps",
        title: "Vector Maps",
      },
    ],
  },
  {
    href: "/calendar",
    icon: Calendar,
    title: "Calendar",
  },
  {
    href: "/404",
    icon: Share,
    title: "Multi Level",
    children: [
      {
        href: "/404",
        title: "Two Levels",
        children: [
          {
            href: "/404",
            title: "Item 1",
          },
          {
            href: "/404",
            title: "Item 2",
          },
        ],
      },
      {
        href: "/404",
        title: "Three Levels",
        children: [
          {
            href: "/404",
            title: "Item 1",
            children: [
              {
                href: "/404",
                title: "Item 1",
              },
              {
                href: "/404",
                title: "Item 2",
              },
            ],
          },
          {
            href: "/404",
            title: "Item 2",
          },
        ],
      },
    ],
  },
];*/

const navItems = [
  {
    pages: pagesSection,
  },
];

export default navItems;
