import React from "react";
import {
  useTable,
  usePagination,
  useSortBy,
  useFilters,
  useGlobalFilter,
} from "react-table";

import { Card, Table, Pagination, Row, Col, Form } from "react-bootstrap";
import { FaSort, FaSortDown, FaSortUp } from "react-icons/fa";
import DefaultColumnFilter from "../../../utils/DefaultColumnFilter";
import { useTranslation } from "react-i18next";
import FiltersCard from "../../../utils/FiltersCard";

const AlertsTable = ({ columns, data }) => {
  const { t } = useTranslation();
  const filterTypes = React.useMemo(
    () => ({
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
      defaultColumn,
      filterTypes,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <>
      <FiltersCard headerGroups={headerGroups} />
      <Card>
        <Card.Body>
          <Table striped hover responsive {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      <span {...column.getSortByToggleProps()}>
                        {column.render("Header")}
                        <span>
                          {column.sortable ? (
                            column.isSorted ? (
                              column.isSortedDesc ? (
                                <FaSortUp className="ms-2" />
                              ) : (
                                <FaSortDown className="ms-2" />
                              )
                            ) : (
                              <FaSort className="ms-2" />
                            )
                          ) : null}
                        </span>
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </Table>

          <Row>
            <Col md="6">
              <span className="mx-2">
                {t("Page")}{" "}
                <strong>
                  {pageIndex + 1} {t("of")} {pageOptions.length}
                </strong>
              </span>
              <span className="ms-3 me-2">{t("Show")}:</span>
              <Form.Select
                className="d-inline-block w-auto"
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {[10, 20, 30, 40, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </Form.Select>

              <span className="ms-3 me-2">{t("Go to page")}:</span>
              <Form.Control
                className="d-inline-block"
                type="number"
                defaultValue={pageIndex + 1}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0;
                  gotoPage(page);
                }}
                style={{ width: "75px" }}
              />
            </Col>
            <Col md="6">
              <Pagination className="float-end">
                <Pagination.First
                  onClick={() => gotoPage(0)}
                  disabled={!canPreviousPage}
                />
                <Pagination.Prev
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                />
                <Pagination.Next
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                />
                <Pagination.Last
                  onClick={() => gotoPage(pageCount - 1)}
                  disabled={!canNextPage}
                />
              </Pagination>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default AlertsTable;
