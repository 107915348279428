import React from "react";

// All pages that rely on 3rd party components (other than Bootstrap) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";

//Guards
import AuthGuard from "./components/guards/AuthGuard";

//CRM
import Viewers from "./pages/crm/Viewers";
import Subaccounts from "./pages/crm/Subaccounts";
import Customers from "./pages/crm/Customers";
import Tenants from "./pages/crm/Tenants";
import Managers from "./pages/crm/Managers";
import Admins from "./pages/crm/Admins";

//Orders
import Orders from "./pages/pages/orders/Orders";

//Reports
import ReportsPage from "./pages/pages/reports/ReportsPage";

//Support
import SupportPage from "./pages/pages/support/SupportPage";

//Watchdog Alerts
import AlertsPage from "./pages/pages/alerts/AlertsPage";

//SIMs
import Sims from "./pages/pages/sims/Sims";
import SimProfile from "./pages/pages/sims/profile/SimProfile";

// Pages
import Profile from "./pages/pages/Profile";
import Settings from "./pages/pages/settings/Settings";
import Clients from "./pages/crm/Clients";
import Invoice from "./pages/pages/Invoice";
import Tasks from "./pages/pages/Tasks";
import Chat from "./pages/pages/Chat";

// Auth
import Page500 from "./pages/auth/Page500";
import Page404 from "./pages/auth/Page404";
import Page401 from "./pages/auth/Page401";
import SignIn from "./pages/auth/SignIn";
import SignUp from "./pages/auth/SignUp";
import ResetPassword from "./pages/auth/ResetPassword";

// Protected routes
//import ProtectedPage from "./pages/protected/ProtectedPage";
import MainDashboard from "./pages/pages/dashboard/mainDashboard";
import BillingProducts from "./pages/billingproducts/BillingProducts";
import OfferForm from "./pages/offers/OfferForm";
import Offers from "./pages/offers/Offers";
import Invoices from "./pages/invoices/Invoices";
import Statements from "./pages/statements/Statements";
import VerifyMfaPage from "./pages/auth/VerifyMfa";
import Landing from "./layouts/Landing";
import SuccessPage from "./pages/pages/stripe/SuccessPage";
import ErrorPage from "./pages/pages/stripe/ErrorPage";

//import Main from "./components/Main";

const routes = [
  {
    path: "/auth",
    element: <AuthLayout />,
    children: [
      {
        path: "login",
        element: <SignIn />,
      },
      {
        path: "2fa",
        element: <VerifyMfaPage />,
      },
    ],
  },
  {
    path: "dashboard",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "default",
        element: <MainDashboard />,
      },
    ],
  },
  {
    path: "sims",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Sims />,
      },
      {
        path: "profile",
        element: <SimProfile />,
      },
    ],
  },
  {
    path: "account",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "billingproducts",
        element: <BillingProducts />,
      },
      {
        path: "invoices",
        element: <Invoices />,
      },
      {
        path: "statements",
        element: <Statements />,
      },
      {
        path: "orders",
        element: <Orders />,
      },
    ],
  },
  {
    path: "crm",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "viewers",
        element: <Viewers />,
      },
      {
        path: "users",
        element: <Subaccounts />,
      },
      {
        path: "customers",
        element: <Customers />,
      },
      {
        path: "tenants",
        element: <Tenants />,
      },
      {
        path: "managers",
        element: <Managers />,
      },
      {
        path: "admins",
        element: <Admins />,
      },
    ],
  },
  {
    path: "account",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "billingproducts",
        element: <BillingProducts />,
      },
    ],
  },
  {
    path: "catalogue",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "offers",
        element: <Offers />,
      },
      {
        path: "new",
        element: <OfferForm />,
      },
    ],
  },
  {
    path: "reports",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <ReportsPage />,
      },
    ],
  },
  {
    path: "alerts",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <AlertsPage />,
      },
    ],
  },
  {
    path: "support",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <SupportPage />,
      },
    ],
  },
  {
    path: "pages",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "profile",
        element: <Profile />,
      },
      {
        path: "settings",
        element: <Settings />,
      },
      {
        path: "clients",
        element: <Clients />,
      },
      {
        path: "invoice",
        element: <Invoice />,
      },
      {
        path: "tasks",
        element: <Tasks />,
      },
      {
        path: "chat",
        element: <Chat />,
      },
    ],
  },
  {
    path: "auth",
    element: <AuthLayout />,
    children: [
      {
        path: "sign-in",
        element: <SignIn />,
      },
      {
        path: "sign-up",
        element: <SignUp />,
      },
      {
        path: "reset-password",
        element: <ResetPassword />,
      },
      {
        path: "401",
        element: <Page401 />,
      },
      {
        path: "404",
        element: <Page404 />,
      },
      {
        path: "500",
        element: <Page500 />,
      },
    ],
  },
  {
    path: "/",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <MainDashboard />,
      },
    ],
  },
  {
    path: "/profile",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Profile />,
      },
    ],
  },
  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
  {
    path: "stripe",
    element: <Landing />,
    children: [
      {
        path: "success",
        element: <SuccessPage />,
      },
      {
        path: "error",
        element: <ErrorPage />,
      },
    ],
  },
];

export default routes;
