import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row, Fade, Button } from "react-bootstrap";
import { IoPersonAddSharp } from "react-icons/io5";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

import { useSelector } from "react-redux";

import Single from "./Single.js";
import CreateEntityModal from "./modals/CreateEntityModal.js";
import ClientsList from "./ClientsList.js";

function Clients({ title: propsTitle, clientList }) {
  const { t } = useTranslation();
  const [shownClient, setShownClient] = useState({
    id: "",
    fullName: "",
    address: "",
    city: "",
    country: "",
    postcode: "",
    telNumber: "",
    email: "",
    occupation: "",
    company: "",
    tinNumber: "",
    privilege: "",
    activities: {},
    image: "",
  });
  const [open, setOpen] = useState(false);
  const [singleIsShown, setSingleIsShown] = useState(false);
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const [privilege, setPrivilege] = useState();
  const [showCreateEntityModal, setShowCreateEntityModal] = useState(false);
  useEffect(() => {
    if (user) {
      setPrivilege(user.privilege);
    }
  }, [user, privilege]);

  useEffect(() => {
    if (clientList.length > 0) {
      setShownClient(clientList[0]);
    }
  }, [clientList]);

  if (clientList.length > 0 && privilege) {
    return (
      <React.Fragment>
        <Helmet title={propsTitle}>
          <link rel="icon" href={`data:image/x-icon;base64,${user.favicon}`} />
        </Helmet>
        <Container fluid className="p-0">
          <h1 className="h3 mb-3">{t(`${propsTitle}`)}</h1>

          <Row>
            <Col
              md="12"
              xl={open ? "8" : "12"}
              style={{ transition: "all 0.5s" }}
            >
              <ClientsList
                clientList={clientList}
                shownClient={shownClient}
                setShownClient={setShownClient}
                setOpen={setOpen}
                setSingleIsShown={setSingleIsShown}
                title={propsTitle}
                showCreateEntityModal={showCreateEntityModal}
                setShowCreateEntityModal={setShowCreateEntityModal}
              />
            </Col>
            <Fade in={singleIsShown}>
              <Col md="12" xl="4">
                <Single
                  shownClient={shownClient}
                  setOpen={setOpen}
                  setSingleIsShown={setSingleIsShown}
                  privilege={privilege}
                  title={propsTitle}
                />
              </Col>
            </Fade>
          </Row>
          <CreateEntityModal
            showCreateEntityModal={showCreateEntityModal}
            setShowCreateEntityModal={setShowCreateEntityModal}
            entityType={
              propsTitle === "Admins"
                ? "Admin"
                : propsTitle === "Tenants"
                ? "Tenant"
                : propsTitle === "Managers"
                ? "Manager"
                : propsTitle === "Customers"
                ? "Customer"
                : propsTitle === "Subaccounts"
                ? "Subaccount"
                : null
            }
          />
        </Container>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <Helmet title={propsTitle} />
        <Container fluid className="p-0">
          <h1 className="h3 mb-3">{t(`${propsTitle}`)}</h1>
          <Card>
            <Card.Body>
              <Row>
                <Col>
                  <div className="d-flex mt-2 mb-2 float-end">
                    {propsTitle !== "Admins" && propsTitle !== "Viewers" && (
                      <Button
                        style={{
                          "--dynamic-bg-color": !impersonationUser.id
                            ? user.backgroundColor
                            : impersonationUser.backgroundColor,
                          border: "none",
                        }}
                        onClick={() => setShowCreateEntityModal(true)}
                      >
                        <IoPersonAddSharp />{" "}
                        {propsTitle === "Admins"
                          ? "New Admin"
                          : propsTitle === "Tenants"
                          ? "New Tenant"
                          : propsTitle === "Managers"
                          ? "New Manager"
                          : propsTitle === "Customers"
                          ? "New Customer"
                          : propsTitle === "Subaccounts"
                          ? "New Subaccount"
                          : null}
                      </Button>
                    )}
                  </div>
                </Col>
              </Row>
              <div className="text-center">{t("No data found")}</div>
            </Card.Body>
          </Card>
          <CreateEntityModal
            showCreateEntityModal={showCreateEntityModal}
            setShowCreateEntityModal={setShowCreateEntityModal}
            entityType={
              propsTitle === "Admins"
                ? "Admin"
                : propsTitle === "Tenants"
                ? "Tenant"
                : propsTitle === "Managers"
                ? "Manager"
                : propsTitle === "Customers"
                ? "Customer"
                : propsTitle === "Subaccounts"
                ? "Subaccount"
                : null
            }
          />
        </Container>
      </React.Fragment>
    );
  }
}

export default Clients;
