import { Modal, Table, Button, Spinner } from "react-bootstrap";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { FaCheckCircle, FaExclamationCircle } from "react-icons/fa";
import { imeiLock } from "../../../../redux/slices/sims";

const ImeiLockModal = ({ showImeiLockModal, setShowImeiLockModal, sim }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [getStatus, setRequestStatus] = useState("");
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const handleClose = () => {
    setCurrentStep(1);
    setShowImeiLockModal(false);
  };

  return (
    <Modal
      show={showImeiLockModal}
      onHide={handleClose}
      centered
      backdrop="static"
    >
      <Modal.Header>IMEI Lock</Modal.Header>
      <Modal.Body>
        {currentStep === 1 && (
          <Table responsive striped>
            <thead>
              <th>IMSI</th>
              <th>ICCID</th>
            </thead>
            <tbody>
              <tr>
                <td>{sim.imsi}</td>
                <td>{sim.iccid}</td>
              </tr>
            </tbody>
          </Table>
        )}
        {currentStep === 2 && (
          <div className="text-center">
            <Spinner
              animation="border"
              variant="primary"
              className="mt-5 mb-3"
              style={{ width: "5rem", height: "5rem" }}
            />
            <h3>Waiting for completion</h3>
          </div>
        )}
        {currentStep === 3 && getStatus === "Success" && (
          <div className="text-center">
            <FaCheckCircle size={120} className="mt-3" color="#4BBF73" />
            <h3 className="mt-3">Order Complete</h3>
          </div>
        )}
        {currentStep === 3 && getStatus === "Error" && (
          <div className="text-center">
            <FaExclamationCircle size={120} className="mt-3" color="#FF9494" />
            <h3 className="mt-3">Order Error</h3>
            <p>Please contact the administrator.</p>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        {currentStep === 1 && (
          <>
            <Button
              variant="secondary"
              onClick={() => setShowImeiLockModal(false)}
              style={{
                "--dynamic-bg-color": !impersonationUser.id
                  ? user.backgroundColor
                  : impersonationUser.backgroundColor,
                "--dynamic-font-color": !impersonationUser.id
                  ? user.backgroundColor
                  : impersonationUser.backgroundColor,
                border: "none",
              }}
            >
              Cancel
            </Button>
            <Button
              style={{
                "--dynamic-bg-color": !impersonationUser.id
                  ? user.backgroundColor
                  : impersonationUser.backgroundColor,
                "--dynamic-font-color": !impersonationUser.id
                  ? user.backgroundColor
                  : impersonationUser.backgroundColor,
                border: "none",
              }}
              onClick={async () => {
                setCurrentStep(2);
                if (!impersonationUser.id) {
                  await imeiLock(user.id, sim.imsi, sim.imei)
                    .then((response) => {
                      setRequestStatus("Success");
                      setCurrentStep(3);
                    })
                    .catch((error) => {
                      setRequestStatus("Error");
                      setCurrentStep(3);
                    });
                } else {
                  await imeiLock(impersonationUser.id, sim.imsi, sim.imei)
                    .then((response) => {
                      setRequestStatus("Success");
                      setCurrentStep(3);
                    })
                    .catch((error) => {
                      setRequestStatus("Error");
                      setCurrentStep(3);
                    });
                }
              }}
            >
              Submit
            </Button>
          </>
        )}
        {currentStep === 3 && (
          <Button
            style={{
              "--dynamic-bg-color": !impersonationUser.id
                ? user.backgroundColor
                : impersonationUser.backgroundColor,
              "--dynamic-font-color": !impersonationUser.id
                ? user.backgroundColor
                : impersonationUser.backgroundColor,
              border: "none",
            }}
            onClick={async () => {
              setShowImeiLockModal(false);
            }}
          >
            Close
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default ImeiLockModal;
