import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Container } from "react-bootstrap";

import Clients from "./Clients";

import { useDispatch, useSelector } from "react-redux";
import { getSubaccounts } from "../../redux/slices/subaccounts";
import Loader from "../../components/spinners/Loader";

const Subaccounts = () => {
  const dispatch = useDispatch();
  const { subaccounts } = useSelector((state) => state.subaccounts);
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchUsers = async () => {
      if (!impersonationUser.id) {
        if (!user.id) return;
        await dispatch(getSubaccounts(user.id));
      } else {
        await dispatch(getSubaccounts(impersonationUser.id));
      }

      setIsLoading(false);
    };

    fetchUsers();
  }, [dispatch, user.id, impersonationUser.id]);

  if (isLoading) {
    return <Loader />;
  } else {
    return (
      <React.Fragment>
        <Helmet title="Subaccounts" />
        <Container fluid className="p-0">
          <Clients title="Subaccounts" clientList={subaccounts} />
        </Container>
      </React.Fragment>
    );
  }
};

export default Subaccounts;
