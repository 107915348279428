import axios from "../../utils/axios";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  billingProducts: [],
};

const billingProductsSlice = createSlice({
  name: "billingProducts",
  initialState,
  reducers: {
    setbillingProducts(state, action) {
      state.billingProducts = action.payload;
    },
  },
});

export function getBillingProducts(id) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    try {
      await axios
        .get(
          `https://dev.pos2cloud.com:5000/api/billingProducts/${id}/billingProducts`,
          {
            headers: {
              accessToken: `Bearer ${accessToken}`,
            },
          }
        )
        .then((response) => {
          dispatch(
            billingProductsSlice.actions.setbillingProducts(response.data)
          );
        });
    } catch (error) {
      if (
        error.response &&
        (error.response.status === 404 || error.response.status === 204)
      ) {
        dispatch(billingProductsSlice.actions.setbillingProducts([]));
      } else {
        dispatch(billingProductsSlice.actions.setbillingProducts([]));
      }
    }
  };
}

export const { setbillingProducts } = billingProductsSlice.actions;
export default billingProductsSlice.reducer;
