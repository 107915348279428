import React, { useEffect, useState } from "react";
import { Card, Row, Col, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const BalanceCard = () => {
  const { invoices } = useSelector((state) => state.invoices);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const { user } = useSelector((state) => state.user);
  const [balance, setBalance] = useState();
  const { t } = useTranslation();

  console.log(user);

  useEffect(() => {
    let unpaidSum = invoices.reduce((sum, record) => {
      if (record.isPaid === "no") {
        return sum + parseFloat(record.amount);
      } else {
        return sum;
      }
    }, 0);
    setBalance(unpaidSum);
  }, [invoices]);

  return (
    <Card className="flex-fill" style={{ height: "160px" }}>
      <Card.Body>
        <Row>
          <Col className="mt-0">
            <h5 className="card-title">{t("Balance")}</h5>
          </Col>
        </Row>
        <span className="h1 d-inline-block mt-3 mb-4">{balance} &euro;</span>
      </Card.Body>
      <Card.Footer>
        <Button
          style={{
            "--dynamic-bg-color": !impersonationUser.id
              ? user.backgroundColor
              : impersonationUser.backgroundColor,
            border: "none",
          }}
          disabled={balance === 0}
        >
          Make a payment
        </Button>
      </Card.Footer>
    </Card>
  );
};

export default BalanceCard;
