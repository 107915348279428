import { Formik } from "formik";
import * as Yup from "yup";
import React, { useState } from "react";
import { Modal, Form, Button, Spinner } from "react-bootstrap";
import {
  addDistributionEmail,
  getDistributionEmails,
} from "../../../redux/slices/distribution";
import { useDispatch, useSelector } from "react-redux";
import { FaCheckCircle, FaExclamationCircle } from "react-icons/fa";

const DistributionModal = ({
  showDistributionModal,
  setShowDistributionModal,
}) => {
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const dispatch = useDispatch();
  const [currentStep, setCurrentStep] = useState(1);
  const [getStatus, setRequestStatus] = useState("");

  return (
    <Modal show={showDistributionModal} centered backdrop="static">
      <Modal.Header>New Distribution Email</Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={{
            name: "",
            category: "",
            address: "",
            submit: false,
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().required("Required"),
            category: Yup.string().required("Required"),
            address: Yup.string()
              .email("Invalid email address")
              .required("Required"),
          })}
          onSubmit={async (
            values,
            { setErrors, setStatus, setSubmitting, setValues }
          ) => {
            setCurrentStep(2);
            await addDistributionEmail(
              user.id,
              values.name,
              values.category,
              values.address
            )
              .then((response) => {
                setRequestStatus("Success");
                setCurrentStep(3);
              })
              .catch((error) => {
                setRequestStatus("Error");
                setCurrentStep(3);
              });
          }}
        >
          {({
            errors,
            handleBlur,
            setFieldTouched,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            setValues,
            validateForm,
            setFieldValue,
          }) => {
            const nextStep = (
              currentStep,
              validateForm,
              values,
              setFieldTouched
            ) => {
              if (currentStep === 1) {
                validateForm().then((errors) => {
                  if (Object.keys(errors).length === 0) {
                    handleSubmit();
                  } else {
                    Object.keys(values).forEach((field) => {
                      setFieldTouched(field);
                    });
                  }
                });
              }
            };
            if (currentStep === 1) {
              return (
                <Form onSubmit={handleSubmit}>
                  <Form.Group className="mb-3">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.name && !!errors.name}
                    />
                    {!!touched.name && (
                      <Form.Control.Feedback type="invalid">
                        {errors.name}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Category</Form.Label>
                    <Form.Select
                      name="category"
                      value={values.category}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.category && !!errors.category}
                    >
                      <option value="">Select Category</option>
                      <option value="watchdog">Watchdog</option>
                      <option value="invoice">Invoice</option>
                    </Form.Select>
                    {!!touched.category && (
                      <Form.Control.Feedback type="invalid">
                        {errors.category}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Address</Form.Label>
                    <Form.Control
                      type="email"
                      name="address"
                      value={values.address}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.address && !!errors.address}
                    />
                    {!!touched.address && (
                      <Form.Control.Feedback type="invalid">
                        {errors.address}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                  <Modal.Footer>
                    <Button
                      type="submit"
                      style={{
                        "--dynamic-bg-color": !impersonationUser.id
                          ? user.backgroundColor
                          : impersonationUser.backgroundColor,
                        border: "none",
                      }}
                      onClick={(e) => {
                        nextStep(
                          currentStep,
                          validateForm,
                          values,
                          setFieldTouched
                        );
                      }}
                    >
                      Submit
                    </Button>
                    <Button
                      className="me-2"
                      onClick={() => {
                        setValues("");
                        setShowDistributionModal(false);
                      }}
                      style={{
                        "--dynamic-bg-color": !impersonationUser.id
                          ? user.backgroundColor
                          : impersonationUser.backgroundColor,
                        border: "none",
                      }}
                    >
                      Cancel
                    </Button>
                  </Modal.Footer>
                </Form>
              );
            } else if (currentStep === 2) {
              return (
                <div className="text-center">
                  <Spinner
                    animation="border"
                    variant="primary"
                    className="mt-5 mb-3"
                    style={{ width: "5rem", height: "5rem" }}
                  />
                  <h3>Waiting for completion</h3>
                </div>
              );
            } else if (currentStep === 3) {
              if (getStatus === "Success") {
                return (
                  <>
                    <div className="text-center">
                      <FaCheckCircle
                        size={120}
                        className="mt-3"
                        color="#4BBF73"
                      />
                      <h3 className="mt-3">Order Complete</h3>
                    </div>
                    <Modal.Footer>
                      <Button
                        style={{
                          "--dynamic-bg-color": !impersonationUser.id
                            ? user.backgroundColor
                            : impersonationUser.backgroundColor,
                          border: "none",
                        }}
                        onClick={async () => {
                          await dispatch(getDistributionEmails(user.id));
                          setShowDistributionModal(false);
                          setValues("");
                          setCurrentStep(1);
                        }}
                      >
                        Close
                      </Button>
                    </Modal.Footer>
                  </>
                );
              } else if (getStatus === "Error") {
                return (
                  <>
                    <div className="text-center">
                      <FaExclamationCircle
                        size={120}
                        className="mt-3"
                        color="#FF9494"
                      />
                      <h3 className="mt-3">Order Error</h3>
                      <p>Please contact the administrator.</p>
                    </div>
                    <Modal.Footer>
                      <Button
                        style={{
                          "--dynamic-bg-color": !impersonationUser.id
                            ? user.backgroundColor
                            : impersonationUser.backgroundColor,
                          border: "none",
                        }}
                        onClick={async () => {
                          await dispatch(getDistributionEmails(user.id));
                          setShowDistributionModal(false);
                          setValues("");
                          setCurrentStep(1);
                        }}
                      >
                        Close
                      </Button>
                    </Modal.Footer>
                  </>
                );
              }
            }
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default DistributionModal;
