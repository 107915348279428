import { Card, Spinner, Image } from "react-bootstrap";
import { useSelector } from "react-redux";

const Loader = () => {
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);

  return (
    <Card
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <Card.Body
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <div
          className="spinner-container"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "60px",
          }}
        >
          <Image
            src={`data:${
              !impersonationUser.id
                ? user.whitelabelMimeType
                : impersonationUser.whitelabelMimeType
            };base64,${
              !impersonationUser.id
                ? user.whitelabelImage
                : impersonationUser.whitelabelImage
            }`}
            style={{ maxHeight: "300px", width: "auto" }}
            alt="Spinner"
          />
          <Spinner
            animation="border"
            role="status"
            style={{
              "--dynamic-spinner-color": !impersonationUser.id
                ? user.backgroundColor
                : impersonationUser.backgroundColor,
            }}
          />
        </div>
      </Card.Body>
    </Card>
  );
};

export default Loader;
