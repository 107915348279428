import React, { useState, useEffect, useRef } from "react";
import { Form } from "react-bootstrap";

// This is a custom filter UI for selecting
// a unique option from a list
const SelectColumnFilterInput = ({
  column: { filterValue, setFilter, preFilteredRows, id },
  filterIsShown,
  setFilterIsShown,
}) => {
  const filterRef = useRef(null);

  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  function useOutsideAlerter(ref, setFilterIsShown) {
    useEffect(() => {
      function handleClickOutside(event, setFilterIsShown) {
        if (ref.current && !ref.current.contains(event.target)) {
          setFilterIsShown(false);
        }
      }

      document.addEventListener("click", function (event) {
        handleClickOutside(event, setFilterIsShown);
      });
      return () => {
        document.removeEventListener("click", handleClickOutside);
      };
    }, [ref, setFilterIsShown]);
  }

  useOutsideAlerter(filterRef, setFilterIsShown);

  // Render a multi-select box
  return (
    <Form.Group className="mt-1">
      <Form.Select
        className="w-75"
        value={filterValue}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
      >
        <option value="">All</option>
        {options.map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </Form.Select>
    </Form.Group>
  );
};

export default function SelectColumnFilter({
  column: { filterValue, preFilteredRows, setFilter, id },
}) {
  const [filterIsShown, setFilterIsShown] = useState(false);
  return (
    <SelectColumnFilterInput
      column={{ filterValue, preFilteredRows, setFilter, id }}
      filterIsShown={filterIsShown}
      setFilterIsShown={setFilterIsShown}
    />
  );
}
