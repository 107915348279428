import axios from "../../utils/axios";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  viewers: [],
};

const viewersSlice = createSlice({
  name: "viewers",
  initialState,
  reducers: {
    setViewers(state, action) {
      state.viewers = action.payload;
    },
    updateFullName(state, action) {
      const { id, fullName } = action.payload;
      const updatedViewers = state.viewers.map((viewer) => {
        if (viewer.id === id) {
          return {
            ...viewer,
            fullName: fullName,
          };
        }
        return viewer;
      });
      return {
        ...state,
        viewers: updatedViewers,
      };
    },
    updateAddress(state, action) {
      const { id, address } = action.payload;
      const updatedViewers = state.viewers.map((viewer) => {
        if (viewer.id === id) {
          return {
            ...viewer,
            address: address,
          };
        }
        return viewer;
      });
      return {
        ...state,
        viewers: updatedViewers,
      };
    },
    updateCity(state, action) {
      const { id, city } = action.payload;
      const updatedViewers = state.viewers.map((viewer) => {
        if (viewer.id === id) {
          return {
            ...viewer,
            city: city,
          };
        }
        return viewer;
      });
      return {
        ...state,
        viewers: updatedViewers,
      };
    },
    updateCountry(state, action) {
      const { id, country } = action.payload;
      const updatedViewers = state.viewers.map((viewer) => {
        if (viewer.id === id) {
          return {
            ...viewer,
            country: country,
          };
        }
        return viewer;
      });
      return {
        ...state,
        viewers: updatedViewers,
      };
    },
    updatePostcode(state, action) {
      const { id, postcode } = action.payload;
      const updatedViewers = state.viewers.map((viewer) => {
        if (viewer.id === id) {
          return {
            ...viewer,
            postcode: postcode,
          };
        }
        return viewer;
      });
      return {
        ...state,
        viewers: updatedViewers,
      };
    },
    updateTelephone(state, action) {
      const { id, telephone } = action.payload;
      const updatedViewers = state.viewers.map((viewer) => {
        if (viewer.id === id) {
          return {
            ...viewer,
            telephone: telephone,
          };
        }
        return viewer;
      });
      return {
        ...state,
        viewers: updatedViewers,
      };
    },
    updateMobile(state, action) {
      const { id, mobile } = action.payload;
      const updatedViewers = state.viewers.map((viewer) => {
        if (viewer.id === id) {
          return {
            ...viewer,
            mobile: mobile,
          };
        }
        return viewer;
      });
      return {
        ...state,
        viewers: updatedViewers,
      };
    },
    updateEmail(state, action) {
      const { id, email } = action.payload;
      const updatedViewers = state.viewers.map((viewer) => {
        if (viewer.id === id) {
          return {
            ...viewer,
            email: email,
          };
        }
        return viewer;
      });
      return {
        ...state,
        viewers: updatedViewers,
      };
    },
    updateOccupation(state, action) {
      const { id, occupation } = action.payload;
      const updatedViewers = state.viewers.map((viewer) => {
        if (viewer.id === id) {
          return {
            ...viewer,
            occupation: occupation,
          };
        }
        return viewer;
      });
      return {
        ...state,
        viewers: updatedViewers,
      };
    },
    updateCompany(state, action) {
      const { id, company } = action.payload;
      const updatedViewers = state.viewers.map((viewer) => {
        if (viewer.id === id) {
          return {
            ...viewer,
            company: company,
          };
        }
        return viewer;
      });
      return {
        ...state,
        viewers: updatedViewers,
      };
    },
    updateTIN(state, action) {
      const { id, tin } = action.payload;
      const updatedViewers = state.viewers.map((viewer) => {
        if (viewer.id === id) {
          return {
            ...viewer,
            tinNumber: tin,
          };
        }
        return viewer;
      });
      return {
        ...state,
        viewers: updatedViewers,
      };
    },
    updatePrivilege(state, action) {
      const { id, privilege } = action.payload;
      const updatedViewers = state.viewers.map((viewer) => {
        if (viewer.id === id) {
          return {
            ...viewer,
            privilege: privilege,
          };
        }
        return viewer;
      });
      return {
        ...state,
        viewers: updatedViewers,
      };
    },
    addViewer: (state, action) => {
      const newViewer = action.payload;
      return {
        ...state,
        viewers: [...state.viewers, newViewer],
      };
    },
    removeViewer: (state, action) => {
      const idToRemove = action.payload;
      const newViewers = state.viewers.filter(
        (viewer) => viewer.id !== idToRemove
      );
      return {
        ...state,
        viewers: newViewers,
      };
    },
  },
});

export function getViewers(id) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    try {
      const response = await axios.get(
        "https://dev.pos2cloud.com:5000/api/viewers/" + id + "/getViewers",
        {
          headers: {
            accessToken: "Bearer " + accessToken,
          },
        }
      );
      dispatch(viewersSlice.actions.setViewers(response.data));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        dispatch(viewersSlice.actions.setViewers([]));
      }
    }
  };
}

export function updateViewerName(id, fullName) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("fullName", fullName);
    try {
      await axios.put(
        "https://dev.pos2cloud.com:5000/api/systemusers/" +
          id +
          "/update/fullName",
        formData,
        {
          headers: {
            accessToken: "Bearer " + accessToken,
          },
        }
      );
      dispatch(viewersSlice.actions.updateFullName({ id, fullName }));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.log("Viewer not found");
      } else if (error.status === 500) {
        console.log("Internal Server Error");
      }
    }
  };
}

export function updateViewerAddress(id, address) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("address", address);
    try {
      await axios.put(
        "https://dev.pos2cloud.com:5000/api/systemusers/" +
          id +
          "/update/address",
        formData,
        {
          headers: {
            accessToken: "Bearer " + accessToken,
          },
        }
      );
      dispatch(viewersSlice.actions.updateAddress({ id, address }));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.log("Viewer not found");
      } else if (error.status === 500) {
        console.log("Internal Server Error");
      }
    }
  };
}

export function updateViewerCity(id, city) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("city", city);
    try {
      await axios.put(
        "https://dev.pos2cloud.com:5000/api/systemusers/" + id + "/update/city",
        formData,
        {
          headers: {
            accessToken: "Bearer " + accessToken,
          },
        }
      );
      dispatch(viewersSlice.actions.updateCity({ id, city }));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.log("Viewer not found");
      } else if (error.status === 500) {
        console.log("Internal Server Error");
      }
    }
  };
}

export function updateViewerCountry(id, country) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("country", country);
    try {
      await axios.put(
        "https://dev.pos2cloud.com:5000/api/systemusers/" +
          id +
          "/update/country",
        formData,
        {
          headers: {
            accessToken: "Bearer " + accessToken,
          },
        }
      );
      dispatch(viewersSlice.actions.updateCountry({ id, country }));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.log("Viewer not found");
      } else if (error.status === 500) {
        console.log("Internal Server Error");
      }
    }
  };
}

export function updateViewerPostcode(id, postcode) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("postcode", postcode);
    try {
      await axios.put(
        "https://dev.pos2cloud.com:5000/api/systemusers/" +
          id +
          "/update/postcode",
        formData,
        {
          headers: {
            accessToken: "Bearer " + accessToken,
          },
        }
      );
      dispatch(viewersSlice.actions.updatePostcode({ id, postcode }));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.log("Viewer not found");
      } else if (error.status === 500) {
        console.log("Internal Server Error");
      }
    }
  };
}

export function updateViewerTelephone(id, telephone) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("telephone", telephone);
    try {
      await axios.put(
        "https://dev.pos2cloud.com:5000/api/systemusers/" +
          id +
          "/update/telephone",
        formData,
        {
          headers: {
            accessToken: "Bearer " + accessToken,
          },
        }
      );
      dispatch(viewersSlice.actions.updateTelephone({ id, telephone }));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.log("Viewer not found");
      } else if (error.status === 500) {
        console.log("Internal Server Error");
      }
    }
  };
}

export function updateViewerMobile(id, mobile) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("mobile", mobile);
    try {
      await axios.put(
        "https://dev.pos2cloud.com:5000/api/systemusers/" +
          id +
          "/update/mobile",
        formData,
        {
          headers: {
            accessToken: "Bearer " + accessToken,
          },
        }
      );
      dispatch(viewersSlice.actions.updateMobile({ id, mobile }));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.log("Viewer not found");
      } else if (error.status === 500) {
        console.log("Internal Server Error");
      }
    }
  };
}

export function updateViewerEmail(id, email) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("email", email);
    try {
      await axios.put(
        "https://dev.pos2cloud.com:5000/api/systemusers/" +
          id +
          "/update/email",
        formData,
        {
          headers: {
            accessToken: "Bearer " + accessToken,
          },
        }
      );
      dispatch(viewersSlice.actions.updateEmail({ id, email }));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.log("Viewer not found");
      } else if (error.status === 500) {
        console.log("Internal Server Error");
      }
    }
  };
}

export function updateViewerOccupation(id, occupation) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("occupation", occupation);
    try {
      await axios.put(
        "https://dev.pos2cloud.com:5000/api/systemusers/" +
          id +
          "/update/occupation",
        formData,
        {
          headers: {
            accessToken: "Bearer " + accessToken,
          },
        }
      );
      dispatch(viewersSlice.actions.updateOccupation({ id, occupation }));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.log("Viewer not found");
      } else if (error.status === 500) {
        console.log("Internal Server Error");
      }
    }
  };
}

export function updateViewerCompany(id, company) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("company", company);
    try {
      await axios.put(
        "https://dev.pos2cloud.com:5000/api/systemusers/" +
          id +
          "/update/company",
        formData,
        {
          headers: {
            accessToken: "Bearer " + accessToken,
          },
        }
      );
      dispatch(viewersSlice.actions.updateCompany({ id, company }));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.log("Viewer not found");
      } else if (error.status === 500) {
        console.log("Internal Server Error");
      }
    }
  };
}

export function updateViewerTIN(id, tin) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("tin", tin);
    try {
      await axios.put(
        "https://dev.pos2cloud.com:5000/api/systemusers/" + id + "/update/tin",
        formData,
        {
          headers: {
            accessToken: "Bearer " + accessToken,
          },
        }
      );
      dispatch(viewersSlice.actions.updateTIN({ id, tin }));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.log("Viewer not found");
      } else if (error.status === 500) {
        console.log("Internal Server Error");
      }
    }
  };
}

export function updateViewerPrivilege(
  userId,
  clientId,
  privilege,
  upperEntityId
) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("entityId", clientId);
    formData.append("privilege", privilege);
    if (privilege !== "ADMIN" && privilege !== "VIEWER") {
      formData.append("upperEntityId", upperEntityId);
    } else {
      formData.append("upperEntityId", -1);
    }
    try {
      await axios.put(
        "https://dev.pos2cloud.com:5000/api/systemusers/" +
          userId +
          "/update/privilege",
        formData,
        {
          headers: {
            accessToken: "Bearer " + accessToken,
          },
        }
      );
      dispatch(viewersSlice.actions.updatePrivilege({ clientId, privilege }));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.log("Viewer not found");
      } else if (error.status === 500) {
        console.log("Internal Server Error");
      }
    }
  };
}

export function addNewViewer(user) {
  return (dispatch) => {
    dispatch(viewersSlice.actions.addViewer({ user }));
  };
}

export function removeExistingViewer(id) {
  return (dispatch) => {
    dispatch(viewersSlice.actions.removeViewer(id));
  };
}

export const {
  setViewers,
  updateFullName,
  updateAddress,
  updateCity,
  updateCountry,
  updatePostcode,
  updateTelephone,
  updateEmail,
  updateOccupation,
  updateCompany,
  updateTIN,
  updatePrivilege,
  addViewer,
  removeViewer,
} = viewersSlice.actions;
export default viewersSlice.reducer;
