import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, ProgressBar, Button, Table, Spinner } from "react-bootstrap";
import { FaCheckCircle, FaExclamationCircle } from "react-icons/fa";
import { useState } from "react";
import { getSims, removeAllSimWatchdogs } from "../../../../redux/slices/sims";

const RemoveAllWatchdogsModal = ({
  openRemoveWatchdogModal,
  setOpenRemoveWatchdogModal,
  selectedSims,
  page,
  pageSize,
  filters,
}) => {
  const handleClose = () => setOpenRemoveWatchdogModal(false);
  const dispatch = useDispatch();
  const [now, setNow] = useState(30);
  const [currentStep, setCurrentStep] = useState(1);
  const [status, setStatus] = useState("");
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);

  useEffect(() => {
    if (!openRemoveWatchdogModal) {
      setCurrentStep(1);
      setNow(50);
    }
  }, [openRemoveWatchdogModal]);

  if (selectedSims.length > 0) {
    return (
      <Modal
        show={openRemoveWatchdogModal}
        onHide={handleClose}
        backdrop="static"
        centered
      >
        <Modal.Header>Remove Watchdog</Modal.Header>
        <Modal.Body>
          <div className="progress-tabs">
            <ProgressBar
              className="mb-3"
              striped
              animated
              variant="primary"
              now={now}
            />
          </div>
          {currentStep === 1 && (
            <Table>
              <thead>
                <th>IMSI</th>
                <th>ICCID</th>
              </thead>
              <tbody>
                {selectedSims.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item.imsi}</td>
                      <td>{item.iccid}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          )}
          {currentStep === 2 && (
            <div className="text-center">
              <Spinner
                animation="border"
                variant="primary"
                className="mt-5 mb-3"
                style={{ width: "5rem", height: "5rem" }}
              />
              <h3>Waiting for completion</h3>
            </div>
          )}
          {currentStep === 3 && (
            <div className="text-center">
              {status === "Success" && (
                <FaCheckCircle size={120} className="mt-3" color="#4BBF73" />
              )}
              {status === "Error" && (
                <div className="text-center">
                  <FaExclamationCircle
                    size={120}
                    className="mt-3"
                    color="#FF9494"
                  />
                  <h3 className="mt-3">Order Error</h3>
                  <p>Please contact the administrator.</p>
                </div>
              )}
              <h3 className="mt-3">Order Complete</h3>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          {currentStep === 1 && (
            <>
              <Button
                onClick={() => {
                  setOpenRemoveWatchdogModal(false);
                  setCurrentStep(1);
                  setNow(50);
                }}
                style={{
                  "--dynamic-bg-color": !impersonationUser.id
                    ? user.backgroundColor
                    : impersonationUser.backgroundColor,
                  "--dynamic-font-color": !impersonationUser.id
                    ? user.backgroundColor
                    : impersonationUser.backgroundColor,
                  border: "none",
                }}
              >
                Close
              </Button>
              <Button
                onClick={async () => {
                  setCurrentStep(2);
                  setNow(90);
                  if (!impersonationUser.id) {
                    await removeAllSimWatchdogs(
                      user.id,
                      selectedSims.map((sim) => sim.imsi)
                    )
                      .then(async (response) => {
                        setStatus("Success");
                        setCurrentStep(3);
                        setNow(100);
                      })
                      .catch((error) => {
                        setStatus("Error");
                        setCurrentStep(3);
                        setNow(100);
                      });
                  } else {
                    await removeAllSimWatchdogs(
                      impersonationUser.id,
                      selectedSims.map((sim) => sim.imsi)
                    )
                      .then(async (response) => {
                        setStatus("Success");
                        setCurrentStep(3);
                        setNow(100);
                      })
                      .catch((error) => {
                        setStatus("Error");
                        setCurrentStep(3);
                        setNow(100);
                      });
                  }
                }}
                style={{
                  "--dynamic-bg-color": !impersonationUser.id
                    ? user.backgroundColor
                    : impersonationUser.backgroundColor,
                  "--dynamic-font-color": !impersonationUser.id
                    ? user.backgroundColor
                    : impersonationUser.backgroundColor,
                  border: "none",
                }}
              >
                Submit
              </Button>
            </>
          )}
          {currentStep === 3 && (
            <Button
              onClick={async () => {
                setOpenRemoveWatchdogModal(false);
                await dispatch(getSims(user.id, page + 1, pageSize, filters));
              }}
              style={{
                "--dynamic-bg-color": !impersonationUser.id
                  ? user.backgroundColor
                  : impersonationUser.backgroundColor,
                "--dynamic-font-color": !impersonationUser.id
                  ? user.backgroundColor
                  : impersonationUser.backgroundColor,
                border: "none",
              }}
            >
              Close
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    );
  } else {
    return (
      <Modal show={openRemoveWatchdogModal}>
        <Modal.Header>Remove Watchdog</Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <Spinner animation="border" variant="primary" className="me-2" />
          </div>
        </Modal.Body>
      </Modal>
    );
  }
};

export default RemoveAllWatchdogsModal;
